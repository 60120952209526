import React, { Component, Fragment } from "react";
import { Stage, Layer, Group, Rect, Arc, Text } from "react-konva";
import betchipsnd from "././../../../../../assets/sounds/betoncard.mp3";
import betrussiansnd from "././../../../../../assets/sounds/bet.mp3";
import pokerchipsnd from "././../../../../../assets/sounds/pokerchip_snd.mp3";
import plyerwin_snd from "././../../../../../assets/sounds/successNew.mp3";
import cardsdropping_snd from "././../../../../../assets/sounds/carddroping.mp3";
import cardsshuffle from "././../../../../../assets/sounds/shuffle-cards.mp3";
import reaveldealer_cards from "././../../../../../assets/sounds/dealercards_reavel.mp3";
import clear_snd from "././../../../../../assets/sounds/clear.mp3";
import selectCard from "../../../../../assets/sounds/selectcard.mp3";
import unselectCard from "../../../../../assets/sounds/unselectcard.mp3";
import playerWin from "../../../../../assets/sounds/successNew.mp3"
import playerLose from "../../../../../assets/sounds/successNew.mp3"
// import playerLose from "../../../../../assets/sounds/duplose.mp3"
import Cards from "../TropicalPoker/UI/Tropicalcards";
import Rutablewindows from "../../../../../assets/games/russianPoker/russiantblewindows.png";

import chip_10 from "../../../../../assets/games/russianPoker/black.png";
import chip_25 from "../../../../../assets/games/russianPoker/Purple chip.svg";
import chip_50 from "../../../../../assets/games/russianPoker/blue.png";
import chip_100 from "../../../../../assets/games/russianPoker/red.png";
import chip_500 from "../../../../../assets/games/russianPoker/yellow.png";
import chip_1000 from "../../../../../assets/games/russianPoker/Whitechip.svg"
import "./TropicalPoker.css";
import russianbg from "../../../../../assets/games/russianPoker/BG.png";
import mutesnds from "../../../../../assets/games/russianPoker/mute_snds.png"
// import pokertableruss from "../../../../../assets/games/russianPoker/AssetTable.png";
// import pokertablelandsp from "../../../../../assets/games/russianPoker/landscapetable.png";
import landscapebg from "../../../../../assets/games/russianPoker/landspbg.png";
import porttraitejson from "./TP_portraite&landscape.json";
import balanceicon from "../../../../../assets/games/russianPoker/Balance icon.png";
import balanceiconwindows from "../../../../../assets/games/russianPoker/balancewindows.png";
import exitrussian_popup from "../../../../../assets/games/russianPoker/logOut.png";
// import exitrussian from "../../../../../assets/games/russianPoker/logOut.png";
import exitrussian from "../../../../../assets/games/russianPoker/logOut.png";
import popupExit from "../../../../../assets/common_imgs/exit_btn.png"
// import exitrussian from "../../../../../assets/games/russianPoker/Exit.png";
import beticonTP from "../../../../../assets/games/russianPoker/Bet icon.png";
import beticonwindows from "../../../../../assets/games/russianPoker/betwindows.png";
import winnericn from "../../../../../assets/games/russianPoker/winnericon.png";
import winnericnwindows from "../../../../../assets/games/russianPoker/winnericonwindows.png";
import informicon from "../../../../../assets/games/tropicalstudpoker/i_1.svg";
// import informicon from "../../../../../assets/games/russianPoker/i.png";
import russiantitle from "../../../../../assets/games/russianPoker/russiantitle.png";
import russianlabelimg from "../../../../../assets/games/russianPoker/russianlabel.png";
import optionbtn from "../../../../../assets/games/russianPoker/Option.png";
// import optionbtnwindows from "../../../../../assets/games/tropicalstudpoker/menu.svg";
import optionbtnwindows from "../../../../../assets/games/russianPoker/optionbtnwindows.png";
import volumeimg from "../../../../../assets/games/tropicalstudpoker/volume.svg";
import russianbetbtnup from "../../../../../assets/games/russianPoker/up.png";
import russianbetbtright from "../../../../../assets/games/tropicalstudpoker/TP_btn_backn.png";
import russianbetbtnleft from "../../../../../assets/games/tropicalstudpoker/TP_btn_backn.png";
import russianbetbtndown from "../../../../../assets/games/tropicalstudpoker/TP_btn_backn.png";
import russianbetcle from "../../../../../assets/games/tropicalstudpoker/TP_btn_backn.png";
import betincerementdecrement from "../../../../../assets/games/tropicalstudpoker/betincreamenTP.png";
// import betincerementdecrement1 from "../../../../../assets/games/russianPoker/Balance icon.png"
import betchipsRussian from "../../../../../assets/games/russianPoker/black.png";
import Tropicalbgwindows from "../../../../../assets/games/tropicalstudpoker/Tropical_BG_img.png";
// import Drawbtn from "../../../../../assets/games/russianPoker/drawbtn.png";
import Drawbtn from "../../../../../assets/games/tropicalstudpoker/drawbtnwindows.png";
import Drawbtnwindows from "../../../../../assets/games/tropicalstudpoker/drawbtnwindows.png";
import bnsactiveTP from "../../../../../assets/games/tropicalstudpoker/Active_TP.svg";
import Buybtn from "../../../../../assets/games/russianPoker/buybtn.png";
import Buybtnwindows from "../../../../../assets/games/russianPoker/buybtnwindows.png";
import Betbtn from "../../../../../assets/games/tropicalstudpoker/betbtnWindow.png";
// import Betbtnwindows from "../../../../../assets/games/russianPoker/betbtnwindows.png"
// import Foldbn from "../../../../../assets/games/russianPoker/foldbtn.png";
import Foldbn from "../../../../../assets/games/tropicalstudpoker/foldWindow.png";
import Foldbnwindows from "../../../../../assets/games/tropicalstudpoker/foldWindow.png";
import useridlmg from "../../../../../assets/games/russianPoker/userid.png";
import Russiandeck from "../../../../../assets/games/tropicalstudpoker/DeckTP.png";
import gsap from "gsap";
import DealerCardsContainer from "./UI/TropicalDealerCardsContainer";
import resultCards from "../TropicalPoker/UI/TropicalresultCards";
import Title from "../RussianPoker/Russiantitle";
import { connect } from "react-redux";

import { Howl } from "howler";

const Russianpokerassets = [
  chip_10,
  chip_25,
  chip_50,
  chip_100,
  chip_500,
  chip_1000,
  russianbg,
  mutesnds,
  landscapebg,
  balanceicon,
  balanceiconwindows,
  exitrussian_popup,
  popupExit,
  beticonTP,
  beticonwindows,
  winnericn,
  winnericnwindows,
  informicon,
  russiantitle,
  russianlabelimg,
  optionbtn,
  optionbtnwindows,
  volumeimg,
  russianbetbtnup,
  russianbetbtright,
  russianbetbtnleft,
  russianbetbtndown,
  russianbetcle,
  betincerementdecrement,
  betchipsRussian,
  Tropicalbgwindows,
  Drawbtn,
  Drawbtnwindows,
  bnsactiveTP,
  Buybtn,
  Buybtnwindows,
  Betbtn,
  Foldbn,
  Foldbnwindows,
  useridlmg,
];

// npm i react-konva@17.0.1-3
class TropicalPoker extends Component {
  constructor(props) {
    super(props);
    this.cardsRef = React.createRef();
    this.dealerCardsContainer = React.createRef();

    // this.pokerchipsnd = new Audio(pokerchipsnd);

    // antebetresult and shuffled result : ---------
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    // replaceCards (userSelectedCards) : ---------
    this.replaceCards = [];
    // serverResponse (after draw cards) : ---------
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    // clickOnBuyCard : ---------
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    // DEALER CARDS
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    // CONFIRM_DEALER_CARD : -----------------
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.insuranceActive = false;
    this.balnce = this.props.balance;
    this.antebet = 0;
    this.betsamount = 0;
    this.roundNumber = 0;
    this.betAmount = 0;

    this.state = {
      isPlayingChip: false,
      playersound12: false,
      isPlayingBet: false,
      sound12: null,
      insurenceMinbetAmt: 0,
      isplayerinfoupdate: false,
      bonusactive: 0,
      insurenceMaxbetAmt: 0,
      playerrankinghand: [],
      myOrient: "land",
      betshigh_balnce: null,
      keyboardhandle: false,
      dealenable: false,
      disClearBtn: 1,
      disDrawbtn: 1,
      disBuybtn: 1,
      cmfrmdealeraction: 0,
      cmfrmdealeractionNO: 0,
      FoldbtnTP: 1,
      handId: 0,
      Yesbtn: 1,
      Nobtn: 1,
      betBtnValue: 0,
      disBetBtn: 1,
      playerhand_status: "",
      isoptionmenu: false,
      ShowGamerules: false,
      buttontext: "",
      drawbuttonhandle: false,
      isbonuspattable: false,
      ShowLimits: false,
      isactiveoptionsmenu: false,
      ishistorytable: false,
      balance_rsp: 0,
      updateBet: 0,
      betdeductions: 0,
      antebetcircle: false,
      betchipincrementbtn: false,
      multibuttonsebledsble: false,
      mutesounds: false,
      totalbet: 0,
      bonusbetamount: 0,
      previousBonusbetamount: 0,
      count: 1,
      insurencetbet: 0,
      pokerranking: "",
      pokerrankingdeler: "",
      storebetchips: [],
      storeantebetchips: [],
      storebonuschips: [],
      storeinsurancechips: [],
      prevStatestoreantebetchips: [],
      prevStatestorebonuschips: [],
      totalwinRussian: 0,
      betdecreamentbtn: false,
      idincrement: 0,
      bsbtn: true,
      anteBetAmount: 0,
      previousAnteBetAmount: 0,
      disablemultibutton: false,
      insurencebtn: false,
      isMobilePortraitLndsp: null,
      betbutton: "DEAL",
      activeScreen: null,
      // //GroupProperties
      heightOfLayer: null,
      widthOfLayer: null,
      dealerDistanceFromX: null,
      playerDistanceFromX: null,
      dealerDistanceFromY: null,
      PlayerDistanceFromY: null,
      // SpriteSheetProps
      revealCard: false,
      infoText: this.props.infoText,
      buydrawCheck: false,
      insurencetbet: 0,
      lowBalaceErrorInfo: "",
      ShowHistory: false,
      disableBuyButton: false,

      bnsactvglwcss: null,
      betactvglwcss: null,
      betbuttoncss: null,
      betbtntextcss: null,
      foldbuttoncss: null,
      foldbtntextcss: null,
      buybtncss: null,
      buybtntxtcss: null,
      drawbtncss: null,
      drawbtntxtcss: null,
      betchipstoantebetcss: null,
      betchipstobonusbetcss: null,
      chipsstoredcontainercss: null,
      antebetpdvcss: null,
      antechpimgcss: null,
      antespntxtcss: null,
      bonusdivcss: null,
      bonusimgcss: null,
      bonuspntxtcss: null,
      betbtntxtcss: null,
      betchiptobetbtncss: null,
      betchiptobetbtncss: null,
      betbtntxtcss: null,
      drawchipcss: null,
      drawchiptextcss: null,
      insurancedivcss: null,
      insurancechipcss: null,
      insurancetxtcss: null,
      dealerhandcss: null,
      playerhandcss: null,
      mobileAntiMaskCovercss: null,
      betpannelrussiancovercss: null,
      insurenceactivecss: null,
      rankinginfodelercss: null,
      inforussiancss: null,
      drawnbtnshows: 0,
      drawbtnnewClass: false,
      drawChipXd: null,
      drawChipYd: null,
      drawChipRetrunXd: null,
      drawChipRetrunYd: null,
      PayouyBetBounusData: [],
      PayoutTableToggle: false,
      PayOutselectbtn: "BetPayout",
      Winnindanimation: false,
      AnteWinCHanimation: false,
      DelarAnteWinProLandAN: null
    };

    this.chipref = React.createRef();
    this.chiprefee = React.createRef();
    this.chipdrawref = React.createRef();
    this.chipbnsref = React.createRef();
    this.chipvalue = React.createRef();
    this.chipsarray = React.createRef();
    this.chipbetref = React.createRef();
    this.no = React.createRef();
    this.cardsRef = React.createRef();
    // this.dealerCardsContainer = React.createRef();
    this.buyDealerCardDrawn = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.isMobile = false;
    this.dealenable = false;
    this.resetAnimationChips = false;
    this.playerWinBonus = false;
    this.wanttobuydelercrd = null;

    this.drawReplaceCards = false;

    this.betchipactivated = false;
    this.buydrawchipactivated = false;
    this.ConfirmbuydealercardactivePopup = false;
    this.resetPyrWnCpsAmn = false;

    this.clearTimingLeft = [
      this.clertime1, this.clertime2, this.clertime3, this.clertime4, this.clertime5, this.clertime6, this.clertime7, this.clertime8, this.clertime9,
      this.clertime11, this.clertime12, this.clertime13, this.clertime14, this.clertime15, this.clertime16, this.clertime17, this.clertime18, this.clertime19,
      this.clertime21, this.clertime22, this.clertime23, this.clertime24, this.clertime25, this.clertime26, this.clertime27, this.clertime28, this.clertime29,
      this.clertime31, this.clertime32, this.clertime33, this.clertime34, this.clertime35, this.clertime36, this.clertime37, this.clertime38, this.clertime39,
      this.clertime41, this.clertime42, this.clertime43, this.clertime44, this.clertime45, this.clertime46, this.clertime47, this.clertime48, this.clertime49,
      this.clertime50, this.clertime51, this.clertime52, this.clertime53, this.clertime54, this.clertime55, this.clertime56
    ]
    this.GameRules = [
      { "name": "The game is played using one 52-card deck. The game uses standard poker rules for ranking and comparing hands." },
      { "name": "The player places his mandatory Ante wager, plus an optional Bonus bet." },
      { "name": "The player and the dealer are dealt five cards, with the dealers last (5th) card turned face up." },
      { "name": "The player must make a decision to either Fold, Bet, or Draw." },
      { "name": "FOLD: The players five cards are discarded. The dealer will collect the losing Ante." },
      { "name": "BET: The player places a wager of 2x the Ante in the BET box." },
      { "name": "You have an opportunity to change one of your cards before taking final decision. The change is allowed two times per round." },
      { "name": "DRAW: If you change a card for the first time, it will cost you your Ante." },
      { "name": "REDRAW:if you change a card for the second time, it will cost you two your Ante." },
      { "name": "To change a card, click on the card, and then click DRAW (OR) REDRAW." },
      { "name": "If the player invoked the Exchange option, then the Fold or Bet decision will be made based on the new hand." },
      { "name": "The dealers cards are then opened and the poker value of the hand declared." },
      { "name": "If the dealer qualifies with a hand of Ace-King or higher, the players hand will be settled as follows:" },
      { "name": "In the case that the dealer’s hand does not Qualify, you receive a 1 to 1 payout to Ante." },
      { "name": "Player wins: If the dealer’s cards have Qualify but you have the better cards, you get back your bets, and receive a 1 to 1 payout to your Ante, and the Bet is paid out according to your card combination as indicated in the paytable." },
      { "name": "Tie: If the dealer qualifies but your cards completely equal to dealer’s ones, you get back your Ante and Bet." },
      { "name": "Dealer wins: If the dealer qualifies but your cards lose to dealer’s ones, Ante and Bet return to the dealer, except possibly the Bonus bet which is settled separately." },
      { "name": "The Bonus bet will pay based on the players initial cards only and according to the bonus paytable." }
    ]
    // this.bonuspaytable = [
    //   { k: "Royal Flush", val: "1500 to 1" },
    //   { k: "Straight Flush", val: "800 to 1" },
    //   { k: "Four of a Kind", val: "250 to 1" },
    //   { k: "Full House", val: "150 to 1" },
    //   { k: "Flush", val: "100 to 1" },
    //   { k: "Straight", val: "50 to 1" },
    //   { k: "Three of a Kind", val: "5 to 1" },
    // ];
    this.chipVal = [
      { src: chip_10, key: 1, label: "10", val: 10 },
      { src: chip_25, key: 1, label: "20", val: 20 },
      { src: chip_50, key: 1, label: "50", val: 50 },
      { src: chip_100, key: 1, label: "100", val: 100 },
      { src: chip_500, key: 1, label: "500", val: 500 },
      { src: chip_1000, key: 1, label: "1000", val: 1000 },
      // { src: chip_25, key: 1, val: 1000 },
      // { src: chip_25, key: 1, val: 5000 },
    ];
    this.updatePlayerInfo = this.updatePlayerInfo.bind(this);
    // this.handleOrientationChange = this.handleOrientationChange.bind(this)

  }
  clearallsettimeouts = () => {
    for (let i = 1; i <= this.clearTimingLeft.length; i++) {
      clearTimeout(this.clearTimingLeft[i]);
      // console.log(this.clearTimingLeft[i]);
    }
  }

  componentDidMount() {
    console.log(this.props.TP_payouts)
    document.addEventListener('keydown', this.handleKeyDown);
    this.setState({
      PayouyBetBounusData: this.props.TP_payouts
    })
    this.newSound = null

    const { childRef } = this.props;
    childRef(this);
    this.preloadassetsRussianpoker(Russianpokerassets)
    document.addEventListener("keydown", this.Russian_handleKeyboardEvent);
    this.setState({ buttontext: "clear", insurencebtn: true, betdeductions: 0 });

    // console.log(this.props.rp_chipValues)
    this.setState({ buttontext: "clear", insurencebtn: true })

    if (this.props.tp_chipValues) {
      let newChips = this.props.tp_chipValues.split(",");
      // console.log(newChips);
      // console.log(newChips.length)
      let lblTxt = "";
      // for (let i = 0; i < newChips.slice(0,6).length; i++) {
      for (let i = 0; i < newChips.length; i++) {
        if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
          lblTxt = "K"
        } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
          lblTxt = "M"
        } else if (Number(newChips[i]) > 999999999) {
          lblTxt = "B"
        }
        this.chipVal[i].val = newChips[i]
        // console.log(this.chipVal[i].val, lblTxt);
        this.checkChipValueTropical(Number(this.chipVal[i].val), lblTxt, i);
      }

    }
    this.betrussiansnd = new Audio(betrussiansnd);
    // this.reaveldealer_cards = new Audio(reaveldealer_cards);
    this.reaveldealer_cards = new Howl({ src: [reaveldealer_cards], html5: true, });

    this.selectCard = new Audio(selectCard);
    this.unselectCard = new Audio(unselectCard);
    // this.playerWin = new Audio(playerWin);
    // this.playerLose = new Audio(playerLose);
    this.cardsshuffle = new Audio(cardsshuffle);

    this.pokerchipsnd = this.initializeChipSound();
    this.betchipsnd = this.initializeBetSound();
    this.playerWin = this.initializePlayerWinSound();
    this.playerLose = this.initializePlayerLoseSound();

    document.getElementById("Tropicalgamecover").style.display = "none";
    // document.getElementById("Tropicalgamecover").classList.add("gamecover_enbledisableTP")


    // console.log(porttraitejson.pokertable.height);
    window.addEventListener("resize", this.handleResize);

    // window.addEventListener("resize", this.handleOrientationChange);

    this.handleOrientationChange();

    // console.log("Width: " + window.screen.width);
    // console.log("height: " + window.screen.height);
    let decreasrDisable = document.getElementById('betdecrementTP')
    decreasrDisable.classList.add('icon_opacity')

    const betchipsRussianpoker = document.getElementById("betchipsTP");
    this.setState({ infoText: this.props.language.Place_antebet })
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      // document.getElementById("Payout_TPS").style.display = "block"
      // document.getElementById("BonusPayout_TPS").style.display = "none"

      document.getElementById("landscapeInfoTP").style.display = "none";
      document.getElementById("infocontainerTP").classList.add("infotext_windowsTP")


      // this.ShowBonuspayouttable();
      // document.getElementById("paytable").classList.add("paytable")
      //  document.getElementById("waviy").classList.add("waviy")
      document.getElementById("infrmiconTP").classList.add("addpointerTP")
      document.getElementById("TspoptionTP").classList.add("addpointerTP")
      const buybutton = document.getElementById("buybtnTP");
      buybutton.classList.add("addpointerTP")

      document.getElementById("minmaxbetsTP").classList.add("minmaxbets_windowsTP");
      this.setState({
        activeScreen: 0
      })

      document.getElementById("container_playerdealerTP").classList.add("container_playerdealerTP");
      document.getElementById("Dealer").classList.add("Dealer_windowsTP")
      document.getElementById("Player").classList.add("Player_windowsTP")
      // document.getElementById("TspoptionTP").style.display = "none"


      document.getElementById("buydrawTropical").classList.add("insurencenodropTP")
      document.getElementById("betTropical").classList.add("insurencenodropTP")
      document.getElementById('volumeTropical').classList.add("addpointerTP")
      document.getElementById("drawtextTP").classList.add("drawtextwindowsTP");
      document.getElementById("betpannelTropicalcover").style.display = "none";
      document.getElementById("betpannelTropicalcover").classList.add("pannelcoverTP");

      document.getElementById("betcontainerTP").classList.add("betpannelrussianwindowsTP");
      document.getElementById("infoTropicalP").classList.add("windowsinfoTP");


      document.getElementById("TPbetcircle").classList.add("addpointerTP");
      document.getElementById("betbtnTP").classList.add("addpointerTP");
      document.getElementById("FoldbtnTP").classList.add("addpointerTP");

      document.getElementById("bonusTropical").classList.add("bnspoinerTP");
      // document.getElementById("bonusTropical").classList.add("addpointerTP")

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          if (document.getElementById("val0")) {
            document.getElementById("val0").classList.remove("valtext_windowsTP");
            document.getElementById("val0").textContent = this.chipVal[0].label;
            document.getElementById("val0").classList.add("valtext_windows_chip10TP", "chip_10textTP");

          }


          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          break;
        default:
          break;
      }
      Object.assign(betchipsRussianpoker.style, porttraitejson.betchpwindows);


    } else {

      // document.getElementById("paytable").style.display = "none"

      this.setState({
        activeScreen: 1
      })
      const confirmbuydealerElement = document.getElementById("confirmbuydealer_popupTP");
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntialTP");
      const containerPlayerdealerElement = document.getElementById("container_playerdealerTP");
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      const drawbutton = document.getElementById("drawbtnTP");
      drawbutton.classList.add("drwnodropTP")
      const buybutton = document.getElementById("buybtnTP");
      // buybutton.classList.add("addpointerTP")


      containerPlayerdealerElement.className = "";
      containerPlayerdealerElement.style.cssText = "";




      drawbutton.src = "";
      drawbutton.style.cssText = "";
      buybutton.src = "";
      buybutton.style.cssText = "";
      betbutton.src = "";
      betbutton.style.cssText = "";
      foldbutton.src = "";
      foldbutton.style.cssText = "";

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;

          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;

      }





      document.getElementById("val0").style.cssText = "";
      document.getElementById("infoTropicalP").className = "";
      document.getElementById("infoTroppicalland").className = "";
      confirmbuydealerElement.classList.add('confirmbuy-dealerpopupTP');
      if (window.innerWidth < window.innerHeight) {
        document.getElementById("infocontainerTP").style.display = "block"
        document.getElementById("landscapeInfoTP").style.display = "none"

        document.getElementById("val0").classList.add("valtextPortraitTP", "valtextPortrait1TP");


        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.add("PlayerCardpannelcoverPortraitTP");
        document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
        document.getElementById("mobileAntiMaskCoverTP").classList.add("mobileAntiMaskCoverTP");
        document.getElementById("val0").classList.remove('valtext0');
        // confirmbuydealerElement.classList.add('confirmbuy-dealerpopupTP');

        document.getElementById("infoTropicalP").classList.add("portraitinfoTP");
        // document.getElementById('volumeTropical').classList.add("addpointerTP")
        // betChipText.style.cssText = "";

        containerPlayerdealerElement.classList.add("cntr_pyrdlr_prtTP");
        document.getElementById("Player").style.fontSize = "80px";

      } else {
        document.getElementById("landscapeInfoTP").style.display = "block";
        document.getElementById("infocontainerTP").style.display = "none"

        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.add("PlayerCardpannelcoverLndspTP");

        document.getElementById("val0").classList.add("valtextLandscapeTP", "valtextPortrait1TP");
        containerPlayerdealerElement.classList.add("cntr_pyrdlr_lnspTP");
        document.getElementById("Player").style.fontSize = "80px";

        document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
        document.getElementById("mobileAntiMaskCoverTP").classList.add("mobileLndspAntiMaskCoverTP");

        document.getElementById("infoTroppicalland").classList.add("lndspinfoTP");


      }
      document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }

    this.showPreviousBetsStatus();
  }

  handleKeyDown = (event) => {
    console.log(event.key)
    //   if (event.key === 'Tab' || "Enter") {

    event.preventDefault();  // Disable the Tab key globally
    console.log('Tab key is disabled globally!');
    // }

  };


  preloadassetsRussianpoker = (Russainassets) => {

    Russainassets.forEach((loadedimages) => {
      const img = new Image();
      img.src = loadedimages;
    });

  }


  checkChipValueTropical(chipValue, chpTxt, i) {

    if (chipValue >= 1000) {
      chipValue = chipValue / 1000;
      this.checkChipValueTropical(chipValue, chpTxt, i)
    } else {
      // console.log(chpTxt);
      this.showResultChipValueTropical(chipValue, chpTxt, i);
      return chipValue
    }
  }
  showResultChipValueTropical(chVal, chipTxt, index) {
    // console.log(chVal);
    // console.log(chVal + "" + chipTxt)
    this.chipVal[index].label = chVal + "" + chipTxt;
    // console.log(this.chipVal)
  }
  componentWillUnmount() {
    // document.removeEventListener("keydown", this.Russian_handleKeyboardEvent);

    document.removeEventListener('keydown', this.handleKeyDown);

    window.removeEventListener("resize", this.handleResize);
    this.betchipsnd.pause();
    this.betrussiansnd.pause();
    // this.reaveldealer_cards.pause();

    this.selectCard.pause();
    this.unselectCard.pause();
    this.playerWin.pause();
    this.playerLose.pause();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {

    if (document.visibilityState === "hidden") {
      console.log("The game is in the background or the user switched tabs.");
      // Pause the game, animations, or sound

    } else if (document.visibilityState === "visible") {
      console.log("The user returned to the game.");

      // Resume the game, animations, or sound
      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "GET_GAME_STATE",
        object: {
          // gameId: this.state.gameId,
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          // handId:this.handId,

        },
      };
      this.props.network.sendof(body);
      console.log(body);
      this.body = {};
    }

  };


  clearActivesWeDid = () => {

    this.setState({
      bsbtn: true,
      pokerranking: "",
      pokerrankingdeler: "",
    });

    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.insuranceActive = false;

    if (document.getElementById('rankinginfodelerTP')) {
      document.getElementById('rankinginfodelerTP').className = "";
      document.getElementById('rankinginfodelerTP').textContent = "";
      // document.getElementById("insurencetext").style.color = "";
      document.getElementById("bonustextTP").style.color = "";
      document.getElementById("bettextTP").style.color = "";
    }

    if (document.getElementById('rankinginfoTP')) {

      document.getElementById('rankinginfoTP').className = "";
      document.getElementById('rankinginfoTP').textContent = "";
    }



    this.cardsRef.current.ReBet();
    this.dealerCardsContainer.current.ReBet();

    this.betchipactivated = false;
    this.buydrawchipactivated = false;
    this.ConfirmbuydealercardactivePopup = false;
    this.resetPyrWnCpsAmn = false;


  }

  handleResize() {
    // console.log("calling resize");
    // document.getElementById("bacaratbgimTP");
    this.handleOrientationChange();
  }

  handleOrientationChange = () => {

    // if (this.state.isactiveoptionsmenu) {
    //   this.Showoptionsmenu();

    // }
    // if(this.state.ShowLimits){
    //   this.ShowLimits();
    // }
    // if (this.state.ishistorytable) {
    //   // document.getElementById("prevhistoryTP")
    //   this.ShowHistory();
    // }

    document.getElementById("buybtnTP").style.opacity = "1"
    this.setState({ disableBuyButton: false })

    if (document.getElementById("optionsmenubuttonTP")) {
      gsap.to('#optionsmenubuttonTP', { autoAlpha: 0 })
    }
    this.setState({ isoptionmenu: false, ShowLimits: false, ShowGamerules: false });

    if (document.getElementById("histry_tropical")) {
      document.getElementById("histry_tropical").className = "";

    }
    if (document.getElementById("exithistoryTP")) {
      document.getElementById("exithistoryTP").className = "";

    }
    if (document.getElementById("prevhistoryTP")) {
      document.getElementById("prevhistoryTP").className = "";

    }
    if (document.getElementById("histry_bodyTP")) {
      document.getElementById("histry_bodyTP").className = "";
    }
    if (document.getElementById("heading_hstiryTP")) {
      document.getElementById("heading_hstiryTP").className = "";

    }





    if (this.state.isbonuspattable) {
      this.ShowBonuspayouttable();
      this.showPayoutBonusTable12('BonusPayout');
    }
    // console.log(window.innerHeight);
    // console.log(window.innerWidth);
    const isPortrait = window.innerHeight > window.innerWidth;
    if (isPortrait) {
      // console.log(" -----------  PORT     -------------------");
      this.setState({
        myOrient: "port",
      });
    } else {
      // console.log(" -----------  LAND     -------------------");
      this.setState({
        myOrient: "land",
      });
    }


    const betactiveElement = document.getElementById("betactiveTP");
    const tropicaldeck = document.getElementById("tropicaldeck");

    const bacaratbgimTP = document.getElementById("bacaratbgimTP");
    const pokertable = document.getElementById("pokertableimTP");
    const pokertablelnspe = document.getElementById("pokertablelandscapeTP");
    const useriddetl = document.getElementById("useridTP");
    const russiantiname = document.getElementById("tropicaltitle");
    const russianlabel = document.getElementById("tropicaltitle");
    const russianbncicon = document.getElementById("balnceiconTP");
    const exitbtnTP = document.getElementById("exitbtnTP");
    const beticonrussian = document.getElementById("beticonTP");
    const winnericonrussian = document.getElementById("winnericonTP");
    const informi = document.getElementById("infrmiconTP");
    const optionrussianbtn = document.getElementById("TspoptionTP");
    const volumeRussianimg = document.getElementById("volumeTropical");
    const bonusRussiangme = document.getElementById("bonusTropical");
    const buydrawRussiangme = document.getElementById("buydrawTropical");
    const betRussiangme = document.getElementById("betTropical");
    const betcirclerussian = document.getElementById("TPbetcircle");
    const betchipincrementbtn = document.getElementById("betincrenetTP");
    const betchipdecrementbtn = document.getElementById("betdecrementTP");
    const betchipsRussianpoker = document.getElementById("betchipsTP");
    const pokerrankingtwo = document.getElementById("Straightflush");
    const pokerrankingone = document.getElementById("royalflush");
    const pokerrankingthree = document.getElementById("frofkind");
    const pokerrankingfour = document.getElementById("fullhse");
    const pokerrankingfive = document.getElementById("flush");
    const pokerrankingsix = document.getElementById("stright");
    const pokerrankingseven = document.getElementById("Threekind");
    const pokerrankingeight = document.getElementById("twopair");
    const pokerrankingnine = document.getElementById("onepair");
    const pokerrankingten = document.getElementById("aceking");
    const Russianbgwindowsimg = document.getElementById("Tropicalbgwindows");
    const Russiantblewindows = document.getElementById("tpntblewindows");
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const drawbutton = document.getElementById("drawbtnTP");
    const buybutton = document.getElementById("buybtnTP");
    const drwtext = document.getElementById("drawtextTP");
    const betbtntextTP = document.getElementById("betbtntextTP");
    const foldbtntext = document.getElementById("foldtextTP");
    const dealerBuyCardElement = document.getElementById("dealerBuyCardTP");
    const dealerChipsAddToBuyElement = document.getElementById("dealerChipsAddToBuyButtonTP");

    const bonusInfoTheadElement = document.getElementById('bonusInfoTheadTP');
    const bonusInfoTbodyElement = document.getElementById("TPbonusInfoTbody");

    const winpopuprspelement = document.getElementById("winpopup_TP");
    const playerDetailsElement = document.getElementById("playerDetailsTP");
    const containerPlayerdealerElement = document.getElementById("container_playerdealerTP");
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainerTP');
    const minmaxbetsElement = document.getElementById("minmaxbetsTP")

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
    const cardDeckDivElment = document.getElementById("TropicaPokerDeck")



    containerPlayerdealerElement.className = "";
    playerDetailsElement.className = "";
    minmaxbetsElement.className = "";



    bacaratbgimTP.style.cssText = "";

    optionrussianbtn.src = "";
    optionrussianbtn.style.cssText = "";


    bonusRussiangme.src = "";
    bonusRussiangme.style.cssText = "";


    betcirclerussian.src = "";
    betcirclerussian.style.cssText = "";

    russianbncicon.src = "";
    russianbncicon.style.cssText = "";

    beticonrussian.src = "";
    beticonrussian.style.cssText = "";

    exitbtnTP.src = "";
    exitbtnTP.style.cssText = "";


    winnericonrussian.src = "";
    winnericonrussian.style.cssText = "";

    informi.src = "";
    informi.style.cssText = "";


    volumeRussianimg.src = "";
    volumeRussianimg.style.cssText = "";

    betchipincrementbtn.src = "";
    betchipincrementbtn.style.cssText = "";

    // betchipsRussianpoker.src = "";
    // betchipsRussianpoker.style.cssText="";

    betchipdecrementbtn.src = "";
    betchipdecrementbtn.style.cssText = "";


    useriddetl.src = "";
    useriddetl.style.cssText = "";
    // betactiveElement.className = ""

    dealerBuyCardElement.className = "";
    dealerChipsAddToBuyElement.className = "";
    winpopuprspelement.className = "";
    if (chipsstoredcontainerElement !== null) {
      chipsstoredcontainerElement.className = "";
    }


    if (bonusInfoTheadElement && bonusInfoTbodyElement) {
      bonusInfoTheadElement.className = "";
      bonusInfoTbodyElement.className = "";

    }





    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.setState({
        heightOfLayer: 1105,
        // widthOfLayer: 950,
        widthOfLayer: 2200,
        // dealerDistanceFromX: 250,
        // playerDistanceFromX: 1020,    
        dealerDistanceFromX: 590,
        playerDistanceFromX: 580,
        dealerDistanceFromY: -195,
        PlayerDistanceFromY: 450,
        isMobilePortraitLndsp: false,
        scaleX: 1.8,
        scaleY: 1.8,
        activeScreen: 0
      });
      this.isMobile = false;
      this.systemOS = "Windows";
      document.getElementById("topcardscontainerTP").classList.remove("topcardsTP");
      document.getElementById("betcontainerTP").classList.remove("betpannelTropicalstud");
      document.getElementById("betcontainerTP").classList.add("betpannelrussianwindowsTP");
      playerDetailsElement.classList.add("playerIdDetailswindowsTP");
      document.getElementById("infocontainerTP").style.display = "block"
      document.getElementById("landscapeInfoTP").style.display = "none";

      containerPlayerdealerElement.classList.add("container_playerdealerTP");
      if (russiantiname) {
        russiantiname.src = russiantitle;
        russiantiname.style.cssText = "";

      }
      tropicaldeck.src = Russiandeck
      Object.assign(tropicaldeck.style, porttraitejson.tropicaldeck)




      drwtext.classList.remove("drawtextportraiteTP");
      betbtntextTP.classList.remove("betportraiteTP");
      foldbtntext.classList.remove("foldtextportraiteTP");

      drwtext.classList.add("drawtextwindowsTP");
      betbtntextTP.classList.add("betwindowsTP");
      foldbtntext.classList.add("foldtextwindowsTP");
      document.getElementById("antetextTP").classList.remove("antetextlnspeTP,antetextportraiteTP");
      // document.getElementById("antetextTP").classList.remove("antetextportraiteTP");
      document.getElementById("antetextTP").classList.add("antetextwindowsTP");
      drwtext.classList.remove("drawtextlandspeTP");
      betbtntextTP.classList.remove("betLandscapeTP");
      foldbtntext.classList.remove("foldtextLandscapeTP");

      Russianbgwindowsimg.style.background = `url(${Tropicalbgwindows})`;

      Object.assign(Russianbgwindowsimg.style, porttraitejson.russianbgwindows)



      // Russiantblewindows.src = Rutablewindows;
      // Object.assign(Russiantblewindows.style, porttraitejson.russtablewindows)


      optionrussianbtn.src = optionbtnwindows;

      Object.assign(optionrussianbtn.style, porttraitejson.optionbtnwindows)

      russianbncicon.src = balanceiconwindows;
      Object.assign(russianbncicon.style, porttraitejson.balanceiconwindows)

      document.getElementById("balance").classList.remove("balanceportraiteTP,balancelndscapeTP");
      document.getElementById("balance").classList.add("balancewindowsTP");

      useriddetl.src = useridlmg;
      Object.assign(useriddetl.style, porttraitejson.useridwindowsTP)



      document.getElementById("useridtextTP").classList.remove("useridportraiteTP,useridlandscapeTP");
      document.getElementById("useridtextTP").classList.add("useridwindowsTP");

      beticonrussian.src = beticonwindows;

      Object.assign(beticonrussian.style, porttraitejson.beticonwindows);
      document.getElementById("betsTP").classList.remove("betslandspeTP,betsportraiteTP");
      document.getElementById("betsTP").classList.add("betswindowsTP");



      if (russiantiname) {
        russiantiname.src = russiantitle;
        Object.assign(russiantiname.style, porttraitejson.russiantitlewindows);

      }

      // if (russianlabel) {
      //   russianlabel.src = russianlabelimg;
      //   Object.assign(russiantiname.style, porttraitejson.russiantitlewindows);

      // }

      winnericonrussian.src = winnericnwindows;
      Object.assign(winnericonrussian.style, porttraitejson.winnericonwindows);

      document.getElementById("winsTP").classList.remove("winslandspeTP,winsportraiteTP");
      document.getElementById("winsTP").classList.add("winswindowsTP");

      informi.src = informicon;
      Object.assign(informi.style, porttraitejson.infrmicnlnspewindows);

      volumeRussianimg.src = volumeimg;
      Object.assign(volumeRussianimg.style, porttraitejson.volumewindows);


      betchipincrementbtn.src = betincerementdecrement;
      Object.assign(betchipincrementbtn.style, porttraitejson.beincrementwindows);


      betchipdecrementbtn.src = betincerementdecrement;
      Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrementwindows);

      betcirclerussian.src = russianbetcle;
      Object.assign(betcirclerussian.style, porttraitejson.becirclewindows);


      bonusRussiangme.src = russianbetbtright;
      Object.assign(bonusRussiangme.style, porttraitejson.bonusbtnwindows);

      document.getElementById("bonustextTP").classList.remove("bonustextportraiteTP,bonustextlndspeTP");
      document.getElementById("bonustextTP").classList.add("bonustextTPS");

      buydrawRussiangme.src = russianbetbtnleft;
      Object.assign(buydrawRussiangme.style, porttraitejson.drawbuybtnwindows);

      document.getElementById("buydrawtextTP").classList.add("buydrawtextTP");

      betRussiangme.src = russianbetbtndown;
      Object.assign(betRussiangme.style, porttraitejson.betbtnwindows);

      document.getElementById("bettextTP").classList.remove("bettextlndspeTP,bettextportraiteTP");
      document.getElementById("bettextTP").classList.add("bettextTP");

      pokerrankingone.classList.remove("royalflshportraiteTP");
      document.getElementById("royaltoTP");

      pokerrankingtwo.classList.remove("strightportraiteTP");
      document.getElementById("strghttoTP").classList.remove("royalflshportraitetoTP");

      pokerrankingthree.classList.remove("fourofkindportraiteTP");
      document.getElementById("frkndtoTP").classList.remove("royalflshportraitetoTP");

      pokerrankingfour.classList.remove("fullhouseportraiteTP");
      document.getElementById("fullhsetoTP").classList.remove("royalflshportraitetoTP");

      pokerrankingfive.classList.remove("flushportraiteTP");
      document.getElementById("flushtoTP").classList.remove("royalflshportraitetoTP");

      pokerrankingsix.classList.remove("strightTPS");
      document.getElementById("strighttoTP").classList.remove("royalflshportraitetoTP");

      pokerrankingseven.classList.remove("threekindportraiteTP");
      document.getElementById("ThreekindtoTP").classList.remove("royaltocolorTP");

      pokerrankingeight.classList.remove("twopairportraiteTP");
      document.getElementById("twopairtoTP").classList.remove("royaltocolorTP");

      pokerrankingnine.classList.remove("onepairportraiteTP");
      document.getElementById("onepairtoTP").classList.remove("royaltocolorTP");

      pokerrankingten.classList.remove("acekingportraiteTP");
      document.getElementById("acekingtoTP").classList.remove("royaltocolorTP");


      pokerrankingone.classList.remove("royalflshlndspeTP");
      document.getElementById("royaltoTP").classList.remove("royaltocolorTP");

      pokerrankingtwo.classList.remove("strightflushlndspeTP");
      document.getElementById("strghttoTP").classList.remove("royaltocolorTP");

      pokerrankingthree.classList.remove("fourofkindlndspeTP");
      document.getElementById("frkndtoTP").classList.remove("royaltocolorTP");

      pokerrankingfour.classList.remove("fullhouselndspeTP");
      document.getElementById("fullhsetoTP").classList.remove("royaltocolorTP");

      pokerrankingfive.classList.remove("flushlndspeTP");
      document.getElementById("flushtoTP").classList.remove("royaltocolorTP");

      pokerrankingsix.classList.remove("strightlndspeTP");
      document.getElementById("strighttoTP").classList.remove("royaltocolorTP");

      pokerrankingseven.classList.remove("threekindlndspeTP");
      document.getElementById("ThreekindtoTP").classList.remove("royaltocolorTP");

      pokerrankingeight.classList.remove("twopairlndspeTP");
      document.getElementById("twopairtoTP").classList.remove("royaltocolorTP");

      pokerrankingnine.classList.remove("onepairlndspeTP");
      document.getElementById("onepairtoTP").classList.remove("royaltocolorTP");

      pokerrankingten.classList.remove("acekinglndspeTP");
      document.getElementById("acekingtoTP").classList.remove("royaltocolorTP");

      pokerrankingone.classList.add("royalflshwindowsTP");
      document.getElementById("royaltoTP").classList.add("royaltocolorTP");

      pokerrankingtwo.classList.add("strightflushwindowsTP");
      document.getElementById("strghttoTP").classList.add("royaltocolorTP");

      pokerrankingthree.classList.add("fourofkindwindowsTP");
      document.getElementById("frkndtoTP").classList.add("royaltocolorTP");

      pokerrankingfour.classList.add("fullhouseTP");
      document.getElementById("fullhsetoTP").classList.add("royaltocolorTP");

      pokerrankingfive.classList.add("flushTPS");
      document.getElementById("flushtoTP").classList.add("royaltocolorTP");

      pokerrankingsix.classList.add("strightwindowsTP");
      document.getElementById("strighttoTP").classList.add("royaltocolorTP");

      pokerrankingseven.classList.add("threekindTP");
      document.getElementById("ThreekindtoTP").classList.add("royaltocolorTP");

      pokerrankingeight.classList.add("twopairTPS");
      document.getElementById("twopairtoTP").classList.add("royaltocolorTP");

      pokerrankingnine.classList.add("onepairTPS");
      document.getElementById("onepairtoTP").classList.add("royaltocolorTP");

      pokerrankingten.classList.add("acekingTPS");
      document.getElementById("acekingtoTP").classList.add("royaltocolorTP");

      document.getElementById("playerDealerCardContainerTP").classList.remove("stage-top-mobile-portrait_TP");
    } else {
      tropicaldeck.src = Russiandeck
      cardDeckDivElment.className = ""
      document.getElementById("infoTropicalP").className = "";
      document.getElementById("infoTroppicalland").className = "";
      pokerrankingone.classList.remove("royalflshwindowsTP");
      document.getElementById("royaltoTP").classList.remove("royaltocolorTP");

      pokerrankingtwo.classList.remove("strightflushwindowsTP");
      document.getElementById("strghttoTP").classList.remove("royaltocolorTP");

      pokerrankingthree.classList.remove("fourofkindwindowsTP");
      document.getElementById("frkndtoTP").classList.remove("royaltocolorTP");

      pokerrankingfour.classList.remove("fullhouseTP");
      document.getElementById("fullhsetoTP").classList.remove("royaltocolorTP");

      pokerrankingfive.classList.remove("flushTPS");
      document.getElementById("flushtoTP").classList.remove("royaltocolorTP");

      pokerrankingsix.classList.remove("strightwindowsTP");
      document.getElementById("strighttoTP").classList.remove("royaltocolorTP");

      pokerrankingseven.classList.remove("threekindTP");
      document.getElementById("ThreekindtoTP").classList.remove("royaltocolorTP");

      pokerrankingeight.classList.remove("twopairTPS");
      document.getElementById("twopairtoTP").classList.remove("royaltocolorTP");

      pokerrankingnine.classList.remove("onepairTPS");
      document.getElementById("onepairtoTP").classList.remove("royaltocolorTP");

      pokerrankingten.classList.remove("acekingTPS");
      document.getElementById("acekingtoTP").classList.remove("royaltocolorTP");

      pokerrankingone.classList.remove("royalflshlndspeTP");
      document.getElementById("royaltoTP").classList.remove("royaltocolorTP");

      pokerrankingtwo.classList.remove("strightflushlndspeTP");
      document.getElementById("strghttoTP").classList.remove("royaltocolorTP");

      pokerrankingthree.classList.remove("fourofkindlndspeTP");
      document.getElementById("frkndtoTP").classList.remove("royaltocolorTP");

      pokerrankingfour.classList.remove("fullhouselndspeTP");
      document.getElementById("fullhsetoTP").classList.remove("royaltocolorTP");

      pokerrankingfive.classList.remove("flushlndspeTP");
      document.getElementById("flushtoTP").classList.remove("royaltocolorTP");

      pokerrankingsix.classList.remove("strightlndspeTP");
      document.getElementById("strighttoTP").classList.remove("royaltocolorTP");

      pokerrankingseven.classList.remove("threekindlndspeTP");
      document.getElementById("ThreekindtoTP").classList.remove("royaltocolorTP");

      pokerrankingeight.classList.remove("twopairlndspeTP");
      document.getElementById("twopairtoTP").classList.remove("royaltocolorTP");

      pokerrankingnine.classList.remove("onepairlndspeTP");
      document.getElementById("onepairtoTP").classList.remove("royaltocolorTP");

      pokerrankingten.classList.remove("acekinglndspeTP");
      document.getElementById("acekingtoTP").classList.remove("royaltocolorTP");

      document.getElementById("crdscoverTP").className = "";
      document.getElementById("crdscoverTP").cssText = "";

      pokerrankingone.className = "";
      pokerrankingtwo.className = "";
      pokerrankingthree.className = "";
      pokerrankingfour.className = "";
      pokerrankingfive.className = "";
      pokerrankingsix.className = "";
      pokerrankingseven.className = "";
      pokerrankingeight.className = "";
      pokerrankingnine.className = "";
      pokerrankingten.className = "";
      popupbetGreaterthanBalnceElement.className = "";

      document.getElementById("royaltoTP").classList.add("royalflshportraitetoTP");
      document.getElementById("strghttoTP").classList.add("royalflshportraitetoTP");
      document.getElementById("frkndtoTP").classList.add("royalflshportraitetoTP");
      document.getElementById("fullhsetoTP").classList.add("royalflshportraitetoTP");
      document.getElementById("flushtoTP").classList.add("royalflshportraitetoTP");
      document.getElementById("strighttoTP").classList.add("royalflshportraitetoTP");
      document.getElementById("ThreekindtoTP").classList.add("royaltocolorTP");
      document.getElementById("twopairtoTP").classList.add("royaltocolorTP");
      document.getElementById("onepairtoTP").classList.add("royaltocolorTP");
      document.getElementById("acekingtoTP").classList.add("royaltocolorTP");

      // console.log(isPortrait);
      document.getElementById("playerDealerCardContainerTP").classList.remove("stage-top-mobile-portrait_TP");
      document.getElementById("playerDealerCardContainerTP").classList.remove("stage-top-mobile-landscape_TP");
      let betcontainerElement = document.getElementById("betcontainerTP");

      Russianbgwindowsimg.style.cssText = "";



      useriddetl.src = "";
      useriddetl.style.cssText = "";


      // Russiantblewindows.src = "";
      // Russiantblewindows.style.cssText = "";

      bacaratbgimTP.style.cssText = "";


      optionrussianbtn.src = "";
      optionrussianbtn.style.cssText = "";


      russianbncicon.src = "";
      russianbncicon.style.cssText = "";

      beticonrussian.src = "";
      beticonrussian.style.cssText = "";


      exitbtnTP.src = "";
      exitbtnTP.style.cssText = "";

      russiantiname.className = "";
      if (russiantiname) {
        russiantiname.src = russiantitle;
        russiantiname.style.cssText = "";

      }


      winnericonrussian.src = "";
      winnericonrussian.style.cssText = "";


      informi.src = "";
      informi.style.cssText = "";




      volumeRussianimg.src = "";
      volumeRussianimg.style.cssText = "";




      betchipsRussianpoker.src = "";
      betchipsRussianpoker.style.cssText = "";




      betchipincrementbtn.src = "";
      betchipincrementbtn.style.cssText = "";



      betchipdecrementbtn.src = "";
      betchipdecrementbtn.style.cssText = "";




      let chipsElementText = document.getElementById("val0");
      betcontainerElement.style.cssText = "";
      chipsElementText.className = "";
      let chipsElmtextclass = "";

      const betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");


      if (this.playerWinBonus === true) {
        if (isPortrait) {
          if (this.state.bonusbetamount) {
            if (document.getElementById("chip10centerbnsTP")) {
              gsap.to("#chip10centerbnsTP", {
                x: -630, y: 925, duration: 0.00001, opacity: 1

              });
            }

          }

        } else {

          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbnsTP')) {

              gsap.to("#chip10centerbnsTP", {
                x: 550, y: -130, duration: 0.00001, autoAlpha: 1,
              });

            }

          }

        }
      } else {
        this.playerWinBonus = false
      }



      if (this.betchipactivated === true) {
        if (isPortrait) {
          gsap.to(this.chipbetref.current, {
            x: 305,
            // x: -27,
            y: -925,
            duration: 0.000001
          });


          gsap.to(betTextaddtoBetElement, {
            x: 314,
            y: -1710,
            duration: 0.000001
          });

        } else {
          gsap.to(this.chipbetref.current, {
            x: -772,
            y: 38,
            duration: 0.000001
          });


          gsap.to(betTextaddtoBetElement, {
            x: -772,
            y: 38,
            duration: 0.000001

          });


        }

      } else {
        this.betchipactivated = false;
      }


      if (this.buydrawchipactivated === true) {
        if (isPortrait) {
          gsap.to(this.chipdrawref.current, {
            x: 322,
            y: -1230,
            duration: 0.000001,
          });
        } else {
          gsap.to(this.chipdrawref.current, {
            x: -1230,
            y: 0,
            duration: 0.000001,
          });
        }
      } else {
        this.buydrawchipactivated = false
      }

      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");
      if (this.ConfirmbuydealercardactivePopup === true) {
        confirmbuydealerPopupElement.display = "block"
        confirmbuydealerPopupElement.className = "";
        if (isPortrait) {
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait_TP");
          // confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp_TP");
        } else {
          // confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait_TP");
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp_TP");
        }

      } else {
        this.ConfirmbuydealercardactivePopup = false;
      }


      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          chipsElementText.textContent = this.chipVal[0].label;
          chipsElmtextclass = "valtextPortrait1TP";

          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          chipsElementText.textContent = this.chipVal[1].label;
          chipsElmtextclass = "bet-text-addtoBet20TP";
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          chipsElementText.textContent = this.chipVal[2].label;
          chipsElmtextclass = "bet-text-addtoBet20TP";
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          chipsElementText.textContent = this.chipVal[3].label;
          chipsElmtextclass = "bet-text-addtoBet20TP";
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          chipsElementText.textContent = this.chipVal[4].label;
          chipsElmtextclass = "bet-text-addtoBet20TP";
          break;
        case 6:
          betchipsRussianpoker.src = chip_1000;
          chipsElementText.textContent = this.chipVal[5].label;
          chipsElmtextclass = "bet-text-addtoBet20TP";
          break;

        default:
          break;

      }


      const rankinginfodelerElement = document.getElementById("rankinginfodelerTP");
      const rankinginfoElement = document.getElementById("rankinginfoTP");
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";
      rankinginfoElement.style.cssText = "";


      if (bonusInfoTheadElement && bonusInfoTbodyElement) {
        Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
        Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
      }
      betbtntextTP.className = "";
      foldbtntext.className = "";



      winpopuprspelement.classList.add("winPopupForMptIntlTP");
      let betchipsdraw = document.getElementById("betchipsaddtodrawTP");
      // betchipsdraw.classList.remove("betchipsaddtodrawPositionTP")
      const winamtElement12 = document.getElementById("winamt_TPS");
      if (isPortrait) {
        document.getElementById("infocontainerTP").style.display = "block"
        document.getElementById("landscapeInfoTP").style.display = "none";

        console.log(this.state.Winnindanimation)
        if (this.state.Winnindanimation) {
          gsap.to("#chipcenterpositionTP", { opacity: 0, zIndex: -10 });
          gsap.to("#betchipsaddtobetTP", {
            opacity: 0, zIndex: -10, onComplete: () => {
              // betText.textContent = this.topLable_rsp( Number((res.anteWinAmount + res.winAmount).toFixed(2)));
            }
          });
          gsap.to("#betTextaddtoBetTP", { opacity: 0, zIndex: -10 });

          winpopuprspelement.classList.add("winPopupForMptSwTP");
          winamtElement12.classList.add("winamtMpSwTP");
          winpopuprspelement.classList.remove("winPopupForMLspTP");
        }
        if (this.state.AnteWinCHanimation) {
          gsap.to("#dealerchipTP", { opacity: 0, zIndex: -10, autoAlpha: 0 });
        }

        if (this.state.PayoutTableToggle) {
          if (document.getElementById("PAYOUTTABLETPS")) {
            document.getElementById("PAYOUTTABLETPS").classList.add("showPayoutTPprtrt")
            document.getElementById("PAYOUTTABLETPS").classList.remove("showPayoutLandscpeTP")
          }
        }

        if (this.state.betshigh_balnce !== null) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortraitTP")
        }

        document.getElementById("infoTropicalP").classList.add("portraitinfoTP");
        minmaxbetsElement.classList.add("minmaxbets_portraitTP");

        drwtext.classList.remove("drawtextlandspeTP");
        drwtext.classList.remove("drawtextwindowsTP");

        if (this.state.drawbtnnewClass) {
          //  betchipsdraw.classList.add("betchipsaddtodrawPositionTP")
        }

        // document.getElementById("antetextTP").classList.remove("antetextwindowsTP");
        document.getElementById("antetextTP").classList.remove("antetextlnspeTP,antetextwindowsTP");


        containerPlayerdealerElement.classList.add("cntr_pyrdlr_prtTP");
        document.getElementById("Player").style.fontSize = "80px"
        // pokertablelnspe.src = "";
        // pokertablelnspe.style.cssText = "";

        playerDetailsElement.classList.add("playerIdDetailsPortraitTP");
        document.getElementById("playerDealerCardContainerTP").classList.add("stage-top-mobile-portrait_TP");
        document.getElementById("topcardscontainerTP").classList.add("topcardsTP");
        pokerrankingone.classList.add("royalflshportraiteTP");
        pokerrankingtwo.classList.add("strightportraiteTP");
        pokerrankingthree.classList.add("fourofkindportraiteTP");
        pokerrankingfour.classList.add("fullhouseportraiteTP");
        pokerrankingfive.classList.add("flushportraiteTP");
        pokerrankingsix.classList.add("strightTPS");
        pokerrankingseven.classList.add("threekindportraiteTP");
        pokerrankingeight.classList.add("twopairportraiteTP");
        pokerrankingnine.classList.add("onepairportraiteTP");
        pokerrankingten.classList.add("acekingportraiteTP");
        Object.assign(betcontainerElement.style, porttraitejson.betpannelrussianPortrait);

        drwtext.classList.add("drawtextportraiteTP");
        betbtntextTP.classList.add("betportraiteTP");
        foldbtntext.classList.add("foldtextportraiteTP");
        document.getElementById("antetextTP").className = "";
        document.getElementById("antetextTP").classList.add("antetextportraiteTP");
        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextPortraitTP", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextPortraitTP");
        }

        bacaratbgimTP.style.background = `url(${russianbg})`;
        bacaratbgimTP.style.backgroundSize = "cover";
        bacaratbgimTP.style.width = porttraitejson.bacaratbg.width;
        bacaratbgimTP.style.height = porttraitejson.bacaratbg.height;
        bacaratbgimTP.style.backgroundSize = porttraitejson.bacaratbg.background;
        bacaratbgimTP.style.left = 0;

        // betchipsRussianpoker.src = betchipsRussian;
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchp);


        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, porttraitejson.beincrementportraite);

        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrement)


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, porttraitejson.volume)

        bonusRussiangme.src = russianbetbtright;

        Object.assign(bonusRussiangme.style, porttraitejson.bonusbtn);


        document.getElementById("bonustextTP").className = "";
        document.getElementById("bonustextTP").classList.add("bonustextportraiteTP");

        buydrawRussiangme.src = russianbetbtnleft;
        buydrawRussiangme.style.position = porttraitejson.drawbuybtn.position;
        buydrawRussiangme.style.width = porttraitejson.drawbuybtn.width;
        buydrawRussiangme.style.height = porttraitejson.drawbuybtn.height;
        buydrawRussiangme.style.top = porttraitejson.drawbuybtn.top;
        buydrawRussiangme.style.left = porttraitejson.drawbuybtn.left;
        document.getElementById("buydrawtextTP").className = "";

        document.getElementById("buydrawtextTP").classList.add("buydrawtextportraiteTP");

        betRussiangme.src = russianbetbtndown;

        Object.assign(betRussiangme.style, porttraitejson.betbtnTP);


        document.getElementById("bettextTP").className = "";

        document.getElementById("bettextTP").classList.add("bettextportraiteTP");

        betcirclerussian.src = russianbetcle;
        Object.assign(betcirclerussian.style, porttraitejson.becircle);

        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, porttraitejson.optionbtn);

        dealerBuyCardElement.classList.add('dealerBuyCardTP');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortraitTP');


        if (pokertable) {
          // pokertable.src = pokertableruss;
          // pokertable.style.backgroundSize = "cover";

          // Object.assign(pokertable.style, porttraitejson.pokertable);
        }

        // russianbncicon.src = balanceicon;
        russianbncicon.src = balanceiconwindows;
        Object.assign(russianbncicon.style, porttraitejson.balanceiconportraite);


        document.getElementById("balance").className = "";
        document.getElementById("balance").classList.add("balanceportraiteTP");
        // document.getElementById("balance").classList.remove("balancelndscapeTP");

        // beticonrussian.src = beticonTP;
        beticonrussian.src = beticonwindows;

        Object.assign(beticonrussian.style, porttraitejson.beticonTP);


        document.getElementById("betsTP").className = "";
        // document.getElementById("betsTP").classList.remove("betslandspeTP");
        document.getElementById("betsTP").classList.add("betsportraiteTP");

        // exitbtnTP.src = exitrussian;
        Object.assign(exitbtnTP.style, porttraitejson.exit);



        if (russiantiname) {
          russiantiname.src = russiantitle;

          Object.assign(russiantiname.style, porttraitejson.russiantitle);


        }

        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, porttraitejson.winnericonTP);



        document.getElementById("winsTP").className = "";
        document.getElementById("winsTP").classList.add("winsportraiteTP");

        informi.src = informicon;
        Object.assign(informi.style, porttraitejson.infrmicn);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, porttraitejson.useridportraiteTP);

        document.getElementById("useridtextTP").className = "";
        document.getElementById("useridtextTP").classList.add("useridportraiteTP");



        if (this.state.pokerrankingdeler !== "") {
          document.getElementById('rankinginfodelerTP').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerPortraitTP")
        } else {
          document.getElementById('rankinginfodelerTP').className = "";

        }

        if (this.state.pokerranking !== "") {
          rankinginfoElement.classList.add("pokerrankingPortaitTP")
        } else {
          document.getElementById('rankinginfoTP').className = "";
        }


        if (this.state.isMobilePortraitLndsp) {
          rankinginfoElement.style.left = "35%";
        }


        cardDeckDivElment.classList.add("cardDeckPortraitTP")
        this.setState({
          // heightOfLayer: 1500,
          heightOfLayer: 1200,
          widthOfLayer: 800,
          // widthOfLayer: 550,
          dealerDistanceFromX: -280,
          playerDistanceFromX: -120,

          dealerDistanceFromY: -150,
          PlayerDistanceFromY: 750,
          // dealerDistanceFromY: -90,
          // PlayerDistanceFromY: 940,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,

          bnsactvglwcss: "bnsactivePortraitTP",
          betactvglwcss: "betactivePortraitTP",
          betbuttoncss: porttraitejson.Betbtnportraite,
          betbtntextcss: "betportraitTP",
          foldbuttoncss: porttraitejson.foldbtnportraite,
          foldbtntextcss: "rebettextportraitTP",
          buybtncss: porttraitejson.Buybtnportraite,
          buybtntxtcss: "buytextPortraitTP",
          drawbtncss: porttraitejson.Drawbtnportraite,
          drawbtntxtcss: "drawtextPortraitTP",
          betchipstoantebetcss: porttraitejson.Betchiptoanteprotrait,
          betchipstobonusbetcss: porttraitejson.betchipstobnsPortrait,
          chipsstoredcontainercss: "",
          antebetpdvcss: "chip-center-position-TP",
          antechpimgcss: "chip20centerpositionPortraitTP",
          antespntxtcss: "antebetChipPortrait20TP",
          bonusdivcss: "bonusChipsContainerForPortraitTP",
          bonusimgcss: "bonusChipsForPortraitTP",
          bonuspntxtcss: "bonusChipsTextForPortraitTP",
          betchiptobetbtncss: porttraitejson.BetchiptobetPortrait,
          // betbtntxtcss:"bet-text-addtoBet-portrait",
          betbtntxtcss: `bet-text-addtoBet-portrait-TP ${chipsElmtextclass}`,
          buydrawcontainer: "drawChipDivTP",
          drawchipcss: "betchipsaddtodrawTP",
          // drawchiptextcss:"betTextaddtodrawTP" ,
          drawchiptextcss: `betTextaddtodrawTP ${chipsElmtextclass}`,
          insurancedivcss: "",
          insurancechipcss: "chiptoinsurencePortraitTP",
          insurancetxtcss: "insuranceTextPortraitTP",
          dealerhandcss: "pokerrankingdelerPortraitTP",
          playerhandcss: "pokerrankingPortaitTP",
          mobileAntiMaskCovercss: "mobileAntiMaskCoverTP",
          betpannelrussiancovercss: "PlayerCardpannelcoverPortraitTP",
          insurenceactivecss: "insurenceactivePortraitTP",
          rankinginfodelercss: "pokerrankingdelerPortraitTP",
          inforussiancss: "portraitinfoTP",
          drawChipXd: 350,
          drawChipYd: -2500,
          drawChipRetrunXd: 608,
          drawChipRetrunYd: -116,
          DelarAnteWinProLandAN: porttraitejson.dealerchipstoplayerPortrait
        })

      } else {
        document.getElementById("playerDealerCardContainerTP").classList.add("stage-top-mobile-landscape_TP");
        document.getElementById("landscapeInfoTP").style.display = "block";
        document.getElementById("infocontainerTP").style.display = "none"

        console.log(this.state.Winnindanimation)
        if (this.state.Winnindanimation) {

          gsap.to("#chipcenterpositionTP", { opacity: 0, zIndex: -10 });
          gsap.to("#betchipsaddtobetTP", {
            opacity: 0, zIndex: -10, onComplete: () => {
              // betText.textContent = this.topLable_rsp( Number((res.anteWinAmount + res.winAmount).toFixed(2)));
            }
          });
          gsap.to("#betTextaddtoBetTP", { opacity: 0, zIndex: -10 });
          winpopuprspelement.classList.add("winPopupForMLspTP");
          winamtElement12.classList.add("winamtMpSwTP");
          winpopuprspelement.classList.remove("winPopupForMptSwTP");

        }
        if (this.state.AnteWinCHanimation) {
          gsap.to("#dealerchipTP", { opacity: 0, zIndex: -10, autoAlpha: 0 });
        }

        if (this.state.PayoutTableToggle) {
          if (document.getElementById("PAYOUTTABLETPS")) {
            document.getElementById("PAYOUTTABLETPS").classList.add("showPayoutLandscpeTP")
            document.getElementById("PAYOUTTABLETPS").classList.remove("showPayoutTPprtrt")
          }
        }

        this.setState({
          // dealerDistanceFromX: -385,
          // playerDistanceFromX: 615,
          // dealerDistanceFromY: 200,
          // PlayerDistanceFromY: 220,
          heightOfLayer: 1050,
          // widthOfLayer: 530,
          widthOfLayer: 760,
          dealerDistanceFromX: -300,
          playerDistanceFromX: -150,
          // dealerDistanceFromX: -270,
          // playerDistanceFromX: -130,
          dealerDistanceFromY: -330,
          // dealerDistanceFromY: -200,
          PlayerDistanceFromY: 480,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,

          bnsactvglwcss: "bnsactiveLandscapeTP",
          betactvglwcss: "betactiveLndspTP",
          betbuttoncss: porttraitejson.Betbtnlndspe,
          betbtntextcss: "betLandscapeTP",
          foldbuttoncss: porttraitejson.foldbtnlndspe,
          foldbtntextcss: "foldtextLandscapeTP",
          buybtncss: porttraitejson.Buybtnlndspe,
          buybtntxtcss: "buytextLndspTP",
          drawbtncss: porttraitejson.Drawbtnlndspe,
          drawbtntxtcss: "drawtextLndspTP",
          betchipstoantebetcss: porttraitejson.betchiplndspe,
          betchipstobonusbetcss: porttraitejson.betchipstobnsLndsp,
          chipsstoredcontainercss: "chipsstoredcontainerLndspTP",
          antebetpdvcss: "chip-center-position-lndsp-TP",
          antechpimgcss: "chip20centerpositionPortraitTP",
          antespntxtcss: "antebetChipPortrait20TP",
          bonusdivcss: "bonusChipsCntrFrLndspTP",
          bonusimgcss: "bonusChipsForPortraitTP",
          bonuspntxtcss: "bonusChipsTextfrLndspTP",
          betchiptobetbtncss: porttraitejson.BetchiptobetLndsp,
          // betbtntxtcss:"bet-text-addtoBet-lndsp",
          betbtntxtcss: `bet-text-addtoBet-lndsp-TP ${chipsElmtextclass}`,
          buydrawcontainer: "drawChipDivLndspTP",
          drawchipcss: "betchipsaddtodrawTP",
          // drawchiptextcss:"betTextaddwLndspTP",
          drawchiptextcss: `betTextaddwLndspTP ${chipsElmtextclass}`,
          insurancedivcss: "chip-to-insurence-lndspTP",
          insurancechipcss: "chiptoinsurenceLndSpeTP",
          insurancetxtcss: "insuranceTextLndspeTP",
          dealerhandcss: "pokerrankingdelerLndSpeTP",
          playerhandcss: "pokerrankingPlayerLndspTP",
          mobileAntiMaskCovercss: "mobileLndspAntiMaskCoverTP",
          betpannelrussiancovercss: "PlayerCardpannelcoverLndspTP",
          insurenceactivecss: "insurenceactiveLndspTP",
          rankinginfodelercss: "pokerrankingdelerLndSpeTP",
          inforussiancss: "lndspinfoTP",
          drawChipXd: -1250,
          drawChipYd: -1000,
          drawChipRetrunXd: 130,
          drawChipRetrunYd: -36,
          DelarAnteWinProLandAN: porttraitejson.dealerchipstoplayerLndsp
        });
        cardDeckDivElment.classList.add("cardDeckLandscapeTP")
        if (this.state.pokerrankingdeler !== "") {
          document.getElementById('rankinginfodelerTP').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerLndSpeTP")
        } else {
          document.getElementById('rankinginfodelerTP').className = "";
        }

        if (this.state.pokerranking !== "") {
          document.getElementById('rankinginfoTP').className = "";
          rankinginfoElement.classList.add("pokerrankingPlayerLndspTP")
        } else {
          document.getElementById('rankinginfoTP').className = "";
        }

        if (this.state.isMobilePortraitLndsp) {
          // rankinginfoElement.style.left = "62.5%";
          rankinginfoElement.style.left = "43.5%";
        }

        if (this.state.betshigh_balnce !== null) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndspTP")
        }
        document.getElementById("infoTroppicalland").classList.add("lndspinfoTP");
        minmaxbetsElement.classList.add("minmaxbets_lndspTP");

        containerPlayerdealerElement.classList.add("cntr_pyrdlr_lnspTP");
        if (chipsstoredcontainerElement) {
          chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndspTP");
        }
        document.getElementById("Player").style.fontSize = "80px";

        document.getElementById("infoTroppicalland").classList.add("lndspinfoTP");


        document.getElementById("topcardscontainerTP").classList.remove("topcardsTP");
        document.getElementById("betcontainerTP").classList.remove("betpannelTropicalstud");

        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextLandscapeTP", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextLandscapeTP");
        }
        Object.assign(betcontainerElement.style, porttraitejson.betpannelrussianIandscape);

        drwtext.classList.remove("drawtextportraiteTP");

        drwtext.classList.remove("drawtextwindowsTP");
        // foldbtntext.className = "";
        // betbtntextTP.className = "";

        drwtext.classList.add("drawtextlandspeTP");
        betbtntextTP.classList.add(this.state.betbtntextcss);
        foldbtntext.classList.add(this.state.foldbtntextcss);

        pokerrankingone.classList.add("royalflshlndspeTP");
        document.getElementById("royaltoTP").classList.add("royaltocolorTP");

        pokerrankingtwo.classList.add("strightflushlndspeTP");
        document.getElementById("strghttoTP").classList.add("royaltocolorTP");

        pokerrankingthree.classList.add("fourofkindlndspeTP");
        document.getElementById("frkndtoTP").classList.add("royaltocolorTP");

        pokerrankingfour.classList.add("fullhouselndspeTP");
        document.getElementById("fullhsetoTP").classList.add("royaltocolorTP");

        pokerrankingfive.classList.add("flushlndspeTP");
        document.getElementById("flushtoTP").classList.add("royaltocolorTP");

        pokerrankingsix.classList.add("strightlndspeTP");
        document.getElementById("strighttoTP").classList.add("royaltocolorTP");

        pokerrankingseven.classList.add("threekindlndspeTP");
        document.getElementById("ThreekindtoTP").classList.add("royaltocolorTP");

        pokerrankingeight.classList.add("twopairlndspeTP");
        document.getElementById("twopairtoTP").classList.add("royaltocolorTP");

        pokerrankingnine.classList.add("onepairlndspeTP");
        document.getElementById("onepairtoTP").classList.add("royaltocolorTP");

        pokerrankingten.classList.add("acekinglndspeTP");
        document.getElementById("acekingtoTP").classList.add("royaltocolorTP");

        betcirclerussian.src = "";
        betcirclerussian.style.cssText = "";
        betRussiangme.src = "";
        betRussiangme.style.cssText = "";


        buydrawRussiangme.src = "";
        buydrawRussiangme.style.cssText = "";


        bonusRussiangme.src = "";
        bonusRussiangme.style.cssText = "";


        playerDetailsElement.classList.add("playerIdDetailsLandscapeTP");
        bacaratbgimTP.style.background = `url(${landscapebg})`;
        bacaratbgimTP.style.backgroundSize = "cover";
        bacaratbgimTP.style.width = porttraitejson.bacaratbg.width;
        bacaratbgimTP.style.height = porttraitejson.bacaratbg.height;
        bacaratbgimTP.style.left = 0;

        dealerBuyCardElement.classList.add('dealerBuyCardLndspTP');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortraitTP');
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchiplndspe);


        bonusRussiangme.src = russianbetbtright;
        Object.assign(bonusRussiangme.style, porttraitejson.bonusbtnlndspe);
        document.getElementById("bonustextTP").className = "";
        document.getElementById("bonustextTP").classList.add("bonustextlndspeTP");
        russiantiname.src = russiantitle;
        Object.assign(russiantiname.style, porttraitejson.russiantitlelndscape);


        buydrawRussiangme.src = russianbetbtnleft;
        Object.assign(buydrawRussiangme.style, porttraitejson.drawbuybtnlndspe);


        document.getElementById("buydrawtextTP").className = "";

        document.getElementById("buydrawtextTP").classList.add("buydrawtextlndspeTP");

        document.getElementById("antetextTP").className = "";
        document.getElementById("antetextTP").classList.add("antetextlnspeTP");

        betRussiangme.src = russianbetbtndown;
        Object.assign(betRussiangme.style, porttraitejson.betbtnlndspe);


        document.getElementById("bettextTP").className = "";
        document.getElementById("bettextTP").classList.add("bettextlndspeTP");

        betcirclerussian.src = russianbetcle;
        Object.assign(betcirclerussian.style, porttraitejson.becirclelndspe);


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, porttraitejson.volumelnspe);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, porttraitejson.useridlndspe);


        document.getElementById("useridtextTP").className = "";
        document.getElementById("useridtextTP").classList.add("useridlandscapeTP");

        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, porttraitejson.optionbtnlandspe);

        beticonrussian.src = beticonwindows;
        // beticonrussian.src = beticonTP;
        Object.assign(beticonrussian.style, porttraitejson.beticonlandspe);


        document.getElementById("betsTP").className = "";
        document.getElementById("betsTP").classList.add("betslandspeTP");
        // beticonrussian.style.transform =porttraitejson.beticonlandspe.transform;

        // russianbncicon.src = balanceicon;
        russianbncicon.src = balanceiconwindows;
        Object.assign(russianbncicon.style, porttraitejson.balanceiconlndspe);


        document.getElementById("balance").className = "";
        document.getElementById("balance").classList.add("balancelndscapeTP");
        // russianbncicon.style.transform = porttraitejson.balanceicon.transform;

        // exitbtnTP.src = exitrussian;
        Object.assign(exitbtnTP.style, porttraitejson.exitlanspe);


        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, porttraitejson.winnericonlndspe);


        document.getElementById("winsTP").className = "";
        document.getElementById("winsTP").classList.add("winslandspeTP");

        informi.src = informicon;
        Object.assign(informi.style, porttraitejson.infrmicnlnspe);

        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, porttraitejson.betincreamentlndspe);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrementlndspe);

      }

      const drawtextElement = document.getElementById("drawtextTP");
      if (drawtextElement) {
        drawtextElement.classList.add("drawtextopacityTP");
      }

    }
  };

  showPreviousBetsStatus = () => {
    const { response } = this.props.gameState;
    console.log(response)
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      if (response) {
        document.getElementById("betpannelTropicalcover").style.display = "block";
        document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
      }
      else {
        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

      }
    }

    if (response) {
      this.buyCardUnsequenceArray = [...this.props?.gameState?.response?.playerFinalCards];

      this.highlightPlayerCardsName(response.playerHand);

      this.setState({
        anteBetAmount: response.anteBetAmount,
        bonusbetamount: response.bonusBetAmount,
        // playerrankinghand: handrank

      });

      switch (response.action) {
        case "PARAM_BET":

          this.showcardsAdBetsParamState(response, response.playerFinalCards, response.dealerCards);
          this.showParamBetStatus(false);
          this.ShowbonusstatusTousr(response);

          this.setState({
            infoText: response.message
          })


          break;
        case "BET":
          this.showrussianPokerresp(response);
          break;
        case "FOLD":
          // this.Show_FoldHandresp(response);
          if (
            (navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {

            const drawbutton = document.getElementById("drawbtnTP");
            const buybutton = document.getElementById("buybtnTP");
            const betbutton = document.getElementById("betbtnTP");
            const foldbutton = document.getElementById("FoldbtnTP");

            const drawtextElement = document.getElementById("drawtextTP");
            const betbtntextElement = document.getElementById("betbtntextTP");
            const foldtextElement = document.getElementById("foldtextTP");

            betbtntextElement.textContent = "";
            foldtextElement.textContent = "";
            drawtextElement.textContent = "";

            drawbutton.src = "";
            drawbutton.style.cssText = "";
            buybutton.src = "";
            buybutton.style.cssText = "";

            betbutton.src = "";
            betbutton.style.cssText = "";


            foldbutton.src = "";
            foldbutton.style.cssText = "";
            this.Show_FoldHandresp(response);

          } else {
            this.showPlayerorDealerorFold(response);
          }

          break;
        case "CONFIRM_FOLD":
          // this.Show_FoldHandresp(response);
          if (
            (navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            this.Show_FoldHandresp(response);

          } else {
            this.showPlayerorDealerorFold(response);
          }


          break;
        case "REPLACE_CARDS":
          this.replaceCards = []
          // this.ShowrpReplaceCards(response);
          this.drawReplaceCards = true;
          if (
            (navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
           
            //  this.showcardsAdBetsParamState(response,response.dealerCards,response.playerFinalCards);
            this.ShowBuyplayercardresp(response, 'REPLACE_CARDS');
            //  this.ShowrpReplaceCards(response);

          } else {

            this.ShowBuyplayercardresp(response, 'REPLACE_CARDS');
          }

          break;

        case "REDRAW_CARDS":
          this.replaceCards = []
          // this.ShowrpReplaceCards(response);
          this.drawReplaceCards = true;
          if (
            (navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
           
            //  this.showcardsAdBetsParamState(response,response.dealerCards,response.playerFinalCards);
            this.ShowBuyplayercardresp(response, 'REDRAW_CARDS');
          } else {

            this.ShowBuyplayercardresp(response, 'REDRAW_CARDS');
          }

          break;

        default:
          return []
      }

    }




  }


  showcardsAdBetsParamState = (response, playerFinalCards, dealerCards) => {
    setTimeout(() => {
      this.setState({
        totalbet: response.totalBetAmount,
        totalwinRussian: (Number(response.bonusBetAmount)) ? response.bonusWinAmount : 0,
        // totalwinRussian: (response.bonus && Number(response.bonusBetAmount)) ? response.bonusWinAmount : 0,
        // betdeductions:response.totalBetAmount....................commmented on 18-10-24.....................
      })
    }, 250)
    // this.onDrawingCards(response.playerFinalCards, false);
    // console.log(dealerCards)
    // console.log(playerFinalCards)




    let convrAntebt = this.topLable_rsp(response.anteBetAmount);

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (Number(response.anteBetAmount) > 0) {
        let antebetstatebetamount = this.topLable_rsp(response.anteBetAmount)
        this.setState((prevState) => ({
          storebetchips: [
            ...prevState.storebetchips,
            <div id="chipcenterpositionTP">
              <img id="centerchipsTP" className="chip10centerpositionTP"
                src={this.ReturnChipBasedAmount(response.anteBetAmount)}
                // src={chip_10}
                alt="Chip 10"
              />
              <span id="antebetval_10TP" className="antebet_textval10TP">{antebetstatebetamount}</span>
            </div>

          ],
        }));
      }
      if ((response.action === "PARAM_BET") ||
        (response.message === `Attention! You have a ${response.playerHand}. Are you sure you want to fold?` && response.action === "FOLD") ||
        (response.action === "REPLACE_CARDS" || response.action === "REDRAW_CARDS")
      ) {
        // if (response.bonus) {
        if (Number(response.bonusBetAmount) > 0 && Number(response.bonusWinAmount) > 0) {
          let bonusbetstateamnt = response.bonusBetAmount
          this.setState((prevState) => ({
            storebetchips: [
              ...prevState.storebetchips,
              <div id="chip10centerbnsTP">
                <img className="chip10centerbnsTP"
                  src={this.ReturnChipBasedAmount(bonusbetstateamnt)}
                  // src={chip_10}
                  alt="Chip 10"
                />
                <span id="antebetval_10TP" className="bonusChipsForwindows_val10_TP">{bonusbetstateamnt}</span>
              </div>
            ],
            totalwinRussian: response.bonusWinAmount,
          }));

          setTimeout(() => {
            this.ShowBonuspayouttable();
            this.showPayoutBonusTable12('BonusPayout');
            // this.bonuspaytable.map((obj, val) => {
            //   const bonushandplayer = document.getElementById(`numTropical${val}`);
            //   const bonushandplayerval = document.getElementById(`numbersvalrussianTP${val}`);
            //   let playerbonuhand = response.playerHand.split(" , ");
            //   if (bonushandplayer && playerbonuhand.includes(obj.k)) {
            //     if (bonushandplayer.classList.contains("numTP")) {
            //       bonushandplayer.classList.remove("numTP")

            //       bonushandplayer.classList.add("bonuswinhgltTP")
            //     }
            //     bonushandplayerval.classList.add("bonuspayouthgltTP")
            //   }
            // });

          }, 1000)
          this.ShowbonusstatusTousr(response);
        }

        // }
      }
      if (response.playerBuyCardAmount > 0 || response.playerReplaceCardAmount > 0) {

        gsap.set("#betchipsaddtodrawTP", { clearProps: "transform" });
        setTimeout(() => {
          gsap.to("#betchipsaddtodrawTP", {
            x: -1084,
            y: -598,
            duration: 0.1
          });
        }, 10);

        let betexttodraw = document.getElementById("betchipsaddtodrawTextTP");
        betexttodraw.classList.add("bet-text-addtodrawTP", "bet-text-addtoinsurence10TP")
        gsap.to("#betTextaddtodrawTP", { opacity: 2, zIndex: 15 })
        let betchipsdraw = document.getElementById("betchipsaddtodrawTP");


        // console.log(betexttodraw)

        let betTextaddtoBetClass = "bet-text-addtodraw20TP";
        // this.setState((prevState) => ({
        //   totalbet: prevState.totalbet + prevState.anteBetAmount

        // }));
        let buyreplacestatebet
        // Object.assign(betchipsdraw.style,porttraitejson.drawchpgamestatewindows)
        Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

        if (response.playerBuyCardAmount > 0) {

          betchipsdraw.src = chip_10;
          buyreplacestatebet = this.topLable_rsp(response.playerBuyCardAmount)
          betexttodraw.textContent = buyreplacestatebet
          gsap.to("#betchipsaddtodrawTP", { x: -4, y: -2, duration: 0.1 })
          document.getElementById("betchipsaddtodrawTextTP").classList.add("bet-text-addtodrawTP,bet-text-addtodraw10TP")
          // console.log(betexttodraw)
        }
        else {
          betchipsdraw.src = chip_10;
          buyreplacestatebet = this.topLable_rsp(response.playerReplaceCardAmount)
          document.getElementById("betchipsaddtodrawTextTP").classList.add("bet-text-addtodrawTP,bet-text-addtodraw10TP")
          betexttodraw.textContent = buyreplacestatebet
          // console.log(betexttodraw)
        }

      }



    }
    else {

      if (Number(response.anteBetAmount) > 0) {
        document.getElementById("mobileAntiMaskCoverTP").style.display = "block";
        this.setState((prevState) => ({
          storeantebetchips: [
            ...prevState.storeantebetchips,
            { chip: this.ReturnChipBasedAmount(response.anteBetAmount), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1TP" },

          ],
          bsbtn: true
        }));

      }


      // switch(response.action){
      //   case "PARAM_BET":


      if ((response.action === "PARAM_BET") ||
        (response.message === `Attention! You have a ${response.playerHand}. Are you sure you want to fold?` && response.action === "FOLD") ||
        (response.action === "REPLACE_CARDS" || response.action === "REDRAW_CARDS")
      ) {
        // if (response.bonus) {
        if (Number(response.bonusBetAmount) > 0 && Number(response.bonusWinAmount) > 0) {
          // if(true){
          let convrBonusbet = this.topLable_rsp(response.bonusBetAmount);
          this.setState((prevState) => ({
            storebonuschips: [
              ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(response.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },

            ],
            totalwinRussian: response.bonusWinAmount,
            bsbtn: true
          }));
          setTimeout(() => {
            this.ShowBonuspayouttable();
            this.showPayoutBonusTable12('BonusPayout');

            // this.bonuspaytable.map((obj, val) => {
            //   const bonushandplayer = document.getElementById(`numTropical${val}`);
            //   const bonushandplayerval = document.getElementById(`numbersvalrussianTP${val}`);



            //   let playerbonuhand = response.playerHand.split(" , ");

            //   if (bonushandplayer && playerbonuhand.includes(obj.k)) {
            //     const isPortrait = window.innerHeight > window.innerWidth;

            //     if (isPortrait) {
            //       if (bonushandplayer.classList.contains("numPortraitTP")) {
            //         bonushandplayer.classList.remove("numPortraitTP")
            //         bonushandplayerval.classList.add("bonuspayouthglt_portraiteTP")
            //         bonushandplayer.classList.add("bonuswinhglt_portriteTP")

            //       }

            //     }
            //     else {
            //       if (bonushandplayer.classList.contains("numPortraitTP")) {
            //         bonushandplayer.classList.remove("numPortraitTP")
            //         bonushandplayerval.classList.add("bonuspayouthglt_portraiteTP")
            //         bonushandplayer.classList.add("bonuswinhglt_portriteTP")

            //       }

            //     }


            //   }
            // });

          }, 1000)

          setTimeout(() => {
            this.ShowBonuspayouttable()
            this.showPayoutBonusTable12('BonusPayout');
          }, 2500)
          this.ShowbonusstatusTousr(response);
        }
        // }

      }
    }
    this.shuffledPlayerCards = [...playerFinalCards]
    this.randomDealerCards = []
    for (let m = 0; m < dealerCards.length; m++) {
      if (dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(dealerCards[m]);


      }
    }

    // console.log("this.randomDealerCards", this.randomDealerCards);
    // console.log("this.randomPlayerCards", this.shuffledPlayerCards);

    let cnt = this.shuffledPlayerCards.length;
    let cnt2 = this.randomDealerCards.length;
    for (let i = 0; i < cnt2; i++) {
      let b1 = this.randomDealerCards[i];
      switch (i) {
        case 0:
          this.dealerCardsContainer.current.showPreviousDealercards("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.showPreviousDealercards("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.showPreviousDealercards("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.showPreviousDealercards("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.showPreviousDealercards("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.showPreviousDealercards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    for (let i = 0; i < cnt; i++) {
      let b2 = this.shuffledPlayerCards[i];
      switch (i) {
        case 0:
          this.cardsRef.current.showPreviousPlayercards("cardZero", b2, cnt);
          break;
        case 1:
          this.cardsRef.current.showPreviousPlayercards("cardOne", b2, cnt);
          break;
        case 2:
          this.cardsRef.current.showPreviousPlayercards("cardTwo", b2, cnt);
          break;
        case 3:
          this.cardsRef.current.showPreviousPlayercards("cardThree", b2, cnt);
          break;
        case 4:
          this.cardsRef.current.showPreviousPlayercards("cardFour", b2, cnt);
          break;
        case 5:
          this.cardsRef.current.showPreviousPlayercards("cardFive", b2, cnt);
          // this.cardsRef.current.addCards("cardFive", b2, cnt);
          break;
        default:
          break;
      }

    }
  }

  ShowbonusstatusTousr = (res) => {

    let info = "";
    let spokerranking = "";
    let betactiveTP = ""

    setTimeout(() => {
      this.setState({ drawbuttonhandle: true, disDrawbtn: 1 });
      document.getElementById("drawtextTP").classList.add("drawtextopacityTP");
      document.getElementById("drawbtnTP").classList.add("drwnodropTP");

    }, 500)

    let mode = ""
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {
      document.getElementById("playerDealerCardContainerTP").classList.add("addpointerTP")
      info = "windowsinfoTP";
      spokerranking = "windowspokerrankingTP";
      betactiveTP = "betactiveTP"
      mode = "Windows"

    } else {
      //  this.setState({ isbonuspattable: false });
      document.getElementById("betpannelTropicalcover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        spokerranking = "pokerrankingPlayerLndspTP";
        info = "lndspinfoTP";
        // betactiveTP = "betactiveLndspTP";
        betactiveTP = this.state.betactvglwcss;
        mode = "landscape";
      } else {
        info = "portraitinfoTP";
        // betactiveTP = "betactivePortraitTP";
        betactiveTP = this.state.betactvglwcss;
        spokerranking = "pokerrankingPortaitTP";
        mode = "portrait";
      }
    }

    if (document.getElementById("infoTropicalP")) {
      document.getElementById("infoTropicalP").classList.add(info);
      document.getElementById("infoTroppicalland").classList.add(info);
    };

    this.setState({
      pokerranking: res.playerHand,
      infoText: res.message,
      multibuttonsebledsble: false,
      disablemultibutton: false


    });

    document.getElementById("rankinginfoTP").className = "";
    document.getElementById("rankinginfoTP").classList.add(spokerranking);
    this.setState({ isbonuspattable: false });
    document.getElementById("bnsactiveTP").src = "";
    document.getElementById("bonustextTP").style.color = "";
    if (Number(res.bonusWinAmount) > 0) {
      // if (res.bonus && Number(res.bonusWinAmount) > 0) {
      if (mode === "Windows") {
        if (res.bonusBetAmount) {
          setTimeout(() => {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbnsTP", {
              x: 415, y: 60, duration: 0.1, opacity: 1,
              onComplete: () => {
                gsap.set("#chip10centerbnsTP", { opacity: 1 });
                document.getElementById("glowonwinchipTP").classList.add("chipglow_bonusTP")
              }
            });
            t1.play();
            const bonusText = document.querySelectorAll("#chip10centerbnsTP span");
            // console.log(bonusText)
            for (let b = 0; b < bonusText.length; b++) {
              bonusText[b].textContent = res.bonusWinAmount;
            }
          }, 500);

        }
      }
      else if (mode === "portrait") {
        this.playerWinBonus = true;
        this.setState({ isbonuspattable: false });
        document.getElementById("bonustextTP").style.color = "";
        // if (res.bonus) {
        if (Number(res?.bonusWinAmount) > 0) {
          // if(true){
          setTimeout(() => {
            if (document.getElementById("chip10centerbnsTP")) {
              gsap.to("#chip10centerbnsTP", {
                x: -630, y: 925, duration: 0.000001, autoAlpha: 1
              });
            }

            const bonusText = document.querySelectorAll("#chip10centerbnsTP span");
            // console.log(bonusText)
            for (let b = 0; b < bonusText.length; b++) {
              bonusText[b].textContent = res.bonusWinAmount;
            }

          }, 500)


        }

      } else {
        this.playerWinBonus = true;
        document.getElementById("bonustextTP").style.color = "";
        // if (res.bonus) {
        if (Number(res?.bonusWinAmount) > 0) {
          setTimeout(() => {
            if (document.getElementById('chip10centerbnsTP')) {
              gsap.to("#chip10centerbnsTP", {
                x: 550, y: -130, duration: 0.000001, autoAlpha: 1,
              });

            }
            const bonusText = document.querySelectorAll("#chip10centerbnsTP span");
            // console.log(bonusText)
            for (let b = 0; b < bonusText.length; b++) {
              bonusText[b].textContent = res.bonusWinAmount;
            }
          }, 500)


        }
      }

    } else {

      if (mode === "Windows") {
        if (this.state.bonusbetamount) {
          let t1 = gsap.timeline();
          t1.to("#chip10centerbnsTP", {
            x: 0, y: -649, duration: 0.5, opacity: 1,
            onComplete: () => {
              gsap.set("#chip10centerbnsTP", { opacity: 0 });
            }
          });
          t1.to("#chip10centerbnsTP", {
            x: 861, y: 557, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set("#chip10centerbnsTP", { opacity: 0, delay: 2 });
            }

          });
          t1.play();
        }

      } else if (mode === "portrait") {
        this.playerWinBonus = false;
        document.getElementById("bonustextTP").style.color = "";
        if (this.state.bonusbetamount) {
          if (document.getElementById('chip10centerbnsTP')) {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbnsTP", {
              x: 0, y: -900, duration: 0.5, opacity: 1,
              onComplete: () => {
                gsap.set("#chip10centerbnsTP", { opacity: 0 });
              }
            });
            t1.to("#chip10centerbnsTP", {

              x: -163, y: 1195, duration: 0.0005, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set("#chip10centerbnsTP", { opacity: 0, });
              }

            });
            t1.play();
          }

        }


      } else {

        this.playerWinBonus = false;
        document.getElementById("bonustextTP").style.color = "";
        if (this.state.bonusbetamount) {
          if (document.getElementById('chip10centerbnsTP')) {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbnsTP", {
              x: 0, y: -900, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
            });
            t1.to("#chip10centerbnsTP", {
              x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

            });
            t1.play();
          }

        }

      }


    }
    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });

    this.highlightPlayerCardsName(res.playerHand);



    document.getElementById("betactiveTP").src = bnsactiveTP;
    document.getElementById("bettextTP").style.color = "#ffffff";
    if (mode === "Windows") {
      document.getElementById("betTropical").classList.add("addpointerTP");
      document.getElementById("betactiveTP").classList.add(betactiveTP);

    } else {

    }
    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });

  }


  showPlayerorDealerorFold = (res) => {

    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
      // this.showParamBetStatus(false); //  disble the all button chips,  if we have are you sure you want to fold cards?
      this.ShowbonusstatusTousr(res);
      this.ConfirmbuydealercardactivePopup = true;
      if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
        this.drawReplaceCards = true;
        //  if(res.playerBuyCardAmount > 0){
        this.prevstateBuyorDrawChip(res);
      }

      this.setState({
        infoText: res.message,
        cmfrmdealeraction: 2,
        cmfrmdealeractionNO: 2
      })
      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");
      if (window.innerHeight > window.innerWidth) {

        confirmbuydealerPopupElement.className = "";
        confirmbuydealerPopupElement.style.display = "block";
        confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait_TP");
        document.getElementById("alertTxtTP").innerHTML = res.message;
        document.getElementById("alertTxtTP").classList.add('alertTxtPortraitTP');
        document.getElementById("yesTP").classList.add("yesbutton_popup_portraitTP")
        document.getElementById("noTP").classList.add("nobutton_popup_portraitTP")

      }
      else {
        confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp_TP");
        document.getElementById("alertTxtTP").innerHTML = res.message;
        document.getElementById("alertTxtTP").classList.add('alertTxtPortraitTP');
        document.getElementById("yesTP").classList.add("yesbutton_popup_portraitTP")
        document.getElementById("noTP").classList.add("nobutton_popup_portraitTP")

      }
    } else {
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      const betbtntextElement = document.getElementById("betbtntextTP");
      const foldtextElement = document.getElementById("foldtextTP");
      const rankinginfodelerElement = document.getElementById("rankinginfodelerTP");
      const rankinginfoElement = document.getElementById("rankinginfoTP");
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];



      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      this.setState({
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        infoText: this.props.language.Place_antebet,
        totalbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        insurencetbet: 0,
        pokerranking: "",
        pokerrankingdeler: "",
        //  totalbet:res.totalBetAmount,,
        betbutton: "REBET",
        buttontext: "REBETX2",
      });


      document.getElementById("betpannelTropicalcover").style.display = "none";
      betbutton.src = Betbtn;
      foldbutton.src = Foldbnwindows;

      betbtntextElement.className = "";
      foldtextElement.className = "";
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";


      betbtntextElement.textContent = this.props.language.Rebet;
      foldtextElement.textContent = this.props.language.RebetX2;

      if (Number(res.anteBetAmount) > 0) {
        let convrAntebt = this.topLable_rsp(res.anteBetAmount);
        this.setState((prevState) => ({
          // storeantebetchips: [
          prevStatestoreantebetchips: [
            ...prevState.storeantebetchips,
            { chip: this.ReturnChipBasedAmount(res.anteBetAmount), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1TP" },

          ],
        }));

      }

      if (Number(res.bonusBetAmount) > 0) {
        let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
        this.setState((prevState) => ({
          // storebonuschips: [
          prevStatestorebonuschips: [
            ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },

          ],
        }));
      }


      this.playerWinBonus = false;
      if (window.innerHeight > window.innerWidth) {
        // portrait;
        setTimeout(() => {
          if (document.getElementById("chipcenterpositionTP")) {
            gsap.to("#chipcenterpositionTP", { x: 13, y: 1200, duration: 0.000001, zIndex: -2, opacity: 0 });
          }

          if (document.getElementById("betchipsaddtobetTP")) {
            gsap.to("#betchipsaddtobetTP", { x: 1, y: 76, duration: 0.000001, zIndex: -2, opacity: 0 });
            gsap.to("#betTextaddtoBetTP", { x: 153, y: 185, duration: 0.000001, zIndex: -2, opacity: 0 });
          }

          gsap.to(this.chipdrawref.current, { x: 608, y: -116, duration: 0.000001, zIndex: -5, opacity: 0 });

          if (document.getElementById("chip10centerbnsTP")) {
            gsap.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.000001, zIndex: -2, opacity: 1 });


          }
          if (this.buyDealerCardDrawn.current) {
            gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.000001, zIndex: -2, opacity: 0 });
          }

        }, 100);



        // betbtntextElement.classList.add("betportraitTP");
        // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);

        betbtntextElement.classList.add(this.state.betbtntextcss);
        Object.assign(betbutton.style, this.state.betbuttoncss);

        // Object.assign(betbtntextElement.style, porttraitejson.betportraiteTP);
        Object.assign(foldbutton.style, this.state.foldbuttoncss);
        foldtextElement.classList.add(this.state.foldbtntextcss);

      } else {
        // landscape


        // betbtntextElement.classList.add("betportraitTP");
        Object.assign(betbutton.style,  this.state.betbuttoncss);
        betbtntextElement.classList.add(this.state.betbtntextcss);

         Object.assign(foldbutton.style, this.state.foldbuttoncss);
        foldtextElement.classList.add(this.state.foldbtntextcss);
        this.ifPlayerWinBonusAmount();

        setTimeout(() => {

          if (document.getElementById("chipcenterpositionTP")) {
            gsap.to("#chipcenterpositionTP", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          }


          if (document.getElementById("betTextaddtoBetTP")) {
            gsap.to("#betTextaddtoBetTP", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
            gsap.to("#betchipsaddtobetTP", { x: 0, y: 0, duration: 0.0001 });

          }
          if (this.chipdrawref) {
            gsap.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          }

          if (this.buyDealerCardDrawn) {
            gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2, duration: 0.0001 })
          }


          if (document.getElementById("dealerchipTP")) {
            gsap.set('#dealerchipTP', { x: 0, y: 0, opacity: 0, zIndex: -10, duration: 0.00001 })
          }

        }, 100)

      }

      setTimeout(() => {

        this.clearActivesWeDid();
      }, 500)

    }

  }

  betChipsIncrementDecremenAction = () => {
    const betchipsRussianpoker = document.getElementById("betchipsTP");

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      document.getElementById("val0").classList.remove("chip_10textTP");
      switch (this.state.count) {
        case 1:
          // console.log(this.storebetchips);
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("valtext_windowsTP");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10TP", "chip_10textTP");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          // this.setState({ totalbet: 20 })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtext_windowsTP");
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          // this.setState({ totalbet: 50 })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtext_windowsTP");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          // this.setState({ totalbet: 100 })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtext_windowsTP");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          // this.setState({ totalbet: 500 })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtext_windowsTP");
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          document.getElementById("val0").textContent = this.chipVal[5].label;
          document.getElementById("val0").classList.add("valtext_windowsTP");
          betchipsRussianpoker.src = chip_1000;
          break;

        default:
          break;
      }

      betchipsRussianpoker.style.position = porttraitejson.betchpwindows.position;
      betchipsRussianpoker.style.width = porttraitejson.betchpwindows.width;
      betchipsRussianpoker.style.top = porttraitejson.betchpwindows.top;
      betchipsRussianpoker.style.left = porttraitejson.betchpwindows.left;
      betchipsRussianpoker.style.zIndex = porttraitejson.betchpwindows.zIndex;


    } else {
      const isPortrait = window.innerHeight > window.innerWidth;

      document.getElementById("val0").textContent = "";
      document.getElementById("val1").textContent = "";
      document.getElementById("val2").textContent = "";
      document.getElementById("val3").textContent = "";

      // console.log(this.state.count)
      document.getElementById("val0").classList.remove("valtextPortrait1TP");

      switch (this.state.count) {
        case 1:
          // console.log(this.storebetchips);
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          document.getElementById("val0").classList.add("valtextPortraitTP", "valtextPortrait1TP");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtextPortraitTP");

          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtextPortraitTP");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtextPortraitTP");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtextPortraitTP");
          betchipsRussianpoker.src = chip_500;
          break;
        case 6:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          document.getElementById("val0").textContent = this.chipVal[5].label;
          document.getElementById("val0").classList.add("valtextPortraitTP");
          betchipsRussianpoker.src = chip_1000;
          break;
        default:
          break;
      }

      if (isPortrait) {

        Object.assign(betchipsRussianpoker.style, porttraitejson.betchp);
      } else {
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchiplndspe);
      }



    }

  }

  IncreamentBetchips = () => {
    let addDisable = document.getElementById('betincrenetTP')
    let decreasrDisable = document.getElementById('betdecrementTP')

    console.log(this.state.count)
    document.getElementById("TPbetcircle").classList.remove("insurencenodropTP")

    if (this.state.count < 6) {
      this.playPokerBetSound(betchipsnd)
      this.setState(
        (prevState) => ({
          count: prevState.count + 1,
        }),

        () => {
          // console.log(this.state.count)
          if (this.state.count > 6) {
            this.setState({ betchipincrementbtn: true, betdecreamentbtn: false });

          }
          else {

            this.playPokerBetSound(betchipsnd)
            this.setState({ betchipincrementbtn: false });

          }
          this.betChipsIncrementDecremenAction()
          console.log(this.state.count)

          if (this.state.count < 6) {
            decreasrDisable.classList.remove('icon_opacity')
          } else {
            addDisable.classList.add('icon_opacity')
            decreasrDisable.classList.remove('icon_opacity')
          }
        })

    }

    // console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
  };



  DecreamentBetchips = () => {
    // console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
    let addDisable = document.getElementById('betincrenetTP')
    let decreasrDisable = document.getElementById('betdecrementTP')

    const betchipsRussianpoker = document.getElementById("betchipsTP");
    document.getElementById("TPbetcircle").classList.remove("insurencenodropTP")

    if (this.state.count > 1) {
      this.setState(
        (prevState) => ({
          count: prevState.count - 1,
        }),
        () => {

          // console.log(this.state.count)
          if (this.state.count < 1) {
            this.setState({ betdecreamentbtn: true, betchipincrementbtn: false });
          } else {
            this.playPokerBetSound(betchipsnd)
            this.setState({ betdecreamentbtn: false });
          }

          this.betChipsIncrementDecremenAction();
          console.log(this.state.count)

          if (this.state.count > 1) {
            addDisable.classList.remove('icon_opacity')
          } else {
            decreasrDisable.classList.add('icon_opacity')
            addDisable.classList.remove('icon_opacity')
          }
        }
      );

    }


  };





  anteBet = () => {

    if (this.state.PayoutTableToggle) {
      this.setState({ PayoutTableToggle: false });
    }

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }

    document.getElementById("betincrenetTP").classList.add("drwnodropTP")
    document.getElementById("betdecrementTP").classList.add("drwnodropTP")

    // console.log("this.props.minBetAmt", this.props.minBetAmt, "this.props.maxBetAmt", this.props.maxBetAmt)

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
    if (popupbetGreaterthanBalnceElement) {
      popupbetGreaterthanBalnceElement.className = "";
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntialTP");
    }

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (this.state.anteBetAmount <= this.props.maxBetAmt) {

        document.getElementById("buttons_coverTP").classList.remove("buttons_coverTP")
      } else {
        document.getElementById("buttons_coverTP").classList.add("buttons_coverTP")

      }

      document.getElementById("foldtextTP").innerHTML = "";

      document.getElementById("crdscoverTP").classList.add("crdscoverTP")


      let bnsactived = document.getElementById("bnsactiveTP");
      bnsactived.src = "";
      bnsactived.classList.remove("bnsctivewindowsTP");
      bnsactived.classList.remove("bnsblinkTP");
      document.getElementById("bonustextTP").style.color = "";
      document.getElementById("betpannelTropicalcover").style.display = "block";
      document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
      // console.log(this.state.anteBetAmount)
      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        this.setState({
          infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
          disBetBtn: 1, keyboardhandle: false, multibuttonsebledsble: true, disablemultibutton: true

        })


        // console.log(this.state.anteBetAmount)
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          document.getElementById("betpannelTropicalcover").style.display = "block";
          document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
          document.getElementById("bonusTropical").classList.add("bnspoinerTP");

          const betbutton = document.getElementById("betbtnTP");
          const foldbutton = document.getElementById("FoldbtnTP");
          betbutton.src = "";
          betbutton.style.display = "none"
          betbutton.style.cssText = ""

          foldbutton.src = "";
          foldbutton.style.display = "none"
          foldbutton.style.cssText = ""
          let betbtntextElement = document.getElementById("betbtntextTP")
          let foldtextElement = document.getElementById("foldtextTP")
          betbtntextElement.textContent = ""
          foldtextElement.textContent = ""
          console.log(Number(this.state.bonusbetamount))
          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "TROPICAL_STUD_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBetAmount: Number(this.state.anteBetAmount.toFixed(2)),
              bonusBetAmount: Number(this.state.bonusbetamount?.toFixed(2)) ? Number(this.state.bonusbetamount?.toFixed(2)) : 0,
              handId: Number(this.props.gameState.handId)
            },
          };
          // console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })

        }
        else {

          this.setState({ multibuttonsebledsble: true, disablemultibutton: false })
          let betbtntextElement = document.getElementById("betbtntextTP")
          let foldtextElement = document.getElementById("foldtextTP")
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
          betbtntextElement.classList.add("betbtntextTP");
          betbtntextElement.textContent = this.props.language.Deal;

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
          foldtextElement.classList.add("foldtextTP");
          foldtextElement.textContent = this.props.language.RP_Clear;
          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            multibuttonsebledsble: true
          })
        }
      }
      else {
        this.setState({ betdecreamentbtn: false, betchipincrementbtn: false });
        document.getElementById("foldtextTP").innerHTML = this.props.language.RP_Clear
        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          multibuttonsebledsble: true


        })
      }

    } else {
      let bnsactived = document.getElementById("bnsactiveTP");
      bnsactived.src = "";
      bnsactived.className = "";
      bnsactived.className = "";
      document.getElementById("bonustextTP").style.color = "";
      this.setState({
        bsbtn: true,
        buydrawCheck: false
      })

      let betbtntextElement = document.getElementById("betbtntextTP")
      let foldtextElement = document.getElementById("foldtextTP")


      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          this.setState({
            infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
            disBetBtn: 1, keyboardhandle: false, multibuttonsebledsble: true, disablemultibutton: true

          })


          document.getElementById("mobileAntiMaskCoverTP").style.display = "block";
          document.getElementById("betpannelTropicalcover").style.display = "block";
          document.getElementById("playerDealerCardContainerTP").style.zIndex = 20;

          betbtntextElement.textContent = "";
          foldtextElement.textContent = "";

          betbutton.src = "";
          foldbutton.src = "";
          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";


          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "TROPICAL_STUD_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBetAmount: Number(this.state.anteBetAmount.toFixed(2)),
              bonusBetAmount: Number(this.state.bonusbetamount.toFixed(2)),
              handId: Number(this.props.gameState.handId)
            },
          };



          // console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })

        }

        else {
          betbutton.src = Betbtn;
          foldbutton.src = Foldbn;
          betbutton.style.cssText = Betbtn;
          foldbutton.style.cssText = Foldbn;
          if (window.innerHeight > window.innerWidth) {
            // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
            // betbtntextElement.classList.add("betportraitTP");

            Object.assign(betbutton.style, this.state.betbuttoncss);
            betbtntextElement.classList.add(this.state.betbtntextcss);
            betbtntextElement.textContent = this.props.language.Deal;


            Object.assign(foldbutton.style, this.state.foldbuttoncss);
            foldtextElement.classList.add("foldtextportraitTP");
            foldtextElement.textContent = this.props.language.RP_Clear;

          } else {

            // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
            // betbtntextElement.classList.add("betLandscapeTP");
            Object.assign(betbutton.style, this.state.betbuttoncss);
            betbtntextElement.classList.add(this.state.betbtntextcss);

            betbtntextElement.textContent = this.props.language.Deal;

             Object.assign(foldbutton.style, this.state.foldbuttoncss);
            foldtextElement.classList.add(this.state.foldbtntextcss);
            foldtextElement.textContent = this.props.language.RP_Clear;

          }


          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            // multibuttonsebledsble: true
          })
        }
      }
      else {
        document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
        document.getElementById("betpannelTropicalcover").style.display = "block";
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;
        if (window.innerHeight > window.innerWidth) {

          Object.assign(betbutton.style, this.state.betbuttoncss);

          betbtntextElement.classList.add(this.state.betbtntextcss);
          betbtntextElement.textContent = this.props.language.Deal;


          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.classList.add("foldtextportraitTP");
          foldtextElement.textContent = this.props.language.RP_Clear;

        } else {

          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.classList.add(this.state.betbtntextcss);
          betbtntextElement.textContent = this.props.language.Deal;

           Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.classList.add(this.state.foldbtntextcss);
          foldtextElement.textContent = this.props.language.RP_Clear;

        }

        // document.getElementById("betpannelTropicalcover").style.display = "none";
        // document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          // multibuttonsebledsble: true


        })
      }
    }

  };


  clearbets() {
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    if (this.state.PayoutTableToggle) {
      this.setState({ PayoutTableToggle: false });
    }
    this.clearallsettimeouts()
    // this.updateplayerbalnace();
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};



    document.getElementById("TPbetcircle").classList.remove("insurencenodropTP")
    document.getElementById("bonustextTP").style.color = ""
    document.getElementById("bnsactiveTP").src = "";
    document.getElementById("bnsactiveTP").classList.remove("betactiveTP");
    this.setState({ multibuttonsebledsble: false })
    // document.getElementById("betbtnTP").classList.add("addpointerTP")
    // document.getElementById("betbtnTP").classList.remove("drwnodropTP")
    this.clertime1 = setTimeout(() => {
      gsap.to(`#betchipsaddtoanteTP${this.state.idincrement}`, { zIndex: -2 });
      gsap.to(this.chipbnsref.current, { zIndex: -2 });
      document.getElementById("betpannelTropicalcover").style.display = "none";
      document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
    }, 1000);



    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");


    betbutton.src = "";
    document.getElementById("betbtntextTP").classList.remove("betwindowsTP");
    document.getElementById("betbtntextTP").textContent = "";
    document.getElementById("betbtntextTP").innerHTML = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    document.getElementById("foldtextTP").classList.remove("foldtextwindowsTP");
    document.getElementById("foldtextTP").textContent = "";
    document.getElementById("foldtextTP").innerHTML = "";
    foldbutton.style.cssText = ""

    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];
    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (this.state.mutesounds) {
        this.clear_snd = new Audio(clear_snd)
        this.clear_snd.pause()

      }
      else {
        this.clear_snd = new Audio(clear_snd)
        this.clear_snd.play()

      }

      this.setState({
        totalbet: 0, disClearBtn: 1,
        anteBetAmount: 0,
        bonusbetamount: 0, bsbtn: true,
        // infoText: this.props.language.Play_Njoy 
        infoText: this.props.language.Place_antebet
      });


      document.getElementById("bonusTropical").classList.add("bnspoinerTP");
      document.getElementById("bonusTropical").classList.add("addpointerTP");
      document.getElementById("TPbetcircle").classList.add("addpointerTP");
      document.getElementById("TPbetcircle").classList.remove("nonpointerTP")
      document.getElementById("bonusTropical").classList.add("drwnodropTP");
      document.getElementById("bonusTropical").classList.remove("addpointerTP")
      if (document.getElementById("popupbet_greaterthanbalnceTP")) {
        this.hidepopup_betshigh();
      }

      gsap.to(`#betchipsaddtoanteTP${this.state.idincrement}`, {
        x: 1,
        y: 0,
        duration: 0.1,
        ease: "power2.inOut",
        zIndex: 2,
        opacity: 0
      });


      this.hidepopup_betshigh();

      document.getElementById("betpannelTropicalcover").style.display = "block";
      document.getElementById("betpannelTropicalcover").classList.add("pannelcoverTP");
      setTimeout(() => {
        if (document.getElementById("chip10centerbnsTP")) {

          gsap.to(`#chip10centerbnsTP`, {
            x: 850,
            y: 500,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: -2,
            opacity: 0
          });


        }


        if (document.getElementById("chipcenterpositionTP")) {

          gsap.to("#chipcenterpositionTP", {

            x: 1000,
            y: 450,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: -2,
            opacity: 0
          });




        }

      }, 500)




    } else {

      // "mobileAntiMaskCoverTP"
      document.getElementById("mobileAntiMaskCoverTP").style.display = "block";
      setTimeout(() => {
        document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
      }, 2000)

      setTimeout(() => {

        if (this.state.mutesounds) {
          this.clear_snd = new Audio(clear_snd)
          this.clear_snd.pause()

        }
        else {
          this.clear_snd = new Audio(clear_snd)
          this.clear_snd.play()

        }

        this.setState({
          totalbet: 0, disClearBtn: 1,
          isbonuspattable: false, anteBetAmount: 0,
          bonusbetamount: 0, bsbtn: true,
          // infoText: this.props.language.Play_Njoy 
          infoText: this.props.language.Place_antebet
        });



        gsap.to(`#betchipsaddtoanteTP${this.state.idincrement}`, {
          x: 1,
          y: 0,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
        });

        setTimeout(() => {
          this.setState({
            // storebetchips:[]
            storeantebetchips: []
          })
        }, 1000)

        const isPortrait = window.innerHeight > window.innerWidth;
        // if(this.state.betshigh_balnce){
        if (document.getElementById("popupbet_greaterthanbalnceTP")) {
          this.hidepopup_betshigh();
        }
        // }

        document.getElementById("betpannelTropicalcover").style.display = "block";
        if (isPortrait) {
          if (document.getElementById("chipcenterpositionTP")) {
            gsap.to("#chipcenterpositionTP", {
              x: 25,
              y: 1200,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: 2,
              opacity: 0
            });
          }

          // let t1 = gsap.timeline();
          // t1.to("#chipcenterpositionTP", {
          //   y: -966, duration: 0.2, opacity: 1,
          //   onComplete: function () {
          //     gsap.set("#chipcenterpositionTP", { opacity: 0 });
          //   }
          // });
          // t1.to("#chipcenterpositionTP", {
          //   x: 13, y:-2170, duration: 0.0001, zIndex: -12, opacity: 0,
          //   onComplete: function () {
          //     gsap.set("#chipcenterpositionTP", { opacity: 0, });
          //   }

          // });
          // t1.play();


          if (document.getElementById('chip10centerbnsTP')) {
            gsap.to(`#chip10centerbnsTP`, {
              x: -160,
              y: 1200,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: -2,
              opacity: 0
            });

          }

        } else {

          if (document.getElementById("chipcenterpositionTP")) {
            gsap.to("#chipcenterpositionTP", {
              x: 1050,
              y: 0,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: 2,
              opacity: 0
            });
          }


          if (document.getElementById('chip10centerbnsTP')) {
            gsap.to(`#chip10centerbnsTP`, {
              x: 890,
              y: 0,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: -2,
              opacity: 0,
              // autoAlpha: 1,
              // onComplete: () => {
              //   gsap.to(`#chip10centerbnsTP`, {
              //     x: 0,
              //     y: 0,
              //     autoAlpha: 0,
              //     duration: 0.000001,
              //     zIndex: -2,
              //   })
              // }
            });

          }

        }

      }, 1000);
    }
  }

  Showbonustouser = (res) => {

    let info = "";
    let spokerranking = "";
    let betactiveTP = ""

    let mode = ""
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {
      mode = "Windows"
      betactiveTP = "betactiveTP"


    } else {
      document.getElementById("betpannelTropicalcover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        betactiveTP = "betactiveLndspTP";
        // betactiveTP = this.state.betactvglwcss;
        mode = "landscape";
      } else {
        betactiveTP = "betactivePortraitTP";
        // betactiveTP = this.state.betactvglwcss;
        mode = "portrait";
      }
    }

    // document.getElementById("betactiveTP").src = bnsactiveTP;
    document.getElementById("betactiveTP").classList.add(betactiveTP);

    this.clertime2 = setTimeout(() => {
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)

      ) {

        document.getElementById("bonustextTP").style.color = "";
        document.getElementById("playerDealerCardContainerTP").classList.add("addpointerTP");
        info = "windowsinfoTP";
        spokerranking = "windowspokerrankingTP"
        mode = "Windows"


      } else {
        //  this.setState({ isbonuspattable: false });
        // document.getElementById("betpannelTropicalcover").style.display = "none";
        this.setState({ bsbtn: true })
        if (window.innerWidth > window.innerHeight) {
          spokerranking = "pokerrankingPlayerLndspTP";
          info = "lndspinfoTP";
          mode = "landscape";
        } else {
          info = "portraitinfoTP";
          spokerranking = "pokerrankingPortaitTP";
          mode = "portrait";
        }
      }
      if (document.getElementById("infoTropicalP")) {
        document.getElementById("infoTropicalP").classList.add(info);
        document.getElementById("infoTroppicalland").classList.add(info);
      };

      this.setState({
        pokerranking: res.playerHand,
        // infoText: res.message,
        multibuttonsebledsble: false,
        disablemultibutton: false

        // betBtnValue:1,

      });
      document.getElementById("rankinginfoTP").className = "";
      document.getElementById("rankinginfoTP").classList.add(spokerranking);
      this.setState({ isbonuspattable: false });
      document.getElementById("bnsactiveTP").src = "";
      document.getElementById("bonustextTP").style.color = "";
    }, 4000);

    this.clertime3 = setTimeout(() => {

      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });

      this.highlightPlayerCardsName(res.playerHand);



      if (Number(res.bonusWinAmount) > 0) {
        // if (res.bonus && Number(res.bonusWinAmount) > 0) {


        this.setState({ totalwinRussian: res.bonusWinAmount })
        this.ShowBonuspayouttable();
        this.showPayoutBonusTable12('BonusPayout');
        // this.bonuspaytable.map((obj, val) => {
        //   const bonushandplayer = document.getElementById(`numTropical${val}`);
        //   const bonushandplayerval = document.getElementById(`numbersvalrussianTP${val}`);

        //   let playerbonuhand = res.playerHand.split(" , ");
        //   if (
        //     navigator.userAgentData &&
        //     navigator.userAgentData.platform &&
        //     navigator.userAgentData.platform == "Windows" || (navigator.userAgent.indexOf("Windows") !== -1)


        //   ) {
        //   }
        //   else {
        //     setTimeout(() => {

        //       this.playRemainingSounds(this.playerWin)

        //     }, 500)

        //   }

        //   if (bonushandplayer && playerbonuhand.includes(obj.k)) {

        //     if (
        //       (navigator.userAgentData &&
        //         navigator.userAgentData.platform &&
        //         navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)


        //     ) {
        //       setTimeout(() => {
        //         this.playRemainingSounds(this.playerWin)
        //       }, 1500)

        //       if (bonushandplayer.classList.contains("numTP")) {
        //         bonushandplayer.classList.remove("numTP");

        //         bonushandplayer.classList.add("bonuswinhgltTP");
        //       }
        //       bonushandplayerval?.classList.add("bonuspayouthgltTP");

        //     }
        //     else {


        //       setTimeout(() => {
        //         this.ShowBonuspayouttable()
        //       }, 1500)
        //       const isPortrait = window.innerHeight > window.innerWidth;

        //       if (isPortrait) {
        //         if (bonushandplayer.classList.contains("numPortraitTP")) {
        //           bonushandplayer.classList.remove("numPortraitTP")
        //           bonushandplayerval.classList.add("bonuspayouthglt_portraiteTP")
        //           bonushandplayer.classList.add("bonuswinhglt_portriteTP")

        //         }

        //       }
        //       else {
        //         if (bonushandplayer.classList.contains("numPortraitTP")) {
        //           bonushandplayer.classList.remove("numPortraitTP")
        //           bonushandplayerval.classList.add("bonuspayouthglt_portraiteTP")
        //           bonushandplayer.classList.add("bonuswinhglt_portriteTP")

        //         }

        //       }

        //     }


        //   }
        // });

        // if(true){
        const bonusText = document.querySelectorAll("#chip10centerbnsTP span");
        // console.log(bonusText)
        for (let b = 0; b < bonusText.length; b++) {
          bonusText[b].textContent = res.bonusWinAmount;
        }

        if (mode === "Windows") {
          if (this.state.bonusbetamount) {

            gsap.set(["#chip10centerbnsTP"], { clearProps: "all" });
            let t1 = gsap.timeline();
            t1.to("#chip10centerbnsTP", {
              x: 415, y: 60, duration: 0.5, opacity: 1, delay: 1,
              onComplete: () => {
                gsap.set("#chip10centerbnsTP", { opacity: 1 });
                document.getElementById("glowonwinchipTP").classList.add("chipglow_bonusTP")
              }
            });
            // t1.to("#chip10centerbnsTP", {
            //   x: 861, y: 546, duration: 0.5, zIndex: -2, opacity: 0,
            //   onComplete: () => {
            //     gsap.set("#chip10centerbnsTP", { opacity: 0, delay: 2 });
            //   }

            // });
            t1.play();

          }

        }
        else if (mode === "portrait") {
          this.playerWinBonus = true;
          // this.setState({ isbonuspattable: false });
          document.getElementById("bonustextTP").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById("chip10centerbnsTP")) {
              gsap.to("#chip10centerbnsTP", {
                x: -630, y: 925, duration: 0.5, opacity: 1
                // x: -615, y: 920, duration: 0.5, opacity: 1
              });
            }

          }

        } else {
          this.playerWinBonus = true;
          document.getElementById("bonustextTP").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbnsTP')) {

              gsap.to("#chip10centerbnsTP", {
                x: 550, y: -130, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });

            }

          }

        }


      } else {
        if (mode === "Windows") {
          if (res.bonusBetAmount > 0) {
            setTimeout(() => {
              this.playRemainingSounds(this.playerLose)
              this.playPokerChipSound(this.pokerchipsnd);
            }, 500)
          }
          if (this.state.bonusbetamount) {

            let t1 = gsap.timeline();
            t1.to("#chip10centerbnsTP", {
              x: 0, y: -649, duration: 0.5, opacity: 0, delay: 0.5,
              onComplete: () => {
                gsap.set("#chip10centerbnsTP", { opacity: 0 });
              }
            });
            t1.to("#chip10centerbnsTP", {
              x: 861, y: 557, duration: 0.1, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set("#chip10centerbnsTP", { opacity: 0 });
              }

            });
            t1.play();
          }

        } else if (mode === "portrait") {
          setTimeout(() => {
            // if (this.state.mutesounds) {
            //   this.playerLose = new Audio(playerLose)
            //   this.playerLose.pause();
            // }
            // else {

            //   this.playerLose = new Audio(playerLose)
            //   this.playerLose.play();

            // }
            if (res.bonusBetAmount > 0) {
              this.playRemainingSounds(this.playerLose)
              this.playPokerChipSound(this.pokerchipsnd);
            }

          }, 300)

          this.playerWinBonus = false;
          document.getElementById("bonustextTP").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbnsTP')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbnsTP", {
                x: 0, y: -900, duration: 0.5, opacity: 1,
                onComplete: () => {
                  gsap.set("#chip10centerbnsTP", { opacity: 0 });
                }
              });
              t1.to("#chip10centerbnsTP", {
                // x: 0, y: 557, duration: 0.0005, zIndex: -2, opacity: 0,
                x: -163, y: 1195, duration: 0.0005, zIndex: -2, opacity: 0,
                onComplete: () => {
                  gsap.set("#chip10centerbnsTP", { opacity: 0, });
                }

              });
              t1.play();
            }

          }


        } else {
          setTimeout(() => {
            if (res.bonusBetAmount > 0) {
              this.playRemainingSounds(this.playerLose)
              this.playPokerChipSound(this.pokerchipsnd);

            }
          }, 300)
          this.playerWinBonus = false;
          document.getElementById("bonustextTP").style.color = "";
          if (this.state.bonusbetamount) {
            if (document.getElementById('chip10centerbnsTP')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbnsTP", {
                x: 0, y: -900, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });
              t1.to("#chip10centerbnsTP", {
                x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

              });
              t1.play();
            }

          }

        }


      }


    }, 4000);

    if (mode === "Windows") {

    } else {
      document.getElementById("betactiveTP").classList.add(betactiveTP);
    }




    setTimeout(() => {

      // document.getElementById("bettextTP").style.color = "#ffffff";
      // document.getElementById("betactiveTP").src = bnsactiveTP;
      if (mode === "Windows") {
        document.getElementById("betTropical").classList.add("addpointerTP");
        document.getElementById("betactiveTP").classList.add(betactiveTP);
      }
    }, 5500)




    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];

    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });

  }







  TPAnteResultRes = (res) => {
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    // this.updateplayerbalnace();

    this.Showbonustouser(res);
    this.setState({ disClearBtn: 1, disBuybtn: 0 })
    this.randomPlayerCards = [...res.playerCards];
    this.shuffledPlayerCards = [...res.playerFinalCards];
    // console.log(this.randomPlayerCards)
    // console.log(this.shuffledPlayerCards)

    // this.randomDealerCards = [...res.dealerCards];

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      betbutton.style.display = "none"
      foldbutton.style.display = "none"
      let betbtntextElement = document.getElementById("betbtntextTP")
      let foldtextElement = document.getElementById("foldtextTP")
      betbtntextElement.textContent = ""
      foldtextElement.textContent = ""

      document.getElementById("buttons_coverTP").classList.add("buttons_coverTP")
      setTimeout(() => {
        this.setState({ infoText: res.message })
      }, 7500)

    }

    for (let m = 0; m < res.dealerCards.length; m++) {
      if (res.dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(res.dealerCards[m]);
      }
    }

    // console.log("this.randomDealerCards", this.randomDealerCards);
    // console.log("this.randomPlayerCards", this.randomPlayerCards);

    let cnt = this.randomPlayerCards.length;

    let mutesounds = this.state.mutesounds;

    function playSound(i) {
      let audio = new Audio(cardsdropping_snd);
      audio.currentTime = 0;
      if (mutesounds) {
        audio.pause();
      } else {
        audio.play();
      }


      audio.addEventListener('ended', function () {
        if (i + 1 < cnt) {
          this.clertime46 = setTimeout(() => {
            playSound(i + 1);
          }, 200)
          // },250)
        }
      });
    }


    for (let i = 0; i < cnt; i++) {
      var b1 = this.randomPlayerCards[i];
      // playSound(i);

      switch (i) {
        case 0:
          this.cardsRef.current.addCards("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.addCards("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.addCards("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.addCards("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.addCards("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.addCards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    document.getElementById("betpannelTropicalcover").style.display = "block";
    this.clertime4 = setTimeout(() => {
      this.setState({
        pokerranking: res.playerHand,

      });
      this.onDrawingCards(this.shuffledPlayerCards, false, res, true);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainerTP").style.zIndex = 1;
      }
      // }, 1300)
    }, cnt * 2 * 500);

    setTimeout(() => {
      document.getElementById("betpannelTropicalcover").style.display = "none";
    }, 7000)


    let dealerCardsLength = this.randomDealerCards.length;

    this.clertime5 = setTimeout(() => {
      for (let i = 0; i < dealerCardsLength; i++) {
        var a1 = this.randomDealerCards[i];
        // playSound(i);
        switch (i) {
          case 0:
            this.dealerCardsContainer.current.addCards(
              "cardZero",
              a1,
              dealerCardsLength
            );
            break;
          case 1:
            this.dealerCardsContainer.current.addCards(
              "cardOne",
              a1,
              dealerCardsLength
            );
            break;
          case 2:
            this.dealerCardsContainer.current.addCards(
              "cardTwo",
              a1,
              dealerCardsLength
            );
            break;
          case 3:
            this.dealerCardsContainer.current.addCards(
              "cardThree",
              a1,
              dealerCardsLength
            );
            break;
          case 4:
            this.dealerCardsContainer.current.addCards(
              "cardFour",
              a1,
              dealerCardsLength
            );
            break;
          default:
            break;
        }
      }
      // const foldbutton = document.getElementById("FoldbtnTP");
      // foldbutton.src = Foldbnwindows;
      // document.getElementById("foldtextTP").textContent = "Fold";
      // document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");
      // foldbutton.style.position = porttraitejson.foldbtnwindows.position;
      // foldbutton.style.top = porttraitejson.foldbtnwindows.top;
      // foldbutton.style.right = porttraitejson.foldbtnwindows.right;
      // foldbutton.style.width = porttraitejson.foldbtnwindows.width;
      // foldbutton.style.zIndex = porttraitejson.foldbtnwindows.zIndex;
      // }, dealerCardsLength * 300);
    }, dealerCardsLength * 400);

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)

    ) {

      document.getElementById("TPbetcircle").classList.add("addpointerTP")
      document.getElementById("TPbetcircle").classList.remove("insurencenodropTP");
    }

  };



  onDrawingCards(data, drawBuyHideAndShow, res, cardBasedOnSound) {

    // console.log(data);
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      var b1 = data[i];
      this.cardsshuffle = new Audio(cardsshuffle);
      this.cardsshuffle.currentTime = 0;
      // if (cardBasedOnSound) {
      //   if (b1) {
      //     if (this.cardsshuffle) {
      //       if (this.state.mutesounds) {
      //         this.cardsshuffle.pause();
      //       } else {
      //         this.cardsshuffle.play()

      //       }
      //     }


      //   }
      // }
      switch (i) {
        case 0:
          this.cardsRef.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }


    this.clertime6 = setTimeout(() => {
      this.setState({ infoText: res.message });
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.showParamBetStatus(drawBuyHideAndShow)

      }

      else {
        this.showParamBetStatus(drawBuyHideAndShow)
      }
    }, 2000)
  }


  showParamBetStatus = (drawBuyHideAndShow) => {
    const drawbutton = document.getElementById("drawbtnTP");
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const drawtextElement = document.getElementById("drawtextTP");

    const betbtntextElement = document.getElementById("betbtntextTP");
    const foldtextElement = document.getElementById("foldtextTP");

    betbtntextElement.textContent = "";
    foldtextElement.textContent = "";

    drawbutton.src = Drawbtnwindows;
    // drawbutton.style.cssText = "";


    betbutton.src = "";
    betbutton.style.cssText = "";


    foldbutton.src = "";
    foldbutton.style.cssText = "";

    drawtextElement.classList.add("drawtextopacityTP");

    this.setState({ drawbuttonhandle: true, disDrawbtn: 1 });

    document.getElementById("bettextTP").style.color = "#ffffff";
    document.getElementById("betactiveTP").src = bnsactiveTP;

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.buyCardsequenceArray = [];
      this.setState({ keyboardhandle: true, disBuybtn: 0, keyboardhandle: true, FoldbtnTP: 0 })
      if (drawBuyHideAndShow) {
        drawbutton.src = "";
        document.getElementById("drawtextTP").textContent = "";
        this.setState({ disDrawbtn: 1, disBuybtn: 1 })

      } else {
        document.getElementById("crdscoverTP").classList.remove("crdscoverTP")
        document.getElementById("buttons_coverTP").classList.remove("buttons_coverTP")
        drawbutton.src = Drawbtnwindows;
        document.getElementById("drawtextTP").textContent = this.props.language.Draw;
        Object.assign(drawbutton.style, porttraitejson.Drawbtnwindows);
        this.setState({ keyboardhandle: true, disBuybtn: 0, disBetBtn: 0, keyboardhandle: true, betBtnValue: 1 })


      }


      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
      document.getElementById("betbtntextTP").textContent = this.props.language.Bet;
      document.getElementById("betbtntextTP").classList.add("betwindowsTP");


      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
      document.getElementById("foldtextTP").textContent = this.props.language.Fold;
      document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");
      document.getElementById("betpannelTropicalcover").style.display = "block";
      document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");


      if (this.state.drawnbtnshows == 1) {
        const drawbutton = document.getElementById("drawbtnTP");
        drawbutton.src = Drawbtnwindows;
        drawbutton.classList.add("drwnodropTP")
        console.log(this.props.language.ReDraw)
        document.getElementById("drawtextTP").textContent = this.props.language.ReDraw;
        Object.assign(drawbutton.style, porttraitejson.Drawbtnwindows);
        document.getElementById('crdscoverTP').classList.remove("crdscoverTP")
      }

    } else {
      drawtextElement.textContent = "";
      const isPortrait = window.innerHeight > window.innerWidth;
      document.getElementById("mobileAntiMaskCoverTP").style.display = "block";
      if (isPortrait) {
        if (drawBuyHideAndShow) {
          drawtextElement.classList.remove("drawtextPortraitTP");
          drawtextElement.textContent = "";
          drawbutton.src = "";

        } else {

          drawbutton.src = Drawbtn;
          drawtextElement.classList.add("drawtextPortraitTP");
          drawtextElement.textContent = this.props.language.Draw;
          Object.assign(drawbutton.style, porttraitejson.Drawbtnportraite);
        }

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add(this.state.betbtntextcss);
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextportraitTP");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style,  this.state.betbuttoncss);

        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, this.state.foldbuttoncss);

        setTimeout(() => {
          if (this.state.drawnbtnshows == 1) {
            const drawbutton = document.getElementById("drawbtnTP");
            drawbutton.src = Drawbtn;
            drawbutton.classList.add("drwnodropTP")
            document.getElementById("drawtextTP").textContent = this.props.language.ReDraw;
            document.getElementById("drawtextTP").classList.add("drawtextPortraitTP")
            Object.assign(drawbutton.style, porttraitejson.Drawbtnportraite);
            document.getElementById('betpannelTropicalcover').classList.remove("PlayerCardpannelcoverPortraitTP")
            document.getElementById("betpannelTropicalcover").style.display = "none";
          }
        }, 500)

      } else {

        if (drawBuyHideAndShow) {
          drawtextElement.classList.remove("drawtextLndspTP");
          drawtextElement.textContent = "";
          drawbutton.src = "";

        } else {
          drawbutton.src = Drawbtn;
          drawtextElement.classList.add("drawtextLndspTP");
          drawtextElement.textContent = this.props.language.Draw;
          Object.assign(drawbutton.style, porttraitejson.Drawbtnlndspe);

        }

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add(this.state.betbtntextcss);
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add(this.state.foldbtntextcss);
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, this.state.betbuttoncss);

        foldbutton.src = Foldbn;
         Object.assign(foldbutton.style, this.state.foldbuttoncss);

        setTimeout(() => {
          if (this.state.drawnbtnshows == 1) {
            const drawbutton = document.getElementById("drawbtnTP");
            drawbutton.src = Drawbtn;
            drawbutton.classList.add("drwnodropTP")
            document.getElementById("drawtextTP").textContent = this.props.language.ReDraw;
            document.getElementById("drawtextTP").classList.add("drawtextLndspTP")
            Object.assign(drawbutton.style, porttraitejson.Drawbtnlndspe);
            document.getElementById('betpannelTropicalcover').classList.remove("PlayerCardpannelcoverLndspTP")
            document.getElementById("betpannelTropicalcover").style.display = "none";
          }
        }, 500)

      }
    }

    //   setTimeout(() => {
    //     const drawbutton = document.getElementById("drawbtnTP");
    //     drawbutton.src = Drawbtnwindows;
    //     document.getElementById("drawtextTP").textContent = this.props.language.Draw;
    //     Object.assign(drawbutton.style, porttraitejson.Drawbtnwindows);
    //  }, 5000);



  }



  drawCard() {

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();

    } else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    // const replaceCardslength = this.replaceCards.length
    console.log(this.state.anteBetAmount)
    console.log(this.state.bonusbetamount)
    console.log(this.props.balance)
    console.log(document.getElementById("drawtextTP").textContent)

    if (Number((this.state.anteBetAmount * 3 + this.state.anteBetAmount + this.state.bonusbetamount).toFixed(2)) > this.props.balance) {
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the Draw of ${(this.state.anteBetAmount).toFixed(1)},Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation()
    }
    else if ((Number((this.state.anteBetAmount * 4 + this.state.anteBetAmount + this.state.bonusbetamount).toFixed(2)) > this.props.balance) && (document.getElementById("drawtextTP").textContent) == "Re Draw") {
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the Draw of ${(this.state.anteBetAmount).toFixed(1)},Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation()
    }
    else {
      if (this.state.betshigh_balnce === true) {
        this.hidepopup_betshigh();
      }
      this.setState({ betshigh_balnce: null })

      this.playPokerChipSound(this.pokerchipsnd);

      const drawbutton = document.getElementById("drawbtnTP");
      const buybutton = document.getElementById("buybtnTP");
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");

      const drawtextElement = document.getElementById("drawtextTP");

      const betbtntextElement = document.getElementById("betbtntextTP");
      const foldtextElement = document.getElementById("foldtextTP");


      drawbutton.src = "";
      drawbutton.style.cssText = ""

      buybutton.src = "";
      buybutton.style.cssText = ""


      betbutton.src = "";
      betbutton.style.cssText = ""


      foldbutton.src = "";
      foldbutton.style.cssText = ""



      drawtextElement.textContent = "";
      betbtntextElement.textContent = "";
      foldtextElement.textContent = "";



      let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainerTP");
      let betchipsdraw = document.getElementById("betchipsaddtodrawTP");
      let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawTextTP");

      betchipsaddtodrawContainerElement.classList.remove("drawChipDivPortraiteTP");

      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (this.state.drawnbtnshows == 1) {
          this.setState((prevState) => ({
            totalbet: prevState.totalbet + prevState.anteBetAmount * 2,
            buydrawCheck: !prevState.buydrawCheck,
            betdeductions: prevState.betdeductions + prevState.anteBetAmount * 2
          }));
          this.drawbuybets(Number(this.state.anteBetAmount) * 2)
        } else {
          this.setState((prevState) => ({
            totalbet: prevState.totalbet + prevState.anteBetAmount,
            buydrawCheck: !prevState.buydrawCheck,
            betdeductions: prevState.betdeductions + prevState.anteBetAmount
          }));
          this.drawbuybets(this.state.anteBetAmount)
        }



        document.getElementById("crdscoverTP").classList.add("crdscoverTP")


        const drawbutton = document.getElementById("drawbtnTP");
        const buybutton = document.getElementById("buybtnTP");

        // document.getElementById("drawtextTP").classList.remove("drawtextwindowsTP");

        let t4 = gsap.timeline();
        t4.to("#betchipsaddtodrawTP", {
          x: -1185, y: -464, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodrawTP", { opacity: 1 });
          }

        });

      } else {

        let betTextaddtodrawElementClass = "bet-text-addtoBet20TP";
        if (this.state.drawnbtnshows == 1) {
          betchipsdraw.src = this.ReturnChipBasedAmount(Number((Number(this.state.anteBetAmount) * 2).toFixed(2)));
        } else {
          betchipsdraw.src = this.ReturnChipBasedAmount(Number((this.state.anteBetAmount).toFixed(2)));

        }
        switch (this.state.count) {
          case 1:
            // betchipsdraw.src = chip_10;
            betTextaddtodrawElementClass = "bet-text-addtoBet10TP";
            break;
          case 2:
            // betchipsdraw.src = chip_25;
            break;
          case 3:
            // betchipsdraw.src = chip_50;
            break;
          case 4:
            // betchipsdraw.src = chip_100;

            break;
          case 5:
            // betchipsdraw.src = chip_500;
            break;
          case 6:
            // betchipsdraw.src = chip_1000;
            break;

          default:
            betTextaddtodrawElementClass = "bet-text-addtoBet20TP";
            break;
        }

        this.buydrawchipactivated = true;
        if (this.state.drawnbtnshows == 1) {
          this.setState((prevState) => ({
            totalbet: prevState.totalbet + Number(prevState.anteBetAmount) * 2,
            buydrawCheck: !prevState.buydrawCheck,
            betdeductions: prevState.betdeductions + Number(prevState.anteBetAmount) * 2,
            infoText: ""
          }));
          betTextaddtodrawElement.textContent = this.topLable_rsp(Number(this.state.anteBetAmount) * 2);
        } else {
          this.setState((prevState) => ({
            totalbet: prevState.totalbet + (prevState.anteBetAmount),
            buydrawCheck: !prevState.buydrawCheck,
            betdeductions: prevState.betdeductions + (prevState.anteBetAmount),
            infoText: ""
          }));
          betTextaddtodrawElement.textContent = this.topLable_rsp(this.state.anteBetAmount);
        }


        document.getElementById("betpannelTropicalcover").style.display = "block";
        document.getElementById("playerDealerCardContainerTP").style.zIndex = 20;
        if (window.innerHeight > window.innerWidth) {
          drawtextElement.classList.remove("betportraitTP");
          betchipsaddtodrawContainerElement.classList.add("drawChipDivTP");
          betchipsaddtodrawContainerElement.classList.add("drawChipDivPortraiteTP");
          betTextaddtodrawElement.className = "";
          betTextaddtodrawElement.style.cssText = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddtodrawTP');
          betchipsdraw.classList.add("betchipsaddtodrawTP");
          this.state.drawbtnnewClass = true
          // betchipsdraw.classList.add("betchipsaddtodrawPositionTP")

          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: 322,
            y: -1230,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha: 1
          });

        } else {
          drawtextElement.classList.remove("betLandscapeTP");

          betchipsaddtodrawContainerElement.classList.add("drawChipDivLndspTP");
          betTextaddtodrawElement.className = "";
          betTextaddtodrawElement.style.cssText = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndspTP');

          betchipsdraw.classList.add("betchipsaddtodrawTP")
          gsap.to(this.chipdrawref.current, {
            x: -1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            autoAlpha: 1,
            autoAlpha: 1
          });
        }
      }

      // console.log(this.replaceCards);
      if (this.state.drawnbtnshows == 1) {
        const body = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "TROPICAL_STUD_POKER_BET",
          object: {
            gameId: this.props.gameId,
            action: "REDRAW_CARDS",
            playerId: Number(this.props.playerId),
            // pcards: this.replaceCards,
            pcards: this.replaceCards,
            handId: Number(this.props.gameState.handId)
          },
        };
        this.props.network.sendof(body);

        document.getElementById("buybtnTP").style.opacity = "1"
        this.setState({ disableBuyButton: false })
      } else {
        const body = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "TROPICAL_STUD_POKER_BET",
          object: {
            gameId: this.props.gameId,
            action: "REPLACE_CARDS",
            playerId: Number(this.props.playerId),
            // pcards: this.replaceCards,
            pcards: this.replaceCards,
            handId: Number(this.props.gameState.handId)
          },
        };
        this.props.network.sendof(body);

        document.getElementById("buybtnTP").style.opacity = "1"
        this.setState({ disableBuyButton: false })

      }
      // console.log(body); 


    }


  }

  revealDealercards(res) {
    this.dealerCardsRandom = [...res.dealerCards];
    this.dealerShuffledCards = [...res.dealerFinalCards];
    // this.setState({ totalwinRussian: res.totalWinAmount });
    // console.log(this.state.totalwinRussian);
    let cnt = this.dealerCardsRandom.length;
    // if (this.state.mutesounds) {

    //   this.reaveldealer_cards.pause();
    // } else {
    //   this.reaveldealer_cards.play();
    // }
    this.playRemainingSounds(this.reaveldealer_cards)

    for (let i = 0; i < cnt; i++) {
      let b1 = this.dealerCardsRandom[i];
      switch (i) {
        case 0:
          if (this.randomDealerCards[0] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.randomDealerCards[1] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.randomDealerCards[2] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.randomDealerCards[3] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.randomDealerCards[4] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.randomDealerCards[5] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    this.clertime6 = setTimeout(() => {
      // console.log("before", this.randomDealerCards);
      // this.suffleDealerCards(this.randomDealerCards.sort(() => Math.random()-0.5));
      this.suffleDealerCards(this.dealerShuffledCards);
      // console.log("after", this.randomDealerCards);
    }, this.dealerShuffledCards.length * 500);

  }
  updateplayerbalnace = () => {
    this.props.gamstateHandle();
    // this.setState({betdeductions:0,noupdateblnce:true})
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

  }

  highlightPlayerCardsName = (playerHand) => {
    this.setState({
      pokerranking: playerHand
    });

    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });


    let handrank = playerHand.split(" , ");
    // console.log(handrank)

    handrank.forEach(hand => {
      switch (hand) {
        case "Royal Flush":
          document.getElementById("royalflush").style.color = "#ffffff";
          break;
        case "Straight Flush":
          document.getElementById("Straightflush").style.color = "#ffffff";
          break;
        case "Four of a Kind":
          document.getElementById("frofkind").style.color = "#ffffff";
          break;
        case "Full House":
          document.getElementById("fullhse").style.color = "#ffffff";
          break;
        case "Flush":
          document.getElementById("flush").style.color = "#ffffff";
          break;
        case "Straight":
          document.getElementById("stright").style.color = "#ffffff";
          break;
        case "Three of a Kind":
          document.getElementById("Threekind").style.color = "#ffffff";
          break;
        case "Two Pair":
          document.getElementById("twopair").style.color = "#ffffff";
          break;
        case "One Pair":
          document.getElementById("onepair").style.color = "#ffffff";
          break;
        case "Ace-King":
          document.getElementById("aceking").style.color = "#ffffff";
          break;
        default:
          break;
      }


    })


  }

  insurenceactiveornot = (res) => {
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");


    this.clertime7 = setTimeout(() => {
      document.getElementById("rankinginfodelerTP").classList.add("windowspokerrankingdelerTP");
      this.setState({ pokerrankingdeler: res.dealerHand, })
    }, 3500);

    document.getElementById("betbtntextTP").textContent = "";
    document.getElementById("foldtextTP").textContent = "";
    this.revealDealercards(res)
    setTimeout(() => {
      this.checkpayerordealerwins(res);
    }, 3500)


  }


  Showinsurenceactiveornot = (res) => {
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    document.getElementById("betbtntextTP").textContent = "";
    document.getElementById("foldtextTP").textContent = "";

    this.showcheckpayerordealerwins(res);

  }

  showrussianPokerresp = (res) => {
    // console.log(res)
    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodelerTP").style.textContent= res.message
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
    this.setState({ playerhand_status: res.playerHand, disableBuyButton: false })
    document.getElementById("buybtnTP").style.opacity = "1"


    const betbtntextElement = document.getElementById("betbtntextTP");
    const foldtextElement = document.getElementById("foldtextTP");

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // let t1 = gsap.timeline();
      //     t1.to("#chip10centerbnsTP", {
      //       x:813, y:553, duration: 0.5, opacity: 0,
      //       onComplete: () => {
      //         gsap.set("#chip10centerbnsTP", { opacity:0});
      //       }
      //     });
      this.Showinsurenceactiveornot(res)
      // this.revealDealercards(res);

    }

    else {
      // document.getElementById("insurenceactive").className = "";
      // this.setState({
      //   insurenceMinbetAmt: res.insuranceMinBetAmount, insurenceMaxbetAmt: res.insuranceMaxBetAmount

      // });

      if (window.innerHeight > window.innerWidth) {

        if (res.insurance && res.winner !== "Player" && res.winner !== "Dealer" && res.winner !== "Dealer_no_hand") {
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
          // document.getElementById("insurencetext").style.color = "#ffffff";
          // document.getElementById("insurenceactive").src = bnsactiveTP;
          // document.getElementById("insurenceactive").classList.add("insurenceactivePortraitTP");
          // document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerPortraitTP");

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({
            buttontext: "insurence",
            betbutton: "noinsurence",
            insurencebtn: false,
            // disablemultibutton: true,
            betdecreamentbtn: false,
            betchipincrementbtn: false,
            pokerranking: res.playerHand,
            // pokerrankingdeler:res.dealerHand,
            infoText: res.message,
            totalbet: res.totalBetAmount,
          });



          // document.getElementById("foldtextTP").style.opacity = "0.5";
          // foldbutton.style.opacity = "0.5"

          betbutton.src = Betbtn;
          Object.assign(betbutton.style,  this.state.betbuttoncss);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, this.state.foldbuttoncss);

          betbtntextElement.textContent = this.props.language.No_Insurence;
          betbtntextElement.className = "";
          betbtntextElement.style.cssText = "";
          betbtntextElement.classList.add("noinsurencePortraitTP");
          document.getElementById("foldtextTP").textContent = this.props.language.Insurence;
          document.getElementById("foldtextTP").classList.add("insurencePortraitTP");
        } else {

          // setTimeout(() => {
          //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          //   document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerPortraitTP");
          // }, 3000);



          switch (res.winner) {
            case "Dealer":
            case "Player":
              this.showPlayerorDealerorFold(res)

              // this.ifDealerWinsMobilePortraitAction();
              // break;
              // this.ifPlayerWinsMobilePortraitAction();

              // if(Number(res.anteBetAmount) >0){
              //   let convrAntebt = this.topLable_rsp(res.anteBetAmount);
              //   this.setState((prevState) => ({
              //     storeantebetchips: [
              //       ...prevState.storeantebetchips,
              //       { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1TP" },

              //     ],
              //   }));

              // }


              break;

            case "Dealer_no_hand":
              this.dealerShuffledCards = [...res.dealerFinalCards]
              if (res.insurance) {
                if (res.insuranceBetAmount > 0) {
                  let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
                  this.setState((prevState) => ({
                    storeinsurancechips: [
                      ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10TP" },
                    ],
                    insurencetbet: res.insuranceBetAmount,

                  }));
                }
              }





              this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
              this.shwnPrvsSteBuyDrawBtchips(res)
              document.getElementById("betactiveTP").src = "";
              document.getElementById("bettextTP").style.color = "";
              document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerPortraitTP");
              this.setState({
                betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true,
                infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand
              });
              document.getElementById("betbtntextTP").textContent = this.props.language.Yes;
              betbutton.src = Betbtn;
              Object.assign(betbutton.style,  this.state.betbuttoncss);


              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, this.state.foldbuttoncss);

              // foldbutton.src = Foldbn;
              foldtextElement.textContent = this.props.language.No;
              document.getElementById("betbtntextTP").classList.remove("insurenceTP", "betwindowsTP");
              foldtextElement.classList.remove("noinsurenceTP", "foldtextwindowsTP");
              document.getElementById("betbtntextTP").classList.add(this.state.betbtntextcss);
              foldtextElement.classList.add("foldtextportraitTP");
            default:
              break;
          }

        }


      } else {

        if (res.insurance && res.winner !== "Player" && res.winner !== "Dealer" && res.winner !== "Dealer_no_hand") {
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
          // document.getElementById("insurencetext").style.color = "#ffffff";
          // document.getElementById("insurenceactive").src = bnsactiveTP;
          // document.getElementById("insurenceactive").classList.add("insurenceactiveLndspTP");
          if (this.state.pokerrankingdeler !== "") {
            document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerLndSpeTP");
          }
          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({
            infoText: res.message,
            // pokerrankingdeler: res.dealerHand,
            pokerranking: res.playerHand,
            buttontext: "insurence",
            betbutton: "noinsurence",
            insurencebtn: false,
            // disablemultibutton: true,
            betdecreamentbtn: false,
            betchipincrementbtn: false,

          });


          betbutton.src = Betbtn;
          Object.assign(betbutton.style, this.state.betbuttoncss);


          foldbutton.src = Foldbn;
           Object.assign(foldbutton.style, this.state.foldbuttoncss);

          document.getElementById("betbtntextTP").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntextTP").className = "";
          document.getElementById("betbtntextTP").style.cssText = "";
          document.getElementById("betbtntextTP").classList.add(this.state.betbtntextcss);
          document.getElementById("foldtextTP").textContent = this.props.language.Insurence;
          document.getElementById("foldtextTP").classList.add(this.state.foldbtntextcss);

          // document.getElementById("foldtextTP").style.opacity = "0.5";
          // foldbutton.style.opacity = "0.5"
        } else {
          this.prevStatecheckPlayerOrDealerWinMblLndspe(res);

        }

      }

    }

  }


  shwnPrvsSteBuyDrawBtchips = (res) => {

    const rankinginfoElement = document.getElementById("rankinginfoTP");

    if (Number(res.anteBetAmount) > 0) {
      let convrAntebt = this.topLable_rsp(res.anteBetAmount);
      this.setState((prevState) => ({
        anteBetAmount: res.anteBetAmount,
        totalbet: res.totalBetAmount,
        infoText: res.message,
        //  storeantebetchips: [
        //    ...prevState.storeantebetchips,
        //    { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1TP" },
        //  ],
      }));

    }

    if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
      this.prevstateBuyorDrawChip(res);
    }


    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");

    const drawbutton = document.getElementById("drawbtnTP");
    const buybutton = document.getElementById("buybtnTP");
    let betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetTP");
    let betTextaddtoBetClass = "bet-text-addtoBet20TP";

    let convrBet = this.topLable_rsp(res.betAmount);
    betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(res.betAmount);

    switch (this.state.count) {
      case 1:
        // betchipsaddtobetwindows.src = chip_10;
        // betTextaddtoBetElement.textContent = 10;
        betTextaddtoBetElement.textContent = convrBet;
        betTextaddtoBetClass = "bet-text-addtoBet10TP";
        break;
      case 2:
        // betchipsaddtobetwindows.src = chip_25;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 3:
        // betchipsaddtobetwindows.src = chip_50;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 4:
        // betchipsaddtobetwindows.src = chip_100;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 5:
        // betchipsaddtobetwindows.src = chip_500;
        betTextaddtoBetElement.textContent = convrBet;
        break;
      case 6:
        // betchipsaddtobetwindows.src = chip_1000;
        betTextaddtoBetElement.textContent = convrBet;
        break;

      default:
        betTextaddtoBetClass = "bet-text-addtoBet20TP"
        break;
    }
    this.betchipactivated = true;

    document.getElementById("betpannelTropicalcover").style.display = "block";
    if (window.innerHeight > window.innerWidth) {
      rankinginfoElement.classList.add("pokerrankingPortaitTP");
      betTextaddtoBetElement.className = "";
      // betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-portrait");
      betTextaddtoBetElement.style.color = "#ffffff";
      Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetPortrait)
      gsap.to(this.chipbetref.current, {
        // x: -10,
        // y: -950,
        x: -27,
        y: -925,
        duration: 0.00001,
        zIndex: 2,
        autoAlpha: 1,
        opacity: 1,
      });


      gsap.to(betTextaddtoBetElement, {
        // x: 12,
        // y: -1739,
        x: -3,
        y: -1710,
        duration: 0.00001,
        zIndex: 2,
        opacity: 1,
        visibility: true,
        onStart: function () {
          betTextaddtoBetElement.style.zIndex = 2;
          betTextaddtoBetElement.style.visibility = 'visible';
        }
      });

    } else {
      rankinginfoElement.classList.add("pokerrankingPlayerLndspTP");
      betTextaddtoBetElement.className = "";
      // betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-lndsp");
      betTextaddtoBetElement.style.color = "#ffffff";
      Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetLndsp);

      gsap.to(this.chipbetref.current, {
        x: -1060,
        y: 170,
        duration: 0.00001,
        zIndex: 2,
        autoAlpha: 1
      });


      gsap.to(betTextaddtoBetElement, {
        x: -1060,
        y: 170,
        duration: 0.00001,
        zIndex: 2,
        autoAlpha: 1,
        onStart: function () {
          betTextaddtoBetElement.style.zIndex = 2;
          betTextaddtoBetElement.style.visibility = 'visible';
        }
      });


      if (this.playerWinBonus === true) {
        if (document.getElementById('chip10centerbnsTP')) {
          gsap.to("#chip10centerbnsTP", {
            x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
          });
        }
      }


    }



  }



  prevStatecheckPlayerOrDealerWinMblLndspe = (res) => {

    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const drawtextElement = document.getElementById("drawtextTP");
    const betbtntextElement = document.getElementById("betbtntextTP");
    const foldtextElement = document.getElementById("foldtextTP");

    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
    document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerLndSpeTP");
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    drawtextElement.className = "";
    drawtextElement.style.cssText = "";
    drawtextElement.textContent = "";



    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";
    betbtntextElement.textContent = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";
    foldtextElement.textContent = "";


    switch (res.winner) {
      case "Dealer":
      case "Player":
        this.showPlayerorDealerorFold(res)

        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      case "Dealer_no_hand":
        this.dealerShuffledCards = [...res.dealerFinalCards]
        if (res.insurance) {
          if (res.insuranceBetAmount > 0) {
            let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
            this.setState((prevState) => ({
              storeinsurancechips: [
                ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10TP" },
              ],
              insurencetbet: res.insuranceBetAmount
            }));
          }
        };

        if (res.action !== "BUY_DEALER_CARD") {
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res)
          document.getElementById("betactiveTP").src = "";
          document.getElementById("bettextTP").style.color = "";

          betbtntextElement.className = "";
          foldtextElement.className = "";
          document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerLndSpeTP");
          this.setState({
            betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true,
            infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand, disablemultibutton: false
          });

          betbtntextElement.classList.add(this.state.betbtntextcss);
          betbtntextElement.textContent = this.props.language.Yes;
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, this.state.betbuttoncss);

          foldtextElement.classList.add(this.state.foldbtntextcss);
          foldbutton.src = Foldbn;
           Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.No;
        }
      default:
        break;
    }


  }

  russianPokerrespTP = (res) => {
    console.log(res)
    this.state.drawbtnnewClass = false
    this.state.drawnbtnshows = 0
    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodelerTP").style.textContent= res.message
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
    this.setState({
      playerhand_status: res.playerHand, insurenceMinbetAmt: res.insuranceMinBetAmount,
      insurenceMaxbetAmt: res.insuranceMaxBetAmount,
      disableBuyButton: false
    })

    document.getElementById("buybtnTP").style.opacity = "1"
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // if (res.bonusWinAmount > 0) {
      //   this.removehigltbonusclasses(res);
      // }
      this.removehigltbonusclasses(res);

      // this.setState({infoText:res.message})
      // let t1 = gsap.timeline();
      // t1.to("#chip10centerbnsTP", {
      //   x: 813, y: 553, duration: 0.5, opacity: 0,
      //   onComplete: () => {
      //     gsap.set("#chip10centerbnsTP", { opacity: 0 });
      //   }
      // });
      if (res.bonusWinAmount > 0) {
        // if (this.playerWinBonus === true) {
        let t1 = gsap.timeline();
        document.getElementById("glowonwinchipTP").classList.remove("chipglow_bonusTP")
        t1.to("#chip10centerbnsTP", {
          x: 360, y: -900, duration: 0.2, zIndex: -2, opacity: 1,
          onComplete: () => {
            gsap.set("#chip10centerbnsTP", { opacity: 1, zIndex: -2 });

          }
        });
      }

      this.insurenceactiveornot(res)

    }

    else {
      this.setState({ bsbtn: true })
      // document.getElementById("insurenceactive").className = "";
      //  const poker_Rankingsinsurence = [
      //     "Royal Flush","Straight","Straight Flush", "Four of a Kind", 
      //     "Full House", "Flush", "Three of a Kind"
      //   ];
      if (window.innerHeight > window.innerWidth) {


        // if (poker_Rankingsinsurence.includes(res.playerHand)) {

        // if (
        //   res.playerHand == "Royal Flush" || "Straight Flush" || "Four of a Kind" || "Full House" || "Flush" || "Three of a Kind") {
        // res.playerHand == "One Pair" ||{

        if (res.bonusWinAmount > 0) {
          // if (this.playerWinBonus === true) {
          if (document.getElementById("chip10centerbnsTP")) {
            let ml = gsap.timeline()
            ml.to("#chip10centerbnsTP", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
            ml.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
            // gsap.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
            this.playerWinBonus = false;
          }
        }
        if (res.insurance) {

        } else {

          // setTimeout(() => {
          //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          //   document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerPortraitTP");
          // }, 3000);

          this.revealDealercards(res)
          this.clertime7 = setTimeout(() => {


            this.setState({ pokerrankingdeler: res.dealerHand, })
            // document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerPortraitTP");
            document.getElementById("rankinginfodelerTP").classList.add(`${this.state.rankinginfodelercss}`);

            switch (res.winner) {
              case "Dealer":
                setTimeout(() => {
                  this.ifDealerWinsMobilePortraitAction(res);
                }, 1000)
                break;

              // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
              case "Player":
                this.ifPlayerWinsMobilePortraitAction(res);
                break;
              // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
              case "Dealer_no_hand":
                document.getElementById("betactiveTP").src = "";
                document.getElementById("bettextTP").style.color = "";

                this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true, infoText: res.message, });
                document.getElementById("betbtntextTP").textContent = this.props.language.Yes;
                betbutton.src = Betbtn;
                // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
                Object.assign(betbutton.style, this.state.betbuttoncss);


                foldbutton.src = Foldbn;
                // Object.assign(foldbutton.style, this.state.foldbuttoncss);
                Object.assign(foldbutton.style, this.state.foldbuttoncss);

                // foldbutton.src = Foldbn;
                document.getElementById("foldtextTP").textContent = this.props.language.No;
                document.getElementById("betbtntextTP").classList.remove("insurenceTP", "betwindowsTP");
                document.getElementById("foldtextTP").classList.remove("noinsurenceTP", "foldtextwindowsTP");
                document.getElementById("betbtntextTP").classList.add(`${this.state.betbtntextcss}`);
                document.getElementById("foldtextTP").classList.add(`${this.state.foldbtntextcss}`);
              // document.getElementById("betbtntextTP").classList.add("betportraitTP");
              // document.getElementById("foldtextTP").classList.add("foldtextportraitTP");
              default:
                break;
            }

          }, 3500)
        }
        setTimeout(() => {
          if (res.winner == "Player")
            this.setState({
              Winnindanimation: true
            })
        }, 5000)

        setTimeout(() => {
          if (res.winner == "Player")
            this.setState({
              AnteWinCHanimation: true
            })
        }, 3500)

      } else {

          this.revealDealercards(res);
          this.clertime7 = setTimeout(() => {
            this.checkPlayerOrDealerWinMblLndspe(res);
          }, 5000)

        setTimeout(() => {
          if (res.winner == "Player") {
            this.setState({
              Winnindanimation: true
            })
          }
        }, 5000)

        setTimeout(() => {
          if (res.winner == "Player") {
            this.setState({
              AnteWinCHanimation: true
            })
          }
        }, 3500)

      }
      setTimeout(() => {
        this.setState({
          Winnindanimation: false,
          AnteWinCHanimation: false
        })
      }, 11000)
    }

  }
  checkPlayerOrDealerWinMblLndspe = (res) => {

    // this.playerWin = new Audio(playerWin);
    // this.playerWin.currentTime = 0;

    // this.playerLose = new Audio(playerLose);
    // this.playerLose.currentTime = 0;

    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const drawtextElement = document.getElementById("drawtextTP");
    const betbtntextElement = document.getElementById("betbtntextTP");
    const foldtextElement = document.getElementById("foldtextTP");
    const winpopuprspelement = document.getElementById("winpopup_TP");
    const winamtElement = document.getElementById("winamt_TPS");

    const anteText = document.querySelectorAll("#chipcenterpositionTP span");
    const betText = document.querySelector("#betTextaddtoBetTP");


    const betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
    this.setState({
      // infoText: res.message, 
      pokerrankingdeler: res.dealerHand,
    })
    // document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerLndSpeTP");
    document.getElementById("rankinginfodelerTP").classList.add(`${this.state.rankinginfodelercss}`);
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    drawtextElement.className = "";
    drawtextElement.style.cssText = "";
    drawtextElement.textContent = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";
    betbtntextElement.textContent = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";
    foldtextElement.textContent = "";




    switch (res.winner) {
      case "Dealer":
        this.updateplayerbalnace();
        // console.log(this.state.anteBetAmount, this.state.bonusbetamount);
        this.setState((prevState) => ({
          infoText: res.message,
          totalwinRussian: res.totalWinAmount,
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          prevStatestoreantebetchips: [...prevState.storeantebetchips],
          prevStatestorebonuschips: [...prevState.storebonuschips],
          storebonuschips: [],

          // betdeductions: 0
        }));

        if (Number(res.bonusBetAmount) > 0) {
          if (this.state.storebonuschips.length === 0) {
            let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
            this.setState((prevState) => ({
              prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },]
            }))
          }
        }

        this.clertime48 = setTimeout(() => {
          this.clearActivesWeDid();
        }, 2500)





        document.getElementById("betactiveTP").src = "";
        document.getElementById("bettextTP").style.color = "";
        document.getElementById("betactiveTP").classList.remove("betactiveTP");
        this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
        let t1 = gsap.timeline();

        t1.to("#chipcenterpositionTP", {
          y: -700, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { autoAlpha: 0 });
          }
        });
        t1.to("#chipcenterpositionTP", {
          y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { autoAlpha: 0, });
          }
        });
        t1.play();

        if (window.innerHeight > window.innerWidth) {
          let t2 = gsap.timeline();
          t2.to("#betchipsaddtobetTP", {
            x: -50, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtobetTP", { opacity: 0 });
            }
          });
          t2.to("#betchipsaddtobetTP", {
            x: 30, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

          });
          t2.play();

          let t3 = gsap.timeline();
          t3.to("#betTextaddtoBetTP", {
            x: 110, y: -2000, duration: 0.5, ease: "power2.inOut", opacity: 0,
            onComplete: () => {
              gsap.set("#betTextaddtoBetTP", { opacity: 0 });
            }
          });
          t3.to("#betTextaddtoBetTP", {
            x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

          });
        } else {
          let t2 = gsap.timeline();
          t2.to("#betchipsaddtobetTP", {
            y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtobetTP", { autoAlpha: 0 });
            }
          });
          t2.to("#betchipsaddtobetTP", {
            x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

        });
        t2.play();

        let t3 = gsap.timeline();
        t3.to("#betTextaddtoBetTP", {
          y: -700, duration: 0.5, ease: "power2.inOut", autoAlpha: 0,
          onComplete: () => {
            gsap.set("#betTextaddtoBetTP", { autoAlpha: 0 });
          }
        });
        t3.to("#betTextaddtoBetTP", {
          x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,

        });

          t3.play();
        }



        // let t4 = gsap.timeline();
        // t4.to(this.chipdrawref.current, {
        //   y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
        //   onComplete: () => {
        //     gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
        //   }
        // });
        // t4.to(this.chipdrawref.current, {
        //   x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
        //   onComplete: () => {
        //     gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
        //   }
        // });

        // t4.play();


        if (this.wanttobuydelercrd === true) {
          let t1 = gsap.timeline()
          t1.to(this.buyDealerCardDrawn.current, { y: -700, duration: 0.5 });
          t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
          t1.to(this.buyDealerCardDrawn.current, { opacity: 0, y: 0, zIndex: -5 });
          t1.play();
        }


        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";



        this.clertime49 = setTimeout(() => {
          document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
          this.setState({ infoText: this.props.language.Place_antebet, totalwinRussian: 0, storeantebetchips: [], storeinsurancechips: [], });
          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
            autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
              winpopuprspelement.classList.remove("winPopupForMLspTP");
              winamtElement.classList.remove("winamtMpSwTP");
            }
          })
          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscapeTP');
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          foldbutton.src = Foldbn;
          //  Object.assign(foldbutton.style, this.state.foldbuttoncss);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscapeTP");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
        }, 3000);

        // delaerChipAnimDiv.className ="";
        // dealerchipElement.src = "";

        // if (this.state.mutesounds) {
        //   this.playerLose.pause();
        // } else {
        //   this.playerLose.play();
        // }
        this.playRemainingSounds(this.playerLose)

        document.getElementById("onepair").style.color = "";
        document.getElementById("Threekind").style.color = "";
        document.getElementById("aceking").style.color = "";
        document.getElementById("fullhse").style.color = "";
        document.getElementById("Straightflush").style.color = "";
        document.getElementById("frofkind").style.color = "";
        document.getElementById("twopair").style.color = "";
        document.getElementById("flush").style.color = "";
        document.getElementById("stright").style.color = "";
        document.getElementById("royalflush").style.color = "";
        break;

      // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
      case "Player":

        const delaerChipAnimDiv = document.getElementById('delaerchipanimTP')
        const dealerchipElement = document.getElementById('dealerchipTP');


        if (Number(res.anteWinAmount) > Number(res.anteBetAmount)) {
          dealerchipElement.src = this.ReturnChipBasedAmount(Number(res.anteBetAmount));
          // Object.assign(dealerchipElement.style, porttraitejson.dealerchipstoplayerLndsp)
          Object.assign(dealerchipElement.style, this.state.DelarAnteWinProLandAN)


          if (window.innerHeight > window.innerWidth) {
            let t1 = gsap.timeline()
            t1.to("#dealerchipTP", {
              autoAlpha: 1,
              zIndex: 2,
              x: -150,
              y: 1050,
              duration: 0.5,
              ease: "power2.inOut",
              onComplete: () => {
                gsap.set('#dealerchipTP', { opacity: 0, zIndex: -10 })
              },
            });

            t1.to("#dealerchipTP", {
              x: -75,
              y: 20,
              duration: 0.002,
              ease: "power2.inOut",
              autoAlpha: 0,
              zIndex: -10

            })
            setTimeout(() => {
              for (let b = 0; b < anteText.length; b++) {
                // anteText[b].textContent = res.anteWinAmount;
                anteText[b].textContent = this.topLable_rsp(res.anteWinAmount);
              }
            }, 500);
          } else {
            let t6 = gsap.timeline()
            t6.to("#dealerchipTP", {
              // x: 645,
              // y: 425,
              x: -180,
              y: 590,
              duration: 0.5,
              ease: "power2.inOut",
              onStart: () => {
                gsap.set('#dealerchipTP', { autoAlpha: 1, zIndex: 2 })
              },
              onComplete: () => {
                gsap.set('#dealerchipTP', { y: 0, autoAlpha: 0, zIndex: -10 })

              for (let b = 0; b < anteText.length; b++) {
                // anteText[b].textContent = res.anteWinAmount;
                anteText[b].textContent = this.topLable_rsp(res.anteWinAmount);
              }

              },
            });
          }
        }

        setTimeout(() => {
          // this.updateplayerbalnace();
          this.clertime53 = setTimeout(() => {
            this.clearActivesWeDid();
          }, 3000);

          // console.log(this.state.anteBetAmount, this.state.bonusbetamount);

          this.setState((prevState) => ({
            // infoText: res.message,
            isbonuspattable: false,
            // totalwinRussian: res.totalWinAmount,
            previousAnteBetAmount: res.anteBetAmount,
            previousBonusbetamount: res.bonusBetAmount,
            insurencetbet: 0,
            anteBetAmount: 0,
            bonusbetamount: 0,
            prevStatestoreantebetchips: [...prevState.storeantebetchips],
            prevStatestorebonuschips: [...prevState.storebonuschips],
            storebonuschips: [],
            // storeinsurancechips: [],
          }));

          if (Number(res.bonusBetAmount) > 0) {
            if (this.state.storebonuschips.length === 0) {
              let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
              this.setState((prevState) => ({
                prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },]
              }))
            }
          }




          betText.textContent = this.topLable_rsp(res.winAmount);
          this.resetAnimationChips = true;



          setTimeout(() => {
            // if (this.state.mutesounds) {
            //   this.playerWin.pause();
            // } else {
            //   this.playerWin.play();
            // }
            this.playRemainingSounds(this.playerWin)
            // winpopuprspelement.classList.add("winPopupForMLspTP");
            // winamtElement.classList.add("winamtMpSwTP");
            this.setState({
              infoText: res.message,
              totalwinRussian: res.totalWinAmount,
            })
          }, 2000)

          gsap.fromTo(winpopuprspelement,
            {
              autoAlpha: 0, scale: 0, duration: 0.5,
            },
            {
              autoAlpha: 1, scale: 1, duration: 0.5, delay: 2, rotate: 0, onStart: () => {
                setTimeout(() => {
                  // winpopuprspelement.classList.add("winPopupForMLspTP");
                  // winamtElement.classList.add("winamtMpSwTP");
                  // this.setState({
                  //   infoText: res.message,
                  //   totalwinRussian: res.totalWinAmount,
                  // })
                }, 1500)

              }
            },
          )




          document.getElementById("betactiveTP").src = "";
          document.getElementById("bettextTP").style.color = "";
          document.getElementById("betactiveTP").classList.remove("betactiveTP");

          if (window.innerHeight > window.innerWidth) {
            gsap.to("#chipcenterpositionTP", { x: -370, y: 860, duration: 1, });
            gsap.to("#betchipsaddtobetTP", {
              x: -440, y: -70, duration: 1, delay: 0.5, onComplete: () => {
                betText.textContent = this.topLable_rsp(Number((res.anteWinAmount + res.winAmount).toFixed(2)));
              }
            });
            gsap.to("#betTextaddtoBetTP", { x: -440, y: -860, duration: 1, delay: 0.5 });
            winpopuprspelement.classList.add("winPopupForMptSwTP");
            winamtElement.classList.add("winamtMpSwTP");

            winpopuprspelement.classList.remove("winPopupForMLspTP");

          } else {

            if (document.getElementById("chipcenterpositionTP")) {
              gsap.to("#chipcenterpositionTP", { x: 700, y: -130, duration: 0.5 });
            }

            if (document.getElementById("betchipsaddtobetTP")) {
              gsap.to("#betchipsaddtobetTP", { x: -460, y: -97, duration: 0.5, delay: 0.6 });
            }
            if (document.getElementById("betTextaddtoBetTP")) {
              gsap.to("#betTextaddtoBetTP", {
                x: -460, y: -97, duration: 0.5, delay: 0.6, onComplete: () => {
                  betText.textContent = this.topLable_rsp(Number((res.anteWinAmount + res.winAmount).toFixed(2)));
                }
              });
            }
            setTimeout(() => {
              winpopuprspelement.classList.add("winPopupForMLspTP");
              winamtElement.classList.add("winamtMpSwTP");
            }, 2000)
          }
          // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });
          let t5 = gsap.timeline();
          t5.to(this.chipdrawref.current, {
            // x: 360, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
            x: 320, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { opacity: 0 });
            }
          });
          t5.to(this.chipdrawref.current, {
            x: 608, y: -116, duration: 0.001, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { opacity: 0, delay: 2 });
            }
          });

          t5.play();
          // if(this.wanttobuydelercrd === true){
          //    gsap.to(this.buyDealerCardDrawn.current, { x: -450, y: -280, duration: 0.5, delay: 1 });
          // }

          if (this.wanttobuydelercrd === true) {
            let t1 = gsap.timeline()
            t1.to(this.buyDealerCardDrawn.current, { y: -700, duration: 0.5 });
            t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
            t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
            t1.play();
          }

          this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })


          betbtntextElement.className = "";
          foldtextElement.className = "";




          this.resetPyrWnCpsAmn = true;

        }, 1000);

        this.clertime54 = setTimeout(() => {

          delaerChipAnimDiv.className = "";
          dealerchipElement.src = "";
          dealerchipElement.className = "";

          document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscapeTP');
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

          foldbutton.src = Foldbnwindows;
          //  Object.assign(foldbutton.style, this.state.foldbuttoncss);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscapeTP");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);



          this.updateplayerbalnace();
          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
            autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
              winpopuprspelement.classList.remove("winPopupForMLspTP");
              winamtElement.classList.remove("winamtMpSwTP");
              winpopuprspelement.classList.remove("winPopupForMptSwTP");
            }
          })
          this.setState({
            totalbet: 0,
            totalwinRussian: 0,
            infoText: this.props.language.Play_Njoy,
            storeantebetchips: [],
            storeinsurancechips: []
          });

          document.getElementById("onepair").style.color = "";
          document.getElementById("Threekind").style.color = "";
          document.getElementById("aceking").style.color = "";
          document.getElementById("fullhse").style.color = "";
          document.getElementById("Straightflush").style.color = "";
          document.getElementById("frofkind").style.color = "";
          document.getElementById("twopair").style.color = "";
          document.getElementById("flush").style.color = "";
          document.getElementById("stright").style.color = "";
          document.getElementById("royalflush").style.color = "";

          gsap.to("#chipcenterpositionTP", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to("#betTextaddtoBetTP", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to("#betchipsaddtobetTP", { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });
          gsap.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0 });

          if (this.wanttobuydelercrd === true) {
            gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2, duration: 0.0001 })

          }
          // gsap.set('#dealerchipTP', { x: 0, y: 0, opacity: 0, zIndex: -10, duration: 0.00001 })
        }, 5000)




        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      case "Dealer_no_hand":
        if (res.action !== "BUY_DEALER_CARD") {

          document.getElementById("betactiveTP").src = "";
          document.getElementById("bettextTP").style.color = "";
          betbtntextElement.className = "";
          foldtextElement.className = "";

          this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true, disablemultibutton: false, infoText: res.message });

          // betbtntextElement.classList.add("betLandscapeTP");
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);
          betbtntextElement.textContent = this.props.language.Yes;
          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style, this.state.betbuttoncss);

          // foldtextElement.classList.add("foldtextLandscapeTP");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
          foldbutton.src = Foldbn;
          //  Object.assign(foldbutton.style, this.state.foldbuttoncss);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.No;
        }
      default:
        break;
    }

  }
  ifDealerWinsMobilePortraitAction = (res) => {
    // this.playerLose = new Audio(playerLose);
    // this.playerLose.currentTime = 0;
    // console.log(this.state.storebonuschips)
    const winpopuprspelement = document.getElementById("winpopup_TP");
    const winamtElement = document.getElementById("winamt_TPS");
    this.clertime47 = setTimeout(() => {

      this.updateplayerbalnace();
      // console.log(this.state.anteBetAmount, this.state.bonusbetamount);
      this.setState((prevState) => ({
        infoText: res.message,
        totalwinRussian: res.totalWinAmount,
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        totalbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        insurencetbet: 0,
        prevStatestoreantebetchips: [...prevState.storeantebetchips],
        prevStatestorebonuschips: [...prevState.storebonuschips],
        storebonuschips: [],
        // storeinsurancechips: [],
        // betdeductions: 0
      }));
      if (Number(res.bonusBetAmount) > 0) {
        if (this.state.storebonuschips.length === 0) {
          let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
          this.setState((prevState) => ({
            prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },]
          }))
        }
      }




      this.clertime48 = setTimeout(() => {
        this.clearActivesWeDid();
      }, 1000);

      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      const betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");


      document.getElementById("betactiveTP").src = "";
      document.getElementById("bettextTP").style.color = "";
      document.getElementById("betactiveTP").classList.remove("betactiveTP");
      this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
      let t1 = gsap.timeline();

      t1.to("#chipcenterpositionTP", {
        y: -2060, duration: 0.5, opacity: 1,
        onComplete: () => {
          gsap.set("#chipcenterpositionTP", { opacity: 0 });
        }
      });
      t1.to("#chipcenterpositionTP", {
        x: 13, y: 1200, duration: 0.0001, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set("#chipcenterpositionTP", { opacity: 0, });
        }
      });
      t1.play();

      if (window.innerHeight > window.innerWidth) {
        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobetTP", {
          x: -50, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtobetTP", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtobetTP", {
          x: 30, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

      });
      t2.play();

      let t3 = gsap.timeline();
      t3.to("#betTextaddtoBetTP", {
        x: 110, y: -2000, duration: 0.5, ease: "power2.inOut", opacity: 0,
        onComplete: () => {
          gsap.set("#betTextaddtoBetTP", { opacity: 0 });
        }
      });
      t3.to("#betTextaddtoBetTP", {
        x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

      });

        t3.play();
        
      } else {

        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobetTP", {
          y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtobetTP", { autoAlpha: 0 });
          }
        });
        t2.to("#betchipsaddtobetTP", {
          x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

        });
        t2.play();

        let t3 = gsap.timeline();
        t3.to("#betTextaddtoBetTP", {
          y: -700, duration: 0.5, ease: "power2.inOut", autoAlpha: 0,
          onComplete: () => {
            gsap.set("#betTextaddtoBetTP", { autoAlpha: 0 });
          }
        });
        t3.to("#betTextaddtoBetTP", {
          x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,

        });

        t3.play();
      }



      // let t4 = gsap.timeline();
      // t4.to(this.chipdrawref.current, {
      //   // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 0 });
      //   }
      // });
      // t4.to(this.chipdrawref.current, {
      //   x: 608, y: -116, duration: 0.5, zIndex: -2, opacity: 0,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
      //   }
      // });

      // t4.play();

      if (this.wanttobuydelercrd === true) {
        let t1 = gsap.timeline()
        t1.to(this.buyDealerCardDrawn.current, { x: -190, y: -2200, duration: 0.5 });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
        t1.play();
      }


      this.clertime49 = setTimeout(() => {
        document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
        gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
          autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
            winpopuprspelement.classList.remove("winPopupForMptSwTP");
            winamtElement.classList.remove("winamtMpSwTP");
            winpopuprspelement.classList.remove("winPopupForMLspTP");
          }
        })
        this.setState({ infoText: this.props.language.Place_antebet, totalwinRussian: 0, storeantebetchips: [], storeinsurancechips: [], });
        betbutton.src = Betbtn;
        // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
        Object.assign(betbutton.style, this.state.betbuttoncss);

        foldbutton.src = Foldbn;
        // Object.assign(foldbutton.style, this.state.foldbuttoncss);
        Object.assign(foldbutton.style, this.state.foldbuttoncss);
        document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;

        document.getElementById("betbtntextTP").classList.add(`${this.state.betbtntextcss}`);
        document.getElementById("foldtextTP").classList.add(`${this.state.foldbtntextcss}`);
        // document.getElementById("betbtntextTP").classList.add("betportraitTP");
        // document.getElementById("foldtextTP").classList.add("rebettextportraitTP");

        document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
      }, 3000);

      // delaerChipAnimDiv.className ="";
      // dealerchipElement.src = "";

      // if (this.state.mutesounds) {
      //   this.playerLose.pause();
      // } else {
      //   this.playerLose.play();
      // }
      this.playRemainingSounds(this.playerLose)

      document.getElementById("onepair").style.color = "";
      document.getElementById("Threekind").style.color = "";
      document.getElementById("aceking").style.color = "";
      document.getElementById("fullhse").style.color = "";
      document.getElementById("Straightflush").style.color = "";
      document.getElementById("frofkind").style.color = "";
      document.getElementById("twopair").style.color = "";
      document.getElementById("flush").style.color = "";
      document.getElementById("stright").style.color = "";
      document.getElementById("royalflush").style.color = "";


    }, 2000);


  }



  suffleDealerCards = (data) => {
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      let b1 = data[i];
      this.cardsshuffle = new Audio(cardsshuffle);
      // if (this.cardsshuffle) {
      //   this.cardsshuffle.currentTime = 0;
      //   if (this.state.mutesounds) {
      //     this.cardsshuffle.pause()
      //   } else {
      //     this.cardsshuffle.play()
      //   }



      // }
      switch (i) {
        case 0:
          this.dealerCardsContainer.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }
  };

  TPReplaceCards = (res, actionName) => {
    // let handrank = "One Pair , Ace-King".split(" , ");
    // this.setState({ playerrankinghand: handrank })
    this.drawReplaceCards = true;
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      // this.insurenceactiveornot(res);
      // this.Showbonustouser(res);
      setTimeout(() => {
        let t4 = gsap.timeline();
        t4.to("#betchipsaddtodrawTP", {
          x: -1084, y: -1266, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodrawTP", { opacity: 0 });
          }
        });
        t4.to("#betchipsaddtodrawTP", {
          x: 0, y: 0, zIndex: -10, duration: 0.1, opacity: 0, delay: 0.5
          // x: 100, y: -135, zIndex: -10, duration: 0.1, opacity: 0, delay: 0.5
        });
        gsap.to("#betTextaddtodrawTP", { opacity: 0, duration: 0.1, zIndex: -15 })
        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, zIndex: -2, delay: 1 })
      }, 3000)

    } else {

      this.setState({
        bsbtn: true,
      })

      setTimeout(() => {
        let t4 = gsap.timeline();
        t4.to(this.chipdrawref.current, {
          x: this.state.drawChipXd, y: this.state.drawChipYd, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { opacity: 0 });
          }
        });
        t4.to(this.chipdrawref.current, {
          x: this.state.drawChipRetrunXd, y: this.state.drawChipRetrunYd, zIndex: -10, duration: 0.1, opacity: 0, delay: 0.5

        });
        // gsap.to("#betTextaddtodrawTP", { opacity: 0, duration: 0.1, zIndex: -15, delay: 1})

        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, zIndex: -2, delay: 1 })
      }, 3000)

    }


    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.replaceCards = []
    // console.log(res);

    this.unsequenceDrawCards = [...res.playerCards];
    this.sequenceDrawCards = [...res.playerFinalCards];
    let cnt = this.unsequenceDrawCards.length;

    let numberOfCardsToDraw = res.playerNewCardsToReplace.length;
    if (actionName == 'REPLACE_CARDS') {
      this.state.drawnbtnshows = 1
    } else {
      this.state.drawnbtnshows = 0
    }

    for (let i = 0; i < cnt; i++) {

      var b1 = this.unsequenceDrawCards[i];

      switch (i) {
        case 0:
          if (this.shuffledPlayerCards[0] != b1) {
            this.cardsRef.current.Drawcard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.shuffledPlayerCards[1] != b1) {
            this.cardsRef.current.Drawcard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.shuffledPlayerCards[2] != b1) {
            this.cardsRef.current.Drawcard("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.shuffledPlayerCards[3] != b1) {
            this.cardsRef.current.Drawcard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.shuffledPlayerCards[4] != b1) {
            this.cardsRef.current.Drawcard("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.shuffledPlayerCards[5] != b1) {
            this.cardsRef.current.Drawcard("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    this.clertime8 = setTimeout(() => {
      this.highlightPlayerCardsName(res.playerHand);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainerTP").style.zIndex = 1;
      }
      this.setState({ pokerranking: res.playerHand })
      this.onDrawingCards(this.sequenceDrawCards, true, res, true);
      this.shuffledPlayerCards = [...this.sequenceDrawCards];

      const drawbutton = document.getElementById("drawbtnTP");
      document.getElementById("drawtextTP").textContent = "";

      drawbutton.src = "";
      drawbutton.style.cssText = "";


    }, numberOfCardsToDraw * 2 * 900);

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      if (this.state.drawnbtnshows != 1 || actionName == 'REDRAW_CARDS') {
        document.getElementById("crdscoverTP").classList.add("crdscoverTP")
      }
    } else {
      if (window.innerWidth < window.innerHeight) {
        if (this.state.drawnbtnshows != 1 || actionName == 'REDRAW_CARDS') {
          document.getElementById("betpannelTropicalcover").style.display = "block";
          document.getElementById("betpannelTropicalcover").classList.add("PlayerCardpannelcoverPortraitTP");
        }
      } else {
        if (this.state.drawnbtnshows != 1 || actionName == 'REDRAW_CARDS') {
          document.getElementById("betpannelTropicalcover").style.display = "block";
        }
      }
    }

  };

  ShowrpReplaceCards = (res) => {

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {


      // this.insurenceactiveornot(res);
      // this.Showbonustouser(res);

    } else {

      this.setState({ infoText: res.message });

      // console.log(res);

      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards)

    }

    this.highlightPlayerCardsName(res.playerHand);
    if (this.state.isMobilePortraitLndsp === true) {
      document.getElementById("playerDealerCardContainerTP").style.zIndex = 1;
    }
    this.setState({ pokerranking: res.playerHand })
    const drawbutton = document.getElementById("drawbtnTP");
    const buybutton = document.getElementById("buybtnTP");
    document.getElementById("drawtextTP").textContent = "";
    drawbutton.src = "";
    drawbutton.style.cssText = "";
    buybutton.src = "";
    buybutton.style.cssText = "";


  };
  ShowBuyplayercardresp(res, actionName) {
    console.log(res)
    this.state.drawnbtnshows = 0
    if (actionName == 'REPLACE_CARDS') {
      this.state.drawnbtnshows = 1
      const drawtextElement = document.getElementById("drawtextTP");
      if (drawtextElement) {
        drawtextElement.classList.add("drawtextopacityTP");
      }
      this.setState({ drawbuttonhandle: true });
    } else {
      this.state.drawnbtnshows = 0
    }

    const drawbutton = document.getElementById("drawbtnTP");
    const buybutton = document.getElementById("buybtnTP");

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      if (this.state.drawnbtnshows != 1 || actionName == 'REDRAW_CARDS') {
        document.getElementById("crdscoverTP").classList.add("crdscoverTP")
      }
      document.getElementById("rankinginfoTP").classList.add("windowspokerrankingTP");
      this.setState({
        infoText: res.message,
        pokerranking: res.playerHand,
        totalbet: res.totalBetAmount,
        anteBetAmount: res.anteBetAmount,
        buttontext: "fold",
        betbutton: "Bet",
        FoldbtnTP: 0,
        betBtnValue: 1,
        disBetBtn: 0,
        keyboardhandle: true

      });
      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");

      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
      document.getElementById("betbtntextTP").textContent = this.props.language.Bet;
      document.getElementById("betbtntextTP").classList.add("betwindowsTP");


      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
      document.getElementById("foldtextTP").textContent = this.props.language.Fold;
      document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");

      document.getElementById("bnsactiveTP").src = bnsactiveTP
      document.getElementById("bnsactiveTP").classList.add("betactiveTP");
      let betexttodraw = document.getElementById("betTextaddtodrawTP");
      gsap.to("#betTextaddtodrawTP", { opacity: 0, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtodrawTP");


      Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

      let betTextaddtoBetClass = "bet-text-addtodraw20TP";
      // this.setState((prevState) => ({
      //   totalbet: prevState.totalbet + prevState.anteBetAmount

      // }));
      if (res.playerBuyCardAmount > 0) {
        betexttodraw.textContent = this.topLable_rsp(res.playerBuyCardAmount);
      }
      else {
        betexttodraw.textContent = this.topLable_rsp(res.playerReplaceCardAmount);
      }

      switch (this.state.count) {
        case 1:

          betchipsdraw.src = chip_10;
          betTextaddtoBetClass = "bet-text-addtodraw10TP";

          break;
        case 2:

          betchipsdraw.src = chip_25;
          betexttodraw.textContent = this.chipVal[1].label;
          break;
        case 3:

          betchipsdraw.src = chip_50;

          break;
        case 4:
          betchipsdraw.src = chip_100;
          break;
        case 5:
          betchipsdraw.src = chip_500;
          break;
        case 6:
          betchipsdraw.src = chip_1000;
          break;

        default:
          break;
      }
      this.clertime45 = setTimeout(() => {
        betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodrawTP");

      }, 100)


      // let t1 = gsap.timeline();
      // // t1.to("#betchipsaddtodrawTP,betchipsaddtconfrmTP", {
      // t1.to("#betchipsaddtodrawTP", {
      //   x: -1084,
      //   y: -598, duration: 0.1, opacity: 1,

      // });
      // t1.play();

      //  this.insurenceactiveornot(res);
      // this.Showbonustouser(res);

      this.setState({ infoText: res.message });

      if (this.state.drawnbtnshows == 1) {
        const drawbutton = document.getElementById("drawbtnTP");
        drawbutton.src = Drawbtnwindows;
        drawbutton.classList.add("drwnodropTP")
        document.getElementById("drawtextTP").textContent = this.props.language.ReDraw;
        Object.assign(drawbutton.style, porttraitejson.Drawbtnwindows);
        document.getElementById('crdscoverTP').classList.remove("crdscoverTP")
      }

    } else {
      if (window.innerWidth < window.innerHeight) {
        if (this.state.drawnbtnshows != 1 || actionName == 'REDRAW_CARDS') {
          document.getElementById("betpannelTropicalcover").style.display = "block";
          document.getElementById("betpannelTropicalcover").classList.add("PlayerCardpannelcoverPortraitTP");
        }
      } else {
        if (this.state.drawnbtnshows != 1 || actionName == 'REDRAW_CARDS') {
          document.getElementById("betpannelTropicalcover").style.display = "block";
        }
      }

      this.setState({
        infoText: res.message,
        pokerranking: res.playerHand,
        totalbet: res.totalBetAmount,
        anteBetAmount: res.anteBetAmount,
        buttontext: "fold",
        betbutton: "Bet",
        buydrawCheck: true
        // bonusbetamount: res.bonusBetAmount,
        // betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
      });

      document.getElementById("betbtntextTP").innerHTML = "";
      document.getElementById("betbtntextTP").className = "";
      document.getElementById("foldtextTP").className = "";
      document.getElementById("foldtextTP").textContent = "";
      document.getElementById("drawtextTP").textContent = "";


      this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);

      this.showParamBetStatus(false);

      if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
        //  if(res.playerBuyCardAmount > 0){
        this.prevstateBuyorDrawChip(res);
      }
      drawbutton.src = "";
      drawbutton.style.position = "";
      drawbutton.style.height = "";
      drawbutton.style.width = "";
      drawbutton.style.transform = "";

      document.getElementById("drawtextTP").textContent = "";

      buybutton.src = "";
      buybutton.style.position = "";
      buybutton.style.width = "";

    }
  }



  prevstateBuyorDrawChip = (res) => {
    let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainerTP");
    let betchipsdraw = document.getElementById("betchipsaddtodrawTP");
    let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawTextTP");
    const rankinginfoElement = document.getElementById("rankinginfoTP");

    let betactiveTP
    if (window.innerWidth > window.innerHeight) {
      betactiveTP = "betactiveLndspTP";
      // betactiveTP = this.state.betactvglwcss;

    } else {
      betactiveTP = "betactivePortraitTP";
      // betactiveTP = this.state.betactvglwcss;

    }
    // console.log(typeof res.betAmount, res.betAmount > 0, Number(res.betAmount) > 0)
    if (res.betAmount > 0) {
      document.getElementById("betactiveTP").src = "";
      document.getElementById("betactiveTP").className = "";
    } else {
      document.getElementById("betactiveTP").src = bnsactiveTP;
      document.getElementById("betactiveTP").classList.add(betactiveTP);

    }







    let convrBuy = this.topLable_rsp(res.anteBetAmount);
    let buyordraw = res.anteBetAmount;
    if (res.playerReplaceCardAmount > 0) {
      convrBuy = this.topLable_rsp(res.playerReplaceCardAmount);
      buyordraw = res.playerReplaceCardAmount;

    }
    this.highlightPlayerCardsName(res.playerHand);
    let betTextaddtodrawElementClass = "bet-text-addtoBet20TP";
    betchipsdraw.src = this.ReturnChipBasedAmount(buyordraw);
    switch (this.state.count) {
      case 1:
        // betchipsdraw.src = chip_10;
        // betTextaddtodrawElement.textContent = 10;
        betTextaddtodrawElement.textContent = convrBuy;
        betTextaddtodrawElementClass = "bet-text-addtoBet10TP";
        break;
      case 2:
        // betchipsdraw.src = chip_25;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 3:
        // betchipsdraw.src = chip_50;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 4:
        // betchipsdraw.src = chip_100;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 5:
        // betchipsdraw.src = chip_500;
        betTextaddtodrawElement.textContent = convrBuy;
        break;
      case 6:
        // betchipsdraw.src = chip_1000;
        betTextaddtodrawElement.textContent = convrBuy;
        break;

      default:
        betTextaddtodrawElementClass = "bet-text-addtoBet20TP";
        break;
    }


    this.buydrawchipactivated = true;
    if (window.innerHeight > window.innerWidth) {
      document.getElementById("playerDealerCardContainerTP").style.zIndex = 1;
      document.getElementById("betpannelTropicalcover").style.display = "block";
      document.getElementById("betpannelTropicalcover").classList.add("pannelcoverTP");
      document.getElementById("betbtntextTP").classList.remove("betportraitTP");
      document.getElementById("foldtextTP").classList.remove("foldtextportraitTP");

      betchipsaddtodrawContainerElement.classList.add("drawChipDivTP");
      betTextaddtodrawElement.className = "";
      betTextaddtodrawElement.style.color = "#ffffff";

      // betTextaddtodrawElement.classList.add('betTextaddtodrawTP', betTextaddtodrawElementClass);

      betchipsdraw.classList.add("betchipsaddtodrawTP")
      rankinginfoElement.classList.add("pokerrankingPortaitTP");
      gsap.to(this.chipdrawref.current, {
        // x: 360,
        x: 322,
        y: -1230,
        duration: 0.000001,
        zIndex: 12,
        autoAlpha: 1,
        onStart: () => {
          gsap.set(this.chipdrawref.current, { opacity: 1 });
        }
      });

    } else {

      // document.getElementById("playerDealerCardContainerTP").style.zIndex = 20;
      document.getElementById("betpannelTropicalcover").style.display = "block";
      document.getElementById("betpannelTropicalcover").classList.add("PlayerCardpannelcoverLndspTP");
      document.getElementById("betbtntextTP").classList.remove("betLandscapeTP");
      document.getElementById("foldtextTP").classList.remove("foldtextLandscapeTP");

      betchipsaddtodrawContainerElement.classList.add("drawChipDivLndspTP");
      betTextaddtodrawElement.className = "";
      betTextaddtodrawElement.style.cssText = "";
      betTextaddtodrawElement.style.color = "#ffffff";
      // betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndspTP');

      betchipsdraw.classList.add("betchipsaddtodrawTP")
      rankinginfoElement.classList.add("pokerrankingPlayerLndspTP");
      gsap.to(this.chipdrawref.current, {
        x: -1230,
        y: 0,
        duration: 0.00001,
        zIndex: 12,
        opacity: 1,
        onStart: () => {
          gsap.set(this.chipdrawref.current, { opacity: 1 });
        }
      });

    }

    if (rankinginfoElement) {
      if (window.innerHeight > window.innerWidth) {

        if (this.state.isMobilePortraitLndsp) {
          rankinginfoElement.style.left = "35%";
        }
      } else {
        if (this.state.isMobilePortraitLndsp) {
          // rankinginfoElement.style.left = "62.5%";
          rankinginfoElement.style.left = "43.5%";
        }
      }


    };


    if (this.state.isMobilePortraitLndsp) {
      document.getElementById("playerDealerCardContainerTP").style.zIndex = 1;
    }


  }


  FoldCards() {

    if (this.state.PayoutTableToggle) {
      this.setState({ PayoutTableToggle: false });
    }

    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    this.clearallsettimeouts()

    // this.setState({bsbtn:true,insurencebtn:true,antebetcircle:true})
    const drawbutton = document.getElementById("drawbtnTP");
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");


    document.getElementById("drawtextTP").textContent = "";

    betbutton.src = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    foldbutton.style.cssText = ""

    drawbutton.src = "";
    drawbutton.style.cssText = ""


    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      this.setState({ FoldbtnTP: 1 })
      // document.getElementById("betTextaddtodrawTP").style.zIndex=-1
      document.getElementById("betbtntextTP").classList.remove("betwindowsTP");
      document.getElementById("foldtextTP").classList.remove("foldtextwindowsTP");
      document.getElementById("drawtextTP").textContent = "";
      document.getElementById("betbtntextTP").textContent = "";

    } else {

      document.getElementById("onepair").style.color = "";
      document.getElementById("Threekind").style.color = "";
      document.getElementById("aceking").style.color = "";
      document.getElementById("fullhse").style.color = "";
      document.getElementById("Straightflush").style.color = "";
      document.getElementById("frofkind").style.color = "";
      document.getElementById("twopair").style.color = "";
      document.getElementById("flush").style.color = "";
      document.getElementById("stright").style.color = "";
      document.getElementById("royalflush").style.color = "";


      this.setState({ betchipincrementbtn: false, betdecreamentbtn: false });

      document.getElementById("betbtntextTP").classList.remove("betportraitTP");
      document.getElementById("foldtextTP").classList.remove("foldtextportraitTP");
      document.getElementById("betbtntextTP").textContent = "";
      document.getElementById("foldtextTP").textContent = "";

      // document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
      // document.getElementById("betpannelTropicalcover").style.display = "none";
      // const isPortrait = window.innerHeight > window.innerWidth;
      // if (isPortrait) {
      //   document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
      //   document.getElementById("betpannelTropicalcover").style.display = "none";
      // } else {
      // }
    }

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),

      CN: "TROPICAL_STUD_POKER_BET",
      object: {
        action: "FOLD",
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
        handId: Number(this.props.gameState.handId)
      },
    };
    // console.log(body);
    this.props.network.sendof(body);

  }


  FoldHandresp(res) {
    console.log(res.bonusBetAmount)
    console.log(res)
    // document.getElementById("insurencetext").style.color = "";
    document.getElementById("bonustextTP").style.color = "";
    document.getElementById("bettextTP").style.color = "";
    this.state.drawnbtnshows = 0
    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2 })
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {


        const drawbutton = document.getElementById("drawbtnTP");
        const buybutton = document.getElementById("buybtnTP");
        const betbutton = document.getElementById("betbtnTP");
        const foldbutton = document.getElementById("FoldbtnTP");

        const drawtextElement = document.getElementById("drawtextTP");
        const betbtntextElement = document.getElementById("betbtntextTP");
        const foldtextElement = document.getElementById("foldtextTP");

        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";

        drawbutton.src = "";
        drawbutton.style.cssText = "";


        buybutton.src = "";
        buybutton.style.cssText = "";

        betbutton.src = "";
        betbutton.style.cssText = "";


        foldbutton.src = "";
        foldbutton.style.cssText = "";


        document.getElementById("confirmbuydealer_popupTP").style.display = "block";
        document.getElementById("alertTxtTP").classList.add("alertTxtTP");

        document.getElementById("alertTxtTP").innerHTML = res.message;
        document.getElementById("confirmbuydealer_popupTP").classList.add("popup_cnfrmbuydealrcardTP");
        document.getElementById("yesTP").classList.add("yesbutton_popupTP")
        document.getElementById("noTP").classList.add("nobutton_popupTP")

      }
      else {
        this.setState({ bsbtn: true })
        const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");


        setTimeout(() => {
          document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
          document.getElementById("betpannelTropicalcover").style.display = "none";
        }, 500)
        // const isPortrait = window.innerHeight > window.innerWidth;
        // if (isPortrait) {
        //   document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
        //   document.getElementById("betpannelTropicalcover").style.display = "none";

        // } else {

        // }
        if (window.innerHeight > window.innerWidth) {

          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait_TP");
          document.getElementById("alertTxtTP").innerHTML = res.message;
          document.getElementById("alertTxtTP").classList.add('alertTxtPortraitTP');
          document.getElementById("yesTP").classList.add("yesbutton_popup_portraitTP")
          document.getElementById("noTP").classList.add("nobutton_popup_portraitTP")

        }
        else {
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp_TP");
          document.getElementById("alertTxtTP").innerHTML = res.message;
          document.getElementById("alertTxtTP").classList.add('alertTxtPortraitTP');
          document.getElementById("yesTP").classList.add("yesbutton_popup_portraitTP")
          document.getElementById("noTP").classList.add("nobutton_popup_portraitTP")

        }
      }


    }
    else {

      // this.clear_snd = new Audio(clear_snd);
      // if (this.state.mutesounds) {
      //   this.clear_snd.pause();
      // }
      // else {
      //   this.clear_snd.play();
      // }

      // if (this.state.mutesounds) {
      //   this.playerLose.pause();
      // } else {
      //   this.playerLose.play();
      // }
      this.playRemainingSounds(this.playerLose)

      this.HideBonuspayouttable()

      this.updateplayerbalnace();
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      this.clertime10 = setTimeout(() => {
        this.setState({ infoText: this.props.language.Place_antebet });
      }, 1000);
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      document.getElementById("betactiveTP").src = "";
      document.getElementById("betactiveTP").classList.remove("betactiveTP");
      // document.getElementById("betpannelTropicalcover").style.display = "block";

      // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
      // const betbutton = document.getElementById("betbtnTP");
      // const foldbutton = document.getElementById("FoldbtnTP");
      document.getElementById("glowonwinchipTP").classList.remove("chipglow_bonusTP")



      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (res.bonusWinAmount > 0) {

          this.removehigltbonusclasses(res);
        }
        this.clertime10 = setTimeout(() => {
          this.setState({ infoText: this.props.language.Place_antebet });
        }, 1500);
        gsap.set("#betTextaddtodrawTP ,betchipsaddtconfrmTP", { zIndex: -2, opacity: 0, duration: 0.1 })
        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount ? res.bonusBetAmount : 0,
          totalbet: 0,
          totalwinRussian: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          // betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          keyboardhandle: true,
          betBtnValue: 2,
          disBetBtn: 0,
          infoText: res.message,
          betchipincrementbtn: false, betdecreamentbtn: false
        }));
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");
        document.getElementById("rankinginfoTP").classList.remove("windowspokerrankingTP");
        document.getElementById("bnsactiveTP").classList.remove("betactiveTP");

        document.getElementById("betdecrementTP").classList.remove("drwnodropTP")
        document.getElementById("betincrenetTP").classList.remove("drwnodropTP")


        // this.clertime11=setTimeout(() => {
        setTimeout(() => {
          document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

          document.getElementById("betbtntextTP").textContent = "";
          document.getElementById("foldtextTP").textContent = "";
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

          document.getElementById("betbtntextTP").innerHTML = this.props.language.Rebet;
          document.getElementById("betbtntextTP").classList.add("betwindowsTP");


          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
          document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");






        }, 2000)

        this.setState({ bsbtn: true });
        document.getElementById("bonusTropical").classList.remove("addpointerTP");
        document.getElementById("bonusTropical").classList.add("drwnodropTP");


        gsap.set("#betTextaddtodrawTP ,betchipsaddtconfrmTP", { zIndex: -2, opacity: 0, duration: 0.1 })
        let t1 = gsap.timeline();
        t1.to("#chipcenterpositionTP", {
          // y: -2060, duration: 20.5, opacity: 1,
          x: 0, y: -662, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { opacity: 0 });
          }
        });
        t1.to("#chipcenterpositionTP", {
          x: 955, y: 554, duration: 0.1, zIndex: -12, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { opacity: 0 });
          }

        });

        t1.play();
        // document.getElementById("centerchipsTP").classList.remove("chip10centerpositionTP")

        // gsap.to("#betTextaddtodrawTP,betchipsaddtodrawTextTP", { opacity: 0, duration: 0.1 })


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtodrawTP, betchipsaddtconfrmTP", {
          x: -1082,
          y: -1267, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodrawTP ,betchipsaddtconfrmTP", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtodrawTP ,betchipsaddtconfrmTP", {
          x: 45, y: -54, duration: 0.5, zIndex: -2, opacity: 0,

          onComplete: () => {
            gsap.set("#betchipsaddtodrawTP ,betchipsaddtconfrmTP", { opacity: 0, delay: 2 });

          }

        });
        t2.play();


        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, duration: 0.1, zIndex: -2 })
        let centerbns = gsap.timeline();
        document.getElementById("glowonwinchipTP").classList.remove("chipglow_bonusTP")
        if (document.getElementById("chip10centerbnsTP")) {
          centerbns.to("#chip10centerbnsTP", {
            x: 478, y: -674, duration: 0.5, zIndex: -2, opacity: 0,

            onComplete: () => {
              gsap.set("#chip10centerbnsTP", { opacity: 0 });

            }

          });
          centerbns.play();
        }
        gsap.set(["#betchipsaddtconfrmTP", "#betchipsaddtodrawTP", "#chipcenterpositionTP", "#betchipsaddtobetTP"], { clearProps: "all" });
      }
      else {
        this.clertime10 = setTimeout(() => {
          this.setState({ infoText: this.props.language.Place_antebet });
        }, 1000);
        this.HideBonuspayouttable()
        this.setState((prevState) => ({
          previousAnteBetAmount: prevState.anteBetAmount,
          previousBonusbetamount: prevState.bonusbetamount,
          totalbet: 0,
          totalwinRussian: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          prevStatestoreantebetchips: [...prevState.storeantebetchips],
          prevStatestorebonuschips: [...prevState.storebonuschips],
          storebonuschips: [],
          storeinsurancechips: [],
          // betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          infoText: res.message,
          bsbtn: true
        }));

        setTimeout(() => {
          betbutton.src = Betbtn;
          //document.getElementById("betbtntextTP").textContent = "";
          document.getElementById("betbtntextTP").innerHTML = this.props.language.Rebet;
          document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
        }, 1500)



        const betbtntextElement = document.getElementById("betbtntextTP");
        const foldtextElement = document.getElementById("foldtextTP");
        const betactiveBlinkElement = document.getElementById("betactiveTP");

        const drawbtnElement = document.getElementById("drawbtnTP");
        const buybtnElement = document.getElementById("buybtnTP");
        const drawtextElment = document.getElementById("drawtextTP");

        if (res.action === "CONFIRM_FOLD") {
          if (drawbtnElement && drawtextElment) {
            drawbtnElement.src = "";
            buybtnElement.src = "";
            drawtextElment.textContent = "";
          }

          if (window.innerHeight > window.innerWidth) {
            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 608, y: -116, duration: 0.5, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
              }
            });

            t4.play();

          } else {
            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
              }
            });

            t4.play();
          }

        }



        betactiveBlinkElement.src = "";
        betactiveBlinkElement.classList.remove("betactivePortraitTP");
        let rankinginfoElement = document.getElementById("rankinginfoTP");
        rankinginfoElement.className = "";
        rankinginfoElement.textContent = "";


        setTimeout(() => {

          this.clearActivesWeDid();
        }, 500)


        setTimeout(() => {
          betbutton.src = Betbtn;
          foldbutton.src = Foldbn;
          document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
        }, 1500)
        if (window.innerHeight > window.innerWidth) {

          setTimeout(() => {
            Object.assign(betbutton.style,  this.state.betbuttoncss)
            Object.assign(foldbutton.style, this.state.foldbuttoncss);

            betbtntextElement.textContent = this.props.language.Rebet;
            foldtextElement.textContent = this.props.language.RebetX2;
            // porttraitejson.betportraiteTP
            betbtntextElement.classList.add(this.state.betbtntextcss);
            foldtextElement.classList.add(this.state.foldbtntextcss);
          }, 1500)


          // x: 25,
          // y: 1200,
          let t1 = gsap.timeline();
          t1.to("#chipcenterpositionTP", {
            // x:25, y: 1200, duration: 0.2, opacity: 1,
            x: 25, y: -2060, duration: 0.2, opacity: 1,
            onComplete: function () {
              gsap.set("#chipcenterpositionTP", { opacity: 0 });
            }
          });
          t1.to("#chipcenterpositionTP", {
            // x: 13,
            y: -1130, duration: 0.0001, zIndex: -12, opacity: 0,
            onComplete: function () {
              gsap.set("#chipcenterpositionTP", { opacity: 0, });
            }

          });
          t1.play();

          if (this.state.buydrawCheck) {

            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, {
              x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 0 });
              }
            });
            t4.to(this.chipdrawref.current, {
              x: 608, y: -116, duration: 0.5, zIndex: -2, opacity: 0,
              onComplete: () => {
                gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
              }
            });
            t4.play();
          }
          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbnsTP")) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbnsTP", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
              ml.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
              // gsap.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
              this.playerWinBonus = false;

            }

          }



        } else {

          // IF MOBILE LANDSCAPE ;


          setTimeout(() => {
            Object.assign(betbutton.style, this.state.betbuttoncss)
             Object.assign(foldbutton.style, this.state.foldbuttoncss);

            betbtntextElement.textContent = this.props.language.Rebet;
            foldtextElement.textContent = this.props.language.RebetX2;
            // porttraitejson.betportraiteTP
            betbtntextElement.classList.add(this.state.betbtntextcss);
            foldtextElement.classList.add(this.state.foldbtntextcss);
          }, 1500)




          let t1 = gsap.timeline();
          t1.to("#chipcenterpositionTP",
            { x: 0, y: -600, duration: 0.5, autoAlpha: 1, ease: "power2.inOut", });
          t1.to("#chipcenterpositionTP",
            { x: 0, y: 0, duration: 0.0001, zIndex: -12, autoAlpha: 0 });
          t1.play();

          if (this.state.buydrawCheck) {
            let t4 = gsap.timeline();
            t4.to(this.chipdrawref.current, { y: -600, duration: 0.3, ease: "power2.inOut", autoAlpha: 1 });
            t4.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0 });
            t4.play();
          }

          if (this.playerWinBonus === true) {
            // this.ifPlayerWinBonusAmount();
            let ml = gsap.timeline()
            ml.to("#chip10centerbnsTP", {
              x: 600, y: -500, duration: 0.2, autoAlpha: 1,
            });
            ml.to("#chip10centerbnsTP", {
              x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
            });
          }
        }

      }
    }

  }
  Show_FoldHandresp(res) {

    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      if (res.playerReplaceCardAmount > 0 || res.playerBuyCardAmount > 0) {
        this.drawReplaceCards = true;

      }


      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2, infoText: res.message })
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {



        this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerCards);
        this.showParamBetStatus(false);
        this.ShowbonusstatusTousr(res);



        document.getElementById("confirmbuydealer_popupTP").style.display = "block";
        document.getElementById("alertTxtTP").classList.add("alertTxtTP");

        document.getElementById("alertTxtTP").innerHTML = res.message;
        document.getElementById("confirmbuydealer_popupTP").classList.add("popup_cnfrmbuydealrcardTP");
        document.getElementById("yesTP").classList.add("yesbutton_popupTP")
        document.getElementById("noTP").classList.add("nobutton_popupTP")

      }

    }
    else {
      this.updateplayerbalnace();
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      this.clertime10 = setTimeout(() => {
        this.setState({ infoText: this.props.language.Place_antebet });
      }, 700);
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      document.getElementById("betactiveTP").src = "";
      document.getElementById("betactiveTP").classList.remove("betactiveTP");
      // document.getElementById("betpannelTropicalcover").style.display = "block";
      document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

      // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
      // const betbutton = document.getElementById("betbtnTP");
      // const foldbutton = document.getElementById("FoldbtnTP");
      betbutton.src = Betbtn;
      //document.getElementById("betbtntextTP").textContent = "";
      document.getElementById("betbtntextTP").innerHTML = this.props.language.Rebet;
      document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;



      // this.setState({ infoText: res.message });



      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          keyboardhandle: true,
          betBtnValue: 2,
          disBetBtn: 0,
          infoText: res.message
        }));

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");
        document.getElementById("rankinginfoTP").classList.remove("windowspokerrankingTP");
        document.getElementById("bnsactiveTP").classList.remove("betactiveTP");



        document.getElementById("betbtntextTP").textContent = "";
        document.getElementById("foldtextTP").textContent = "";
        // this.clertime11=setTimeout(() => {

        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

        document.getElementById("betbtntextTP").innerHTML = this.props.language.Rebet;
        document.getElementById("betbtntextTP").classList.add("betwindowsTP");


        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
        document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true });
        document.getElementById("bonusTropical").classList.remove("addpointerTP");
        document.getElementById("bonusTropical").classList.add("drwnodropTP");



        let t1 = gsap.timeline();
        t1.to("#chipcenterpositionTP", {
          // y: -2060, duration: 20.5, opacity: 1,
          x: 0, y: -662, duration: 0.2, opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { opacity: 0 });
          }
        });
        t1.to("#chipcenterpositionTP", {
          x: 955, y: 554, duration: 0.1, zIndex: -12, opacity: 0,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { opacity: 0 });
          }

        });

        t1.play();
        // document.getElementById("centerchipsTP").classList.remove("chip10centerpositionTP")


        let t2 = gsap.timeline();
        t2.to("#betchipsaddtodrawTP, betchipsaddtconfrmTP", {
          x: -1082,
          y: -1267, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodrawTP ,betchipsaddtconfrmTP", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtodrawTP ,betchipsaddtconfrmTP", {
          x: 45, y: -54, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#betchipsaddtodrawTP ,betchipsaddtconfrmTP", { opacity: 0, delay: 2 });
          }

        });
        t2.play();
      }
    }
  }

  ifPlayerWinBonusAmount = () => {
    if (document.getElementById("chip10centerbnsTP")) {
      this.playerWinBonus = false;
      let tl = gsap.timeline();
      // tl.to("#chip10centerbnsTP", { x: 890, y: 0, duration: 0.5, zIndex: 2, ease: "power2.inOut", autoAlpha: 1 });
      tl.to("#chip10centerbnsTP", { x: 0, y: 0, duration: 0.00001, zIndex: -2, autoAlpha: 0 });

    }

  }

  TotalbetamountGreaterthanbalance(betamnt) {
    let balance = this.balnce;

    if (navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform === "Windows") {

      if (this.state.totalbet > this.props.balance) {

        document.getElementById("infoTropicalP").classList.add("windowsinfoTP");
        document.getElementById("betbtnTP").classList.remove("addpointerTP")
        document.getElementById("FoldbtnTP").classList.remove("addpointerTP")
        document.getElementById("betbtnTP").classList.add("drwnodropTP")
        document.getElementById("FoldbtnTP").classList.add("drwnodropTP")

        this.setState({
          infoText: this.props.language.Low_Balance,
          disablemultibutton: false,
          multibuttonsebledsble: false


        });
        document.getElementById("betpannelTropicalcover").style.display = "block";
        document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
        document.getElementById("betbtnTP").classList.add("addpointerTP")
        document.getElementById("FoldbtnTP").classList.add("addpointerTP")

      }
      else {
        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");
        // this.setState({ infoText: this.props.language.deal_tostart })
        document.getElementById("betbtnTP").classList.remove("drwnodropTP")
        document.getElementById("FoldbtnTP").classList.remove("drwnodropTP")



      }
    } else {
      const inforussianElement = document.getElementById("infoTropicalP");
      const inforussianlandElement = document.getElementById("infoTroppicalland");
      const betbtnElement = document.getElementById("betbtnTP");
      const FoldbtnElement = document.getElementById("FoldbtnTP");
      const mobileAntiMaskCoverElement = document.getElementById("mobileAntiMaskCoverTP")


      if (window.innerHeight > window.innerWidth) {
        // console.log(this.state.totalbet, this.props.balance)
        if (this.state.totalbet > this.props.balance) {
          inforussianElement.className = "";
          betbtnElement.className = "";
          FoldbtnElement.className = "";
          inforussianElement.classList.add('portraitinfoTP');
          mobileAntiMaskCoverElement.style.display = 'block';
          mobileAntiMaskCoverElement.classList.add('mobileAntiMaskCoverTP');
          this.setState({
            infoText: this.props.language.Low_Balance,
            disablemultibutton: false,
            multibuttonsebledsble: false
          });

        }

      } else {
        if (this.state.totalbet > this.props.balance) {
          inforussianlandElement.className = "";
          betbtnElement.className = "";
          FoldbtnElement.className = "";
          inforussianlandElement.classList.add('lndspinfoTP');
          mobileAntiMaskCoverElement.style.display = 'none';
          mobileAntiMaskCoverElement.classList.remove('mobileLndspAntiMaskCoverTP');
          this.setState({ infoText: this.props.language.deal_tostart });
          betbtnElement.classList.remove("drwnodropTP");
          FoldbtnElement.classList.remove("drwnodropTP");
        }

      }
    }

  }



  previousantebonousamount(bettextTP) {
    let rebetamount = 0;
    let rebetorrebetX2 = 1;
    if (bettextTP == "REBET") {
      rebetorrebetX2 = 1;
      // console.log(bettextTP)
      rebetamount = this.state.previousAnteBetAmount + this.state.previousBonusbetamount
      // this.setState({ totalbet: rebetamount }, () => {
      //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
      // })
      this.setState({
        totalbet: rebetamount,
        anteBetAmount: this.state.previousAnteBetAmount,
        bonusbetamount: this.state.previousBonusbetamount,
        betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
        betBtnValue: 0, disBetBtn: 0
      })
      console.log(this.state.previousAnteBetAmount)
      console.log(this.state.previousBonusbetamount)
      console.log(this.state.betdeductions)
      // console.log("rebet    : ", this.state.anteBetAmount, this.state.bonusbetamount, this.state.previousAnteBetAmount, this.state.previousBonusbetamount);

    }
    // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
    //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
    // );
    else if (bettextTP == "REBETX2") {
      rebetorrebetX2 = 2;
      let rebetdouble = (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2;
      rebetamount = rebetdouble;
      this.setState((prevState) => ({
        totalbet: rebetamount,
        anteBetAmount: prevState.previousAnteBetAmount * 2,
        bonusbetamount: prevState.previousBonusbetamount * 2,
        betdeductions: (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2,
        betBtnValue: 0, disBetBtn: 0

      }));
      // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
      // console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount, this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
    }
  }


  ReturnChipBasedAmount = (amt) => {
    const amount = Number(amt)
    let chipImage = chip_10;
    if (Number(this.chipVal[0].val) <= amount && Number(this.chipVal[1].val) > amount) {
      chipImage = chip_10
    } else if (Number(this.chipVal[1].val) <= amount && Number(this.chipVal[2].val) > amount) {
      chipImage = chip_25
    }
    else if (Number(this.chipVal[2].val) <= amount && Number(this.chipVal[3].val) > amount) {
      chipImage = chip_50
    }
    else if (Number(this.chipVal[3].val) <= amount && Number(this.chipVal[4].val) > amount) {
      chipImage = chip_100

    } else if (Number(this.chipVal[4].val) <= amount && Number(this.chipVal[5].val) > amount) {
      chipImage = chip_500

    } else {
      chipImage = chip_1000
    }
    return chipImage
  }

  removeChipSound = () => {

  }


  playPokerChipSound = (chipSound) => {
    const { isPlayingChip, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {

      if (isPlayingChip) {
        chipSound.stop();
      }
      if (!chipSound) {
        currentSound = this.initializeChipSound();
      }

      currentSound.play();
      this.setState({
        isPlayingChip: true
      })
    }

  };

  initializeChipSound = () => {
    let chipSound = new Howl({
      src: [pokerchipsnd],
      html5: true, // Enable HTML5 Audio for mobile compatibility
    });
    this.setState({
      chipSound,
      isPlayingChip: false
    })
    return chipSound
  }

  playPokerBetSound = (chipSound) => {
    const { isPlayingBet, mutesounds } = this.state;
    let currentSound = chipSound;

    if (!mutesounds) {
      if (isPlayingBet && chipSound && typeof chipSound.stop === "function") {
        chipSound.stop();
      }


      if (!chipSound || typeof chipSound.play !== "function") {
        currentSound = this.initializeBetSound();
      }


      if (currentSound && typeof currentSound.play === "function") {

        currentSound.play();
        this.setState({
          isPlayingBet: true,
        });
      } else {
        console.error("Invalid sound object. Unable to play sound.");
      }
    }
  };
  initializeBetSound = () => {
    try {
      const betSound = new Howl({
        src: [betchipsnd],
        html5: true, // Enable HTML5 Audio for mobile compatibility
      });
      this.setState({
        betSound,
        isPlayingBet: false,
      });
      return betSound;
    } catch (error) {
      console.error("Error initializing bet sound:", error);
      return null;
    }
  };
  initializePlayerWinSound = () => {

    let winSound = new Howl({
      src: [playerWin],
      html5: true,
    });

    return winSound
  }
  initializePlayerLoseSound = () => {
    let winSound = new Howl({
      src: [playerLose],
      html5: true,
    });

    return winSound

  }
  playRemainingSounds = (chipSound) => {
    const { isPlayingBet, mutesounds } = this.state;
    let currentSound = chipSound;
    if (!mutesounds) {
      // if (currentSound) {
      //   chipSound.stop();
      // }
      // if (!chipSound) {
      //   currentSound = this.initializeBetSound();
      // }

      currentSound.play();
      // this.setState({
      //   isPlayingBet: true
      // })
    }
  }


  anteclick(bettextTP) {
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    // const betbutton = document.getElementById("betbtnTP");
    // const foldbutton = document.getElementById("FoldbtnTP");
    // this.pokerchipsnd = new Audio(pokerchipsnd);
    // this.pokerchipsnd.currentTime = 0;

    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainerTP');
    const betchipstoantebet = document.getElementById(`betchipsaddtoanteTP${this.state.idincrement}`);

    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const betbtntextElement = document.getElementById("betbtntextTP");
    const foldtextElement = document.getElementById("foldtextTP");
    let bnsactived = document.getElementById("bnsactiveTP");
    // bnsactived.src = bnsactiveTP;
    const winpopuprspelement = document.getElementById("winpopup_TP");
    const winamtElement = document.getElementById("winamt_TPS");

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      // if (bettextTP == "REBET") {
      //   this.state.bonusactive=this.props.gameState.response.bonusBetAmount
      // }
      // else if (bettextTP == "REBETX2") {
      //   this.state.bonusactive=this.props.gameState.response.bonusBetAmount*2
      // }
      document.getElementById("bonusTropical").classList.remove("drwnodropTP");
      document.getElementById("bonusTropical").classList.add("addpointerTP");
    } else {

      if (bettextTP == "REBETX2" || bettextTP == "REBET") {
        betbutton.src = "";
        foldbutton.src = "";
        betbutton.style.cssText = "";
        foldbutton.style.cssText = "";
        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = "";
        foldtextElement.textContent = "";
      }

    }


    document.getElementById("betbtnTP").classList.remove("bnspoinerTP")


    gsap.to(`#betchipsaddtoanteTP${this.state.idincrement}`, {
      opacity: 1
    });


    // pokerranking

    this.setState((prevState) => ({
      idincrement: prevState.idincrement + 1,
      // betbutton: "DEAL",
      // buttontext: "clear",
      pokerranking: "",
      pokerrankingdeler: "",
      // totalwinRussian: 0,
      disClearBtn: 0,
      betBtnValue: 0,
      disBetBtn: 0,
      keyboardhandle: true
      //  totalbet:prevState.totalbet + 1,
    }));

    // this.cardsRef.current.ReBet();
    // this.dealerCardsContainer.current.ReBet();
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.drawReplaceCards = false;

    document.getElementById('rankinginfodelerTP').className = "";
    document.getElementById('rankinginfoTP').className = "";
    document.getElementById("bonusTropical").classList.remove("bnspoinerTP");
    let rebetamount = 0;
    let rebetorrebetX2 = 1;


    // this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })


    if (this.state.previousAnteBetAmount * rebetorrebetX2 <= this.props.maxBetAmt) {


      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (this.state.previousBonusbetamount > 0 || this.state.previousAnteBetAmount > 0) {
          if (bettextTP == "REBET") {
            rebetorrebetX2 = 1;
            rebetamount = this.state.previousAnteBetAmount + this.state.previousBonusbetamount
            this.setState({
              totalbet: rebetamount,
              anteBetAmount: this.state.previousAnteBetAmount,
              bonusbetamount: this.state.previousBonusbetamount,
              betBtnValue: 0, disBetBtn: 0

            })

          }
          else if (bettextTP == "REBETX2") {
            rebetorrebetX2 = 2;
            let rebetdouble = (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2;

            rebetamount = rebetdouble;
            this.setState((prevState) => ({
              totalbet: rebetamount,
              anteBetAmount: prevState.previousAnteBetAmount * 2,
              bonusbetamount: prevState.previousBonusbetamount * 2,
              betBtnValue: 0, disBetBtn: 0

            }));
            // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
            // console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
          }
        }
        // }
        else {
          // this.setState({ infoText: this.props.language.bonus_maxbet })
        }

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })


        this.setState({ betbutton: "DEAL", buttontext: "clear", })
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
        const textbetsHighElement = document.getElementById("textbets_highTP");
        const attentionElement = document.getElementById("attentionTP");
        const betbtnElement = document.getElementById("betbtnTP");

        let rebetstatus = 1;
        if (bettextTP == "REBETX2" || bettextTP == "REBET") {


          // if (this.state.anteBetAmount >= this.props.maxBetAmt) {
          //   this.setState({
          //     betdeductions: (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2,
          //     betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
          //   })
          // }
          // else {
          //   this.setState({
          //     betdeductions: 0,
          //     betdeductions: 0
          //   })
          // }

          console.log(this.state.betdeductions)

          let bnsactived = document.getElementById("bnsactiveTP");
          bnsactived.classList.add("bnsctivewindowsTP");
          bnsactived.classList.add("bnsblinkTP");

          // document.getElementById("crdscoverTP").classList.add("crdscoverTP")
          document.getElementById("playerDealerCardContainerTP").classList.add("addpointerTP")

          // if (this.props.gameState.response && this.state.previousBonusbetamount >0 && this.state.bonusactive<=this.props.maxBetAmt) {
          // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {
          // if (this.props.gameState.response.bonusBetAmount > 0) {

          if (this.state.previousBonusbetamount > 0) {
            var tl = gsap.timeline();
            tl.to(this.chipbnsref.current, {
              x: -872,
              y: -465,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipbnsref.current, {
              x: 0,
              y: 0,
              opacity: 0,
              duration: 0.00001,
            });
            // tl.reverse();
            tl.to(this.chipbnsref.current, { opacity: 1, zIndex: -2 });
            tl.play();
          }
          //  }
          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;

          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
              if (bettextTP == "REBETX2") {
                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbnsTP span");
                // console.log(this.state.anteBetAmount)
                // let chipcenterpositionElement = document.querySelectorAll("#chipcenterpositionTP span");
                // let chip10centerbnsElement = document.querySelectorAll("#chip10centerbnsTP span");
                // console.log(chipcenterpositionElement)
                // for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //   chipcenterpositionElement[a].innerHTML = this.state.previousAnteBetAmount * 2;
                // }
                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount * 2;
                }
                let doubleante = (this.state.previousAnteBetAmount) * 2
                // if (this.props.gameState.response && this.state.previousBonusbetamount >0   && this.state.bonusactive<=this.props.maxBetAmt) {
                // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {
                // if (this.props.gameState.response.bonusBetAmount > 0) {
                if (this.state.previousBonusbetamount > 0) {
                  let doublebnsbet = (this.state.previousBonusbetamount) * 2;
                  chip10centerbnsElement = this.topLable_rsp(doublebnsbet);
                }
                // }
                totalAnteBetAmount = this.topLable_rsp(doubleante);
                rebetstatus = 2;
                // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
              } else {
                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbnsTP span");

                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount;
                }

                totalAnteBetAmount = this.topLable_rsp(this.state.previousAnteBetAmount);
                // console.log(totalAnteBetAmount)
                chip10centerbnsElement = this.topLable_rsp(this.state.bonusbetamount);
                rebetstatus = 1;

                // if (this.props.gameState.response && this.state.previousBonusbetamount >0   && this.state.bonusactive<=this.props.maxBetAmt){
                // if (this.props.gameState.response && this.state.previousBonusbetamount >0) {

                // if (this.props.gameState.response.bonusBetAmount > 0) {
                if (this.state.previousBonusbetamount > 0) {
                  let chip10centerbnsElement = document.querySelectorAll("#chip10centerbnsTP span");

                  for (let b = 0; b < chip10centerbnsElement.length; b++) {
                    chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount;
                  }

                  chip10centerbnsElement = this.topLable_rsp(this.state.bonusbetamount);
                }
                // }

                // totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
              }
            }
          }


          if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance) ||
            Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
            // console.log("this.state.anteBetAmount :------", this.state.anteBetAmount);
            // if (Number(this.state.anteBetAmount)  + Number(this.state.bonusbetamount) > 39) {

            this.setState({
              anteBetAmount: 0,
              bonusbetamount: 0,
              totalbet: 0,
            })
            // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true });
            const betbutton = document.getElementById("betbtnTP");
            const foldbutton = document.getElementById("FoldbtnTP");
            const betbtntextElement = document.getElementById("betbtntextTP");
            const foldtextElement = document.getElementById("foldtextTP");

            betbutton.src = "";
            foldbutton.src = "";
            betbtntextElement.className = "";
            foldtextElement.className = "";
            //  if(this.props.balance!=0){
            if (this.state.isplayerinfoupdate) {
              if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
             
                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                })
                 
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              } else if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
               
                this.setState({
                  betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with bet ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }

            }



            //  }


            document.getElementById("betbtnTP").classList.remove("addpointerTP")
            document.getElementById("betbtnTP").classList.add("drwnodropTP")
            popupbetGreaterthanBalnceElement.className = "";
            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
              { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

              popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowsTP");
              textbetsHighElement.classList.add("poptext_betshighTP");
              attentionElement.classList.add("attention_textTP");

            }

          }
          else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {
            document.getElementById("betbtntextTP").textContent = this.props.language.Deal;
            document.getElementById("betbtntextTP").classList.add("betwindowsTP");
            document.getElementById("foldtextTP").classList.remove("rebettextwindowsTP");
            document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
            document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");
            this.setState({ betbutton: "DEAL", buttontext: "clear", })

            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
              multibuttonsebledsble: true,
              anteBetAmount: 0,
              bonusbetamount: 0,
              totalbet: 0,
            })

          } else {
            bnsactived.src = bnsactiveTP;
            this.previousantebonousamount(bettextTP)
            this.setState({ multibuttonsebledsble: false, bsbtn: false, });
            this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
            gsap.to("#chipcenterpositionTP", { opacity: 0 });
            const betbutton = document.getElementById("betbtnTP");
            const foldbutton = document.getElementById("FoldbtnTP");


            document.getElementById("betbtntextTP").textContent = "";
            document.getElementById("foldtextTP").textContent = "";

            betbutton.src = "";
            betbutton.style.position = "";
            betbutton.style.width = "";
            betbutton.style.transform = "";

            foldbutton.src = "";
            foldbutton.style.position = "";
            foldbutton.style.width = "";
            foldbutton.style.transform = "";

            //  this.clertime25=setTimeout(() => {
            betbutton.src = Betbtn;
            Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
            document.getElementById("betbtntextTP").textContent = this.props.language.Deal;


            document.getElementById("betbtntextTP").classList.add("betwindowsTP");

            foldbutton.src = Foldbnwindows;
            Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
            document.getElementById("foldtextTP").classList.remove("rebettextwindowsTP");
            document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
            document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");

            document.getElementById("bonustextTP").style.color = "#ffffff";

            // Object.assign(betchipstoantebet.style,porttraitejson.Betchiptoantewindows)
            this.clertime25 = setTimeout(() => {

              Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)
            }, 1500)
            console.log(this.props.gameState.response.bonusBetAmount)
            // if (this.props.gameState.response.bonusBetAmount && this.state.previousBonusbetamount >0
            // && this.state.bonusactive<=this.props.maxBetAmt

            // ) {s
            if (this.state.previousBonusbetamount > 0) {
              let bonusbetAmount = 0;
              //               if (Number(this.props.gameState.response.bonusBetAmount > 0 && this.state.bonusactive<=this.props.maxBetAmt
              // )) {
              // if (Number(this.props.gameState.response.bonusBetAmount) > 0) {
              console.log(this.state.previousBonusbetamount)

              if (this.state.previousBonusbetamount > 0) {
                console.log(this.state.previousBonusbetamount)
                this.ShowBonuspayouttable();
                this.showPayoutBonusTable12('BonusPayout');
                let totalbets_bonus

                if (bettextTP == "REBET") {
                  totalbets_bonus = this.topLable_rsp(this.state.previousBonusbetamount)
                  bonusbetAmount = this.state.previousBonusbetamount
                }
                else {
                  totalbets_bonus = this.topLable_rsp(this.state.previousBonusbetamount * 2)
                  bonusbetAmount = this.state.previousBonusbetamount * 2
                }
                let betchipstobns = document.getElementById("betchipsaddtobonusTP");

                console.log(this.state.count)

                switch (this.state.count) {
                  case 1:
                    betchipstobns.src = chip_10;
                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                            <img className="chip10centerbnsTP"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_10TP" className="bonusChipsForwindows_val10_TP">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;
                  case 2:
                    betchipstobns.src = chip_25;
                    //  document.getElementById("antebetval_10TP").textContent = "";

                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                            <img className="chip100centerbnsTP"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_20TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;
                  case 3:

                    betchipstobns.src = chip_50;


                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                            <img className="chip100centerbnsTP"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_50TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;
                  case 4:

                    betchipstobns.src = chip_100;


                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                            <img className="chip100centerbnsTP"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_100TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);


                    break;
                  case 5:
                    betchipstobns.src = chip_500;


                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                            <img className="chip100centerbnsTP"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_500TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);
                  case 6:
                    betchipstobns.src = chip_1000;
                    this.clertime13 = setTimeout(() => {
                      this.setState((prevState) => ({
                        storebetchips: [
                          ...prevState.storebetchips,
                          <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                            <img className="chip100centerbnsTP"
                              src={this.ReturnChipBasedAmount(Number(bonusbetAmount.toFixed(2)))}
                              alt="Chip 10"
                            />
                            <span id="val_500TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                          </div>

                        ],
                      }));
                    }, 150);

                    break;

                  default:
                    break;
                }

                Object.assign(betchipstobns.style, porttraitejson.betchipstobnswindows)

              }
            }

            var tl = gsap.timeline();
            tl.to(this.chipref.current, {
              x: -1082,
              y: -480,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipref.current, {
              opacity: 0,
              x: -54,
              y: -17,
              duration: 0.00001,
              zIndex: -2,
            });
            // tl.reverse();
            tl.to(this.chipref.current, { opacity: 0, zIndex: -2 });
            tl.play();

            // const betchipstoantebet = document.getElementById("betchipsaddtoantecclickTP");

            const betchipstoantebet = document.getElementById(`betchipsaddtoanteTP${this.state.idincrement}`);

            // let totalBetAmountForLimitsCheck = 0;
            // for (let k = 1; k <= this.chipVal.length; k++) {
            //   if (this.state.count === k) {
            //     totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
            //   }
            // }

            // this.clertime12=setTimeout(() => {
            // document.getElementById("bonustextTP").style.color = "#ffffff";

            Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)
            console.log(this.state.previousAnteBetAmount)

            switch (this.state.count) {
              case 1:
                betchipstoantebet.src = chip_10;
                this.clertime13 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterpositionTP">
                        <img id="centerchipsTP" className="chip10centerpositionTP"
                          src={this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount) * rebetorrebetX2)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windowsTP" className="antebet_textval10TP">{totalAnteBetAmount}</span>
                      </div>

                    ],
                  }));
                }, 150);

                break;
              case 2:
                betchipstoantebet.src = chip_25;
                //  document.getElementById("antebetval_10TP").textContent = "";

                this.clertime14 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterpositionTP">

                        <img
                          id="centerchipsTP"
                          className="chip20centerpositionTP"
                          src={this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount) * rebetorrebetX2)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windowsTP" className="antebet_text20TP">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);

                break;
              case 3:

                betchipstoantebet.src = chip_50;

                // document.getElementById("antebetval_20").textContent = "";
                this.clertime15 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterpositionTP">

                        <img
                          id="centerchipsTP"
                          className="chip50centerpositionTP"
                          src={this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount) * rebetorrebetX2)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windowsTP" className="antebet_text50TP">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);

                break;
              case 4:

                betchipstoantebet.src = chip_100;

                // document.getElementById("antebetval_20").textContent = "";
                this.clertime15 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterpositionTP">

                        <img
                          id="centerchipsTP"
                          className="chip100centerpositionTP"
                          src={this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount) * rebetorrebetX2)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windowsTP" className="antebet_text100TP">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);


                break;
              case 5:
                betchipstoantebet.src = chip_500;

                this.clertime17 = setTimeout(() => {

                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterpositionTP">

                        <img
                          id="centerchipsTP"
                          className="chip500centerpositionTP"

                          src={this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount) * rebetorrebetX2)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windowsTP" className="antebet_text50TP">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                break;
              case 6:
                betchipstoantebet.src = chip_1000;

                this.clertime17 = setTimeout(() => {


                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterpositionTP">

                        <img
                          id="centerchipsTP"
                          className="chip500centerpositionTP"

                          src={this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount) * rebetorrebetX2)}
                          alt="Chip 10"
                        />
                        <span id="antebet_windowsTP" className="antebet_text50TP">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                break;

              default:
                break;
            }

            if (this.state.bonusbetamount > 0) {
              // this.setState({ isbonuspattable: true })
              // gsap.to("#chip10centerbnsTP", { x: 0, y: 0, duration: 0.5, opacity: 1, zIndex: 1, });
              // this.ShowBonuspayouttable()
              this.showPayoutBonusTable12('BonusPayout');

            }


          }




          document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");
          document.getElementById("rankinginfoTP").classList.remove("windowspokerrankingTP");
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();
          this.randomPlayerCards = [];
          this.shuffledPlayerCards = [];
          // document.getElementById("betpannelTropicalcover").style.display = "none";
          // document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
          this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
          document.getElementById("betincrenetTP").classList.remove("drwnodropTP")
          document.getElementById("betdecrementTP").classList.remove("drwnodropTP")

          this.playPokerChipSound(this.pokerchipsnd);

          // this.setState({infoText:"Press Deal to start the Game!"})
          // const betbutton = document.getElementById("betbtnTP");
          // const foldbutton = document.getElementById("FoldbtnTP");
          const betbutton = document.getElementById("betbtnTP");
          const foldbutton = document.getElementById("FoldbtnTP");


          document.getElementById("bonusTropical").classList.add("addpointerTP");




          this.setState((prevState) => ({
            idincrement: prevState.idincrement + 1,
            //  totalbet:prevState.totalbet + 1,
          }));
          this.clertime18 = setTimeout(() => {
            gsap.to(`#chipcenterpositionTP`, { zIndex: -15 })
          }, 550);
          // const betchipstoantebet = document.getElementById(
          //   `betchipsaddtoanteTP${this.state.idincrement}`
          // );
          // console.log(document.getElementById('chipcenterpositionTP'))
          const betchipstoantebet = document.getElementById("betchipsaddtoantecclickTP");

          var tl = gsap.timeline();
          tl.to(this.chipref.current, {
            x: -1082,
            y: -480,
            duration: 0.2,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipref.current, {
            opacity: 0,
            x: -54,
            y: -17,
            duration: 0.00001,
            zIndex: -2,
          });
          // tl.reverse();
          tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
          tl.play();


        }
        else {
          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              // console.log(this.state.anteBetAmount)
              // console.log(Number(this.chipVal[k - 1].val))
              totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
              totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
            }
          }

          if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
            //  document.getElementById("TPbetcircle").classList.add("nonpointerTP")

            this.playPokerChipSound(this.pokerchipsnd);

            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
            }, () => {
              // console.log(this.state.infoText)
            })
            //  },100)
            document.getElementById("TPbetcircle").classList.remove("addpointerTP")
            document.getElementById("TPbetcircle").classList.add("insurencenodropTP")

          } else {

            // document.getElementById("TPbetcircle").classList.remove("nonpointerTP")
            // console.log(totalBetAmountForLimitsCheck)

            // console.log(this.state.anteBetAmount)
            console.log(totalAnteBetAmount)
            // console.log(Number(this.props.maxBetAmt))

            if (Number(totalAnteBetAmount) + Number(this.state.bonusbetamount) > this.props.balance ||
              Number(totalAnteBetAmount * 3) + Number(this.state.bonusbetamount) > this.props.balance) {
              // if (totalBetAmountForLimitsCheck > 50) {
              // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true,disBetBtn:1 })

              // if (totalAnteBetAmount * 3+ Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
              // } else if (totalAnteBetAmount+ Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true,disBetBtn:1 })
              // }

              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);
                }
            }
              if (totalAnteBetAmount + Number(this.state.bonusbetamount) > this.props.balance) {
                // alert(2)

                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })

                betbutton.src = Betbtn;
                Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
                document.getElementById("betbtntextTP").classList.remove("betwindowsmlylmTP");
                document.getElementById("betbtntextTP").textContent = this.props.language.Deal;
                document.getElementById("betbtntextTP").classList.add("betwindowsTP");

                foldbutton.src = Foldbnwindows;
                Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
                document.getElementById("foldtextTP").classList.remove("rebettextwindowsTP");
                document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
                document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");

                if(this.state.totalbet == 0){
                betbutton.src = "";
                foldbutton.src = "";
                betbtntextElement.className = "";
                foldtextElement.className = "";
                }
                // betbutton.src = "";
                // foldbutton.src = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
              } else if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
                document.getElementById("betbtntextTP").textContent = this.props.language.Deal;
                document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
                betbutton.src = Betbtn;
                Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
                document.getElementById("betbtntextTP").classList.remove("betwindowsmlylmTP");
                document.getElementById("betbtntextTP").textContent = this.props.language.Deal;
                document.getElementById("betbtntextTP").classList.add("betwindowsTP");

                foldbutton.src = Foldbnwindows;
                Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
                document.getElementById("foldtextTP").classList.remove("rebettextwindowsTP");
                document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
                document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");
                this.setState({
                  betshigh_balnce: true,
                  betbutton: "DEAL", buttontext: "clear",
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with bet ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true

                if(this.state.totalbet == 0){
                betbutton.src = "";
                foldbutton.src = "";
                betbtntextElement.className = "";
                foldtextElement.className = "";
                }

              }



              betbtnElement.classList.remove("addpointerTP")
              betbtnElement.classList.add("drwnodropTP");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowsTP");
                textbetsHighElement.classList.add("poptextBetshighwindowsTP");
                attentionElement.classList.add("attentionTextwindowsTP");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {


            else {
              this.playPokerChipSound(this.pokerchipsnd);

              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {

                  if (this.state.anteBetAmount + Number(this.chipVal[k - 1].val) < Number(this.props.balance)) {

                    this.setState({ multibuttonsebledsble: false, bsbtn: false, });
                    this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
                    this.setState({ betbutton: "DEAL", buttontext: "clear", });
                    let bnsactived = document.getElementById("bnsactiveTP");
                    bnsactived.src = bnsactiveTP;
                    bnsactived.classList.add("bnsctivewindowsTP");
                    bnsactived.classList.add("bnsblinkTP");

                    if (this.chipref) {
                      var tl = gsap.timeline();
                      tl.to(this.chipref.current, {
                        x: -1080,
                        y: -480,
                        duration: 0.2,
                        ease: "power2.inOut",
                        zIndex: 12,
                      });
                      tl.to(this.chipref.current, {
                        opacity: 0,
                        x: 0,
                        y: 0,
                        zIndex: -2,
                        duration: 0.00001,
                      });
                      // tl.reverse();
                      tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
                      tl.play();
                    }
                    console.log(this.state.count)
                    switch (this.state.count) {
                      case 1:
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[0].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                        }));
                        betchipstoantebet.src = chip_10;
                        this.clertime19 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterpositionTP">
                                <img id="centerchipsTP" className="chip10centerpositionTP"
                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windowsTP" className="antebet_textval10TP">{totalAnteBetAmount}</span>
                              </div>

                            ],
                          }));
                        }, 150);

                        break;
                      case 2:
                        betchipstoantebet.src = chip_25;
                        //  document.getElementById("antebetval_10TP").textContent = "";
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[1].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                        }));
                        this.clertime20 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterpositionTP">

                                <img
                                  id="centerchipsTP"
                                  className="chip20centerpositionTP"
                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windowsTP" className="antebet_text20TP">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);

                        break;
                      case 3:

                        betchipstoantebet.src = chip_50;
                        // document.getElementById("antebetval_20").textContent = "";
                        this.clertime21 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterpositionTP">

                                <img
                                  id="centerchipsTP"
                                  className="chip50centerpositionTP"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />

                                <span id="antebet_windowsTP" className="antebet_text50TP">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[2].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                        }));
                        break;
                      case 4:
                        betchipstoantebet.src = chip_100;

                        // document.getElementById("antebetval_50").textContent = "";
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[3].val),
                        }));
                        this.clertime22 = setTimeout(() => {
                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterpositionTP">

                                <img
                                  id="centerchipsTP"
                                  className="chip100centerpositionTP"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windowsTP" className="antebet_text100TP">{totalAnteBetAmount}</span>
                              </div>
                            ],

                          }));
                        }, 150);
                        break;
                      case 5:
                        betchipstoantebet.src = chip_500;
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[4].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                        }));
                        this.clertime23 = setTimeout(() => {

                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterpositionTP">

                                <img
                                  id="centerchipsTP"
                                  className="chip500centerpositionTP"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windowsTP" className="antebet_text50TP">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);
                        break;
                      case 6:
                        betchipstoantebet.src = chip_1000;
                        this.setState((prevState) => ({
                          totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                          betdeductions: prevState.totalbet + Number(this.chipVal[5].val),
                          anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                        }));
                        this.clertime23 = setTimeout(() => {

                          this.setState((prevState) => ({
                            storebetchips: [
                              ...prevState.storebetchips,
                              <div id="chipcenterpositionTP">

                                <img
                                  id="centerchipsTP"
                                  className="chip500centerpositionTP"

                                  src={this.ReturnChipBasedAmount(totalAnteBetAmount)}
                                  alt="Chip 10"
                                />
                                <span id="antebet_windowsTP" className="antebet_text50TP">{totalAnteBetAmount}</span>
                              </div>
                            ],
                          }));
                        }, 150);
                        break;

                      default:
                        break;

                    }


                    // this.clertime25=setTimeout(() => {
                    betbutton.src = Betbtn;
                    Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
                    document.getElementById("betbtntextTP").classList.remove("betwindowsmlylmTP");
                    document.getElementById("betbtntextTP").textContent = this.props.language.Deal;
                    document.getElementById("betbtntextTP").classList.add("betwindowsTP");

                    foldbutton.src = Foldbnwindows;
                    Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
                    document.getElementById("foldtextTP").classList.remove("rebettextwindowsTP");
                    document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
                    document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");

                    document.getElementById("bonustextTP").style.color = "#ffffff";
                    Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)


                    // }, 1500)

                  }
                  else {

                    let doubleantebetamount = this.state.anteBetAmount * 2;
                    let amount = 0
                    for (let k = 1; k <= this.chipVal.length; k++) {
                      if (this.state.count === k) {
                        amount = Number(this.chipVal[k - 1].val);

                      }
                    }
                    this.setState({
                      betshigh_balnce: true,
                      // betshigh_balnce: false,
                      lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with bet ${(doubleantebetamount + amount).toFixed(1)} Please DEPOSIT to continue playing.`
                    }) // multibuttonsebledsble: true




                    betbtnElement.classList.remove("addpointerTP")
                    betbtnElement.classList.add("drwnodropTP");
                    popupbetGreaterthanBalnceElement.className = "";

                    gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                      { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

                    if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                      popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowsTP");
                      textbetsHighElement.classList.add("poptextBetshighwindowsTP");
                      attentionElement.classList.add("attentionTextwindowsTP");

                    }

                  }

                }
              }
            }
            totalAnteBetAmount = this.topLable_rsp(totalBetAmountForLimitsCheck)
          }

        }

        if (this.state.count > 0) {
          this.TotalbetamountGreaterthanbalance(this.state.totalbet);

        }
      }

      else {
        this.betchipactivated = false;
        this.buydrawchipactivated = false;
        this.ConfirmbuydealercardactivePopup = false;
        this.resetPyrWnCpsAmn = false;


        document.getElementById("betpannelTropicalcover").style.display = "block";
        if (document.getElementById("rankinginfodelerTP")) {
          document.getElementById("rankinginfodelerTP").style.cssText = "";
          document.getElementById("rankinginfoTP").style.cssText = "";
        };
        const betbtnElement = document.getElementById("betbtnTP");
        const betbtntextElement = document.getElementById("betbtntextTP");
        const foldtextElement = document.getElementById("foldtextTP");
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
        const textbetsHighElement = document.getElementById("textbets_highTP");
        const attentionElement = document.getElementById("attentionTP");




        betbtntextElement.className = "";
        foldtextElement.className = "";
        betbtntextElement.textContent = this.props.language.Deal;
        foldtextElement.textContent = this.props.language.RP_Clear;
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;


        this.setState({
          multibuttonsebledsble: false,
          betbutton: "DEAL",
          buttontext: "clear",
        })

        const isPortrait = window.innerHeight > window.innerWidth;

        const rankinginfoElement = document.getElementById("rankinginfoTP");
        if (isPortrait) {
          document.getElementById("betpannelTropicalcover").classList.add("PlayerCardpannelcoverPortraitTP");
          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "35%";
            }
          }

          if (this.resetAnimationChips) {
            if (document.getElementById("chipcenterpositionTP")) {
              gsap.to("#chipcenterpositionTP", { x: 107, y: 1086, duration: 0.00001, zIndex: -2, opacity: 0 });
            }

            if (document.getElementById("betchipsaddtobetTP")) {
              gsap.to("#betchipsaddtobetTP", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
              gsap.to("#betTextaddtoBetTP", { x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0 });
            }

            // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
            gsap.to(this.chipdrawref.current, { x: 608, y: -116, duration: 0.00001, zIndex: -5, opacity: 0 });
            // if (this.playerWinBonus === true) {

            // if (document.getElementById("chip10centerbnsTP")) {
            //   gsap.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
            //   this.playerWinBonus = false;

            // }


            // }
            if (this.wanttobuydelercrd === true) {
              gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
            }
          }

          this.resetAnimationChips = false;
          bnsactived.classList.add("bnsactivePortraitTP");
          bnsactived.classList.add("bnsblinkTP");

          Object.assign(betbutton.style,  this.state.betbuttoncss);
          betbtntextElement.classList.add(this.state.betbtntextcss);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.classList.add('foldtextportraitTP');



          let rebetstatus = 1;
          if (bettextTP === "REBETX2") {
            rebetstatus = 2;
          }
          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          if (bettextTP == "REBETX2" || bettextTP == "REBET") {


            if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance) ||
              Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {

              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }

              // betbutton.src ="" ;
              // foldbutton.src ="" ;
              // betbtntextElement.className ="";
              // foldtextElement.className ="";
              if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";


                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }
              } else if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with bet ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }
              }


              betbtnElement.classList.remove("addpointerTP")
              betbtnElement.classList.add("drwnodropTP");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortraitTP");
                textbetsHighElement.classList.add("poptextBetshighPortraitTP");
                attentionElement.classList.add("attentionTextPortraitTP");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

            } else {
              // this.setState({ multibuttonsebledsble: false });
              bnsactived.src = bnsactiveTP;
              this.previousantebonousamount(bettextTP);
              console.log(this.state.anteBetAmount)
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;

              // console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              // let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              this.playPokerChipSound(this.pokerchipsnd);
              this.setState({
                disablemultibutton: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                bsbtn: false,
                betbutton: "DEAL",
                buttontext: "clear",
                // storeantebetchips: [...this.state.prevStatestoreantebetchips],
                // storebonuschips: [...this.state.prevStatestorebonuschips],
                storeantebetchips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipcolor1: "valtextPortrait1TP"
                  },

                ],
                storebonuschips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipcolor1: "insuranceTextPortraitChip10TP"
                  },

                ],

              }, () => {

                document.getElementById("bonustextTP").style.color = "#ffffff";
                if (document.getElementById('chipcenterpositionTP')) {
                  gsap.to("#chipcenterpositionTP", {
                    x: 107, y: 1107, duration: 0.0001, zIndex: 0, opacity: 1, onStart: () => {
                      gsap.set("#chipcenterpositionTP", { opacity: 0 });
                    }
                  });
                  gsap.to("#chipcenterpositionTP", { x: 0, y: 0, duration: 0.2, autoAlpha: 1, zIndex: 1, });
                  const chipcenterpositionElement = document.querySelectorAll("#chipcenterpositionTP");
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {

                    chipcenterpositionElement[q].style.position = 'relative';
                  }

                }
                // console.log(this.state.previousBonusbetamount, typeof (this.state.previousBonusbetamount))

                // if (this.state.previousBonusbetamount > 0 &&Number(this.state.previousBonusbetamount * rebetstatus) <= this.props.maxBetAmt) {
                if (this.state.previousBonusbetamount > 0) {
                  this.setState({ isbonuspattable: true });
                  let tl2 = gsap.timeline();
                  if (document.getElementById("chip10centerbnsTP")) {
                    tl2.to("#chip10centerbnsTP", { x: -100, y: 1110, duration: 0.0001, zIndex: -2, opacity: 0 });
                    tl2.to("#chip10centerbnsTP", {
                      x: 0, y: 0, duration: 0.2, autoAlpha: 1, zIndex: 2, delay: 0.1, onStart: () => {
                        // x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 2, delay: 0.5, onStart: () => {

                        this.playPokerChipSound(this.pokerchipsnd);
                      }
                    });
                  }
                  this.ShowBonuspayouttable()
                  this.showPayoutBonusTable12('BonusPayout');
                } else {
                  if (document.getElementById("chip10centerbnsTP")) {
                    gsap.to("#chip10centerbnsTP", { duration: 0.000001, opacity: 0 })
                  }
                  // this.setState({ infoText: this.props.language.bonus_maxbet });

                }
              });


            }
          } else {

            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            // console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            // console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            //   this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            //   this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            // );


            if (Number(totalAnteBetAmount) + Number(this.state.bonusbetamount) > Number(this.props.balance) ||
              Number(totalAnteBetAmount * 3 + Number(this.state.bonusbetamount)) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {

              // if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
              // } else if (totalAnteBetAmount  + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true })
              // }
              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }

              if (totalAnteBetAmount + Number(this.state.bonusbetamount) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  // multibuttonsebledsble: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }


              } else if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with bet ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }
              }

              betbtnElement.classList.remove("addpointerTP")
              betbtnElement.classList.add("drwnodropTP");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortraitTP");
                textbetsHighElement.classList.add("poptextBetshighPortraitTP");
                attentionElement.classList.add("attentionTextPortraitTP");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                // console.log(this.state.infoText)
              });



            } else {
              bnsactived.src = bnsactiveTP;
              document.getElementById("bonustextTP").style.color = "#ffffff";
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;

              // console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              // antebetpdvcss:"chip-center-position",
              // antechpimgcss:"chip20centerpositionPortraitTP",
              // antespntxtcss:"antebetChipPortrait20TP",

              this.playPokerChipSound(this.pokerchipsnd);

              switch (this.state.count) {
                case 1:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1TP" },

                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                case 6:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),
                  }))
                  betchipstoantebet.src = chip_1000;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }


              Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoanteprotrait);
              var tl = gsap.timeline();
              tl.to(this.chipref.current, {
                x: -100,
                y: -1100,
                duration: 0.2,
                // duration: 0.2,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1
              });


              tl.to(this.chipref.current, {
                x: 0,
                y: 0,
                duration: 0.0001,
                autoAlpha: 0,
                zIndex: -3,
              });
              tl.play();
            }

          }

        } else {

          if (chipsstoredcontainerElement && chipsstoredcontainerElement !== null) {
            chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndspTP");
          }

          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              // rankinginfoElement.style.left = "62.5%";
              rankinginfoElement.style.left = "43.5%";
            }
          }


          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          betbtntextElement.className = "";
          foldtextElement.className = "";

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.classList.add(this.state.betbtntextcss);

          foldbutton.src = Foldbn;
           Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.classList.add(this.state.foldbtntextcss);

          let rebetstatus = 1;
          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;
          // console.log(this.state.previousAnteBetAmount)
          // console.log(this.state.previousBonusbetamount, bettextTP)
          if (bettextTP === "REBETX2") {
            rebetstatus = 2;
          }
          if (bettextTP === "REBETX2" || bettextTP === "REBET") {

            if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance) ||
              Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {
              // console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })

              // betbutton.src ="" ;
              // foldbutton.src ="" ;
              // betbtntextElement.className ="";
              // foldtextElement.className ="";
              if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {


                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";

                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }

                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })

                

              } else if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.previousAnteBetAmount * rebetstatus).toFixed(1)},with bet ${(this.state.previousAnteBetAmount * rebetstatus * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
               
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }
              }





              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }


              betbtnElement.classList.remove("addpointerTP")
              betbtnElement.classList.add("drwnodropTP");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndspTP");
                textbetsHighElement.classList.add("poptextBetshighPortraitTP");
                attentionElement.classList.add("attentionTextPortraitTP");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

            } else {



              bnsactived.src = bnsactiveTP;
              bnsactived.classList.add("bnsactiveLandscapeTP");
              bnsactived.classList.add("bnsblinkTP");
              this.previousantebonousamount(bettextTP);

              Object.assign(betbutton.style, this.state.betbuttoncss);
              betbtntextElement.classList.add(this.state.betbtntextcss);
              betbutton.src = Betbtn;

              foldbutton.src = Foldbn;
               Object.assign(foldbutton.style, this.state.foldbuttoncss);
              foldtextElement.classList.add(this.state.foldbtntextcss);

              this.playPokerChipSound(this.pokerchipsnd);

              this.setState({
                disablemultibutton: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                // storeantebetchips: [...this.state.prevStatestoreantebetchips],
                // storebonuschips: [...this.state.prevStatestorebonuschips],
                storeantebetchips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousAnteBetAmount * rebetstatus)),
                    chipcolor1: "valtextPortrait1TP"
                  },

                ],
                storebonuschips: [
                  {
                    chip: this.ReturnChipBasedAmount(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipvalue: this.topLable_rsp(Number(this.state.previousBonusbetamount * rebetstatus)),
                    chipcolor1: "insuranceTextPortraitChip10TP"
                  },

                ],

              }, () => {


                // for (let k = 1; k <= this.chipVal.length; k++) {
                //   if (this.state.count === k) {
                //     totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                //     if (bettextTP === "REBETX2") {
                //       let chipcenterpositionElement = document.querySelectorAll("#chipcenterpositionTP span");
                //       let chip10centerbnsElement = document.querySelectorAll("#chip10centerbnsTP span");
                //       for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //         chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                //       }
                //       for (let b = 0; b < chip10centerbnsElement.length; b++) {
                //         chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                //       }

                //       totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                //       rebetstatus = 2;
                //       // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                //     } else {
                //       totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                //       rebetstatus = 1;
                //     }
                //   }
                // }




                document.getElementById("bonustextTP").style.color = "#ffffff";
                if (document.getElementById('chipcenterpositionTP')) {
                  gsap.fromTo("#chipcenterpositionTP",
                    { x: 1200, y: 0, autoAlpha: 0, duration: 0.00001 },
                    { x: 0, y: 0, autoAlpha: 1, duration: 0.2, zIndex: 1, ease: "power2.out" },
                  )
                  const chipcenterpositionElement = document.querySelectorAll("#chipcenterpositionTP");
                  // console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {

                    chipcenterpositionElement[q].style.position = 'relative';
                  }

                }

                if (this.state.previousBonusbetamount > 0) {

                  this.setState({ isbonuspattable: true });
                  gsap.fromTo("#chip10centerbnsTP",
                    { x: 1200, y: 0, autoAlpha: 0, duration: 0.00001, delay: 1 },
                    {
                      x: 0, y: 0, autoAlpha: 1, duration: 0.2, ease: "power2.out", onStart: () => {
                        this.playPokerChipSound(this.pokerchipsnd);
                      }
                    });
                  this.ShowBonuspayouttable()
                  this.showPayoutBonusTable12('BonusPayout');

                } else {
                  gsap.to("#chip10centerbnsTP", { duration: 0.000001, opacity: 0 })
                }

              });


            }
          } else {


            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            // console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            // console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            //   this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            //   this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            // );


            if (Number(totalAnteBetAmount) + Number(this.state.bonusbetamount) > Number(this.props.balance) ||
              Number(totalAnteBetAmount * 3) + Number(this.state.bonusbetamount) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {
              let amount = 0
              for (let k = 1; k <= this.chipVal.length; k++) {
                if (this.state.count === k) {
                  amount = Number(this.chipVal[k - 1].val);

                }
              }
              if (totalAnteBetAmount + Number(this.state.bonusbetamount) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  multibuttonsebledsble: false,
                  // multibuttonsebledsble: true,
                  lowBalaceErrorInfo: `Insufficient Funds to place the ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},Please DEPOSIT to continue playing.`
                })

                // betbutton.src = "";
                // foldbutton.src = "";
                // betbutton.style.cssText = "";
                // foldbutton.style.cssText = "";
                // betbtntextElement.className = "";
                // foldtextElement.className = "";
                // betbtntextElement.textContent = "";
                // foldtextElement.textContent = "";
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }

              } else if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${(this.state.anteBetAmount + amount).toFixed(1)},with bet ${((this.state.anteBetAmount + amount) * 2).toFixed(1)} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
                if(this.state.totalbet == 0){
                  betbutton.src = "";
                  foldbutton.src = "";
                  betbutton.style.cssText = "";
                  foldbutton.style.cssText = "";
                  betbtntextElement.className = "";
                  foldtextElement.className = "";
                  betbtntextElement.textContent = "";
                  foldtextElement.textContent = "";
                }
              }

              betbtnElement.classList.remove("addpointerTP")
              betbtnElement.classList.add("drwnodropTP");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndspTP");
                textbetsHighElement.classList.add("poptextBetshighPortraitTP");
                attentionElement.classList.add("attentionTextPortraitTP");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                // console.log(this.state.infoText)
              });



            } else {
              bnsactived.src = bnsactiveTP;
              document.getElementById("bonustextTP").style.color = "#ffffff";
              betbutton.src = Betbtn;
              Object.assign(betbutton.style, this.state.betbuttoncss);
              betbtntextElement.classList.add(this.state.betbtntextcss);

              foldbutton.src = Foldbn;
               Object.assign(foldbutton.style, this.state.foldbuttoncss);
              foldtextElement.classList.add(this.state.foldbtntextcss);

              bnsactived.classList.add("bnsactiveLandscapeTP");
              bnsactived.classList.add("bnsblinkTP");

              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              Object.assign(betchipstoantebet.style, porttraitejson.betchiplndspe);
              var tl = gsap.timeline();
              tl.to(this.chipref.current, {
                x: -1160,
                y: 40,
                // duration: 0.5,
                duration: 0.2,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1,
              });


              tl.to(this.chipref.current, {
                autoAlpha: 0,
                x: 0,
                y: 0,
                duration: 0.00001,
                zIndex: -3,
              });
              tl.play();

              this.playPokerChipSound(this.pokerchipsnd);

              switch (this.state.count) {
                case 1:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: "valtextPortrait1TP" },

                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                case 6:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[5].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),
                  }))
                  betchipstoantebet.src = chip_1000;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: this.ReturnChipBasedAmount(Number(prevState.anteBetAmount.toFixed(2))), chipvalue: convrAntebt, chipcolor1: null },

                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }

            }
          }




        }
      }


    }
    else {
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("betbtntextTP").textContent = "";
        document.getElementById("foldtextTP").textContent = "";
        document.getElementById("betbtnTP").classList.remove("addpointerTP")
        document.getElementById("betbtnTP").classList.add("bnspoinerTP")
        document.getElementById("betbtntextTP").textContent = this.props.language.Deal;
        document.getElementById("foldtextTP").textContent = this.props.language.RP_Clear;
        this.setState({
          muteunmutesounds: true
        })
      } else {
        this.setState({
          // disablemultibutton: true,
        })

      }
      this.setState({
        totalbet: 0,
        infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
      })

    }



  }


  getClickSound = () => {

    this.selectCard.currentTime = 0;
    if (this.state.mutesounds) {
      this.selectCard.pause();
    } else {
      this.selectCard.play();
    }
  }




  Showoptionsmenu() {
    this.getClickSound();
    this.setState((prevState) => {
      const isMenuOpen = !prevState.isoptionmenu;
      return {
        isoptionmenu: isMenuOpen,
        isactiveoptionsmenu: true,
        ShowLimits: isMenuOpen ? prevState.ShowLimits : false,
        ShowHistory: isMenuOpen ? prevState.ShowHistory : false,
        ShowGamerules: isMenuOpen ? prevState.ShowGamerules : false,
      };
    }, () => {
      // this.setState((prevState) => ({ isoptionmenu:!prevState.isoptionmenu,isactiveoptionsmenu: true }), () => { 
      // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {
      // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {


      var tl = gsap.timeline();
      if (document.getElementById("optionsmenubuttonTP")) {

        tl.to("#optionsmenubuttonTP", {
          x: 513,
          y: 75,
          duration: 0.5,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,

        });
      }
      if (document.getElementById("optiontopmenuTP")) {

        tl.to("#optiontopmenuTP", {
          x: 316,
          y: 0,
          duration: 0.5,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,

        });
      }


      if (document.getElementById("limits")) {

        tl.to("#limits", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 2,

        });
      }

      if (document.getElementById("help")) {
        tl.to("#help", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          opacity: 1,
          // opacity: 0.5,
          stagger: 3,

        });
      }
      if (document.getElementById("history")) {
        tl.to("#history", {
          x: 294,
          y: -5,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 4,

        });
      }
      if (document.getElementById("exitmenuTP")) {
        tl.to("#exitmenuTP", {
          x: 313,
          y: -2,
          duration: 0.2,
          ease: "power2.inOut",
          zIndex: 12,
          autoAlpha: 1,
          // opacity: 1,
          stagger: 5,

        });
      }



      tl.play()

      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (document.getElementById("optionsmenubuttonTP")) {
          document.getElementById("optionsmenubuttonTP").classList.remove("optionsmenu_lndspeTP");
        }

        if (document.getElementById("optionsmenubuttonTP")) {
          document.getElementById("optionsmenubuttonTP").classList.remove("optionsmenu_portraiteTP");
        }



        if (document.getElementById("optionsmenubuttonTP")) {
          document.getElementById("optionsmenubuttonTP").classList.add("optionsmenuTP");
        }
        if (document.getElementById("optiontopmenuTP")) {
          document.getElementById("optiontopmenuTP").classList.add("topmenuTP");
        }
        if (document.getElementById("optiontextTP")) {
          document.getElementById("optiontextTP").classList.add("optionheadingTP");
        }
        if (document.getElementById("limits")) {
          document.getElementById("limits").classList.add("limitstextTP");
        }
        if (document.getElementById("help")) {
          document.getElementById("help").classList.add("helptextTP");
        }
        if (document.getElementById("history")) {
          document.getElementById("history").classList.add("historytextTP");
        }
        if (document.getElementById("arraowTP")) {
          document.getElementById("arraowTP").classList.add("arraowtextTP");
        }
        if (document.getElementById("exitmenuTP")) {
          document.getElementById("exitmenuTP").classList.add("exitoptionmenuTP");
        }


      }
      else {
        if (window.innerHeight > window.innerWidth) {
          if (document.getElementById("optionsmenubuttonTP")) {
            document.getElementById("optionsmenubuttonTP").classList.remove("optionsmenu_lndspeTP");
          }


          if (document.getElementById("optionsmenubuttonTP")) {
            document.getElementById("optionsmenubuttonTP").classList.add("optionsmenu_portraiteTP");
          }
          if (document.getElementById("optiontopmenuTP")) {
            document.getElementById("optiontopmenuTP").classList.add("topmenuTP");
          }
          if (document.getElementById("optiontextTP")) {
            document.getElementById("optiontextTP").classList.add("optionheadingTP");
          }
          if (document.getElementById("limits")) {
            document.getElementById("limits").classList.add("limitstextTP");
          }
          if (document.getElementById("help")) {
            document.getElementById("help").classList.add("helptextTP");
          }
          if (document.getElementById("history")) {
            document.getElementById("history").classList.add("historytextTP");
          }
          if (document.getElementById("arraowTP")) {
            document.getElementById("arraowTP").classList.add("arraowtextTP");
          }
          if (document.getElementById("exitmenuTP")) {
            document.getElementById("exitmenuTP").classList.add("exitoptionmenuTP");
          }


        }
        else {

          if (document.getElementById("optionsmenubuttonTP")) {
            document.getElementById("optionsmenubuttonTP").classList.remove("optionsmenu_portraiteTP");
          }

          if (document.getElementById("optionsmenubuttonTP")) {
            document.getElementById("optionsmenubuttonTP").classList.add("optionsmenu_lndspeTP");
          }
          if (document.getElementById("optiontopmenuTP")) {
            document.getElementById("optiontopmenuTP").classList.add("topmenuTP");
          }
          if (document.getElementById("optiontextTP")) {
            document.getElementById("optiontextTP").classList.add("optionheadingTP");
          }
          if (document.getElementById("limits")) {
            document.getElementById("limits").classList.add("limitstextTP");
          }
          if (document.getElementById("help")) {
            document.getElementById("help").classList.add("helptextTP");
          }
          if (document.getElementById("history")) {
            document.getElementById("history").classList.add("historytextTP");
          }
          if (document.getElementById("arraowTP")) {
            document.getElementById("arraowTP").classList.add("arraowtextTP");
          }
          if (document.getElementById("exitmenuTP")) {
            document.getElementById("exitmenuTP").classList.add("exitoptionmenuTP");
          }


        }

      }
    });

  }
  ShowBonuspayouttable() {
    this.getClickSound();
    document.getElementById("bonustextTP").style.color = "#ffffff";

    this.setState({ isbonuspattable: !this.state.isbonuspattable }, () => {
      for (let i = 0; i < 8; i++) {
        // let bonuspayout_info = document.getElementById(`numbersvalrussianTP${i}`)
        if (
          (navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {

        }
        else {

          // const bonusInfoTheadElement = document.getElementById('bonusInfoTheadTP');
          // const bonusInfoTbodyElement = document.getElementById("TPbonusInfoTbody");
          // const rankingInfoTable = document.getElementById("bonuspaytableInfoTPS");


          // if (rankingInfoTable) {
          //   rankingInfoTable.className = "";
          //   bonusInfoTheadElement.style.cssText = "";
          //   bonusInfoTbodyElement.style.cssText = "";
          // }

          // if (window.innerHeight > window.innerWidth) {
          //   if (bonusInfoTheadElement && bonusInfoTbodyElement) {
          //     Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
          //     Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
          //   }

          //   if (rankingInfoTable) {
          //     rankingInfoTable.classList.add("rankingInfoTablePortraitTP");
          //   }

          //   if (document.getElementById(`numbersvalrussianTP${i}`)) {

          //     if (document.getElementById("TPheading_table")) {
          //       document.getElementById("TPheading_table").style.cssText = "";
          //       document.getElementById("TPheading_table").classList.add("portraittableheadTP");
          //     }

          //     bonuspayout_info.classList.add("numbersvalPortraitTP");
          //   }
          //   if (document.getElementById(`numTropical${i}`)) {

          //     document.getElementById(`numTropical${i}`).style.cssText = "";
          //     document.getElementById(`numTropical${i}`).classList.add("numPortraitTP");
          //   }

          //   if (document.getElementById(`numbersTP${i}`)) {

          //     document.getElementById(`numbersTP${i}`).style.cssText = "";
          //     document.getElementById(`numbersTP${i}`).classList.add("numbersTP");
          //   }


          // } else {

          //   //  IF MOBILE LANDSCAP ;
          //   if (bonusInfoTheadElement && bonusInfoTbodyElement) {
          //     Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
          //     Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
          //   }


          //   if (rankingInfoTable) {
          //     rankingInfoTable.classList.add("rankingInfoTableLndspTP");
          //   }

          //   if (document.getElementById(`numbersvalrussianTP${i}`)) {
          //     if (document.getElementById("TPheading_table")) {
          //       document.getElementById("TPheading_table").style.cssText = "";
          //       document.getElementById("TPheading_table").classList.add("portraittableheadTP");
          //     }
          //     bonuspayout_info.classList.add("numbersvalPortraitTP");
          //   }

          //   if (document.getElementById(`numTropical${i}`)) {
          //     document.getElementById(`numTropical${i}`).style.cssText = "";
          //     document.getElementById(`numTropical${i}`).classList.add("numPortraitTP");
          //   }


          //   if (document.getElementById(`numbersTP${i}`)) {
          //     document.getElementById(`numbersTP${i}`).style.cssText = "";
          //     document.getElementById(`numbersTP${i}`).classList.add("numbersTP");
          //   }


          // }
        }
      }
    });

  }

  HideBonuspayouttable() {
    document.getElementById("bonustextTP").style.color = "";
    if (this.state.isbonuspattable) {
      this.setState({
        isbonuspattable: false,
        PayoutTableToggle: false
      });
    }
    if (this.state.PayoutTableToggle) {
      this.setState({ PayoutTableToggle: false });
    }

  }


  lowBalanceErrorInformationPopupanimation = () => {
    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
    const textbetsHighElement = document.getElementById("textbets_highTP");
    const attentionElement = document.getElementById("attentionTP");


    popupbetGreaterthanBalnceElement.className = "";

    gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, },
      { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });


    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
        popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowsTP");
        textbetsHighElement.classList.add("poptextBetshighwindowsTP");
        attentionElement.classList.add("attentionTextwindowsTP");

      }

    } else {
      if (window.innerHeight > window.innerWidth) {
        // Portrait

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortraitTP");
          textbetsHighElement.classList.add("poptextBetshighPortraitTP");
          attentionElement.classList.add("attentionTextPortraitTP");

        }
      } else {
        // Landscape

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndspTP");
          textbetsHighElement.classList.add("poptextBetshighPortraitTP");
          attentionElement.classList.add("attentionTextPortraitTP");

        }
      }
    }
  }

  BonusActivated() {

    // this.playPokerChipSound(this.pokerchipsnd);

    this.TotalbetamountGreaterthanbalance();
    // document.getElementById("TPbetcircle").classList.remove("addpointerTP")
    document.getElementById("bonusTropical").classList.remove("bnspoinerTP");


    let totalBetAmountForLimitsCheck = 0;

    for (let k = 1; k <= this.chipVal.length; k++) {
      if (this.state.count === k) {
        totalBetAmountForLimitsCheck = this.state.bonusbetamount + Number(this.chipVal[k - 1].val);

      }
    }

    if (Number(totalBetAmountForLimitsCheck.toFixed(2)) <= this.props.maxBetAmt) {
      this.setState({ infoText: this.props.language.deal_tostart });

      let totalbets_bonus = this.topLable_rsp(totalBetAmountForLimitsCheck)
      let betchipstobns = document.getElementById("betchipsaddtobonusTP");

      if (this.state.anteBetAmount * 3 + totalBetAmountForLimitsCheck > this.props.balance) {
        this.setState({
          betshigh_balnce: true,
          lowBalaceErrorInfo: `Insufficient Funds to follow up your ANTE of ${this.state.anteBetAmount.toFixed(1)},with bet ${(this.state.anteBetAmount * 2).toFixed(1)} Please DEPOSIT to continue playing.`
        });
        this.lowBalanceErrorInformationPopupanimation()
      } else {
        this.playPokerChipSound(this.pokerchipsnd);


        this.setState({ isbonuspattable: true, betshigh_balnce: null }, () => {
          if (!this.state.PayoutTableToggle) {
            this.showPayoutBonusTable12('BonusPayout');
          }

          for (let i = 0; i < 8; i++) {
            // let bonuspayout_info = document.getElementById(`numbersvalrussianTP${i}`)

            if (
              (navigator.userAgentData &&
                navigator.userAgentData.platform &&
                navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
            ) {


            } else {

              // const bonusInfoTheadElement = document.getElementById('bonusInfoTheadTP');
              // const bonusInfoTbodyElement = document.getElementById("TPbonusInfoTbody");
              // const rankingInfoTable = document.getElementById("bonuspaytableInfoTPS");

              // if (rankingInfoTable) {
              //   rankingInfoTable.className = "";
              //   bonusInfoTheadElement.style.cssText = "";
              //   bonusInfoTbodyElement.style.cssText = "";
              // }

              // if (window.innerHeight > window.innerWidth) {

              //   if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              //     Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
              //     Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
              //   }


              //   if (rankingInfoTable) {
              //     rankingInfoTable.classList.add("rankingInfoTablePortraitTP");
              //   }

              //   if (document.getElementById(`numbersvalrussianTP${i}`)) {
              //     if (document.getElementById("TPheading_table")) {
              //       document.getElementById("TPheading_table").className = "";
              //       document.getElementById("TPheading_table").classList.add("portraittableheadTP");
              //     }
              //     bonuspayout_info.classList.add("numbersvalPortraitTP");
              //   }
              //   if (document.getElementById(`numTropical${i}`)) {
              //     document.getElementById(`numTropical${i}`).style.cssText = "";
              //     document.getElementById(`numTropical${i}`).classList.add("numPortraitTP");
              //   }

              //   if (document.getElementById(`numbersTP${i}`)) {
              //     document.getElementById(`numbersTP${i}`).style.cssText = "";
              //     document.getElementById(`numbersTP${i}`).classList.add("numbersTP");
              //   }


              // } else {

              //   //  IF MOBILE LANDSCAP ;
              //   if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              //     Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
              //     Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
              //   }

              //   if (rankingInfoTable) {
              //     rankingInfoTable.classList.add("rankingInfoTableLndspTP");
              //   }

              //   if (document.getElementById(`numbersvalrussianTP${i}`)) {
              //     if (document.getElementById("TPheading_table")) {
              //       document.getElementById("TPheading_table").style.cssText = "";
              //       document.getElementById("TPheading_table").classList.add("portraittableheadTP");
              //     }
              //     bonuspayout_info.classList.add("numbersvalPortraitTP");
              //   }

              //   if (document.getElementById(`numTropical${i}`)) {
              //     document.getElementById(`numTropical${i}`).style.cssText = "";
              //     document.getElementById(`numTropical${i}`).classList.add("numPortraitTP");
              //   }

              //   if (document.getElementById(`numbersTP${i}`)) {
              //     document.getElementById(`numbersTP${i}`).style.cssText = "";
              //     document.getElementById(`numbersTP${i}`).classList.add("numbersTP");
              //   }


              // }
            }
          }

        });
        if (
          (navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {
          Object.assign(betchipstobns.style, porttraitejson.betchipstobnswindows)

          // this.playPokerChipSound(this.pokerchipsnd);

          switch (this.state.count) {
            case 1:
              betchipstobns.src = chip_10;
              // document.getElementById("val_500TP").textContent=""

              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[0].val),
              }))

              this.clertime31 = setTimeout(() => {
                this.setState((prevState) => ({

                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbnsTP"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_10TP" className="bonusChipsForwindows_val10_TP">{totalbets_bonus}</span>
                    </div>

                  ],
                }));

              }, 150);

              break;
            case 2:
              betchipstobns.src = chip_25;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[1].val),
              }))
              this.clertime32 = setTimeout(() => {
                this.setState((prevState) => ({

                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbnsTP"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_20TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                    </div>
                  ],

                }));

              }, 150);

              break;
            case 3:
              betchipstobns.src = chip_50;


              this.clertime33 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbnsTP"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_50TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[2].val),
              }));
              break;
            case 4:
              betchipstobns.src = chip_100;


              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[3].val),
              }));
              this.clertime34 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbnsTP"

                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_100TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              break;
            case 5:
              betchipstobns.src = chip_500;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[4].val),
              }));
              this.clertime35 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbnsTP"
                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_500TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              break;
            case 6:
              betchipstobns.src = chip_1000;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[5].val),
                betdeductions: prevState.totalbet + Number(this.chipVal[5].val),
              }));
              this.clertime35 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chip10centerbnsTP" className="chipContainerTP bonusChipsContainerwindowsTP" key={prevState.storebetchips.length}>
                      <img
                        className="chip10centerbnsTP"
                        src={this.ReturnChipBasedAmount(totalbets_bonus)}
                        alt="Chip 10"
                      />
                      <span id="val_500TP" className="bonusChipsForwindowsTP">{totalbets_bonus}</span>
                    </div>
                  ],
                }));

              }, 150);
              break;

            default:
              break;
          }



          var tl = gsap.timeline();
          tl.to(this.chipbnsref.current, {
            x: -873,
            y: -465,
            duration: 0.2,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipbnsref.current, {
            x: 0,
            y: 0,
            opacity: 0,
            duration: 0.00001,
          });
          // tl.reverse();
          tl.to(this.chipbnsref.current, { opacity: 1, zIndex: -2 });
          tl.play();
        }

        else {
          let inforussianElement = document.getElementById("infoTropicalP");
          let inforussianlandElement = document.getElementById("infoTroppicalland");
          inforussianElement.className = "";
          inforussianlandElement.className = "";
          let convrBonusbet = this.topLable_rsp(totalBetAmountForLimitsCheck);
          if (window.innerHeight > window.innerWidth) {
            inforussianElement.classList.add("portraitinfoTP");
            Object.assign(betchipstobns.style, porttraitejson.betchipstobnsPortrait);


            this.setState((prevState) => ({
              // totalbet: prevState.totalbet + 1,
              isbonuspattable: true,
            }));
            switch (this.state.count) {
              case 1:
                betchipstobns.src = chip_10;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),

                }));
                this.clertime31 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },

                    ],
                  }));
                }, 150);

                break;
              case 2:
                betchipstobns.src = chip_25;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),

                }));
                this.clertime32 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);

                break;
              case 3:
                betchipstobns.src = chip_50;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),

                }));
                this.clertime33 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 4:
                betchipstobns.src = chip_100;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),

                }));
                this.clertime34 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),

                }));
                betchipstobns.src = chip_500;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 6:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[5].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),

                }));
                betchipstobns.src = chip_1000;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;

              default:
                break;

            }

            var tl = gsap.timeline();
            tl.to(this.chipbnsref.current, {
              autoAlpha: 1,
              x: 90,
              y: -1100,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipbnsref.current, {
              autoAlpha: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
              zIndex: -2
            });
            tl.play();


          } else {

            //  IF MOBILE LAND SCAPE;

            inforussianlandElement.classList.add("lndspinfoTP");
            Object.assign(betchipstobns.style, porttraitejson.betchipstobnsLndsp);


            this.setState((prevState) => ({
              // totalbet: prevState.totalbet + 1,
              isbonuspattable: true,
            }));

            var tl = gsap.timeline();
            tl.to(this.chipbnsref.current, {
              autoAlpha: 1,
              x: -960,
              y: 40,
              duration: 0.2,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipbnsref.current, {
              autoAlpha: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
              zIndex: -2
            });
            tl.play();
            switch (this.state.count) {
              case 1:
                betchipstobns.src = chip_10;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),

                }));
                this.clertime31 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },

                    ],
                  }));
                }, 150);

                break;
              case 2:
                betchipstobns.src = chip_25;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),

                }));
                this.clertime32 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);

                break;
              case 3:
                betchipstobns.src = chip_50;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),

                }));
                this.clertime33 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 4:
                betchipstobns.src = chip_100;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),

                }));
                this.clertime34 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),

                }));
                betchipstobns.src = chip_500;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;
              case 6:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[5].val),
                  bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[5].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[5].val),

                }));
                betchipstobns.src = chip_1000;
                this.clertime35 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebonuschips: [
                      ...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(Number(prevState.bonusbetamount.toFixed(2))), chipvalue: convrBonusbet, chipcolor1: null },
                    ],
                  }));
                }, 150);
                break;

              default:
                break;

            }
          }




        }


      }

    } else {

      this.playPokerChipSound(this.pokerchipsnd);

      this.setState({ infoText: this.props.language.bonus_maxbet })
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {
        document.getElementById("bonusTropical").classList.add("drwnodropTP");
      }
    }







  }

  TropicalpokerBet() {

    if (this.state.PayoutTableToggle) {
      this.setState({ PayoutTableToggle: false });
    }

    if (this.replaceCards.length > 0) {
      this.cardsRef.current.unselectPokerCards();
    }

    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }

    if (this.state.isbonuspattable === true) {
      this.setState({
        isbonuspattable: false,
      })
    }

    let doubleamt = this.state.anteBetAmount * 2
    // console.log(this.state.anteBetAmount, this.props.balance)
    console.log(this.state.anteBetAmount)
    console.log(this.props.balance)
    console.log(this.state.betdeductions)
    console.log(doubleamt)

    if (Number((this.state.betdeductions + doubleamt).toFixed(2)) <= this.props.balance) {
      if (this.state.betshigh_balnce === true) {
        this.hidepopup_betshigh();
      }
      this.setState((prevState) => ({
        betdeductions: prevState.betdeductions + doubleamt,
        totalbet: prevState.totalbet + doubleamt,
        disBetBtn: 1,
        keyboardhandle: false,
        infoText: ""
      }));


      if (this.state.mutesounds) {
        this.playPokerChipSound(this.pokerchipsnd);
        this.betrussiansnd.pause();

      } else {
        this.betrussiansnd.play();

      }

      const drawbutton = document.getElementById("drawbtnTP");
      const buybutton = document.getElementById("buybtnTP");
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      let betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
      let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetTP");
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {


        gsap.set(["#betTextaddtoBetTP"], { clearProps: "all" })
        this.setState({ disBuybtn: 1, disClearBtn: 1, disDrawbtn: 1, FoldbtnTP: 1, disBetBtn: 1 })
        document.getElementById("crdscoverTP").classList.add("crdscoverTP")
        document.getElementById("betpannelTropicalcover").style.display = "block";
        document.getElementById("betpannelTropicalcover").classList.add("bet_pannelcoverTP");
        document.getElementById("betactiveTP").src = ""
        document.getElementById("betactiveTP").classList.remove("betactiveTP");
        document.getElementById("betbtntextTP").innerHTML = "";

        document.getElementById("glowonwinchipTP").classList.remove("chipglow_bonusTP")


        document.getElementById("drawtextTP").textContent = "";
        document.getElementById("foldtextTP").textContent = "";

        document.getElementById("betbtntextTP").classList.remove("betwindowsTP");
        betbutton.src = "";
        betbutton.style.cssText = ""


        foldbutton.src = "";
        foldbutton.style.cssText = ""
        document.getElementById("foldtextTP").classList.remove("foldtextwindowsTP");


        drawbutton.src = "";
        drawbutton.style.cssText = ""


        buybutton.src = "";
        buybutton.style.cssText = ""


        let betTextaddtoBetClass = "";

        if (
          (navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {

          // gsap.to("#betTextaddtoBetTP", { opacity: 1, duration: 0.1 })
          let russianbet_amount
          console.log(this.state.count)
          switch (this.state.count) {

            case 1:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet10TP";

              break;
            case 2:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20TP";

              break;
            case 3:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20TP";

              break;
            case 4:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20TP";

              break;
            case 5:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20TP";
              break;
            case 6:
              betTextaddtoBetClass = "";
              betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(this.state.anteBetAmount * 2);
              russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2)
              betTextaddtoBetElement.textContent = russianbet_amount
              betTextaddtoBetClass = "bet-text-addtoBet20TP";
              break;

            default:
              break;
          }
          Object.assign(betchipsaddtobetwindows.style, porttraitejson.Betchiptobetwindows)
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-TP");
          gsap.to(this.chipbetref.current, {
            x: -710,
            y: -465,
            duration: 0.2,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 1,
            onComplete: () => {
              // Object.assign(betchipsaddtobetwindows.style, porttraitejson.Betchiptobetwindows)
              // betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-TP");

            }
          });


        }

      }


      else {

        if (this.state.isbonuspattable === true) {
          this.setState({
            isbonuspattable: false,
          })
        }
        let betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
        let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetTP");
        let betTextaddtoBetClass = "bet-text-addtoBet20TP";
        document.getElementById("drawtextTP").textContent = "";
        document.getElementById("foldtextTP").textContent = "";
        betbutton.src = "";
        betbutton.style.cssText = "";
        document.getElementById("betbtntextTP").classList.remove("betwindowsTP");
        document.getElementById("betbtntextTP").textContent = "";
        betbutton.style.position = "";
        betbutton.style.width = "";
        betbutton.style.transform = "";

        foldbutton.src = "";
        foldbutton.style.cssText = "";
        document.getElementById("foldtextTP").classList.remove("foldtextwindowsTP");
        foldbutton.style.position = "";
        foldbutton.style.width = "";
        foldbutton.style.transform = "";

        drawbutton.src = "";
        drawbutton.style.cssText = "";
        drawbutton.style.position = "";
        drawbutton.style.height = "";
        drawbutton.style.width = "";
        drawbutton.style.transform = "";

        buybutton.src = "";
        buybutton.style.cssText = "";
        buybutton.style.position = "";
        buybutton.style.width = "";
        buybutton.style.transform = "";


        document.getElementById("betactiveTP").src = "";
        document.getElementById("betactiveTP").className = "";
        document.getElementById("mobileAntiMaskCoverTP").style.display = "block";
        this.setState({ bsbtn: true })

        // let doubleamt = this.state.totalbet * 2;
        let convrBet = this.topLable_rsp(this.state.anteBetAmount * 2);

        betchipsaddtobetwindows.src = this.ReturnChipBasedAmount(Number((this.state.anteBetAmount * 2).toFixed(2)));
        switch (this.state.count) {
          case 1:
            // betchipsaddtobetwindows.src = chip_10;
            // betTextaddtoBetElement.textContent = 10;
            betTextaddtoBetElement.textContent = convrBet;
            betTextaddtoBetClass = "bet-text-addtoBet10TP";
            break;
          case 2:
            // betchipsaddtobetwindows.src = chip_25;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 3:
            // betchipsaddtobetwindows.src = chip_50;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 4:
            // betchipsaddtobetwindows.src = chip_100;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 5:
            // betchipsaddtobetwindows.src = chip_500;
            betTextaddtoBetElement.textContent = convrBet;
            break;
          case 6:
            // betchipsaddtobetwindows.src = chip_1000;
            betTextaddtoBetElement.textContent = convrBet;
            break;

          default:
            betTextaddtoBetClass = "bet-text-addtoBet20TP"
            break;
        }
        this.betchipactivated = true;

        if (window.innerHeight > window.innerWidth) {
          document.getElementById("betpannelTropicalcover").style.display = "block";
          betTextaddtoBetElement.className = "";
          betTextaddtoBetElement.style.cssText = "";
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-portrait-TP");
          Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetPortrait);

          gsap.to(this.chipbetref.current, {
            // x: -10,
            // y: -950,
            x: 305,
            y: -925,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1,
            opacity: 1,
          });
          gsap.to(betTextaddtoBetElement, {
            // x: 12,
            // y: -1739,
            x: 306,
            y: -1710,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 1,
            visibility: true,
            onStart: function () {
              betTextaddtoBetElement.style.zIndex = 2;
              betTextaddtoBetElement.style.visibility = 'visible';
            }
          });

          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbnsTP")) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbnsTP", { x: 0, y: -900, duration: 0.2, autoAlpha: 1 })
              ml.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, autoAlpha: 0 });
              this.playerWinBonus = false;
            }
          }




        }

        else {
          document.getElementById("betpannelTropicalcover").style.display = "block";
          betTextaddtoBetElement.className = "";
          betTextaddtoBetElement.style.cssText = "";
          betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-lndsp-TP");
          Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetLndsp);

          // gsap.to(this.chipbetref.current, {
          //   x: -288,
          //   y: 130,
          //   duration: 1,
          //   ease: "power2.inOut",
          //   zIndex: 12
          // });
          gsap.to(this.chipbetref.current, {
            x: -773,
            y: 37,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1
          });
          gsap.to(betTextaddtoBetElement, {
            // x: 12,
            // y: -1739,
            x: -773,
            y: 37,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 2,
            autoAlpha: 1,
            onStart: function () {
              betTextaddtoBetElement.style.zIndex = 2;
              betTextaddtoBetElement.style.visibility = 'visible';
            }
          });


          if (this.playerWinBonus === true) {
            if (document.getElementById('chip10centerbnsTP')) {
              let ml = gsap.timeline()
              ml.to("#chip10centerbnsTP", {
                x: 600, y: -500, duration: 0.2, autoAlpha: 1,
              });
              ml.to("#chip10centerbnsTP", {
                x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
              });
            }

          }

          this.playerWinBonus = false;

        }

      }


      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "TROPICAL_STUD_POKER_BET",
        object: {
          action: "BET",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };
      // console.log(body);
      this.props.network.sendof(body);
      // const body2 = {
      //   CN: "GET_PLAYER_INFO",
      //   object: {
      //     gameId: Number(this.props.gameId,),
      //     playerId: Number(this.props.playerId),

      //   },
      //   sessionId: sessionStorage.getItem('sessionId'),
      // }
      // this.props.network.sendof(body2);
      // this.body2 = {};
    } else {
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
      const textbetsHighElement = document.getElementById("textbets_highTP");
      const attentionElement = document.getElementById("attentionTP");

      popupbetGreaterthanBalnceElement.className = "";
      textbetsHighElement.className = "";
      attentionElement.className = "";
      this.setState({
        betshigh_balnce: true,
        lowBalaceErrorInfo: `Insufficient Funds to place the BET of ${doubleamt.toFixed(1)},Please DEPOSIT to continue playing.`
      })
      gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
        { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindowsTP");
          textbetsHighElement.classList.add("poptextBetshighwindowsTP");
          attentionElement.classList.add("attentionTextwindowsTP");

        }
      } else {
        if (window.innerHeight > window.innerWidth) {
          if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortraitTP");
            textbetsHighElement.classList.add("poptextBetshighPortraitTP");
            attentionElement.classList.add("attentionTextPortraitTP");

          }
        } else {
          if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

            popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndspTP");
            textbetsHighElement.classList.add("poptextBetshighPortraitTP");
            attentionElement.classList.add("attentionTextPortraitTP");

          }
        }
      }
    }
  }


  Exitoptionmenu() {
    this.setState({ isoptionmenu: false });
    var tl = gsap.timeline();
    tl.to("#optionsmenubuttonTP", {
      x: 458,
      y: 75,
      duration: 0.5,
      ease: "power2.inOut",
      zIndex: 12,
      opacity: 1
    });
    tl.play();

  }
  topLable_rsp(value) {

    const labelValue = Math.abs(Number(value));
    return labelValue >= 1.0e+9

      ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
      // Six Zeroes for Millions 
      : labelValue >= 1.0e+6

        ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
        // Three Zeroes for Thousands
        : labelValue >= 1.0e+3

          ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

          : Number(labelValue.toFixed(2));
  }
  // disabledrwbutton(){

  //    this.setState({drawbuttonhandle:false})
  //     document.getElementById("drawtextTP").classList.remove("drawtextopacityTP");
  // }
  numberOfCardsToBeDraw = (drawCardArray) => {
    console.log("drawCardArray", drawCardArray);
    console.log("shuffledPlayerCards", this.shuffledPlayerCards);
    this.cardsRef.current.unselectPokerCards12(this.shuffledPlayerCards, drawCardArray);
    this.replaceCards = [...drawCardArray];
    console.log(this.replaceCards.length);
    if (this.replaceCards.length > 0) {
      this.setState({ drawbuttonhandle: false, disDrawbtn: 0 });
      document.getElementById("drawtextTP").classList.remove("drawtextopacityTP");
      document.getElementById("drawbtnTP").classList.remove("drwnodropTP");
      document.getElementById("buybtnTP").style.opacity = "0.5";
      this.setState({ disableBuyButton: true })
    } else {
      this.setState({ drawbuttonhandle: true, disDrawbtn: 1 });
      document.getElementById("drawtextTP").classList.add("drawtextopacityTP");
      document.getElementById("drawbtnTP").classList.add("drwnodropTP");

      document.getElementById("buybtnTP").style.opacity = "1"
      this.setState({ disableBuyButton: false })
    }

  };


  checkpayerordealerwins(res) {


    this.removehigltbonusclasses(res);

    // document.getElementById("insurencetext").style.color = "";
    document.getElementById("bonustextTP").style.color = "";
    document.getElementById("bettextTP").style.color = "";
    this.clearallsettimeouts()
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");

    let bnsactived = document.getElementById("bnsactiveTP");


    this.setState({
      previousAnteBetAmount: res.anteBetAmount,
      previousBonusbetamount: res.bonusBetAmount, isplayerinfoupdate: false
    })

    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    switch (res.winner) {
      case "Dealer":
        this.setState({ infoText: res.message })
        document.getElementById("betpannelinsurececoverTP").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelinsurenceTP");

        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });

        this.updateplayerbalnace();
        document.getElementById("foldtextTP").classList.remove("insurenceTP");
        document.getElementById("betdecrementTP").classList.remove("drwnodropTP")
        document.getElementById("betincrenetTP").classList.remove("drwnodropTP")

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })

        this.playPokerChipSound(this.pokerchipsnd);

        // gsap.from("#chip10centerbnsTP",{x:1046,y:276,zIndex:-2,duration:1})
        document.getElementById("betactiveTP").src = "";
        document.getElementById("bettextTP").style.color = "";
        document.getElementById("betactiveTP").classList.remove("betactiveTP");
        this.setState({ betbutton: "REBET", buttontext: "REBETX2", betBtnValue: 2, keyboardhandle: true });


        let t1 = gsap.timeline();
        t1.to("#chipcenterpositionTP", {
          x: 2,
          y: -662,
          duration: 0.5,
          zIndex: 12,
          ease: "power2.inOut",
          opacity: 1,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { opacity: 0 });
          }
        });

        let t2 = gsap.timeline();
        t2.to("#betchipsaddtobetTP", {
          x: -700,
          y: -1000,
          duration: 0.5,
          zIndex: 12,
          ease: "power2.inOut",
          opacity: 1,
          // delay: 0.5,
          onComplete: () => {
            gsap.set("#betchipsaddtobetTP", { opacity: 0 });
          }
        });

        t2.to("#betchipsaddtobetTP", {
          x: -2,
          y: 72,
          zIndex: -10,
          duration: 0.1,
          opacity: 0,
          delay: 0.5,
        });

        gsap.to("#betTextaddtoBetTP", { duration: 0.1, zIndex: -2 })

        let centerchipsTP = gsap.timeline();
        centerchipsTP.to("#centerchipsTP", {
          zIndex: -2,
          opacity: 0,
          onComplete: () => {
            document.getElementById("centerchipsTP").removeAttribute("src");
            document.getElementById("centerchipsTP").classList.remove("chip10centerpositionTP");
          }
        });
        centerchipsTP.pause();

        let t6 = gsap.timeline();
        t6.to("#betchipsaddtconfrmTP", {
          x: -1084,
          y: -1266, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtconfrmTP", { opacity: 0 });
          }
        });
        t6.to("#betchipsaddtconfrmTP", {
          x: 0, y: 0, zIndex: -10, duration: 0.3, opacity: 0, delay: 1.5
          // x: 100, y: -135, zIndex: -10, duration: 0.3, opacity: 0, delay: 1.5
        });
        // t6.play();

        // gsap.from("#betTextaddtodrawTP", { opacity: 0, zIndex: -3 })
        gsap.from("#betTextaddtoinsurenceTP", { zIndex: -2 })
        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, zIndex: -2 })

        gsap.set(["#chipcenterpositionTP", "#betchipsaddtobetTP"], { clearProps: "all" });

        setTimeout(() => {
          // if (this.state.mutesounds) {
          //   this.playerLose.pause();
          // } else {
          //   this.playerLose.play();
          // }
          this.playRemainingSounds(this.playerLose)

          document.getElementById("buttons_coverTP").classList.remove("buttons_coverTP")

          document.getElementById("betpannelTropicalcover").style.display = "none";
          document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

          document.getElementById("foldtextTP").textContent = "";

          document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;
          document.getElementById("betbtntextTP").classList.add("betwindowsTP");
          document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");


          document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
          this.setState({
            infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0,
            totalbet: 0,
            anteBetAmount: 0,
            bonusbetamount: 0,
            insurencetbet: 0,
            updateBet: 0,
            disablemultibutton: false

          });
          document.getElementById("TPbetcircle").classList.remove("nonpointerTP")

          document.getElementById("bonusTropical").classList.remove("addpointerTP");
          document.getElementById("bonusTropical").classList.add("drwnodropTP");
          document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");
          document.getElementById("rankinginfoTP").className = "";
          document.getElementById("rankinginfodelerTP").textContent = "";
          document.getElementById("rankinginfoTP").textContent = "";
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();
          document.getElementById("crdscoverTP").classList.remove("crdscoverTP")

        }, 3500);


        // gsap.to("#betchipsaddtconfrmTP", { zIndex: -2 })
        // gsap.to("#betchipsaddtodrawTP", { zIndex: -2 })
        gsap.to("#betTextaddtodrawTP", { zIndex: -2 })

        gsap.to("#betchipsaddtobetTP", { zIndex: -2 })

        bnsactived.src = "";
        document.getElementById("bonustextTP").style.color = "";
        bnsactived.classList.remove("bnsctivewindowsTP");
        bnsactived.classList.remove("bnsblinkTP");

        break;
      case "Player":


        document.getElementById("betpannelinsurececoverTP").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelinsurenceTP");
        gsap.to("#betTextaddtoinsurenceTP", { zIndex: -2 })
        gsap.to("#betTextaddtodrawTP", { opacity: 0, zIndex: -20 })

        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });


        this.Dealerbetstoplayer(res)
        var tl = gsap.timeline();

        document.getElementById("betdecrementTP").classList.remove("drwnodropTP")
        document.getElementById("betincrenetTP").classList.remove("drwnodropTP")
        document.getElementById("foldtextTP").innerHTML = "";
        document.getElementById("foldtextTP").classList.remove("insurenceTP");
        document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");

        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount
        }))

        //  gsap.to("#chip10centerbnsTP",{x:-950,y:338,zIndex:-2})
        gsap.from("#betTextaddtoinsurenceTP", { zIndex: -2 })
        // gsap.to("#chip10centerbnsTP",{x:-126,y:269,zIndex:2,duration:1})
        // gsap.from("#chip10centerbnsTP",{x:1046,y:276,zIndex:-2,duration:1,delay:5})
        // gsap.from("#betchipsaddtodrawTP",{x:-949,y:-337})
        // gsap.from("#betchipsaddtodrawTP",{x:44,y:-48,delay:4,opacity:0})
        // gsap.from("#betchipsaddtobetTP",{zIndex:-2})
        // gsap.from("#betTextaddtodrawTP", { opacity: 0, zIndex: -2 })
        //  gsap.to("#betTextaddtoBetTP", { zIndex: -20 })


        let finalwinamount = this.topLable_rsp(res.totalWinAmount)

        this.setState({
          betbutton: "REBET", buttontext: "REBETX2",
          keyboardhandle: true,
          // totalwinRussian: res.totalWinAmount,
          betchipincrementbtn: false, betdecreamentbtn: false,
          betBtnValue: 2
        })
        // a

        // setTimeout(()=>{
        //   this.setState({infoText:`CONGRATULATIONS! You won ${finalwinamount}`,})

        //  },2500)
        document.getElementById("betactiveTP").src = "";
        document.getElementById("bettextTP").style.color = "";
        document.getElementById("betactiveTP").classList.remove("betactiveTP");

        // ............................commmented on 24-07-24 ......................../
        // if(document.getElementById("chipcenterpositionTP")){
        //   gsap.to("#chipcenterpositionTP", { x: 0, y: 264, duration: 0.5 });

        let ttop = gsap.timeline();
        ttop.to("#chipcenterpositionTP", {
          x: 500,
          y: 50,
          duration: 0.5,
          zIndex: 12,
          ease: "power2.inOut",
          opacity: 1,
          delay: 1,
          onComplete: () => {
            gsap.set("#chipcenterpositionTP", { delay: 3, opacity: 0 });
          }
        });


        // const disableChip = () => {

        //   let ttop = gsap.timeline();
        const antetextTP = document.querySelectorAll("#chipcenterpositionTP span");

        setTimeout(() => {
          //  antetextTP.forEach(el => el.textContent = res.anteWinAmount || "");
          antetextTP.forEach(el => el.textContent = this.topLable_rsp(res.anteWinAmount) || "");
        }, 900)

        setTimeout(() => {


          this.playRemainingSounds(this.playerWin)

          if (document.getElementById("winpopup_TP")) {
            document.getElementById("winpopup_TP").classList.add("winpopup_tropical")
            document.getElementById("winamt_TPS").classList.add("winamt_TropicalStud")

          }
          this.setState({ infoText: res.message, totalwinRussian: res.totalWinAmount, })
          // this.updateplayerbalnace();


        }, 3000)


        let t8 = gsap.timeline();
        t8.to("#betchipsaddtconfrmTP", {
          x: -1083,
          y: -1265, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtconfrmTP", { opacity: 0 });
          }
        });
        t8.to("#betchipsaddtconfrmTP", {
          x: 47, y: -52, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#betchipsaddtconfrmTP", { opacity: 1, delay: 2 });
          }

        });
        t8.play();
        let t9 = gsap.timeline();
        t9.to("#betchipsaddtobetTP", {
          x: -525,
          y: -414, duration: 0.5, opacity: 1, delay: 1.5,
          onComplete: () => {
            gsap.set("#betchipsaddtobetTP", { opacity: 0 });
          }
        });
        t9.to("#betchipsaddtobetTP", {
          x: 26, y: -66, duration: 0.1,
          onComplete: () => {
            gsap.set("#betchipsaddtobetTP", { opacity: 0 });

          }

        });
        t9.play()

        let bettextTP = gsap.timeline();

        bettextTP.to("#betTextaddtoBetTP", {
          x: 200,
          y: 50, duration: 0.5, delay: 1.5, opacity: 1,


          onComplete: () => {
            gsap.set("#betTextaddtoBetTP", { opacity: 0 });
            const antetextTP = document.querySelectorAll("#chipcenterpositionTP span");
            // antetextTP.forEach(el => el.textContent = Number((res.anteWinAmount + res.winAmount).toFixed(2)));
            antetextTP.forEach(el => el.textContent = this.topLable_rsp(Number((res.anteWinAmount + res.winAmount).toFixed(2))));
          }

        });
        // bettextTP.to("#betTextaddtoBetTP", {
        //   x: 0,
        //   y: -2, duration: 0.1, delay: 3, opacity: 0,

        // });
        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, zIndex: -2 })

        // const bettext_windows = document.querySelectorAll("#betTextaddtoBetTP");
        // console.log(bettext_windows)

        // let p=bettext_windows.forEach(ele=>ele.textContent=res.winAmount || "")
        // console.log(p)
        setTimeout(() => {
          const bettext_windows = document.querySelectorAll("#betTextaddtoBetTP");
          console.log(bettext_windows);

          bettext_windows.forEach(ele => {
            // ele.textContent = res.winAmount || "";
            ele.textContent = this.topLable_rsp(res.winAmount) || "";
            console.log(ele.textContent);
          });

        }, 500)


        setTimeout(() => {

          this.updateplayerbalnace();
          document.getElementById("buttons_coverTP").classList.remove("buttons_coverTP")
          document.getElementById("betpannelTropicalcover").style.display = "none";
          document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
          document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;

          document.getElementById("betbtntextTP").classList.add("betwindowsTP");

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

          document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
          document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");

          // gsap.to("#betchipsaddtobetTP", { x: 27, y: 0, duration: 0 });

          // document.getElementById("glowonwinchipTP").classList.remove("chipglow_russian")
          gsap.to("#betTextaddtoBetTP", { zIndex: -20 })


          this.setState({
            infoText: this.props.language.Place_antebet,

            totalbet: 0,
            anteBetAmount: 0,
            bonusbetamount: 0,
            insurencetbet: 0,
            disablemultibutton: false,

            disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0
          });

          // gsap.to("#chipcenterpositionTP", {
          //    x: 955, y: 290, duration: 0.1, opacity: 0,

          // });
          document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");


          document.getElementById("TPbetcircle").classList.remove("nonpointerTP")

          // gsap.to("#betchipsaddtobetTP", { zIndex: -2 })
          // gsap.to("#betchipsaddtconfrmTP", { zIndex: -2 })
          // gsap.to("#betchipsaddtodrawTP", { zIndex: -2 })
          // gsap.to("#chipcenterpositionTP", { zIndex: -2 })

          gsap.to("#betchipsaddtodrawTextTP", { zIndex: -2, duration: 0.1 })

          document.getElementById("rankinginfoTP").className = "";
          document.getElementById("rankinginfodelerTP").textContent = "";
          document.getElementById("rankinginfoTP").textContent = "";
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();

          document.getElementById("crdscoverTP").classList.remove("crdscoverTP")
          if (document.getElementById("winpopup_TP")) {
            document.getElementById("winpopup_TP").classList.remove("winpopup_tropical")
            document.getElementById("winamt_TPS").classList.remove("winamt_TropicalStud")
          }
        }, 5000);



        gsap.set(["#chipcenterpositionTP"], { clearProps: "all" });
        bnsactived.src = "";
        document.getElementById("bonustextTP").style.color = "";
        bnsactived.classList.remove("bnsctivewindowsTP");
        bnsactived.classList.remove("bnsblinkTP");

        break;

      case "Dealer_no_hand":

        // gsap.from("#betchipsaddtobetTP", { zIndex: -2 })
        //gsap.from("#betTextaddtodrawTP",{zIndex:-2})
        gsap.from("# ", { zIndex: -2 })
        document.getElementById("betbtntextTP").classList.remove("noinsurenceTP");
        document.getElementById("betactiveTP").src = "";
        document.getElementById("bettextTP").style.color = "";
        this.clertime55 = setTimeout(() => {
          this.setState({
            betbutton: "YES", infoText: res.message, buttontext: "NO", Yesbtn: 0, Nobtn: 0, keyboardhandle: true,
            disablemultibutton: false, disClearBtn: 1, disBuybtn: 1, disDrawbtn: 1, FoldbtnTP: 1


          });
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
          document.getElementById("betbtntextTP").textContent = this.props.language.Yes;

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtextTP").textContent = this.props.language.No;

          document.getElementById("betbtntextTP").classList.remove("insurenceTP");
          document.getElementById("foldtextTP").classList.remove("noinsurenceTP");
          document.getElementById("betbtntextTP").classList.add("betwindowsTP");
          document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");

        }, 500);

      default:
        break;
    }

  }



  Showebetchipsgamestatehandle = (res) => {


    let betTextaddtoBetElement = document.getElementById("betTextaddtoBetTP");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobetTP");
    betchipsaddtobetwindows.src = chip_10;
    betTextaddtoBetElement.textContent = this.topLable_rsp(res.betAmount);
    betTextaddtoBetElement.classList.add("bet-text-addtoBet-TP")
    gsap.to(this.chipbetref.current, {
      x: -965,
      y: -484,
      duration: 0.1,
      ease: "power2.inOut",
      zIndex: 2,
      opacity: 1
    });
    // document.getElementById("rankinginfodelerTP").classList.add("windowspokerrankingdelerTP");
    // document.getElementById("rankinginfoTP").classList.add("windowspokerrankingTP");
    Object.assign(betchipsaddtobetwindows.style, porttraitejson.Betchiptobetwindows)

    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand })
  }


  showcheckpayerordealerwins(res) {
    this.removehigltbonusclasses(res);
    this.clearallsettimeouts()
    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");


    // this.clertime37=setTimeout(() => {
    this.setState((prevState) => ({
      previousAnteBetAmount: prevState.anteBetAmount,
      previousBonusbetamount: prevState.bonusbetamount,
      totalbet: 0,
      // anteBetAmount: 0,
      // bonusbetamount: 0,
      insurencetbet: 0,

      // betdeductions: 0
    }));


    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    switch (res.winner) {
      case "Dealer":
        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });

        this.updateplayerbalnace();

        document.getElementById("foldtextTP").classList.remove("insurenceTP");
        document.getElementById("betdecrementTP").classList.remove("drwnodropTP")
        document.getElementById("betincrenetTP").classList.remove("drwnodropTP")
        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
        this.playPokerChipSound(this.pokerchipsnd);

        // gsap.from("#chip10centerbnsTP",{x:1046,y:276,zIndex:-2,duration:1})
        document.getElementById("betactiveTP").src = "";
        document.getElementById("bettextTP").style.color = "";
        document.getElementById("betactiveTP").classList.remove("betactiveTP");
        this.setState({
          betbutton: "REBET", previousAnteBetAmount: res.anteBetAmount,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,

          previousBonusbetamount: res.bonusBetAmount,


          buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true, totalWinAmount: 0
        });


        gsap.from("#betTextaddtoinsurenceTP", { zIndex: -2 })

        // setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;
        document.getElementById("betbtntextTP").classList.add("betwindows_rebetTP");




        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtextTP").textContent = "";
        document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
        document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");
        // }, 2000);
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0 });

        document.getElementById("bonusTropical").classList.remove("addpointerTP");
        document.getElementById("bonusTropical").classList.add("drwnodropTP");
        document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");
        document.getElementById("rankinginfoTP").className = "";
        document.getElementById("rankinginfodelerTP").textContent = "";
        document.getElementById("rankinginfoTP").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();



        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, zIndex: -2 })
        gsap.to("#betchipsaddtconfrmTP", { zIndex: -2 })
        gsap.to("#betchipsaddtodrawTP", { zIndex: -2 })
        gsap.to("#betTextaddtodrawTP", { zIndex: -2 })

        gsap.to("#betchipsaddtobetTP", { zIndex: -2 })

        // this.showcardsAdBetsParamState(res)

        break;

      //  case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:
      case "Player":



        this.setState({
          infoText: this.props.language.Place_antebet,
          anteBetAmount: 0,
          bonusbetamount: 0,
          disBetBtn: 0,
          bsbtn: true,
          totalwinRussian: 0,
          updateBet: 0,
          bonusBetAmount: 0
        });

        gsap.to("#betTextaddtoinsurenceTP", { zIndex: -2 })
        gsap.to("#betTextaddtodrawTP", { opacity: 0, zIndex: -20 })
        gsap.to("#betTextaddtoBetTP", { zIndex: -20 })
        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });


        this.updateplayerbalnace();
        // this.Dealerbetstoplayer()



        document.getElementById("foldtextTP").textContent = "";

        document.getElementById("betdecrementTP").classList.remove("drwnodropTP")
        document.getElementById("betincrenetTP").classList.remove("drwnodropTP")

        document.getElementById("foldtextTP").classList.remove("insurenceTP");
        document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");

        document.getElementById("betpannelTropicalcover").style.display = "none";
        document.getElementById("betpannelTropicalcover").classList.remove("bet_pannelcoverTP");

        // if (this.state.mutesounds) {
        //   this.plyerwin_snd = new Audio(plyerwin_snd);
        //   this.plyerwin_snd.pause();

        // }
        // else {
        //   this.plyerwin_snd = new Audio(plyerwin_snd);
        //   this.plyerwin_snd.play();

        // }
        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

        }))

        //  gsap.to("#chip10centerbnsTP",{x:-950,y:338,zIndex:-2})
        gsap.from("#betTextaddtoinsurenceTP", { zIndex: -2 })
        // gsap.to("#chip10centerbnsTP",{x:-126,y:269,zIndex:2,duration:1})
        // gsap.from("#chip10centerbnsTP",{x:1046,y:276,zIndex:-2,duration:1,delay:5})
        // gsap.from("#betchipsaddtodrawTP",{x:-949,y:-337})
        // gsap.from("#betchipsaddtodrawTP",{x:44,y:-48,delay:4,opacity:0})
        // gsap.from("#betchipsaddtobetTP",{zIndex:-2})
        // gsap.from("#betTextaddtodrawTP", { opacity: 0, zIndex: -2 })
        gsap.to("#betTextaddtoBetTP", { zIndex: -20 })
        this.setState({
          betbutton: "REBET", buttontext: "REBETX2",
          keyboardhandle: true,
          betchipincrementbtn: false, betdecreamentbtn: false,
          previousAnteBetAmount: res.anteBetAmount, previousBonusbetamount: res.bonusBetAmount,

          betBtnValue: 2
        })
        document.getElementById("betactiveTP").src = "";
        document.getElementById("bettextTP").style.color = "";
        document.getElementById("betactiveTP").classList.remove("betactiveTP");

        // ............................commmented on 24-07-24 ......................../
        // if(document.getElementById("chipcenterpositionTP")){
        //   gsap.to("#chipcenterpositionTP", { x: 0, y: 264, duration: 0.5 });

        // }



        if (document.getElementById("winpopup_TP")) {
          document.getElementById("winpopup_TP").classList.add("winpopup_tropical")
          document.getElementById("winamt_TPS").classList.add("winamt_TropicalStud")

        }
        // document.getElementById("glowonwinchipTP").classList.add("chipglow_russian")


        //  setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;
        document.getElementById("betbtntextTP").classList.add("betwindows_rebetTP");



        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

        document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;

        document.getElementById("foldtextTP").classList.add("rebettextwindowsTP");
        // }, 2000);

        // gsap.to("#betchipsaddtobetTP", { x: 27, y: 0, duration: 0 });
        if (document.getElementById("winpopup_TP")) {
          document.getElementById("winpopup_TP").classList.remove("winpopup_tropical")
          document.getElementById("winamt_TPS").classList.remove("winamt_TropicalStud")
        }
        // document.getElementById("glowonwinchipTP").classList.remove("chipglow_russian")


        // gsap.to("#chipcenterpositionTP", {
        //    x: 955, y: 290, duration: 0.1, opacity: 0,

        // });


        gsap.to("#betchipsaddtobetTP", { zIndex: -2 })
        gsap.to("#betchipsaddtodrawTextTP", { opacity: 0, zIndex: -2 })
        gsap.to("#betchipsaddtconfrmTP", { zIndex: -2 })
        gsap.to("#betchipsaddtodrawTP", { zIndex: -2 })
        gsap.to("#chipcenterpositionTP", { zIndex: -2, opacity: 0 })
        document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");
        document.getElementById("rankinginfoTP").className = "";
        document.getElementById("rankinginfodelerTP").textContent = "";
        document.getElementById("rankinginfoTP").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();



        document.getElementById("rankinginfodelerTP").classList.remove("windowspokerrankingdelerTP");

        // this.showcardsAdBetsParamState(res)

        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      
      case "Dealer_no_hand":
        // this.revealDealercards(res)
        this.dealerShuffledCards = [...res.dealerFinalCards]
        this.setState({
          anteBetAmount: res.anteBetAmount,
          totalbet: res.totalBetAmount,
          infoText: res.message,
          pokerranking: res.playerHand,
          pokerrankingdeler: res.dealerHand



        })
        document.getElementById("rankinginfodelerTP").classList.add("windowspokerrankingdelerTP");
        document.getElementById("rankinginfoTP").classList.add("windowspokerrankingTP");
        this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);


        if (res.betAmount > 0) {

          this.Showebetchipsgamestatehandle(res)
          //  let betTextaddtoBetClass = "bet-text-addtoBet10TP";
          // Object.assign(betchipsaddtobetwindows.style,porttraitejson.Betchiptobetwindows)
        }
        // gsap.from("#betchipsaddtobetTP", { zIndex: -2 })
        //gsap.from("#betTextaddtodrawTP",{zIndex:-2})
        gsap.from("#betTextaddtoinsurenceTP", { zIndex: -2 })
        document.getElementById("betbtntextTP").classList.remove("noinsurenceTP");
        document.getElementById("betactiveTP").src = "";
        document.getElementById("betactiveTP").classList.remove("betactiveTP");
        document.getElementById("bettextTP").style.color = "";
        // this.clertime55=setTimeout(() => {
        this.setState({
          betbutton: "YES", buttontext: "NO", Yesbtn: 0, Nobtn: 0, keyboardhandle: true,

          disablemultibutton: false
        });
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntextTP").textContent = this.props.language.Yes;

        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtextTP").textContent = this.props.language.No;

        document.getElementById("betbtntextTP").classList.remove("insurenceTP");
        document.getElementById("foldtextTP").classList.remove("noinsurenceTP");
        document.getElementById("betbtntextTP").classList.add("betwindowsTP");
        document.getElementById("foldtextTP").classList.add("foldtextwindowsTP");

      // }, 500);

      default:
        break;
    }
    // }, 3500)

  }

  removeconfirmdealercard_popup = () => {
    document.getElementById("confirmbuydealer_popupTP").style.display = "none";
    document.getElementById("confirmbuydealer_popupTP").classList.remove("popup_cnfrmbuydealrcardTP");
    document.getElementById("alertTxtTP").classList.remove("alertTxtTP");
    document.getElementById("alertTxtTP").innerHTML = "";
    document.getElementById("yesTP").classList.remove("yesbutton_popupTP")
    document.getElementById("noTP").classList.remove("nobutton_popupTP")

  }

  ShowbuydealercardResponse(res) {

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      // document.getElementById("rankinginfoTP").classList.add("windowspokerrankingTP");
      document.getElementById("rankinginfodelerTP").classList.add("windowspokerrankingdelerTP");
      this.setState({

        pokerrankingdeler: res.dealerHand,
        // pokerranking:res.playerHand,
        totalwinRussian: res.totalWinAmount
      })



      // this.showcardsAdBetsParamState(res,res.dealerCards,res.playerFinalCards)
      this.showcheckpayerordealerwins(res);

      if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
        //   document.getElementById("Tropicalgamecover").style.display = "block";
        //  document.getElementById("Tropicalgamecover").classList.add("gamecover_enbledisableTP")

        this.setState({ multibuttonsebledsble: true, disablemultibutton: true, Yesbtn: 1, Nobtn: 1 })

        document.getElementById("betbtnTP").classList.remove("addpointerTP")
        document.getElementById("FoldbtnTP").classList.remove("addpointerTP")
        document.getElementById("betbtnTP").classList.add("drwnodropTP")
        document.getElementById("FoldbtnTP").classList.add("drwnodropTP")
        // document.getElementById("crdscoverTP").style.display="block"
        document.getElementById("crdscoverTP").classList.add("crdscoverTP")
        document.getElementById("playerDealerCardContainerTP").classList.remove("addpointerTP")

        document.getElementById("confirmbuydealer_popupTP").style.display = "block";
        document.getElementById("confirmbuydealer_popupTP").classList.add("popup_cnfrmbuydealrcardTP");
        document.getElementById("alertTxtTP").classList.add("alertTxtTP");

        document.getElementById("alertTxtTP").innerHTML = res.message;
        document.getElementById("yesTP").classList.add("yesbutton_popupTP")
        document.getElementById("noTP").classList.add("nobutton_popupTP")
        this.setState({
          //  infoText: res.message
          infoText: ""
        })

      }
      else {
        const betbutton = document.getElementById("betbtnTP");
        const foldbutton = document.getElementById("FoldbtnTP");
        if (
          (navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
        ) {
          this.removeconfirmdealercard_popup();

          this.setState({ betbutton: "REBET", buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true })
          document.getElementById("drawtextTP").textContent = "";
          betbutton.src = "";
          betbutton.style.cssText = ""
          document.getElementById("betbtntextTP").classList.remove("betwindowsTP");


          foldbutton.src = "";
          foldbutton.style.cssText = ""
          document.getElementById("foldtextTP").classList.remove("foldtextwindowsTP");

          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
          document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;

        }

      }

    } else {

      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");
      document.getElementById("playerDealerCardContainerTP").style.zIndex = 1;
      const betbutton = document.getElementById("betbtnTP");
      const foldbutton = document.getElementById("FoldbtnTP");
      if (window.innerHeight > window.innerWidth) {
        switch (res.winner) {
          case "Dealer":
          case "Player":
            this.showPlayerorDealerorFold(res);

            break;
          case "Dealer_no_hand":
            // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
            
            ;
          default:
            break;
        }


        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
          // console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.ConfirmbuydealercardactivePopup = true
          // this.randomDealerCards = [...res.dealerFinalCards];
          this.dealerShuffledCards = [...res.dealerFinalCards]

          if (res.insurance) {
            if (res.insuranceBetAmount > 0) {
              let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
              this.setState((prevState) => ({
                storeinsurancechips: [
                  ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10TP" },
                ],
                insurencetbet: res.insuranceBetAmount,
              }));
            }
          }


          this.setState({
            multibuttonsebledsble: true,
            disablemultibutton: true,
            // infoText: res.message,
            infoText: "",
            pokerranking: res.playerHand,
            pokerrankingdeler: res.dealerHand

          })

          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait_TP");
          document.getElementById("alertTxtTP").innerHTML = res.message;
          document.getElementById("alertTxtTP").classList.add('alertTxtPortraitTP');
          document.getElementById("yesTP").classList.add("yesbutton_popup_portraitTP")
          document.getElementById("noTP").classList.add("nobutton_popup_portraitTP")
          document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerPortraitTP")
          document.getElementById("rankinginfoTP").classList.add("pokerrankingPortaitTP")

        }
        else {


          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          document.getElementById("drawtextTP").textContent = "";
          betbutton.src = "";
          betbutton.style.cssText = ""
          document.getElementById("betbtntextTP").classList.remove("betportraitTP");

          foldbutton.src = "";
          foldbutton.style.cssText = ""
          document.getElementById("foldtextTP").classList.remove("foldtextportraitTP");
          document.getElementById("confirmbuydealer_popupTP").style.display = "none";
          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait_TP");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yesTP").classList.remove("yesbutton_popup_portraitTP")
          document.getElementById("noTP").classList.remove("nobutton_popup_portraitTP")
          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          document.getElementById("betbtntextTP").textContent = this.props.language.Rebet;
          Object.assign(betbutton.style, this.state.betbuttoncss);


          foldbutton.src = Foldbn;
          document.getElementById("foldtextTP").textContent = this.props.language.RebetX2;
          Object.assign(foldbutton.style, this.state.foldbuttoncss);



        }

      } else {

        const drawtextElement = document.getElementById("drawtextTP")
        const foldtextElement = document.getElementById("foldtextTP")
        const betbtntextElement = document.getElementById("betbtntextTP")
        this.prevStatecheckPlayerOrDealerWinMblLndspe(res);


        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {

          //   document.getElementById("Tropicalgamecover").style.display = "block";
          //  document.getElementById("Tropicalgamecover").classList.add("gamecover_enbledisableTP")

          this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res);
          this.ConfirmbuydealercardactivePopup = true;
          this.dealerShuffledCards = [...res.dealerFinalCards];
          if (res.insurance) {
            if (res.insuranceBetAmount > 0) {
              let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
              this.setState((prevState) => ({
                storeinsurancechips: [
                  ...prevState.storeinsurancechips, { chip: this.ReturnChipBasedAmount(res.insuranceBetAmount), chipvalue: convrInsurenceBet, chipcolor1: "insuranceTextPortraitChip10TP" },
                ],
                insurencetbet: res.insuranceBetAmount
              }));
            }
          };

          this.setState({
            multibuttonsebledsble: true,
            disablemultibutton: true,
            // infoText: res.message,
            infoText: "",
            pokerranking: res.playerHand,
            pokerrankingdeler: res.dealerHand
          })
          confirmbuydealerPopupElement.className = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp_TP");
          document.getElementById("alertTxtTP").innerHTML = res.message;
          document.getElementById("alertTxtTP").classList.add('alertTxtPortraitTP');
          document.getElementById("yesTP").classList.add("yesbutton_popup_portraitTP")
          document.getElementById("noTP").classList.add("nobutton_popup_portraitTP")
          document.getElementById("rankinginfodelerTP").classList.add("pokerrankingdelerLndSpeTP")
          document.getElementById("rankinginfoTP").classList.add("pokerrankingPlayerLndspTP")

        }
        else {

          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          drawtextElement.textContent = "";

          betbutton.style.cssText = ""
          betbtntextElement.className = "";

          foldbutton.src = "";
          foldbutton.style.cssText = ""
          foldtextElement.className = "";

          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp_TP");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yesTP").classList.remove("yesbutton_popup_portraitTP")
          document.getElementById("noTP").classList.remove("nobutton_popup_portraitTP")
          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          betbtntextElement.classList.add(this.state.betbtntextcss);


          foldbutton.src = Foldbn;
           Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          foldtextElement.classList.add(this.state.foldbtntextcss);



        }
      }
    }
  };

  drawbuybets(anteAmount) {
    console.log(anteAmount)
    this.playPokerChipSound(this.pokerchipsnd);
    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      this.setState({ infoText: "" })
      let betexttodraw = document.getElementById("betTextaddtodrawTP");
      gsap.to("#betTextaddtodrawTP", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtodrawTP");

      let betTextaddtoBetClass = "";
      // this.setState((prevState) => ({
      //   totalbet: prevState.totalbet + prevState.anteBetAmount

      // }));
      let drwbetamnt
      // betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount)
      // drwbetamnt = (this.state.anteBetAmount)

      betexttodraw.textContent = this.topLable_rsp(anteAmount)
      drwbetamnt = (anteAmount)

      // }
      switch (this.state.count) {
        case 1:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw10TP";
          break;
        case 2:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20TP";

          break;
        case 3:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20TP";
          break;
        case 4:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20TP";
          break;
        case 5:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20TP";
          break;
        case 6:
          betTextaddtoBetClass = "";
          betchipsdraw.src = this.ReturnChipBasedAmount(Number(drwbetamnt));
          betTextaddtoBetClass = "bet-text-addtodraw20TP";
          break;

        default:
          break;
      }
      // this.clertime45 = setTimeout(() => {
      //   betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodrawTP");
      //   Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

      // }, 100)
      let t1 = gsap.timeline();
      // t1.to("#betchipsaddtodrawTP,betchipsaddtconfrmTP", {
      t1.to("#betchipsaddtodrawTP", {
        x: -1084,
        y: -598, duration: 0.2, opacity: 1,
        onComplete: () => {
          // betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodrawTP");
          betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodrawTP");
          Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

        }
      });

      t1.play();


    }
  }

  ifPlayerWinsMobilePortraitAction = (res) => {
    // this.playerWin = new Audio(playerWin);
    // this.playerWin.currentTime = 0;

    const winpopuprspelement = document.getElementById("winpopup_TP");
    const winamtElement = document.getElementById("winamt_TPS");

    const betbutton = document.getElementById("betbtnTP");
    const foldbutton = document.getElementById("FoldbtnTP");
    const betbtntextElement = document.getElementById("betbtntextTP");
    const foldtextElement = document.getElementById("foldtextTP");

    const anteText = document.querySelectorAll("#chipcenterpositionTP span");
    const betText = document.querySelector("#betTextaddtoBetTP");

    const delaerChipAnimDiv = document.getElementById('delaerchipanimTP')
    const dealerchipElement = document.getElementById('dealerchipTP');


    if (Number(res.anteWinAmount) > Number(res.anteBetAmount)) {
      dealerchipElement.src = this.ReturnChipBasedAmount(Number(res.anteBetAmount));
      // Object.assign(dealerchipElement.style, porttraitejson.dealerchipstoplayerPortrait)
      Object.assign(dealerchipElement.style, this.state.DelarAnteWinProLandAN)



      if (window.innerHeight > window.innerWidth) {
        let t1 = gsap.timeline()
        t1.to("#dealerchipTP", {
          autoAlpha: 1,
          zIndex: 2,
          x: -150,
          y: 1050,
          duration: 0.5,
          ease: "power2.inOut",
          onComplete: () => {
            gsap.set('#dealerchipTP', { opacity: 0, zIndex: -10 })
          },
        });

      t1.to("#dealerchipTP", {
        x: -75,
        y: 20,
        duration: 0.002,
        ease: "power2.inOut",
        autoAlpha: 0,
        zIndex: -10

        })
        setTimeout(() => {
          for (let b = 0; b < anteText.length; b++) {
            // anteText[b].textContent = res.anteWinAmount;
            anteText[b].textContent = this.topLable_rsp(res.anteWinAmount);
          }
        }, 500);

      } else {
        let t6 = gsap.timeline()
        t6.to("#dealerchipTP", {
          // x: 645,
          // y: 425,
          x: -180,
          y: 590,
          duration: 0.5,
          ease: "power2.inOut",
          onStart: () => {
            gsap.set('#dealerchipTP', { autoAlpha: 1, zIndex: 2 })
          },
          onComplete: () => {
            gsap.set('#dealerchipTP', { y: 0, autoAlpha: 0, zIndex: -10 })

            for (let b = 0; b < anteText.length; b++) {
              // anteText[b].textContent = res.anteWinAmount;
              anteText[b].textContent = this.topLable_rsp(res.anteWinAmount);
            }

          },
        });
      }

    }


    betText.textContent = this.topLable_rsp(res.winAmount);

    this.clertime52 = setTimeout(() => {
      // this.updateplayerbalnace();

      this.clertime53 = setTimeout(() => {
        this.clearActivesWeDid();
        // }, 1000);
      }, 3000);

      // console.log(this.state.anteBetAmount, this.state.bonusbetamount);

      this.setState((prevState) => ({
        isbonuspattable: false,
        // infoText: res.message,
        // totalwinRussian: res.totalWinAmount,
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        insurencetbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        prevStatestoreantebetchips: [...prevState.storeantebetchips],
        prevStatestorebonuschips: [...prevState.storebonuschips],
        storebonuschips: [],
        // storeinsurancechips: [],
      }));

      if (Number(res.bonusBetAmount) > 0) {
        if (this.state.storebonuschips.length === 0) {
          let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
          this.setState((prevState) => ({
            prevStatestorebonuschips: [...prevState.storebonuschips, { chip: this.ReturnChipBasedAmount(res.bonusBetAmount), chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10TP" },]
          }))
        }
      }

      delaerChipAnimDiv.className = "";
      dealerchipElement.src = "";
      dealerchipElement.className = "";

      this.resetAnimationChips = true;

      setTimeout(() => {
        this.playRemainingSounds(this.playerWin)
        // try{

        // winpopuprspelement.classList.add("winPopupForMptSwTP");
        // winamtElement.classList.add("winamtMpSwTP");
        this.setState({
          infoText: res.message,
          totalwinRussian: res.totalWinAmount,
        }
        )
      }, 1800)



      gsap.fromTo(winpopuprspelement,
        {
          autoAlpha: 0, scale: 0, duration: 0.5,

        },
        {
          autoAlpha: 1, scale: 1, duration: 0.5, delay: 1.8, rotate: 0, onStart: () => {
            setTimeout(() => {
              // winpopuprspelement.classList.add("winPopupForMptSwTP");
              // winamtElement.classList.add("winamtMpSwTP");
              // this.setState({
              //   infoText: res.message,
              //   totalwinRussian: res.totalWinAmount,
              // })
            }, 1500)

          }
        },
      )

      document.getElementById("betactiveTP").src = "";
      document.getElementById("bettextTP").style.color = "";
      document.getElementById("betactiveTP").classList.remove("betactiveTP");

      // let t1 = gsap.timeline()
      if (window.innerHeight > window.innerWidth) {
        gsap.to("#chipcenterpositionTP", { x: -370, y: 860, duration: 1, });
        gsap.to("#betchipsaddtobetTP", {
          x: -440, y: -70, duration: 1, delay: 0.5, onComplete: () => {
            betText.textContent = this.topLable_rsp(Number((res.anteWinAmount + res.winAmount).toFixed(2)));
          }
        });
        gsap.to("#betTextaddtoBetTP", { x: -440, y: -860, duration: 1, delay: 0.5 });
        setTimeout(() => {
          winpopuprspelement.classList.add("winPopupForMptSwTP");
          winamtElement.classList.add("winamtMpSwTP");
        }, 1800)

      } else {

        if (document.getElementById("chipcenterpositionTP")) {
          gsap.to("#chipcenterpositionTP", { x: 700, y: -130, duration: 0.5 });
        }

        if (document.getElementById("betchipsaddtobetTP")) {
          gsap.to("#betchipsaddtobetTP", { x: -460, y: -97, duration: 0.5, delay: 0.6 });
        }
        if (document.getElementById("betTextaddtoBetTP")) {
          gsap.to("#betTextaddtoBetTP", {
            x: -460, y: -97, duration: 0.5, delay: 0.6, onComplete: () => {
              betText.textContent = this.topLable_rsp(Number((res.anteWinAmount + res.winAmount).toFixed(2)));
            }
          });
        }
        winpopuprspelement.classList.add("winPopupForMLspTP");
        winamtElement.classList.add("winamtMpSwTP");

        winpopuprspelement.classList.remove("winPopupForMptSwTP");;
      }

      // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });

      // let t4 = gsap.timeline();
      // t4.to(this.chipdrawref.current, {
      //   // x: 360, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   x: 320, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 0 });
      //   }
      // });
      // t4.to(this.chipdrawref.current, {
      //   x: 608, y: -116, duration: 0.001, zIndex: -2, opacity: 0,
      //   onComplete: () => {
      //     gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
      //   }
      // });

      // t4.play();

      // if(this.wanttobuydelercrd === true){
      //    gsap.to(this.buyDealerCardDrawn.current, { x: -450, y: -280, duration: 0.5, delay: 1 });
      // }

      if (this.wanttobuydelercrd === true) {
        let t1 = gsap.timeline()
        t1.to(this.buyDealerCardDrawn.current, { x: -190, y: -2200, duration: 0.5 });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
        t1.play();
      }

      this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })




    }, 2000)

    this.resetPyrWnCpsAmn = true;

    this.clertime54 = setTimeout(() => {
      document.getElementById("mobileAntiMaskCoverTP").style.display = "none";
      gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, {
        autoAlpha: 0, scale: 0, duration: 0.5, onComplete: () => {
          winpopuprspelement.classList.remove("winPopupForMptSwTP");
          winamtElement.classList.remove("winamtMpSwTP");
          winpopuprspelement.classList.remove("winPopupForMLspTP");
        }
      })
      this.updateplayerbalnace();
      this.setState({
        totalwinRussian: 0,
        totalbet: 0,
        infoText: this.props.language.Play_Njoy,
        storeantebetchips: [],
        storeinsurancechips: [],
      });

      betbutton.src = Betbtn;
      // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
      Object.assign(betbutton.style, this.state.betbuttoncss);

      betbtntextElement.classList.remove('noinsurencePortraitTP', 'betportraiteTP');

      betbtntextElement.textContent = this.props.language.Rebet;


      // betbtntextElement.classList.add("betportraitTP");
      betbtntextElement.classList.add(`${this.state.betbtntextcss}`);

      // Object.assign(betbtntextElement.style, porttraitejson.betportraiteTP);

      foldbutton.src = Foldbnwindows;
      // Object.assign(foldbutton.style, this.state.foldbuttoncss);
      Object.assign(foldbutton.style, this.state.foldbuttoncss);

      foldtextElement.classList.remove('insurencePortraitTP', 'foldtextportraiteTP', 'foldtextportraitTP');
      foldtextElement.textContent = this.props.language.RebetX2;
      // foldtextElement.classList.add("rebettextportraitTP");
      foldtextElement.classList.add(`${this.state.foldbtntextcss}`);


      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];
      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });


      if (this.resetAnimationChips) {
        if (document.getElementById("chipcenterpositionTP")) {
          gsap.to("#chipcenterpositionTP", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
        }

        if (document.getElementById("betchipsaddtobetTP")) {
          gsap.to("#betchipsaddtobetTP", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
          gsap.to("#betTextaddtoBetTP", { x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0 });
        }

        // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
        // gsap.to(this.chipdrawref.current, { x: 608, y: -116, duration: 0.00001, zIndex: -5, opacity: 0 });
        // if (this.playerWinBonus === true) {

        // if (document.getElementById("chip10centerbnsTP")) {
        //   gsap.to("#chip10centerbnsTP", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
        //   this.playerWinBonus = false;

        // }


        // }
        if (this.wanttobuydelercrd === true) {
          gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
        }
      }

      // }, 4000)
    }, 6000)
    // }, 100000)



  }



  muteunmutesounds() {
    // this.getClickSound();

    this.selectCard.currentTime = 0;
    if (this.state.mutesounds) {
      this.selectCard.play();
    } else {
      this.selectCard.pause();
    }

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      document.getElementById('volumeTropical').classList.add("addpointerTP")
    }

    if (!this.state.mutesounds) {
      this.setState({ mutesounds: true })
      document.getElementById('volumeTropical').src = mutesnds;

    }
    else {
      this.setState({ mutesounds: false })
      document.getElementById('volumeTropical').src = volumeimg;

    }
  }


  Dealerbetstoplayer = (response) => {

    if (response.anteWinAmount > response.anteBetAmount) {

      let dealerchipTP = document.getElementById("dealerchipTP")
      dealerchipTP.src = this.ReturnChipBasedAmount(response.anteBetAmount)

      Object.assign(dealerchipTP.style, porttraitejson.dealerchipstoplayer)
      let tl = gsap.timeline()
      tl.to(dealerchipTP, {
        x: -87,
        y: 755,
        duration: 0.8,
        opacity: 1,
        //  delay: 1.5,
        ease: "power2.inOut",
        onComplete: () => {
          gsap.set(dealerchipTP, { opacity: 0 })

        }
      });
      tl.to(dealerchipTP, {
        x: 31,
        y: 0,
        duration: 1,
        ease: "power2.inOut",
      });
      // tl.play();
    }

  }


  hidepopup_betshigh = () => {

    if (
      (navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {

      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
      if (document.getElementById("popupbet_greaterthanbalnceTP")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntialTP");
          }

        }
        );

      }
    } else {
      this.setState({ betshigh_balnce: false })
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnceTP");
      if (document.getElementById("popupbet_greaterthanbalnceTP")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntialTP");
          }

        }
        );

      }
    }
  }

  updatePlayerInfo() {

    this.setState({ isplayerinfoupdate: true })

    this.setState({
      betdeductions: 0
    });

    //  const rankinginfoElement = document.getElementById("rankinginfoTP");
    //  if (rankinginfoElement) {
    //   if (this.state.isMobilePortraitLndsp) {
    //      rankinginfoElement.style.left = "34%";
    //    }
    //  }

  }


  showAnteBetchips = () => {

    return (
      this.state.storeantebetchips.map((eachchip, index) => (
        <div key={index} id="chipcenterpositionTP" className={`${this.state.antebetpdvcss}`}>
          <img
            className={`${this.state.antechpimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          <span className={`${eachchip.chipcolor1} ${this.state.antespntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )
  }

  showBonusBetchips = () => {



    return (

      this.state.storebonuschips.map((eachchip, index) => (
        <div key={index} id="chip10centerbnsTP" className={`${this.state.bonusdivcss}`}>
          <img
            className={`${this.state.bonusimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          {/* id="val_10TP" */}
          <span id={`bonusAmount${index}`} className={`${eachchip.chipcolor1} ${this.state.bonuspntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )

  }


  ShowHistory() {
    this.setState({ ShowHistory: !this.state.ShowHistory, ShowGamerules: false, ShowLimits: this.state.ShowLimits ? false : this.state.ShowLimits, ishistorytable: true }, () => {

      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (document.getElementById("histry_tropical")) {
          document.getElementById("histry_tropical")?.classList.remove("histry_Tropicalportraite")

        }
        if (document.getElementById("exithistoryTP")) {
          document.getElementById("exithistoryTP")?.classList.remove("exihstrybuttonportraiteTP")

        }
        if (document.getElementById("prevhistoryTP")) {
          document.getElementById("prevhistoryTP")?.classList.remove("showprevhsrytbleportraiteTP")

        }
        if (document.getElementById("histry_bodyTP")) {
          document.getElementById("histry_bodyTP")?.classList.remove("histry_bodyportraiteTP")
        }
        if (document.getElementById("heading_hstiryTP")) {
          document.getElementById("heading_hstiryTP")?.classList.remove("heading_hstiryportraiteTP")

        }
        if (document.getElementById("histry_tropical")) {
          document.getElementById("histry_tropical")?.classList.remove("histry_Tropicallndspe")

        }
        if (document.getElementById("exithistoryTP")) {
          document.getElementById("exithistoryTP")?.classList.remove("exihstrybuttonlndspeTP")

        }
        if (document.getElementById("prevhistoryTP")) {
          document.getElementById("prevhistoryTP")?.classList.remove("showprevhsrytblelndspeTP")

        }
        if (document.getElementById("histry_bodyTP")) {
          document.getElementById("histry_bodyTP")?.classList.remove("histry_bodylndspeTP")

        }
        if (document.getElementById("heading_hstiryTP")) {
          document.getElementById("heading_hstiryTP")?.classList.remove("heading_hstirylndspeTP")

        }


        if (document.getElementById("histry_tropical")) {
          document.getElementById("histry_tropical").classList.add("histry_tropical")

        }
        if (document.getElementById("exithistoryTP")) {
          document.getElementById("exithistoryTP").classList.add("exihstrybuttonTP")

        }
        // setTimeout(() => {
        if (document.getElementById("prevhistoryTP")) {
          document.getElementById("prevhistoryTP").classList.add("showprevhsrytbleTP")
        }
        // }, 100)
        if (document.getElementById("histry_bodyTP")) {
          document.getElementById("histry_bodyTP").classList.add("histry_bodyTP")

        }
        if (document.getElementById("heading_hstiryTP")) {
          document.getElementById("heading_hstiryTP").classList.add("heading_hstiryTP")

        }

        // document.getElementById("dealercards_hstryTP").classList.add("dealercards_hstryTP")
        // document.getElementById("dealerhand_hstryTP").classList.add("dealerhand_hstryTP")
        // document.getElementById("playercards_hstryTP").classList.add("playercards_hstryTP")
        // document.getElementById("playerhand_hstry").classList.add("playerhand_hstry")
        // document.getElementById("totalbet_histry").classList.add("totalbet_histry")
        // document.getElementById("totalwin_hstry").classList.add("totalwin_hstry")

      }
      else {

        if (window.innerHeight > window.innerWidth) {

          if (document.getElementById("histry_tropical")) {
            document.getElementById("histry_tropical")?.classList.remove("histry_Tropicallndspe")

          }
          if (document.getElementById("exithistoryTP")) {
            document.getElementById("exithistoryTP")?.classList.remove("exihstrybuttonlndspeTP")

          }
          if (document.getElementById("prevhistoryTP")) {
            document.getElementById("prevhistoryTP")?.classList.remove("showprevhsrytblelndspeTP")

          }
          if (document.getElementById("histry_bodyTP")) {
            document.getElementById("histry_bodyTP")?.classList.remove("histry_bodylndspeTP")

          }
          if (document.getElementById("heading_hstiryTP")) {
            document.getElementById("heading_hstiryTP")?.classList.remove("heading_hstirylndspeTP")

          }
          if (document.getElementById("histry_tropical")) {
            document.getElementById("histry_tropical")?.classList.remove("histry_tropical")

          }
          if (document.getElementById("exithistoryTP")) {
            document.getElementById("exithistoryTP")?.classList.remove("exihstrybuttonTP")

          }
          if (document.getElementById("prevhistoryTP")) {
            document.getElementById("prevhistoryTP")?.classList.remove("showprevhsrytbleTP")

          }
          if (document.getElementById("histry_bodyTP")) {
            document.getElementById("histry_bodyTP")?.classList.remove("histry_bodyTP")

          }
          if (document.getElementById("heading_hstiryTP")) {
            document.getElementById("heading_hstiryTP").classList.add("heading_hstiryTP")

          }

          if (document.getElementById("histry_tropical")) {
            document.getElementById("histry_tropical").classList.add("histry_Tropicalportraite")

          }
          if (document.getElementById("exithistoryTP")) {
            document.getElementById("exithistoryTP").classList.add("exihstrybuttonportraiteTP")

          }
          // setTimeout(() => {
          if (document.getElementById("prevhistoryTP")) {
            document.getElementById("prevhistoryTP").classList.add("showprevhsrytbleportraiteTP")

          }
          // }, 500)
          if (document.getElementById("histry_bodyTP")) {
            document.getElementById("histry_bodyTP").classList.add("histry_bodyportraiteTP")

            if (document.getElementById("heading_hstiryTP")) {
              document.getElementById("heading_hstiryTP").classList.add("heading_hstiryportraiteTP")

            }

          }


        }
        else {


          if (document.getElementById("histry_tropical")) {
            document.getElementById("histry_tropical")?.classList.remove("histry_tropical")

          }
          if (document.getElementById("exithistoryTP")) {
            document.getElementById("exithistoryTP")?.classList.remove("exihstrybuttonTP")

          }
          if (document.getElementById("prevhistoryTP")) {
            document.getElementById("prevhistoryTP")?.classList.remove("showprevhsrytbleTP")

          }
          if (document.getElementById("histry_bodyTP")) {
            document.getElementById("histry_bodyTP")?.classList.remove("histry_bodyTP")

          }
          if (document.getElementById("heading_hstiryTP")) {
            document.getElementById("heading_hstiryTP")?.classList.remove("heading_hstiryTP")

          }

          if (document.getElementById("histry_tropical")) {
            document.getElementById("histry_tropical")?.classList.remove("histry_Tropicalportraite")

          }
          if (document.getElementById("exithistoryTP")) {
            document.getElementById("exithistoryTP")?.classList.remove("exihstrybuttonportraiteTP")

          }
          if (document.getElementById("prevhistoryTP")) {
            document.getElementById("prevhistoryTP")?.classList.remove("showprevhsrytbleportraiteTP")

          }
          if (document.getElementById("histry_bodyTP")) {
            document.getElementById("histry_bodyTP")?.classList.remove("histry_bodyportraiteTP")
          }
          if (document.getElementById("heading_hstiryTP")) {
            document.getElementById("heading_hstiryTP").classList.remove("heading_hstiryportraiteTP")

          }


          if (document.getElementById("histry_tropical")) {
            document.getElementById("histry_tropical").classList.add("histry_Tropicallndspe")

          }
          if (document.getElementById("exithistoryTP")) {
            document.getElementById("exithistoryTP").classList.add("exihstrybuttonlndspeTP")

          }
          // setTimeout(() => {
          if (document.getElementById("prevhistoryTP")) {
            document.getElementById("prevhistoryTP").classList.add("showprevhsrytblelndspeTP")

          }

          // }, 500);
          if (document.getElementById("histry_bodyTP")) {
            document.getElementById("histry_bodyTP").classList.add("histry_bodylndspeTP")

          }
          if (document.getElementById("heading_hstiryTP")) {
            document.getElementById("heading_hstiryTP").classList.add("heading_hstirylndspeTP")

          }

        }

      }

    })


  }



  exithistoryTP() {
    this.setState({ ShowHistory: false })

  }
  hideshowoptionmenu() {

    this.setState({ isoptionmenu: false, ShowLimits: false })
  }
  // animexit(){

  //   if(document.getElementById("exithistoryTP")){
  //     document.getElementById("exithistoryTP").classList.add("exithistoryTP")

  //   }

  // }
  confirmfoldresp() {

  }


  confirmFoldactin(cnfrmtext) {
    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }
    if (this.state.isoptionmenu === true) {
      this.Showoptionsmenu();
    }
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popupTP");
    if (cnfrmtext === true) {
      let betexttodraw = document.getElementById("betchipsaddtodrawTextTP");
      betexttodraw.classList.remove("bet-text-addtodrawTP", "bet-text-addtoinsurence10TP")
      const drawtextElement = document.getElementById("drawtextTP");
      const buybutton = document.getElementById("buybtnTP");
      const drawbutton = document.getElementById("drawbtnTP");
      buybutton.src = "";
      drawbutton.src = "";
      drawtextElement.textContent = "";
      document.getElementById("alertTxtTP").classList.remove("alertTxtTP");

      document.getElementById("alertTxtTP").innerHTML = "";
      document.getElementById("confirmbuydealer_popupTP").style.display = "none";
      document.getElementById("confirmbuydealer_popupTP").classList.remove("popup_cnfrmbuydealrcardTP");
      document.getElementById("yesTP").classList.remove("yesbutton_popupTP")
      document.getElementById("noTP").classList.remove("nobutton_popupTP")

      confirmbuydealerPopupElement.className = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait_TP");
      document.getElementById("alertTxtTP").innerHTML = "";
      document.getElementById("alertTxtTP").classList.remove('alertTxtPortraitTP');
      document.getElementById("yesTP").classList.remove("yesbutton_popup_portraitTP")
      document.getElementById("noTP").classList.remove("nobutton_popup_portraitTP")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp_TP");
      document.getElementById("alertTxtTP").innerHTML = "";
      document.getElementById("alertTxtTP").classList.remove('alertTxtPortraitTP');
      document.getElementById("yesTP").classList.remove("yesbutton_popup_portraitTP")
      document.getElementById("noTP").classList.remove("nobutton_popup_portraitTP")

      const body = {
        sessionId: sessionStorage.getItem("sessionId"),

        CN: "TROPICAL_STUD_POKER_BET",
        object: {
          action: "CONFIRM_FOLD",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
          handId: Number(this.props.gameState.handId)
        },
      };

      this.props.network.sendof(body);

    }

    else {
      if (this.drawReplaceCards === true) {
        this.showParamBetStatus(true);
      } else {
        this.showParamBetStatus(false);
      }
      document.getElementById("alertTxtTP").classList.remove("alertTxtTP");

      document.getElementById("alertTxtTP").innerHTML = "";
      document.getElementById("confirmbuydealer_popupTP").classList.remove("popup_cnfrmbuydealrcardTP");
      document.getElementById("yesTP").classList.remove("yesbutton_popupTP")
      document.getElementById("noTP").classList.remove("nobutton_popupTP")

      confirmbuydealerPopupElement.className = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait_TP");
      document.getElementById("alertTxtTP").innerHTML = "";
      document.getElementById("alertTxtTP").classList.remove('alertTxtPortraitTP');
      document.getElementById("yesTP").classList.remove("yesbutton_popup_portraitTP")
      document.getElementById("noTP").classList.remove("nobutton_popup_portraitTP")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp_TP");
      document.getElementById("alertTxtTP").innerHTML = "";
      document.getElementById("alertTxtTP").classList.remove('alertTxtPortraitTP');
      document.getElementById("yesTP").classList.remove("yesbutton_popup_portraitTP")
      document.getElementById("noTP").classList.remove("nobutton_popup_portraitTP")

    }

  }




  ShowLimits() {
    this.setState({ ShowLimits: !this.state.ShowLimits, ShowGamerules: false, ShowHistory: this.state.ShowHistory ? false : this.state.ShowHistory }, () => {

      // exitLimits
      const exitLimitsElement = document.getElementById("exitLimits");
      // exitLimitsElement.className = "";
      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {
        document.getElementById("Limits")?.classList.remove("LimitsPopup_landspeTP")
        document.getElementById("Limits")?.classList.remove("LimitsPopup_portraiteTP")
        document.getElementById("Limits")?.classList.add("LimitsPopupTP");
        // exitLimitsElement.classList.add("exihstrybuttonportraiteTP");
      }
      else {
        if (window.innerHeight > window.innerWidth) {
          document.getElementById("Limits")?.classList.remove("LimitsPopupTP")
          document.getElementById("Limits")?.classList.remove("LimitsPopup_landspeTP")
          document.getElementById("Limits")?.classList.add("LimitsPopup_portraiteTP")
          // exitLimitsElement.classList.add("exihstrybuttonportraiteTP");

        }
        else {

          document.getElementById("Limits")?.classList.remove("LimitsPopupTP")
          document.getElementById("Limits")?.classList.remove("LimitsPopup_portraiteTP")
          document.getElementById("Limits")?.classList.add("LimitsPopup_landspeTP")
          // exitLimitsElement.classList.add("exihstrybuttonportraiteTP");

        }
      }

    })

  }

  exitLimits = () => {
    this.setState({ ShowLimits: false })
  }
  Gamerules = () => {
    this.setState({ ShowGamerules: !this.state.ShowGamerules, ShowHistory: false, ShowLimits: false }, () => {

      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        // this.setState({ShowGamerules:true},()=>{


        document.getElementById("ShowGamerulesTP")?.classList.remove("GamerulescontainerportTP")
        document.getElementById("gameruleMainHeadingTP")?.classList.remove("gameruleMainHeadingportTP")
        document.getElementById("unorderedlistitemsTP")?.classList.remove("unorderedlistitemsportTP")
        document.getElementById("ShowGamerulesTP")?.classList.remove("GamerulescontainerlndTP")
        document.getElementById("gameruleMainHeadingTP")?.classList.remove("gameruleMainHeadinglndTP")
        document.getElementById("unorderedlistitemsTP")?.classList.remove("unorderedlistitemslndTP")

        document.getElementById("ShowGamerulesTP")?.classList.add("GamerulescontainerTP")
        document.getElementById("gameruleMainHeadingTP")?.classList.add("gameruleMainHeadingTP")
        document.getElementById("unorderedlistitemsTP")?.classList.add("unorderedlistitemsTP")

        // })

      }
      else {
        if (window.innerHeight > window.innerWidth) {


          document.getElementById("ShowGamerulesTP")?.classList.remove("GamerulescontainerTP")
          document.getElementById("gameruleMainHeadingTP")?.classList.remove("gameruleMainHeadingTP")
          document.getElementById("unorderedlistitemsTP")?.classList.remove("unorderedlistitemsTP")
          document.getElementById("ShowGamerulesTP")?.classList.remove("GamerulescontainerlndTP")
          document.getElementById("gameruleMainHeadingTP")?.classList.remove("gameruleMainHeadinglndTP")
          document.getElementById("unorderedlistitemsTP")?.classList.remove("unorderedlistitemslndTP")

          document.getElementById("ShowGamerulesTP")?.classList.add("GamerulescontainerportTP")
          document.getElementById("gameruleMainHeadingTP")?.classList.add("gameruleMainHeadingportTP")
          document.getElementById("unorderedlistitemsTP")?.classList.add("unorderedlistitemsportTP")



        }
        else {

          document.getElementById("ShowGamerulesTP")?.classList.remove("GamerulescontainerTP")
          document.getElementById("gameruleMainHeadingTP")?.classList.remove("gameruleMainHeadingTP")
          document.getElementById("unorderedlistitemsTP")?.classList.remove("unorderedlistitemsTP")
          document.getElementById("ShowGamerulesTP")?.classList.remove("GamerulescontainerportTP")
          document.getElementById("gameruleMainHeadingTP")?.classList.remove("gameruleMainHeadingportTP")
          document.getElementById("unorderedlistitemsTP")?.classList.remove("unorderedlistitemsportTP")
          document.getElementById("ShowGamerulesTP")?.classList.add("GamerulescontainerlndTP")
          document.getElementById("gameruleMainHeadingTP")?.classList.add("gameruleMainHeadinglndTP")
          document.getElementById("unorderedlistitemsTP")?.classList.add("unorderedlistitemslndTP")



        }
      }
    })
  }

  closeMenuOption = () => {
    this.setState({
      isoptionmenu: false,
      ShowLimits: false,
      ShowHistory: false,
      ShowGamerules: false,
    })
  }

  renderTheBonusForView = () => {
    const { isbonuspattable } = this.state

    if ((navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
    ) {
      return (
        <div className="gridTP" style={{ display: 'none' }}>

          {/* <table className="bonuspaytableTP" id="bonuspaytableInfoTPS" >
            <thead id="bonusInfoTheadTP">
              <tr>
                <th id="TPheading_table" className="windowstableheadTP" colSpan="2">BONUS PAY TABLE12</th>
              </tr>
            </thead>
            <tbody id="TPbonusInfoTbody">
              {this.bonuspaytable.map((obj, i) => {
                const parts = obj.val.split(" to ");
                // console.log(parts)
                return (
                  <tr className="numbersTP" key={`card-${obj.k}`} id={`numbersTP${i}`}>
                    <td className="numTP" id={`numTropical${i}`}>{obj.k}</td>
                    <td className="numbersvalTP" id={`numbersvalrussianTP${i}`}>
                      {parts[0]} <span className="span-to">to</span> {parts[1]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}

        </div>
      )
    } else {
      // return (

      // isbonuspattable ? (
      //   <div className="gridTP">
      //     <table className="bonuspaytableTP" id="bonuspaytableInfoTPS">
      //       <thead id="bonusInfoTheadTP">
      //         <tr>
      //           <th id="TPheading_table" colSpan="2">BONUS PAY TABLE</th>
      //         </tr>
      //       </thead>
      //       <tbody id="TPbonusInfoTbody">
      //         {this.bonuspaytable.map((obj, i) => (
      //           <tr key={`card-${obj.k}`} id={`numbersTP${i}`}>
      //             <td id={`numTropical${i}`}>{obj.k}</td>
      //             <td id={`numbersvalrussianTP${i}`}>{obj.val}</td>
      //           </tr>
      //         ))}
      //       </tbody>
      //     </table>
      //   </div>
      // ) : ""
      // )
    }
  }

  removehigltbonusclasses = (response) => {

    const highlightedElements = document.querySelectorAll('.bonuswinhgltTP, .bonuspayouthgltTP');


    highlightedElements.forEach(element => {
      element.classList.remove('bonuswinhgltTP', 'bonuspayouthgltTP');
    });

    // this.bonuspaytable.map((obj, val) => {
    //   const bonushandplayer = document.getElementById(`numTropical${val}`);
    //   bonushandplayer.classList.add("numTP")
    // });

    //   const bonushandplayerval = document.getElementById(`numbersvalrussianTP${val}`);
    //     let playerbonuhand = response.playerHand.split(" , ");

    //     if (bonushandplayer && playerbonuhand.includes(obj.k)) {
    //       if(bonushandplayer.classList.contains("bonuswinhgltTP")){
    //         bonushandplayer.classList.remove("bonuswinhgltTP")
    //         bonushandplayer.style.color="";
    //       }
    //       if(bonushandplayerval.classList.contains("bonuspayouthgltTP")){
    //         bonushandplayerval.classList.remove("bonuspayouthgltTP")
    //         bonushandplayerval.style.color="";
    //       }
    //       bonushandplayer.classList.add("numTP")
    //   }
    // });

  }

  showTableImage = () => {

    const pokertable = document.getElementById("pokertableimTP");
    const pokertablelnspe = document.getElementById("pokertablelandscapeTP");
    const Russiantblewindows = document.getElementById("tpntblewindows");
    if (pokertable && pokertable) {
      if (pokertable.style?.cssText && pokertable?.src) {
        pokertable.style.cssText = "";
        pokertable.src = "";
      }
    }

    if (pokertablelnspe && pokertablelnspe) {
      if (pokertablelnspe.style?.cssText && pokertablelnspe?.src) {
        pokertablelnspe.style.cssText = "";
        pokertablelnspe.src = "";
      }

    }

    if (Russiantblewindows && Russiantblewindows) {
      if (Russiantblewindows.style?.cssText && Russiantblewindows?.src) {
        Russiantblewindows.style.cssText = "";
        Russiantblewindows.src = "";
      }

    }

    // if (navigator.userAgentData?.platform === "Windows" || navigator.userAgent.includes("Windows")) {
    //   if (Russiantblewindows) {
    //     Russiantblewindows.src = Rutablewindows;
    //     Object.assign(Russiantblewindows.style, porttraitejson.russtablewindows)
    //   }

    // 
    if (navigator.userAgentData?.platform === "Windows" || navigator.userAgent.includes("Windows")) {
      import("../../../../../assets/games/tropicalstudpoker/tropicalstudPoker.png").then((module) => {
        // import("../../../../../assets/games/russianPoker/russiantblewindows.png").then((module) => {
        const Rutablewindows = module.default;
        if (Russiantblewindows) {
          Russiantblewindows.src = Rutablewindows;
          Object.assign(Russiantblewindows.style, porttraitejson.russtablewindows);
        }
      });
    }
    else {
      if (window.innerHeight > window.innerWidth) {
        if (pokertable) {

          pokertable.style.backgroundSize = "cover";

          import("../../../../../assets/games/tropicalstudpoker/mobileTable.png").then((module) => {
            const pokertableruss = module.default;
            pokertable.src = pokertableruss;
          });
          Object.assign(pokertable.style, porttraitejson.pokertable);
        }

      } else {
        if (pokertablelnspe) {
          Object.assign(pokertablelnspe.style, porttraitejson.pokertableland);
          import("../../../../../assets/games/tropicalstudpoker/tropical-land.png").then((module) => {
            const pokertablelandsp = module.default;
            pokertablelnspe.src = pokertablelandsp;
          });

        }

      }

    }


  }

  showPayoutBonusTable12(NameType, BonusAC) {
    this.getClickSound();
    this.setState({
      PayoutTableToggle: !this.state.PayoutTableToggle
    }, () => {
      if (this.state.PayOutselectbtn == "BonusPayout") {
        if (document.getElementById("BonusPayoutBtn")) {
          document.getElementById("BonusPayoutBtn").classList.add("PayoutBtnBGC")
        }
        if (document.getElementById("BetPayoutBtn")) {
          document.getElementById("BetPayoutBtn").classList.remove("PayoutBtnBGC")
        }
      } else {
        if (document.getElementById("BonusPayoutBtn")) {
          document.getElementById("BonusPayoutBtn").classList.remove("PayoutBtnBGC")
        }
        if (document.getElementById("BetPayoutBtn")) {
          document.getElementById("BetPayoutBtn").classList.add("PayoutBtnBGC")
        }
      }

      if (
        (navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
      ) {

        if (document.getElementById("PAYOUTTABLETPS")) {
          document.getElementById("PAYOUTTABLETPS").classList.add("showPayoutTP")
        }
      } else {

        if (window.innerHeight > window.innerWidth) {
          if (document.getElementById("PAYOUTTABLETPS")) {
            document.getElementById("PAYOUTTABLETPS").classList.add("showPayoutTPprtrt")
          }

        } else {

          if (document.getElementById("PAYOUTTABLETPS")) {
            document.getElementById("PAYOUTTABLETPS").classList.add("showPayoutLandscpeTP")
          }
        }
      }

      if (document.getElementById("Payout_TPS")) {
        document.getElementById("Payout_TPS").classList.add("payout_winsow_TP")
      }

      if (document.getElementById("payout_bodyTPS")) {
        document.getElementById("payout_bodyTPS").classList.add("payout_bodyTP")

      }

      if (document.getElementById("heading_payout_TPS")) {
        document.getElementById("heading_payout_TPS").classList.add("payoutheadingTP")

      }
    })
    console.log(this.state.PayoutTableToggle)
    this.payoutChangebtn(NameType)
  }

  payoutChangebtn(Name) {
    console.log(Name)
    this.setState({
      PayOutselectbtn: Name
    }, () => { console.log(this.state.PayOutselectbtn) });
    if (Name == "BonusPayout") {
      const BonusPayout123 = [
        {
          "key": "Royal Flush",
          "value": "Bonus X 10001"
        },
        {
          "key": "Straight Flush",
          "value": "Bonus X 2501"
        },
        {
          "key": "Four of a Kind",
          "value": "Bonus X 1001"
        },
        {
          "key": "Full House",
          "value": "Bonus X 251"
        },
        {
          "key": "Flush",
          "value": "Bonus X 101"
        }
      ]
      this.setState({
        PayouyBetBounusData: BonusPayout123
      })
      if (document.getElementById("BonusPayoutBtn")) {
        document.getElementById("BonusPayoutBtn").classList.add("PayoutBtnBGC")
      }
      if (document.getElementById("BetPayoutBtn")) {
        document.getElementById("BetPayoutBtn").classList.remove("PayoutBtnBGC")
      }
    } else {
      this.setState({
        PayouyBetBounusData: this.props.TP_payouts
      })
      if (document.getElementById("BonusPayoutBtn")) {
        document.getElementById("BonusPayoutBtn").classList.remove("PayoutBtnBGC")
      }
      if (document.getElementById("BetPayoutBtn")) {
        document.getElementById("BetPayoutBtn").classList.add("PayoutBtnBGC")
      }
    }


  }
  render() {

    const isWindows = navigator.userAgentData?.platform === "Windows" || navigator.userAgent.includes("Windows");
    const isPortrait = window.innerHeight > window.innerWidth;
    this.roundNumber = this.props.balance - this.state.betdeductions
    this.betAmount = (this.roundNumber)
    console.log(this.betAmount)
    // this.state.balance_rsp = this.topLable_rsp(this.betAmount);
    // this.state.updateBet = this.topLable_rsp(this.state.totalbet)

    // let balance_rsp = this.topLable_rsp(this.betAmount);
    // let updateBet = this.topLable_rsp(this.state.totalbet)
    let balance_rsp = Number(this.betAmount.toFixed(2));
    let updateBet = Number(this.state.totalbet.toFixed(2));
    let popup_winamount = this.topLable_rsp(this.state.totalwinRussian)
    // console.log(this.props.balance, "     ",this.state.betdeductions,"         ",this.roundNumber,balance_rsp)
    const {
      heightOfLayer,
      widthOfLayer,
      dealerDistanceFromX,
      playerDistanceFromX,
      dealerPlayerDistanceFromY,
      isMobilePortraitLndsp,
      dealerDistanceFromY,
      PlayerDistanceFromY,
      pokerranking
    } = this.state;


    return (
      <Fragment>

        <div id="bacaratbgimTP" className="RP_MainDivTP" onClick={() => {

          if (this.state.isoptionmenu === true) {
            this.closeMenuOption();
          }
        }}>

          <img src={this.showTableImage()} id="pokertableimTP" alt="" />
          <img src={this.showTableImage()} id="pokertablelandscapeTP" alt="" />
          {/* <img src="" id="pokertableimTP" alt="" />
          <img src="" id="pokertablelandscapeTP" alt="" /> */}

          <img id="TspoptionTP" src="" onClick={(e) => { e.preventDefault(); this.Showoptionsmenu(); }} alt="" />


          <div style={{ display: 'none' }}>
            <img src="" id="tropicaltitle" alt="" style={{ display: 'none' }} />

          </div>


          <img src="" id="balnceiconTP" alt="" />
          <span id="balance">{balance_rsp}</span>
          <img src="" id="beticonTP" alt="" />
          <span id="betsTP">{updateBet}</span>
          <img src="" id="exitbtnTP" alt="" />
          <img src="" id="winnericonTP" alt="" />
          <div id="playerDetailsTP">
            <span>P ID :  {this.props.playerId}</span>
            <span>ID :  {this.props.gameState.handId}</span>
          </div>
          <div id="winsTP">
            <span id="winamount_rsp">{this.state.totalwinRussian}</span>
          </div>

          <div id="betcontainerTP" className="betpannelTropicalstud">
            <button disabled={this.state.antebetcircle}>
              <img src="" id="TPbetcircle"
                onClick={(e) => { e.preventDefault(); this.anteclick(); }}
                // onPointerDown={(e) => { e.preventDefault(); this.anteclick(); }}
                onPointerUp={(e) => { e.preventDefault(); this.removeChipSound() }}
                alt="" />
            </button>

            <button disabled={this.state.bsbtn}>
              <img src="" id="bonusTropical"
                onClick={(e) => { e.preventDefault(); this.BonusActivated(); }}
                // onPointerDown={(e) => { e.preventDefault(); this.BonusActivated();}}
                onPointerUp={(e) => { e.preventDefault(); this.removeChipSound() }}
                alt="" />
            </button>

            <img src="" id="bnsactiveTP" alt="" className={this.state.bnsactvglwcss} />
            <img src="" id="betactiveTP" alt="" className={this.state.betactvglwcss} />
            {/* <img src="" id="insurenceactive" alt="" className={this.state.insurenceactivecss} /> */}

            <img src="" id="buydrawTropical" alt="" />
            <img src="" id="betTropical" alt="" />
            {/* <span id="insurencetext">{this.props.language.Insurence}</span> */}
            <span id="bonustextTP">{this.props.language.Bonus}</span>
            <span id="bettextTP">{this.props.language.Bet}</span>
            <span id="buydrawtextTP">
              {this.props.language.Draw}
              <br />
              {/* {`/${this.props.language.Buy}`} */}
            </span>
            <span id="antetextTP">{this.props.language.Ante}</span>
          </div>

          <div ref={this.buyDealerCardDrawn} id="dealerBuyCardTP">
            <button type="button" id="dealerChipsAddToBuyButtonTP"> <img src="" id="dealerChipsAddToBuyTP" alt="" /></button>
            <span id="dealerChipsAddToBuyTextTP"></span>
          </div>

          <div id="betpannelTropicalcover" className={this.state.betpannelrussiancovercss}></div>
          <div id="betpannelinsurececoverTP"></div>

          <div id="mobileAntiMaskCoverTP" className={this.state.mobileAntiMaskCovercss}></div>


          <div className="player-dealer-card-containerTP" id="playerDealerCardContainerTP">

            <div id="container_playerdealerTP">
              <h1 id="Dealer">
                DEALER
              </h1>
              <h2 id="Player">
                PLAYER
              </h2>
            </div>
            {/* <Stage width={window.innerWidth} height={window.innerHeight} > */}
            {/* <Stage width={2350} height={heightOfLayer}> */}
            <Stage width={widthOfLayer} height={heightOfLayer}
              style={{ width: `${widthOfLayer}px`, height: `${heightOfLayer}px` }}
              pixelRatio={1}
            >
              <Layer>
                <Group x={dealerDistanceFromX} y={dealerDistanceFromY} stroke="black">
                  <DealerCardsContainer ref={this.dealerCardsContainer} x={450} y={400} xPadding={18}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    isMobilePortrait={isMobilePortraitLndsp} activeScreen={this.state.activeScreen} mutesounds={this.state.mutesounds} />
                </Group>
                <Group x={playerDistanceFromX} y={PlayerDistanceFromY} stroke="black">
                  <Cards ref={this.cardsRef} x={450} y={400} xPadding={18} previousGamestateCardLength6={this.props.previousGamestateCardLength6}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    ratioed={1} hidecardPos={30} numberOfCardsToBeDraw={this.numberOfCardsToBeDraw}
                    isMobilePortrait={isMobilePortraitLndsp} mutesounds={this.state.mutesounds} activeScreen={this.state.activeScreen} />
                </Group>
              </Layer>

            </Stage>
          </div>
          {(this.state.isMobilePortraitLndsp === true) && (
            <div id="chipsstoredcontainerTP" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
              {/* {this.state.storebetchips} */}
              {this.showAnteBetchips()}
              {this.showBonusBetchips()}
            </div>
          )}

          <button disabled={this.state.drawbuttonhandle}>
            <img src="" id="drawbtnTP" onClick={(e) => { e.preventDefault(); this.drawCard(false); }} alt="" style={this.state.drawbtncss} />
          </button>
          <div style={{ display: 'none' }}>
            <button disabled={this.state.disableBuyButton} type="button" >
              <img src="" id="buybtnTP" alt="" style={this.state.buybtncss} />
            </button>
          </div>
          <button disabled={this.state.multibuttonsebledsble}>
            <img style={this.state.betbuttoncss} src="" id="betbtnTP"

              onClick={(e) => {
                e.preventDefault();

                if (this.state.betbutton === "Bet") {
                  this.TropicalpokerBet();
                } else if (this.state.betbutton === "DEAL") {
                  this.anteBet();
                } else if (this.state.betbutton === "REBET") {
                  this.anteclick(this.state.betbutton);
                }
              }}
              alt="" />
          </button>

          <button disabled={this.state.disablemultibutton}>
            <img style={this.state.foldbuttoncss}
              src=""
              id="FoldbtnTP"
              onClick={(e) => {
                e.preventDefault();
                if (this.state.buttontext == "clear") {
                  this.clearbets();
                } else if (this.state.buttontext == "fold") {
                  this.FoldCards();
                } else if (this.state.buttontext == "REBETX2") {
                  this.anteclick(this.state.buttontext);
                }
              }
              }
              alt="" />
          </button>

          {/* <img src="" id="infrmiconTP" onMouseDown={(e) => {
            e.preventDefault();
            this.ShowBonuspayouttable();
            this.HideBonuspayouttable();
          }} */}

          <img src="" id="infrmiconTP" onMouseDown={(e) => {
            e.preventDefault();
            this.showPayoutBonusTable12('BetPayout')
          }}
            // onMouseLeave={(e) => {
            //   e.preventDefault();
            //   this.HideBonuspayouttable();
            // }}
            // onMouseOut={(e) => {
            //   e.preventDefault();
            //   this.HideBonuspayouttable();
            // }}
            alt="" />
          <img src="" id="volumeTropical" onClick={(e) => { e.preventDefault(); this.muteunmutesounds(); }} alt="" />
          <button disabled={this.state.betchipincrementbtn}>
            <img src="" id="betincrenetTP" onClick={(e) => { e.preventDefault(); this.IncreamentBetchips(); }} alt="" />
          </button>
          <button disabled={this.state.betdecreamentbtn}>

            <img src="" id="betdecrementTP"
              onClick={(e) => { e.preventDefault(); this.DecreamentBetchips(); }} alt="" />
          </button>
          <img src="" id="betchipsTP" alt="" />
          {this.chipVal.map((obj, i) => (
            <span key={i} className={`valtext${i}`} id={`val${i}`}></span>
          ))}

          <img src="" ref={this.chipref} id={`betchipsaddtoanteTP${this.state.idincrement}`} alt="" style={this.state.betchipstoantebetcss} />
          <img src="" id="betchipsaddtobonusTP" ref={this.chipbnsref} alt="" style={this.state.betchipstobonusbetcss} />
          <img src="" id="betchipsaddtoantecclickTP" ref={this.chiprefee} alt="" />



          <div ref={this.chipdrawref} id="betchipsaddtodrawContainerTP" className={this.state.buydrawcontainer}>
            <img src="" id="betchipsaddtodrawTP" alt="" className={this.state.drawchipcss} />
            <span id="betchipsaddtodrawTextTP" className={this.state.drawchiptextcss}></span>
          </div>
          <div >
            <img src="" id="betchipsaddtconfrmTP" alt="" />
          </div>

          <span id="betTextaddtodrawTP"></span>

          <img src="" id="betchipsaddtobetTP" ref={this.chipbetref} alt="" style={this.state.betchiptobetbtncss} />
          <span id="betTextaddtoBetTP" className={this.state.betbtntxtcss}></span>
          <div id="delaerchipanimTP">
            <img src="" id="dealerchipTP" alt="" />
          </div>
          <img src="" id="playerwinchip" alt="" />
          <img src="" id="useridTP" alt="" />
          <span id="useridtextTP">{this.props.user}</span>
          <img src="" id="Tropicalbgwindows" onClick={(e) => { e.preventDefault(); this.hidepopup_betshigh() }}

          // onMouseLeave={(e) => {
          //   e.preventDefault();
          //   this.hidepopup_betshigh();
          // }}
          // onMouseMove={(e) => {
          //   e.preventDefault();
          //   this.hidepopup_betshigh();
          // }}


          />
          {/* <h1 id="paytable">PAY TABLE</h1> */}

          <img src={this.showTableImage()} id="tpntblewindows" alt="" />

          <div style={{ display: 'none' }}>
            <h1 id="royalflush">
              Royal Flush-100 <span id="royaltoTP">to</span> 1
            </h1>
            <h1 id="Straightflush">
              Straight Flush-50 <span id="strghttoTP">to</span> 1
            </h1>
            <h1 id="frofkind">
              Four of a kind-20 <span id="frkndtoTP">to</span> 1
            </h1>
            <h1 id="fullhse">
              Full House-7 <span id="fullhsetoTP">to</span> 1
            </h1>
            <h1 id="flush">
              Flush-5 <span id="flushtoTP">to</span> 1
            </h1>
            <h1 id="stright">
              Straight-4 <span id="strighttoTP">to</span> 1
            </h1>
            <h1 id="Threekind">
              Three Of a Kind-3 <span id="ThreekindtoTP">to</span> 1
            </h1>
            <h1 id="twopair">
              Two Pair-2 <span id="twopairtoTP">to</span> 1
            </h1>
            <h1 id="onepair">
              One Pair-1 <span id="onepairtoTP">to</span> 1
            </h1>
            <h1 id="aceking">
              Ace-King-1 <span id="acekingtoTP">to</span> 1
            </h1>

          </div>


          <div id="landscapeInfoTP">
            <span id="infoTroppicalland" className={this.state.inforussiancss}>{this.state.infoText}</span>
          </div>


          <span id="drawtextTP" className={this.state.drawbtntxtcss}></span>
          <span id="betbtntextTP" className={this.state.betbtntextcss}></span>
          <span id="foldtextTP" className={this.state.foldbtntextcss}></span>
          <span id="pokerinfotext"></span>
          <span id="bet_val"></span>
          <div id="topcardscontainerTP"></div>
        </div>

        {(this.state.isMobilePortraitLndsp === false) && (
          <div id="chipsstoredcontainerTP" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
            {this.state.storebetchips}
          </div>

        )}


        {this.renderTheBonusForView()}


        <div id="infocontainerTP">
          <span id="infoTropicalP" className={this.state.inforussiancss}>{this.state.infoText}</span>
        </div>


        <div id="rankinginfoTP">
          {pokerranking ? pokerranking : ""}
        </div>


        <div id="TropicaPokerDeck">
          <img id="tropicaldeck" src="" />
        </div>
        <div>
          <span id="rankinginfodelerTP">{this.state.pokerrankingdeler}</span>
        </div>

        <div id="anitecircleactiveTP"></div>
        {this.state.isoptionmenu ? (
          <div id="optionsmenubuttonTP">
            {/* <div id="optiontopmenuTP">
              <span id="optiontextTP">OPTIONS</span>
            </div> */}
            <div id="buttontscontainerTP">
              <button id="limits" onClick={(e) => {
                e.preventDefault();
                this.ShowLimits();
              }}>LIMITS</button>
              <button id="help" onClick={(e) => {
                e.preventDefault();
                this.Gamerules();
              }}>HELP</button>
              <button id="history"
                onClick={(e) => {
                  e.preventDefault();
                  this.ShowHistory()
                }}>HISTORY</button>
              {/* <button id="arrow">
                <img
                  // src={exitrussian}
                  src={popupExit}
                  id="exitmenuTP"
                  onClick={(e) => {
                    e.preventDefault();
                    this.hideshowoptionmenu();
                  }} /></button> */}

            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.ShowLimits ? (
          <div id="Limits">
            <span>MinBet : {this.props.minBetAmt}</span>
            <span>MaxBet : {this.props.maxBetAmt}</span>
            {/* <button id="exitLimits" onClick={(e) => {
              e.preventDefault();
              this.exitLimits()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : ""}



        {this.state.ShowGamerules ? (
          <div id="ShowGamerulesTP">
            <h1 id="gameruleMainHeadingTP">GAME RULES</h1>
            <ul id="unorderedlistitemsTP">
              {/* <hr id="hrline" /> */}
              {this.GameRules.map((val, key) => (
                <li>{val.name}</li>
              ))}
            </ul>
            {/* <button id="exitLimits" onClick={(e) => {
      e.preventDefault();
      this.exitLimits();
    }}
    // onMouseOver={() => this.animexit()}
    >EXIT</button> */}
          </div>

        ) : ""}

        {this.state.ShowHistory ? (
          <div id="prevhistoryTP">
            {/* <div id="prevhistoryTP" className="historyscaleTP"> */}
            <table id="histry_tropical">
              <thead id="heading_hstiryTP">
                <tr>
                  <th>Hand Id</th>
                  <th>Dealer Cards</th>
                  <th>Dealer Hand</th>
                  <th>Player Cards</th>
                  <th>Player Hand</th>
                  <th>Ante BetAmount</th>
                  <th>Ante WinAmount</th>
                  <th>Bonus BetAmount</th>
                  <th>Bonus WinAmount</th>
                  <th>Bet Amount</th>
                  <th>Bet WinAmount</th>
                  <th>Total Bet</th>
                  <th>Total Win</th>
                </tr>
              </thead>

              <tbody id="histry_bodyTP">
                {this.props.gameState.history.map((obj, i) => (
                  <tr key={`card-${i}`} id={`${i}`}>
                    <td id="dealercards_hstryTP">{obj.result.handId}</td>
                    <td id="dealercards_hstryTP">
                      {console.log(obj.result.dealerFinalCards)}
                      {obj.result.dealerFinalCards.map((card_num, index) => {
                        const matchingCard = resultCards.find(res => res.rsc_val === card_num);
                        return (
                          <img
                            className="cardsimagesTP"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />


                        );
                      })}
                    </td>
                    <td id="dealerhand_hstryTP">{obj.result.dealerHand}</td>
                    <td id="playercards_hstryTP">
                      {obj.result.playerFinalCards.map((card_num, index) => {
                        const matchingCard = resultCards.find(res => res.rsc_val === card_num);
                        return (
                          <img
                            className="cardsimagesTP"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />
                        );
                      })}
                    </td>
                    <td id="playerhand_hstry">{obj.result.playerHand}</td>
                    <td id="totalbet_histry">{obj.result.anteBetAmount ? obj.result.anteBetAmount : 0}</td>
                    <td id="totalwin_hstry">{obj.result.anteWinAmount ? obj.result.anteWinAmount : 0}</td>
                    <td id="playerhand_hstry">{obj.result.bonusBetAmount ? obj.result.bonusBetAmount : 0}</td>
                    <td id="totalbet_histry">{obj.result.bonusWinAmount ? obj.result.bonusWinAmount : 0}</td>
                    <td id="totalwin_hstry">{obj.result.betAmount ? obj.result.betAmount : 0}</td>
                    <td id="playerhand_hstry">{obj.result.winAmount ? obj.result.winAmount : 0}</td>
                    <td id="playerhand_hstry">{obj.result.totalBetAmount ? obj.result.totalBetAmount : 0}</td>
                    <td id="totalbet_histry">{obj.result.totalWinAmount ? obj.result.totalWinAmount : 0}</td>

                  </tr>
                ))}
              </tbody>
            </table>
            {/* <button id="exithistoryTP" onClick={(e) => {
              e.preventDefault();
              this.exithistoryTP()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button> */}
          </div>
        ) : null}

        <div id="Tropicalgamecover">
        </div>
        {/* <div id="waviy">
            {Title.map((im, k) => (
              <img style={{ '--i': k + 1 }} src={im.src} />
            ))}
          </div> */}
        <div id="crdscoverTP"></div>
        <div id="buttons_coverTP"></div>
        <div id="glowonwinchipTP"></div>


        <div id="winpopup_TP">
          <span id="winamt_TPS">{popup_winamount ? popup_winamount : ""}</span>
        </div>

        <div id="minmaxbetsTP">
          <span>Min : {this.props.minBetAmt}</span>
          <span>Max : {this.props.maxBetAmt}</span>
        </div>

        <div id="popupbet_greaterthanbalnceTP">
          {(this.state.betshigh_balnce !== null) &&
            <button className="exit_popupTP" id="exitPopup" onClick={this.hidepopup_betshigh}><img src={popupExit} alt={popupExit} /></button>
          }
          <span id="textbets_highTP">
            <h4 id="attentionTP">Attention!</h4>
            {this.state.lowBalaceErrorInfo}
          </span>

        </div>

        <div id="confirmbuydealer_popupTP" className="confirmbuydealerPopupcss">
          <span id="alertTxtTP" ></span>
          <button id="yesTP" onClick={(e) => {

            if (this.state.cmfrmdealeraction === 1) {
            }
            else {
              this.confirmFoldactin(true);

            }

          }}>YES</button>
          <button id="noTP" ref={this.no}
            onClick={(e) => {
              if (this.state.cmfrmdealeractionNO === 1) {
              }
              else {
                this.confirmFoldactin(false);
              }
            }}

          >NO</button>
        </div>

        <div>
          {this.state.PayoutTableToggle ? (
            <div id="PAYOUTTABLETPS">
              <div className="dis_flex_evn">
                <button className="bet_bonusBTN" type="button" id="BetPayoutBtn" onClick={(e) => {
                  e.preventDefault(); this.payoutChangebtn('BetPayout')
                }}>BET</button>

                <button className="bet_bonusBTN" type="button" id="BonusPayoutBtn" onClick={(e) => {
                  e.preventDefault(); this.payoutChangebtn('BonusPayout')
                }}>BONUS</button>
              </div>
              <table id="Payout_TPS" >
                <thead id="heading_payout_TPS">
                  <tr>
                    <th className="showpayoutwndsThead">PLAYER HAND</th>
                    <th className="showpayoutwndsThead">AMOUNT</th>
                  </tr>
                </thead>

                <tbody id="payout_bodyTPS">
                  {/* {this.props.TP_payouts.map((obj, i) => ( */}
                  {this.state.PayouyBetBounusData.map((obj, i) => {
                    return (
                      <tr key={`card-${i}`} id={`${i}`}>
                        <td id="playerhandTPS">
                          {obj.key}
                        </td>
                        <td id="playerwinamountTPS">{obj.value}</td>
                      </tr>
                    )
                  })}
                </tbody>

              </table>

            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(TropicalPoker)
