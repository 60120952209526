//  ========================================================================= orginal =====================================
import { Emitter } from "./eventEmiter";


export default class servicConnect {
    constructor(props) {
        this.ConnectionEstd = "onConnectionEstablished";
        this.OnReceivingData = "OnReceivingData";
        this.ConnectionClosed = "onConnectionClosed";
        this.ReceiveNoConnection = "ReceiveNoConnection";
        this.webSocketClosed = "webSocketClosed";
    }
    connection() {
        // this._connection = new WebSocket('wss://indicasino.czargaming.com/ws');
        let myGameId = sessionStorage.getItem("gameId");
        console.log(myGameId);
        let mySiteId = sessionStorage.getItem("siteId");
        console.log(mySiteId)
        let myLang = sessionStorage.getItem("lang");

        if(myLang == null){
            myLang = "en";
        }
        
        let myPlayerToken = sessionStorage.getItem("playerToken");
        // let myPlayerToken = "a1b2";

        // let sendsite="apnakhel"
      //  let sendsite="rrrcasino";
         let sendsite="luckhunter";

         

        // this._connection = new WebSocket(`wss://gearupcasino.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
        this._connection = new WebSocket(`wss://ballagames.com/ws/games?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); 

        // this._connection = new WebSocket(`wss://gearupcasino.undertesting.com/ws/games?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);  //Stagings
        // this._connection = new WebSocket(`http://devp4m.czargaming.com/guc/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);  //indicasino staging
        // this._connection = new WebSocket(`wss://devp4m.czargaming.com/guc/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); //Dev
      
        // this._connection = new WebSocket(`ws://10.10.20.166:8987/ws/games?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); // sowmya 

        this._connection.addEventListener('open', this.connectEventHandler.bind(this));
        this._connection.addEventListener('close', this.closeEventHandler.bind(this));
        this._connection.addEventListener('error', this.errorEventHandler.bind(this));
        this._connection.addEventListener('message', this.dataEventHandler.bind(this));
    }    


    connectEventHandler(event) {
        Emitter.emit(this.ConnectionEstd, event);
    }

    closeEventHandler(event) {
        Emitter.emit(this.webSocketClosed, event);
        console.log(event);
    }
    errorEventHandler(event) {
        Emitter.emit(this.ReceiveNoConnection, event);
    }

    dataEventHandler(event) {
        let data = JSON.parse(event['data']);
        Emitter.emit(this.OnReceivingData, data);
        console.log("Respose from server: ");
        console.log(data);
    }
    sendof(body) {
        let data = JSON.stringify(body);
       
        console.log("Request from Client: ")
        console.log(data);
        // console.log(this._connection.readyState);
        //console.log(this._connection);
        // if(this._connection.readyState === WebSocket.CLOSED || this._connection.readyState == 3 || this._connection.readyState == 0){
        //     console.log( 'WebSocket connection closed' )

        // } 
        if (this._connection.readyState == 1) {
            this._connection.send(data);
        } else {
            console.log('WebSocket connection closed')
        }
    }

}

// ========================================================================= original ========================================




// import { Emitter } from "./eventEmiter";

// import AllGamesres39 from "../../../assets/Allgamejson39.json"


// export default class servicConnect {
//     constructor(props) {
//         this.ConnectionEstd = "onConnectionEstablished";
//         this.OnReceivingData = "OnReceivingData";
//         this.ConnectionClosed = "onConnectionClosed";
//         this.ReceiveNoConnection = "ReceiveNoConnection";
//         this.webSocketClosed = "webSocketClosed";

//         this.AllGameResJson = AllGamesres39
//         this.GameID = '';
//         this.PlayerTokenDM = '';
//         this.SiteIdDM = '';
//     }
//     connection() {
//         // this._connection = new WebSocket('wss://indicasino.czargaming.com/ws');
//         let myGameId = sessionStorage.getItem("gameId");
//         console.log(myGameId);
//         let mySiteId = sessionStorage.getItem("siteId");
//         console.log(mySiteId)
//         let myLang = sessionStorage.getItem("lang");

//         if(myLang == null){
//             myLang = "en";
//         }
        
//         let myPlayerToken = sessionStorage.getItem("playerToken");
//         // let myPlayerToken = "a1b2";


//         // -----------------------------------------------------------------------------------------------------

//         this.GameID = myGameId
//         this.SiteIdDM = mySiteId
//         this.PlayerTokenDM = myPlayerToken
//         // -----------------------------------------------------------------------------------------------------

//         // let sendsite="apnakhel"
//       //  let sendsite="rrrcasino";
//         let sendsite="luckhunter";

//         // this._connection = new WebSocket(`wss://gearupcasino.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
//         // this._connection = new WebSocket(`wss://ballagames.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
//     //    this._connection = new WebSocket(`wss://gearupcasino.undertesting.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);  //Stagings
//         // this._connection = new WebSocket(`http://devp4m.czargaming.com/guc/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);  //indicasino staging
//         // this._connection = new WebSocket(`wss://devp4m.czargaming.com/guc/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); //Dev
//         //  this._connection = new WebSocket(`ws://10.10.20.60:8987/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${sendsite}&lang=${myLang}`); // Three of Kind 
//         // this._connection = new WebSocket(`ws://10.10.20.22:8985/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); // sowmya
//         // this._connection = new WebSocket(`ws://10.10.20.54:8969/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); // arun
//         // this._connection = new WebSocket('ws://10.10.20.72:8989/ws?gameId=19&playerToken=nmmn&site=mcasino');
//         // this._connection = new WebSocket(`ws://10.10.20.60:8989/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
//         // this._connection = new WebSocket('ws://10.10.20.201:8084/ws');
//         // this._connection = new WebSocket('ws://10.10.20.72:8989/ws');

//         // ----------------------- For staging connection -----------------------------
//         // this._connection.addEventListener('open', this.connectEventHandler.bind(this));
//         // this._connection.addEventListener('close', this.closeEventHandler.bind(this));
//         // this._connection.addEventListener('error', this.errorEventHandler.bind(this));
//         // this._connection.addEventListener('message', this.dataEventHandler.bind(this));
//         // ----------------------- For staging connection -----------------------------



//         //  -------------------------- For Dummy Local Coneection ---------------------
//         this.DummyWebsocketConnection()
//         //  -------------------------- For Dummy Local Coneection ---------------------
//     }


//     connectEventHandler(event) {
//         Emitter.emit(this.ConnectionEstd, event);
//     }

//     closeEventHandler(event) {
//         Emitter.emit(this.webSocketClosed, event);
//         console.log(event);
//     }
//     errorEventHandler(event) {
//         Emitter.emit(this.ReceiveNoConnection, event);
//     }

//     dataEventHandler(event) {
//         let data = JSON.parse(event['data']);
//         Emitter.emit(this.OnReceivingData, data);
//         console.log("Respose from server: ");
//         console.log(data);
//     }
//     sendof(body) {
//         let data = JSON.stringify(body);
       
//         console.log("Request from Client: ")
//         console.log(data);
//         // console.log(this._connection.readyState);
//         //console.log(this._connection);
//         // if(this._connection.readyState === WebSocket.CLOSED || this._connection.readyState == 3 || this._connection.readyState == 0){
//         //     console.log( 'WebSocket connection closed' )

//         // } 


//         //  -----------------------------------For staging connection ----------------------------------
//         // if (this._connection.readyState == 1) {
//         //     this._connection.send(data);
//         // } else {
//         //     console.log('WebSocket connection closed')
//         // }
//         //  -----------------------------------For staging connection ----------------------------------






//         //  ----------------------------------For Dummy Local Coneection ----------------------------------
//         this.onMessage(data)
//         //  ----------------------------------For Dummy Local Coneection ----------------------------------


//     }

//     // ---------------------------- Dummy connections -----------------------------------

    
//     DummyWebsocketConnection() {

//         this._connection = {
//             eventListeners: {},

//             addEventListener: function (event, callback) {
//                 console.log(`Adding event listener for: ${event}`);
//                 this.eventListeners[event] = callback;
//             },

//             simulateOpen: function () {
//                 this.GameID = sessionStorage.getItem("gameId");
//                     this.SiteIdDM = sessionStorage.getItem("siteId");
//                     this.PlayerTokenDM = sessionStorage.getItem("playerToken");
//                 setTimeout(() => {
//                     console.log(" Simulated WebSocket Opened");
//                     if (this.eventListeners["open"]) {
//                         this.eventListeners["open"]({
//                             type: "open", currentTarget: {
//                                 url: `wss://gearupcasino.undertesting.com/ws?gameId=${this.GameID}&playerToken=${this.PlayerTokenDM}&site=${this.SiteIdDM}&lang=en`
//                             }
//                         });
//                     }
//                 }, 1000);
//             },

//             simulateMessage: function () {
//                 setTimeout(() => {
//                     console.log(" Simulated Message Received");

//                     const simulatedMessage = {
//                         status: 200,
//                         msg: "success",
//                         data: {
//                             sessionId: "9ec711cd03dc485cb34cfaed22050af6",
//                             playerId: 142433122
//                         }
//                     };

//                     if (this.eventListeners["message"]) {
//                         this.eventListeners["message"]({
//                             type: "message",
//                             data: JSON.stringify(simulatedMessage)
//                         });
//                     }
//                 }, 2000);
//             }
//         };

//         // Add listeners

//         this._connection.addEventListener("open", (event) => {
//             console.log(" WebSocket Opened:", event);
//         });
//         this._connection.addEventListener("message", (event) => {
//             console.log("📩 WebSocket Message Received:", event);
//         });

//         this._connection.addEventListener('open', this.connectEventHandler.bind(this));
//         this._connection.addEventListener('message', this.dataEventHandler.bind(this));

//         // Manually trigger events
//         this._connection.simulateOpen();
//         this._connection.simulateMessage();

//     }

//     onMessage(data) {
//         try {
//             const request = JSON.parse(data)
//             console.log("Received Request:", request);
//             this.generateResponse(request);
//         } catch (error) {
//             console.error("Error processing message:", error);
//         }
//     }

//     generateResponse(request) {
//         let ResponseData = []; // Use `let` instead of `const`

//         console.log("Processing Request:", request);
//         console.log("CN:", request.CN);

//         if (request.CN && request.object?.action) {
//             console.log("Fetching Response for Action:", request.object.action);

//             ResponseData = this.AllGameResJson[this.GameID][request.object.action] || { error: "Action not found" };
//         } else {
//             console.log("Fetching Response for CN:", request.CN);

//             ResponseData = this.AllGameResJson[this.GameID][request.CN] || { error: "CN not found" };
//         }

//         console.log("Emitting Response:", ResponseData);
//         Emitter.emit(this.OnReceivingData, ResponseData);
//     }

//     // ---------------------------- Dummy connections -----------------------------------

// }


