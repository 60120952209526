import React, { Component } from "react";
import { Group } from "react-konva";
// import { Card } from "./card";
import { Card } from "./Carebbeancard";
import { Howl } from "howler";
// import CardDefault from "../assests/Images/cards/cards1-(1)-(1).png";
import CardDefault from "../../../../../../assets/games/CarebbeanPoker/rp_sprite_card.png"
import cardsdropping_snd from "../../../../../../assets/sounds/carddroping.mp3";
export default class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.width = window.innerWidth * 0.5;
        this.state = {
            x: this.width - 26,
            y: 50,
            cardCount: 2,
            isFront: true,
            cardZeroAlpha: 1,
            cardOneAlpha: 1,
            cardTwoAlpha: 1,
            cardThreeAlpha: 1,
            cardFourAlpha: 1,
            cardFiveAlpha: 1,
            playerCardZero: "TC",
            playerCardOne: "TS",
            playerCardTwo: "TD",
            playerCardThree: "TC",
            playerCardFour: "TC",
            playerCardFive: "TC",
            cardStyle: CardDefault,
            cardsPropsWhenDropSixCard: [],
            drawSixthCard: false,
            cardsXYAProps: [],
            cardDropSound: null,
            isCardDropSound: false,
            // cardBackStyle: [689, 0, 53, 73],
        };
        this.backCard = "default";
        this.countcards = 2;
        this.drawCardsArray = []

        this.mapCards = {
            "AC": [0, 0, 53, 73],
            "2C": [53, 0, 53, 73],
            "3C": [106, 0, 53, 73],
            "4C": [159, 0, 53, 73],
            "5C": [212, 0, 53, 73],
            "6C": [265, 0, 53, 73],
            "7C": [318, 0, 53, 73],
            "8C": [371, 0, 53, 73],
            "9C": [424, 0, 53, 73],
            "10C": [477, 0, 53, 73],
            "JC": [530, 0, 53, 73],
            "QC": [583, 0, 53, 73],
            "KC": [636, 0, 53, 73],

            "AD": [0, 73, 53, 73],
            "2D": [53, 73, 53, 73],
            "3D": [106, 73, 53, 73],
            "4D": [159, 73, 53, 73],
            "5D": [212, 73, 53, 73],
            "6D": [265, 73, 53, 73],
            "7D": [318, 73, 53, 73],
            "8D": [371, 73, 53, 73],
            "9D": [424, 73, 53, 73],
            "10D": [477, 73, 53, 73],
            "JD": [530, 73, 53, 73],
            "QD": [583, 73, 53, 73],
            "KD": [636, 73, 53, 73],

            "AH": [0, 146, 53, 73],
            "2H": [53, 146, 53, 73],
            "3H": [106, 146, 53, 73],
            "4H": [159, 146, 53, 73],
            "5H": [212, 146, 53, 73],
            "6H": [265, 146, 53, 73],
            "7H": [318, 146, 53, 73],
            "8H": [371, 146, 53, 73],
            "9H": [424, 146, 53, 73],
            "10H": [477, 146, 53, 73],
            "JH": [530, 146, 53, 73],
            "QH": [583, 146, 53, 73],
            "KH": [636, 146, 53, 73],

            "AS": [0, 219, 53, 73],
            "2S": [53, 219, 53, 73],
            "3S": [106, 219, 53, 73],
            "4S": [159, 219, 53, 73],
            "5S": [212, 219, 53, 73],
            "6S": [265, 219, 53, 73],
            "7S": [318, 219, 53, 73],
            "8S": [371, 219, 53, 73],
            "9S": [424, 219, 53, 73],
            "10S": [477, 219, 53, 73],
            "JS": [530, 219, 53, 73],
            "QS": [583, 219, 53, 73],
            "KS": [636, 219, 53, 73],
            "xx": [689, 0, 53, 73],
            "xx1": [689, 73, 53, 73],
            "xx2": [742, 0, 53, 73],
            "xx3": [689, 146, 53, 73],
            "xx4": [689, 219, 53, 73],
        };
        this.cardsArray = [];
        this.playerCards = [];
        this.tableCards = [];
        this.CardStore = [];
        this.hiddencards = [false, false, false, false, false, false];
        this.moveCardFunction = false;
        


        try {
            // this.cardSound = new Audio(cardSound);
        } catch (e) {
            console.log(e);
        }
        this.cardZeroRef = React.createRef();
        this.cardOneRef = React.createRef();
        this.cardTwoRef = React.createRef();
        this.cardThreeRef = React.createRef();
        this.cardFourRef = React.createRef();
        this.cardFiveRef = React.createRef();
        this.groupCards = React.createRef();
        this.handleResize = this.handleResize.bind(this);

        // console.log(props.previousGamestateCardLength6)
        // console.log(props.previousGamestateCardLength6.response.playerFinalCards.length === 6)
        if(props.previousGamestateCardLength6){
            if(props.previousGamestateCardLength6.response){
                if((props.previousGamestateCardLength6.response.action ==="BUY_PLAYER_CARD" || 
                    props.previousGamestateCardLength6.response.playerFinalCards.length === 6)&&
                    props.previousGamestateCardLength6.response.action !=="CONFIRM_FOLD"
                ){
                    this.moveCardFunction = true;
                    this.handleResize()
                }else{
                    this.moveCardFunction = false;
                }

            }

        }
       
    }


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.carddropsound = this.initializeCardDropSound();



    }


    initializeCardDropSound = () => {
        let cardDropSound = new Howl({
            src: [cardsdropping_snd],
            html5: true, // Enable HTML5 Audio for mobile compatibility
        });
        this.setState({
            cardDropSound,
            isCardDropSound: false
        })
        return cardDropSound

    }

    playPokerCardDropSound = (chipSound) => {
        const { isCardDropSound } = this.state;
        const { mutesounds } = this.props
        let currentSound = chipSound;
        if (!mutesounds) {

            if (isCardDropSound) {
                chipSound.stop();
            }
            if (!chipSound) {
                currentSound = this.initializeCardDropSound();
            }

            currentSound.play();
            this.setState({
                isCardDropSound: true
            })
        }

    };

    handleResize() {
        if (
            (navigator.userAgentData &&
              navigator.userAgentData.platform &&
              navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)
          ) {
            if (this.moveCardFunction == true) {

                console.log("-------->----->------->   windows after moving")
                this.setState({
                    cardsXYAProps: [
                        { x: this.props.x - 80, y: this.props.y - 6, angle: 333 },
                        { x: this.props.x - 30, y: this.props.y - 30, angle: 343 },
                        { x: this.props.x + 20, y: this.props.y - 45, angle: 353 },
                        { x: this.props.x + 80, y: this.props.y - 53, angle: 5 },
                        { x: this.props.x + 135, y: this.props.y - 51, angle: 15 },
                        { x: this.props.x + 185, y: this.props.y - 42, angle: 25 },

                    ]
                })

            } else {
                console.log("-------->----->------->windows before moving")
                this.setState({
                    cardsXYAProps: [
                        { x: this.props.x - 600, y: this.props.y -20},
                        { x: this.props.x - 500, y: this.props.y -20},
                        { x: this.props.x -400, y: this.props.y - 20},
                        { x: this.props.x -300, y: this.props.y - 20},
                        { x: this.props.x -200, y: this.props.y - 20 },
                        { x: this.props.x -100, y: this.props.y - 20},
                    ]
                })

            }



        } else {
            // if (window.innerWidth > window.innerHeight) {
            //     if (this.moveCardFunction == true) {
            //         console.log("-------->----->------->mobile  landScape after moving")
            //         this.setState({
            //             cardsXYAProps: [
            //                 { x: this.props.x - 80, y: this.props.y - 6, angle: 333 },
            //                 { x: this.props.x - 30, y: this.props.y - 30, angle: 343 },
            //                 { x: this.props.x + 20, y: this.props.y - 45, angle: 353 },
            //                 { x: this.props.x + 80, y: this.props.y - 53, angle: 5 },
            //                 { x: this.props.x + 135, y: this.props.y - 51, angle: 15 },
            //                 { x: this.props.x + 185, y: this.props.y - 42, angle: 25 },

            //             ]
            //         })

            //     } else {
            //         console.log("-------->----->-------> mobile landScape before moving")
            //         this.setState({
            //             cardsXYAProps: [
            //                 { x: this.props.x - 60, y: this.props.y - 10, angle: 335 },
            //                 { x: this.props.x - 10, y: this.props.y - 30, angle: 345 },
            //                 { x: this.props.x + 40, y: this.props.y - 45, angle: 355 },
            //                 { x: this.props.x + 100, y: this.props.y - 50, angle: 8 },
            //                 { x: this.props.x + 150, y: this.props.y - 45, angle: 17 },
            //                 { x: this.props.x + 185, y: this.props.y - 42, angle: 25 },
            //             ]
            //         })

            //     }

            // } else {


                if (this.moveCardFunction == true) {
                    console.log("-------->----->------->mobile  portrait after moving")
                    this.setState({
                        cardsXYAProps: [
                            { x: this.props.x - (298), y: this.props.y - (10), angle: 330 },
                            { x: this.props.x - (212), y: this.props.y - (60), angle: 345 },
                            { x: this.props.x - (130), y: this.props.y - (85), angle: 355 },
                            { x: this.props.x - 50, y: this.props.y - (95), angle: 5 },
                            { x: this.props.x + (30), y: this.props.y - (90), angle: 15 },
                            { x: this.props.x + (105), y: this.props.y - 73, angle: 25 },
                        ]
                    })

                } else {
                    console.log("-------->----->------->mobile  portrait before moving")
                    this.setState({
                        cardsXYAProps: [
                            { x: this.props.x - (245), y: this.props.y - (40) },
                            { x: this.props.x - (160), y: this.props.y - (40)},
                            { x: this.props.x - (80), y: this.props.y - (40)},
                            // { x: this.props.x + 10, y: this.props.y - (90), angle: 8 },
                            { x: this.props.x + 6, y: this.props.y - (40) },
                            // { x: this.props.x + (95), y: this.props.y - (80), angle: 20 },
                            { x: this.props.x + (92), y: this.props.y - (40) },
                            { x: this.props.x + (185), y: this.props.y - (73) },
                        ]
                    })

                }

            // }
        }




    }





    initSounds() {
        try {
            // this.cardSound = new Audio(cardSound);
        } catch (e) {
            console.log(e);
        }
    }
    // setCardStyle(style) {
    //     switch (style.frontCard) {
    //         case "default":
    //             this.setState({ cardStyle: CardDefault });
    //             break;
    //         case "frontCard1":
    //             this.setState({ cardStyle: CardStyleOne });
    //             break;
    //         case "frontCard2":
    //             this.setState({ cardStyle: CardStyleTwo });
    //             break;
    //         case "frontCard3":
    //             this.setState({ cardStyle: CardStyleThree });
    //             break;
    //         case "frontCard4":
    //             this.setState({ cardStyle: CardStyleFour });
    //             break;
    //         default:
    //             this.setState({ cardStyle: CardDefault });
    //             break;
    //     }

    //     this.backCard = style.backCard;
    // }
    ReBet() {
        this.drawCardsArray = [];
        this.cardsArray = [];
        this.CardStore = [];
        if(this.cardZeroRef.current){
            this.cardZeroRef.current.endGame()
        }
        
        this.moveCardFunction = false;
        this.hiddencards = [false, false, false, false, false, false];

        this.setState({

            x: this.width - 26,
            y: 50,
            cardCount: 2,
            isFront: true,
            cardZeroAlpha: 1,
            cardOneAlpha: 1,
            cardTwoAlpha: 1,
            cardThreeAlpha: 1,
            cardFourAlpha: 1,
            cardFiveAlpha: 1,
            playerCardZero: "TC",
            playerCardOne: "TS",
            playerCardTwo: "TD",
            playerCardThree: "TC",
            playerCardFour: "TC",
            playerCardFive: "TC",
            cardStyle: CardDefault,
            cardsPropsWhenDropSixCard: [],
            drawSixthCard: false,
            // cardsXYAProps: []

        });

        this.handleResize();
       


    }


    showPreviousPlayercards = (card, text, cnt) =>{
        this.moveCardFunction = false;
        console.log("this.props.text", text, "this.mapCards[text] :", this.mapCards[text]);
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
            this.countcards = Number(cnt)
        }
        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    this.hiddencards[0] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.showPreviousPlayercards();
                            } catch (e) { 
                                console.log(e);
                             }
                        });
                    }, 1);
                    break;
                case "cardOne":
                    this.hiddencards[1] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.showPreviousPlayercards();
                        });
                    }, 1);
                    break;
                case "cardTwo":
                    this.hiddencards[2] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.showPreviousPlayercards();
                        });
                    }, 1);

                    break;
                case "cardThree":
                    this.hiddencards[3] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.showPreviousPlayercards();
                    }, 1);

                    break;
                case "cardFour":
                    this.hiddencards[4] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.showPreviousPlayercards();
                    }, 1);

                    break;
                case "cardFive":
                    this.hiddencards[5] = true;
                    this.moveCardFunction = true;
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        // var data = { x: this.props.x + 185, y: this.props.y - 42, angle: 25 }
                        var data = this.state.cardsXYAProps[5]
                        this.setState((prevstate) => ({
                            cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                        }));
                        this.cardFiveRef.current?.showPreviousPlayercards();
                    }, 1);

                    break;
                default:
                    break;
            }
        }



    }

    addCards(card, text, cnt) {
        this.moveCardFunction = false;
        console.log("this.props.text", text, "this.mapCards[text] :", this.mapCards[text]);
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
            this.countcards = Number(cnt)
        }

        // if (text === "xx") {
        //     switch (this.backCard) {
        //         case "default":
        //             text = "xx";
        //             break;
        //         case "backcard1":
        //             text = "xx1";
        //             break;
        //         case "backcard2":
        //             text = "xx2";
        //             break;
        //         case "backcard3":
        //             text = "xx3";
        //             break;
        //         case "backcard4":
        //             text = "xx4";
        //             break;
        //         default:
        //             break;
        //     }
        // }
        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    this.hiddencards[0] = true
                    this.clearCards = setTimeout(() => {
                        this.playPokerCardDropSound(this.carddropsound);
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.animateCard();
                            } catch (e) { 
                                console.log(e);
                             }
                        });
                    }, 250);
                    break;
                case "cardOne":
                    this.hiddencards[1] = true
                    this.clearCards = setTimeout(() => {
                        this.playPokerCardDropSound(this.carddropsound);
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.animateCard();
                        });
                    }, 500);
                    break;
                case "cardTwo":
                    this.hiddencards[2] = true
                    this.clearCards = setTimeout(() => {
                        this.playPokerCardDropSound(this.carddropsound);
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.animateCard();
                        });
                    }, 750);

                    break;
                case "cardThree":
                    this.hiddencards[3] = true
                    this.clearCards = setTimeout(() => {
                        this.playPokerCardDropSound(this.carddropsound);
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.animateCard();
                    }, 1000);

                    break;
                case "cardFour":
                    this.hiddencards[4] = true
                    this.clearCards = setTimeout(() => {
                        this.playPokerCardDropSound(this.carddropsound);
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.animateCard();
                    }, 1250);

                    break;
                case "cardFive":
                    this.hiddencards[5] = true;
                    this.moveCardFunction = true;
                    this.clearCards = setTimeout(() => {
                        this.playPokerCardDropSound(this.carddropsound);
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        // var data = { x: this.props.x + 185, y: this.props.y - 42, angle: 25 }
                        var data = this.state.cardsXYAProps[5]
                        this.setState((prevstate) => ({
                            cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                        }));
                        this.cardFiveRef.current?.animateCard();
                    }, 1500);

                    break;
                default:
                    break;
            }
        }
    }


    SuffleCard(card, text, cnt) {
        console.log(this.CardStore)
        console.log(text)
        console.log("card", card, "text", text, "cnt", cnt)
        if (cnt !== undefined) {
            this.setState({
                cardCount: Number(cnt)
            });


        }
        if (cnt !== undefined && cnt === 6) {
            this.setState({
                drawSixthCard: true
            });


        }

        // if (text === "xx") {
        //     switch (this.backCard) {
        //         case "default":
        //             text = "xx";
        //             break;
        //         case "backcard1":
        //             text = "xx1";
        //             break;
        //         case "backcard2":
        //             text = "xx2";
        //             break;
        //         case "backcard3":
        //             text = "xx3";
        //             break;
        //         case "backcard4":
        //             text = "xx4";
        //             break;
        //         default:
        //             break;
        //     }
        // }

        if (cnt !== undefined) {
            const { cardsPropsWhenDropSixCard } = this.state;
            console.log('cardsPropsWhenDropSixCard', cardsPropsWhenDropSixCard)
            switch (card) {
                case "cardZero":
                    setTimeout(() => {
                      
                      
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {
                                for (let i = 0; i < this.CardStore.length; i++) {
                                    if (this.CardStore[i].text === text && i != 0) {
                                        this.cardZeroRef.current?.SuffleCard(this.CardStore[i], cnt, cardsPropsWhenDropSixCard[0], 0);
                                        break;
                                    }
                                }
                            } catch (e) { 
                                console.log(e); 
                            }
                        });
                    }, 150);
                    break;
                case "cardOne":
                    setTimeout(() => {
                       
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            for (let i = 0; i < this.CardStore.length; i++) {
                                if (this.CardStore[i].text === text && i != 1) {
                                    this.cardOneRef.current?.SuffleCard(this.CardStore[i], cnt, cardsPropsWhenDropSixCard[1], 1);
                                    break;
                                }
                            }
                        });
                    }, 300);
                    break;
                case "cardTwo":
                    setTimeout(() => {
                      
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            for (let i = 0; i < this.CardStore.length; i++) {
                                if (this.CardStore[i].text === text && i != 2) {
                                    this.cardTwoRef.current?.SuffleCard(this.CardStore[i], cnt, cardsPropsWhenDropSixCard[2], 2);
                                    break;
                                }
                            }
                        });
                    }, 450);

                    break;
                case "cardThree":
                    setTimeout(() => {
                       
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        for (let i = 0; i < this.CardStore.length; i++) {
                            if (this.CardStore[i].text === text && i != 3) {
                                this.cardThreeRef.current?.SuffleCard(this.CardStore[i], cnt, cardsPropsWhenDropSixCard[3], 3);
                                break;
                            }
                        }
                    }, 600);

                    break;
                case "cardFour":
                    setTimeout(() => {
                       
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        for (let i = 0; i < this.CardStore.length; i++) {
                            if (this.CardStore[i].text === text && i != 4) {
                                this.cardFourRef.current?.SuffleCard(this.CardStore[i], cnt, cardsPropsWhenDropSixCard[4], 4);
                                break;
                            }
                        }
                    }, 750);

                    break;
                case "cardFive":
                    setTimeout(() => {
                      
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        for (let i = 0; i < this.CardStore.length; i++) {
                            if (this.CardStore[i].text === text && i != 5) {
                                this.cardFiveRef.current?.SuffleCard(this.CardStore[i], cnt, cardsPropsWhenDropSixCard[5], 5);
                                break;

                            }
                        }
                    }, 900);

                    break;
                default:
                    break;
            }
        }



        // var temp=this.cardOneRef;
        // this.cardOneRef=this.cardTwoRef
        // this.cardTwoRef=temp
    }


    Drawcard(card, text, cnt) {

        // console.log(this.CardStore.indexOf(text))

        // console.log(this.groupCards.current.children)
        // var temp= this.groupCards.current.children[0]
        // this.groupCards.current.children[0]=this.groupCards.current.children[1]
        // this.groupCards.current.children[1]=temp;
        // let array=[2,3,1,0,4]
        // for(let i=0;i<5;i++){

        //     this.groupCards.current.children=this.groupCards.current.children[array[i]]
        // }
        console.log("card", card, "text", text, "cnt", cnt)
        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
        }

        // if (text === "xx") {
        //     switch (this.backCard) {
        //         case "default":
        //             text = "xx";
        //             break;
        //         case "backcard1":
        //             text = "xx1";
        //             break;
        //         case "backcard2":
        //             text = "xx2";
        //             break;
        //         case "backcard3":
        //             text = "xx3";
        //             break;
        //         case "backcard4":
        //             text = "xx4";
        //             break;
        //         default:
        //             break;
        //     }
        // }

        if (cnt !== undefined) {
            switch (card) {
                case "cardZero":
                    setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {

                                this.cardZeroRef.current?.DrawanimateCard();

                            } catch (e) { console.log(e);
                             }
                        });
                    }, 250);
                    break;
                case "cardOne":
                    setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            this.cardOneRef.current?.DrawanimateCard();


                        });
                    }, 500);
                    break;
                case "cardTwo":
                    setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            this.cardTwoRef.current?.DrawanimateCard();

                        });
                    }, 750);

                    break;
                case "cardThree":
                    setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        this.cardThreeRef.current?.DrawanimateCard();

                    }, 1000);

                    break;
                case "cardFour":
                    setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        this.cardFourRef.current?.DrawanimateCard();

                    }, 1250);

                    break;
                case "cardFive":
                    setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        this.cardFiveRef.current?.DrawanimateCard();
                    }, 1500);

                    break;
                default:
                    break;
            }
        }
        // var temp=this.cardOneRef;
        // this.cardOneRef=this.cardTwoRef
        // this.cardTwoRef=temp
    }
    addCards_show(card, text) {
        switch (card) {
            case "cardZero":
                this.setState({ playerCardZero: text, cardZeroAlpha: 1 });
                break;
            case "cardOne":
                this.setState({ playerCardOne: text, cardOneAlpha: 1 });
                break;
            case "cardTwo":
                this.setState({ playerCardTwo: text, cardTwoAlpha: 1 });
                break;
            case "cardThree":
                this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                break;
            case "cardFour":
                this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                break;
            case "cardFive":
                this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                break;
            default:
                break;
        }
    }
    showWinningCombination(data) {
        this.setState({
            cardZeroAlpha: 0.35,
            cardOneAlpha: 0.35,
            cardTwoAlpha: 0.35,
            cardThreeAlpha: 0.35,
            cardFourAlpha: 0.35,
            cardFiveAlpha: 0.35,
        });

        try {
            for (let i = 0; i < data.length; i++) {
                switch (Number(data[i].id)) {
                    case 0:
                        this.cardZeroRef.current.pullUp();
                        break;
                    case 1:
                        this.cardOneRef.current.pullUp();
                        break;
                    case 2:
                        this.cardTwoRef.current.pullUp();
                        break;
                    case 3:
                        this.cardThreeRef.current.pullUp();
                        break;
                    case 4:
                        this.cardFourRef.current.pullUp();
                        break;
                    case 5:
                        this.cardFiveRef.current.pullUp();
                        break;
                    default:
                        break;
                }
            }
        } catch (e) { }
    }
    removeCards() {

        this.setState({ cardCount: 0 });
    }

    foldCards() {

        switch (this.state.cardCount) {
            case 2:
                this.cardZeroRef.current.pullDown();
                this.cardOneRef.current.pullDown();
                break;
            case 4:
                this.cardZeroRef.current.pullDown();
                this.cardOneRef.current.pullDown();
                this.cardTwoRef.current.pullDown();
                this.cardThreeRef.current.pullDown();
                break;
            case 5:
                this.cardZeroRef.current.pullDown();
                this.cardOneRef.current.pullDown();
                this.cardTwoRef.current.pullDown();
                this.cardThreeRef.current.pullDown();
                this.cardFourRef.current.pullDown();
                break;
            case 6:
                this.cardZeroRef.current.pullDown();
                this.cardOneRef.current.pullDown();
                this.cardTwoRef.current.pullDown();
                this.cardThreeRef.current.pullDown();
                this.cardFourRef.current.pullDown();
                this.cardFiveRef.current.pullDown();
                break;
            default:
                break;

        }
    }
    showFoldCards() {
        switch (this.state.cardCount) {
            case 2:
                this.cardZeroRef.current.pullUpCards();
                this.cardOneRef.current.pullUpCards();
                break;
            case 4:
                this.cardZeroRef.current.pullUpCards();
                this.cardOneRef.current.pullUpCards();
                this.cardTwoRef.current.pullUpCards();
                this.cardThreeRef.current.pullUpCards();
                break;
            case 5:
                this.cardZeroRef.current.pullUpCards();
                this.cardOneRef.current.pullUpCards();
                this.cardTwoRef.current.pullUpCards();
                this.cardThreeRef.current.pullUpCards();
                this.cardFourRef.current.pullUpCards();
                break;
            case 6:
                this.cardZeroRef.current.pullUpCards();
                this.cardOneRef.current.pullUpCards();
                this.cardTwoRef.current.pullUpCards();
                this.cardThreeRef.current.pullUpCards();
                this.cardFourRef.current.pullUpCards();
                this.cardFiveRef.current.pullUpCards();
                break;
            default:
                break;

        }
    }
    componentWillUnmount() {
        clearTimeout(this.clearCards)
    }


    // <Card ref={this.cardZeroRef} x={this.props.x - 27} y={this.props.y + 0} frame={this.mapCards[this.state.playerCardZero]} scale={this.props.scale} alpha={this.state.cardZeroAlpha} cardStyle={this.state.cardStyle} angled={330} delay={100}></Card>
    //                     <Card ref={this.cardOneRef} x={this.props.x - 9.8} y={this.props.y - 13} frame={this.mapCards[this.state.playerCardOne]} scale={this.props.scale} alpha={this.state.cardOneAlpha} cardStyle={this.state.cardStyle} angled={340} delay={150}></Card>
    //                     <Card ref={this.cardTwoRef} x={this.props.x + 10} y={this.props.y - 22} frame={this.mapCards[this.state.playerCardTwo]} scale={this.props.scale} alpha={this.state.cardTwoAlpha} cardStyle={this.state.cardStyle} angled={350} delay={300}></Card>
    //                     <Card ref={this.cardThreeRef} x={this.props.x + 35} y={this.props.y - 28} frame={this.mapCards[this.state.playerCardThree]} scale={this.props.scale} alpha={this.state.cardThreeAlpha} cardStyle={this.state.cardStyle} angled={10} delay={450}></Card>
    //                     <Card ref={this.cardFourRef} x={this.props.x + 55} y={this.props.y - 24} frame={this.mapCards[this.state.playerCardFour]} scale={this.props.scale} alpha={this.state.cardFourAlpha} cardStyle={this.state.cardStyle} angled={20} delay={600}></Card>
    //                     <Card ref={this.cardFiveRef} x={this.props.x + 75} y={this.props.y - 18} frame={this.mapCards[this.state.playerCardFive]} scale={this.props.scale} alpha={this.state.cardFiveAlpha} cardStyle={this.state.cardStyle} angled={32} delay={750}></Card>






    MoveCard(card, text, cnt) {
        this.moveCardFunction = true;
        this.handleResize()

        let basedOnView = [];

        // basedOnView = [
        //     {
        //         x: this.props.isMobilePortrait ? this.props.x - (298) : this.props.x - 80,
        //         y: this.props.isMobilePortrait ? this.props.y - (10) : this.props.y - 6,
        //         angle: this.props.isMobilePortrait ? 330 : 333
        //     },

        //     {
        //         x: this.props.isMobilePortrait ? this.props.x - (212) : this.props.x - 30,
        //         y: this.props.isMobilePortrait ? this.props.y - (60) : this.props.y - 30,
        //         angle: this.props.isMobilePortrait ? 345 : 343
        //     },

        //     {
        //         x: this.props.isMobilePortrait ? this.props.x - (130) : this.props.x + 20,
        //         y: this.props.isMobilePortrait ? this.props.y - (85) : this.props.y - 45,
        //         angle: this.props.isMobilePortrait ? 355 : 353
        //     },

        //     {
        //         x: this.props.isMobilePortrait ? this.props.x - 50 : this.props.x + 80,
        //         y: this.props.isMobilePortrait ? this.props.y - (95) : this.props.y - 53,
        //         angle: this.props.isMobilePortrait ? 5 : 5
        //     },

        //     {
        //         x: this.props.isMobilePortrait ? this.props.x + (30) : this.props.x + 135,
        //         y: this.props.isMobilePortrait ? this.props.y - (90) : this.props.y - 51,
        //         angle: this.props.isMobilePortrait ? 15 : 15
        //     },
        // ]

        if (cnt !== undefined) {
            this.setState({ cardCount: Number(cnt) });
            console.log('basedOnView', basedOnView)
        }
        if (cnt !== undefined) {
            this.setState({
                cardsPropsWhenDropSixCard: [],
                drawSixthCard: false

            });


            switch (card) {
                case "cardZero":
                    this.hiddencards[0] = true;
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardZero: text, cardZeroAlpha: 1 }, () => {
                            try {
                                // var data = { x: this.props.x - 27, y: this.props.y + 0, angle: 328 }
                                // var data = { x: this.props.x - 27, y: this.props.y + 0, angle: 330 }


                                // var data = { x: this.props.isMobilePortrait ? this.props.x - (275) : this.props.x - 80,
                                // y: this.props.y - 6, angle: 333 }
                                // var data = basedOnView[0]
                                var data = this.state.cardsXYAProps[0];
                                this.cardZeroRef.current?.moveCard(data);

                                this.setState((prevstate) => ({
                                    cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                                }));

                            } catch (e) { 
                                console.log(e); 
                            }
                        });
                    }, 100);


                    break;
                case "cardOne":
                    this.hiddencards[1] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardOne: text, cardOneAlpha: 1 }, () => {
                            // var data = { x: this.props.x - 9.8, y: this.props.y - 13, angle: 340 }
                            // var data = { x: this.props.x - 9.8, y: this.props.y - 10, angle: 340 }
                            // var data = { x: this.props.isMobilePortrait ? this.props.x - (180) : this.props.x - 30,
                            //      y: this.props.y - 30, angle: 343 }
                            // var data = basedOnView[1]
                            var data = this.state.cardsXYAProps[1]
                            this.cardOneRef.current?.moveCard(data);
                            this.setState((prevstate) => ({
                                cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                            }));
                        });
                    }, 200);
                    break;
                case "cardTwo":
                    this.hiddencards[2] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardTwo: text, cardTwoAlpha: 1 }, () => {
                            // var data = { x: this.props.x - 10, y: this.props.y - 22, angle: 350 }
                            // var data = { x: this.props.x + 10, y: this.props.y - 18, angle: 350 }
                            // var data = { x: this.props.isMobilePortrait ? this.props.x - (90) : this.props.x + 20,
                            //      y: this.props.y - 45, angle: 353 }
                            // var data = basedOnView[2]
                            var data = this.state.cardsXYAProps[2]
                            this.cardTwoRef.current?.moveCard(data);
                            this.setState((prevstate) => ({
                                cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                            }));
                        });
                    }, 300);

                    break;
                case "cardThree":
                    this.hiddencards[3] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardThree: text, cardThreeAlpha: 1 });
                        // var data = { x: this.props.x + 35, y: this.props.y - 38, angle: 10 }
                        // var data = { x: this.props.x + 32, y: this.props.y - 23, angle: 5 }
                        // var data = { x:  this.props.isMobilePortrait ? this.props.x : this.props.x + 80,
                        //      y: this.props.y - 53, angle: 5 }
                        // var data = basedOnView[3]
                        var data = this.state.cardsXYAProps[3]
                        this.cardThreeRef.current?.moveCard(data);
                        this.setState((prevstate) => ({
                            cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                        }));
                    }, 400);

                    break;
                case "cardFour":
                    this.hiddencards[4] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFour: text, cardFourAlpha: 1 });
                        // var data = { x: this.props.x + 55, y: this.props.y - 34, angle: 20 }
                        // var data = { x: this.props.x + 55, y: this.props.y - 22, angle: 20 }
                        // var data = { x: this.props.isMobilePortrait ? this.props.x + (90) : this.props.x + 135, 
                        //     y: this.props.y - 51, angle: 15 }
                        // var data = basedOnView[4]
                        var data = this.state.cardsXYAProps[4]
                        this.cardFourRef.current?.moveCard(data);
                        this.setState((prevstate) => ({
                            cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                        }));
                    }, 500);

                    break;
                case "cardFive":
                    this.angle = -10
                    this.hiddencards[5] = true
                    this.clearCards = setTimeout(() => {
                        this.setState({ playerCardFive: text, cardFiveAlpha: 1 });
                        // var data = { x: this.props.x + 185, y: this.props.y - 42, angle: 25 }
                        // this.setState((prevstate) => ({
                        //     cardsPropsWhenDropSixCard: [...prevstate.cardsPropsWhenDropSixCard, data]
                        // }));
                        this.cardFiveRef.current?.animateCard();
                        // var data = { x: this.props.x+75, y: this.props.y, angle: 32 }
                        // this.cardFiveRef.current?.moveCard(data);
                    }, 600);

                    break;
                default:
                    break;
            }
        }

    }



    XY(data) {

        if (data.index == -1) {
            this.CardStore.push(data)
        } else {
            this.CardStore[data.index] = data;
        }
        console.log(this.CardStore)
    }


    UpdateCard(data) {
        console.log(data)
        this.setState({ x: data.x, y: data.y })
    }

    selectCardToBeRemove = (cardCode) => {
        this.drawCardsArray.push(cardCode);
        console.log('AddCard', this.drawCardsArray)
        const { numberOfCardsToBeDraw } = this.props;
        numberOfCardsToBeDraw(this.drawCardsArray);
    }


    selectCardToBeUnRemove = (cardCode) => {

        var indexOfItem = this.drawCardsArray.findIndex((each) => {
            if (each === cardCode) {
                return true
            } else {
                return false
            }
        })
        this.drawCardsArray.splice(indexOfItem, 1);
        console.log('removeCard', this.drawCardsArray);
        const { numberOfCardsToBeDraw } = this.props;
        numberOfCardsToBeDraw(this.drawCardsArray);


    }
    render() {
        const { cardsPropsWhenDropSixCard, drawSixthCard } = this.state;
        // console.log('cardsPropsWhenDropSixCard', cardsPropsWhenDropSixCard, 'drawSixthCard', drawSixthCard);

        return (
            <Group ref={this.groupCards} >

                {/* {this.state.cardCount == 5 &&  */}
                {

                    (

                        <>
                            {/* {this.hiddencards[0] && <Card ref={this.cardZeroRef} x={this.props.x - (20)} y={this.props.y - (6)} frame={this.mapCards[this.state.playerCardZero]} scale={this.props.scale} alpha={this.state.cardZeroAlpha} cardStyle={this.state.cardStyle} angled={335} delay={100} XY={this.XY.bind(this)} text={this.state.playerCardZero} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}></Card>}
                        {this.hiddencards[1] && <Card ref={this.cardOneRef} x={this.props.x + (0)} y={this.props.y - (18)} frame={this.mapCards[this.state.playerCardOne]} scale={this.props.scale} alpha={this.state.cardOneAlpha} cardStyle={this.state.cardStyle} angled={345} delay={200} XY={this.XY.bind(this)} text={this.state.playerCardOne} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}></Card>}
                        {this.hiddencards[2] && <Card ref={this.cardTwoRef} x={this.props.x + (23)} y={this.props.y - (25)} frame={this.mapCards[this.state.playerCardTwo]} scale={this.props.scale} alpha={this.state.cardTwoAlpha} cardStyle={this.state.cardStyle} angled={355} delay={400} XY={this.XY.bind(this)} text={this.state.playerCardTwo} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}></Card>}
                        {this.hiddencards[3] && <Card ref={this.cardThreeRef} x={this.props.x + (50)} y={this.props.y - (27)} frame={this.mapCards[this.state.playerCardThree]} scale={this.props.scale} alpha={this.state.cardThreeAlpha} cardStyle={this.state.cardStyle} angled={15} delay={600} XY={this.XY.bind(this)} text={this.state.playerCardThree} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}></Card>}
                        {this.hiddencards[4] && <Card ref={this.cardFourRef} x={this.props.x + (73)} y={this.props.y - (22)} frame={this.mapCards[this.state.playerCardFour]} scale={this.props.scale} alpha={this.state.cardFourAlpha} cardStyle={this.state.cardStyle} angled={30} delay={800} XY={this.XY.bind(this)} text={this.state.playerCardFour} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}></Card>} */}
                            {/* {this.hiddencards[5] && <Card ref={this.cardFiveRef} x={this.props.x + (100)} y={this.props.y - (18)} frame={this.mapCards[this.state.playerCardFive]} scale={this.props.scale} alpha={this.state.cardFiveAlpha} cardStyle={this.state.cardStyle} angled={40} delay={750} XY={this.XY.bind(this)} text={this.state.playerCardFive}></Card>} */}

                            {this.hiddencards[0] && <Card cardsXYAProps={this.state.cardsXYAProps[0]} ref={this.cardZeroRef} 
                                x={this.state.cardsXYAProps[0].x}  y={this.state.cardsXYAProps[0].y} angled={this.state.cardsXYAProps[0].angle}
                                frame={this.mapCards[this.state.playerCardZero]} scaleX={this.props.scaleX} scaleY={this.props.scaleY}
                                alpha={this.state.cardZeroAlpha} cardStyle={this.state.cardStyle} delay={100} XY={this.XY.bind(this)} 
                                text={this.state.playerCardZero} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} 
                                selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                                index={0}  mutesounds={this.props.mutesounds}
                               
                            ></Card>}

                            {this.hiddencards[1] && <Card cardsXYAProps={this.state.cardsXYAProps[1]}ref={this.cardOneRef} 
                                x={this.state.cardsXYAProps[1].x} y={this.state.cardsXYAProps[1].y} angled={this.state.cardsXYAProps[1].angle}
                                frame={this.mapCards[this.state.playerCardOne]} scaleX={this.props.scaleX} scaleY={this.props.scaleY}
                                alpha={this.state.cardOneAlpha} cardStyle={this.state.cardStyle} delay={200} XY={this.XY.bind(this)} 
                                text={this.state.playerCardOne} x1={this.state.x} y1={this.state.y}UpdateCard={this.UpdateCard.bind(this)} 
                                selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                                index={1}  mutesounds={this.props.mutesounds}
                            ></Card>}

                            {this.hiddencards[2] && <Card cardsXYAProps={this.state.cardsXYAProps[2]} ref={this.cardTwoRef} 
                                x={this.state.cardsXYAProps[2].x} y={this.state.cardsXYAProps[2].y} angled={this.state.cardsXYAProps[2].angle}
                                frame={this.mapCards[this.state.playerCardTwo]} scaleX={this.props.scaleX} scaleY={this.props.scaleY}
                                alpha={this.state.cardTwoAlpha} cardStyle={this.state.cardStyle} delay={400} XY={this.XY.bind(this)} 
                                text={this.state.playerCardTwo} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} 
                                selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                                index={2}  mutesounds={this.props.mutesounds}
                            ></Card>}

                            {this.hiddencards[3] && <Card cardsXYAProps={this.state.cardsXYAProps[3]} ref={this.cardThreeRef} 
                                x={this.state.cardsXYAProps[3].x}y={this.state.cardsXYAProps[3].y} angled={this.state.cardsXYAProps[3].angle}
                                frame={this.mapCards[this.state.playerCardThree]} scaleX={this.props.scaleX} scaleY={this.props.scaleY}
                                alpha={this.state.cardThreeAlpha} cardStyle={this.state.cardStyle} delay={600} XY={this.XY.bind(this)} 
                                text={this.state.playerCardThree} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} 
                                selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                                index={3}  mutesounds={this.props.mutesounds}
                            ></Card>}


                            {this.hiddencards[4] && <Card cardsXYAProps={this.state.cardsXYAProps[4]} ref={this.cardFourRef} 
                                x={this.state.cardsXYAProps[4].x} y={this.state.cardsXYAProps[4].y} angled={this.state.cardsXYAProps[4].angle}
                                frame={this.mapCards[this.state.playerCardFour]} scaleX={this.props.scaleX} scaleY={this.props.scaleY}
                                alpha={this.state.cardFourAlpha} cardStyle={this.state.cardStyle} delay={800} XY={this.XY.bind(this)} 
                                text={this.state.playerCardFour} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)} 
                                selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
                                index={4}  mutesounds={this.props.mutesounds}
                            ></Card>}

                            {this.hiddencards[5] && <Card cardsXYAProps={this.state.cardsXYAProps[5]} ref={this.cardFiveRef} 
                                x={this.state.cardsXYAProps[5].x} y={this.state.cardsXYAProps[5].y} angled={this.state.cardsXYAProps[5].angle}
                                frame={this.mapCards[this.state.playerCardFive]} scaleX={this.props.scaleX} scaleY={this.props.scaleY}
                                alpha={this.state.cardFiveAlpha} cardStyle={this.state.cardStyle} delay={750} XY={this.XY.bind(this)} 
                                text={this.state.playerCardFive} x1={this.state.x} y1={this.state.y}UpdateCard={this.UpdateCard.bind(this)} 
                                index={5}  mutesounds={this.props.mutesounds}
                            ></Card>}
                        </>
                    )
                }


                {/* {this.state.cardCount == 6 && (
                    <>
                        {this.hiddencards[0] &&  <Card ref={this.cardZeroRef} x={this.props.x - 27} y={this.props.y + 0} frame={this.mapCards[this.state.playerCardZero]} scale={this.props.scale} alpha={this.state.cardZeroAlpha} cardStyle={this.state.cardStyle} angled={330} delay={100} XY={this.XY.bind(this)} text={this.state.playerCardZero} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}></Card>}
                        {this.hiddencards[1] &&<Card ref={this.cardOneRef} x={this.props.x - 9.8} y={this.props.y - 13} frame={this.mapCards[this.state.playerCardOne]} scale={this.props.scale} alpha={this.state.cardOneAlpha} cardStyle={this.state.cardStyle} angled={340} delay={150} XY={this.XY.bind(this)} text={this.state.playerCardOne} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}></Card>}
                        {this.hiddencards[2] &&<Card ref={this.cardTwoRef} x={this.props.x + 10} y={this.props.y - 22} frame={this.mapCards[this.state.playerCardTwo]} scale={this.props.scale} alpha={this.state.cardTwoAlpha} cardStyle={this.state.cardStyle} angled={350} delay={300} XY={this.XY.bind(this)} text={this.state.playerCardTwo} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}></Card>}
                         {this.hiddencards[3]&&<Card ref={this.cardThreeRef} x={this.props.x + 35} y={this.props.y - 28} frame={this.mapCards[this.state.playerCardThree]} scale={this.props.scale} alpha={this.state.cardThreeAlpha} cardStyle={this.state.cardStyle} angled={10} delay={450} XY={this.XY.bind(this)} text={this.state.playerCardThree} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}></Card>}
                         {this.hiddencards[4]&&<Card ref={this.cardFourRef} x={this.props.x + 55} y={this.props.y - 24} frame={this.mapCards[this.state.playerCardFour]} scale={this.props.scale} alpha={this.state.cardFourAlpha} cardStyle={this.state.cardStyle} angled={20} delay={600} XY={this.XY.bind(this)} text={this.state.playerCardFour} x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}></Card>}
                         {this.hiddencards[5]&& <Card ref={this.cardFiveRef} x={this.props.x + 75} y={this.props.y - 18} frame={this.mapCards[this.state.playerCardFive]} scale={this.props.scale} alpha={this.state.cardFiveAlpha} cardStyle={this.state.cardStyle} angled={32} delay={750} XY={this.XY.bind(this)} text={this.state.playerCardFive}  x1={this.state.x} y1={this.state.y} UpdateCard={this.UpdateCard.bind(this)}></Card>}
                    </>
                )} */}
            </Group>

        );
    }
}
