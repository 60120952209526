import react from "react";
import da from '../../../../../../assets/games/russianPoker/historycards/ad.png';
import ca from '../../../../../../assets/games/russianPoker/historycards/ac.png';
import ha from '../../../../../../assets/games/russianPoker/historycards/ah.png';
import sa from '../../../../../../assets/games/russianPoker/historycards/as.png';
import d2 from '../../../../../../assets/games/russianPoker/historycards/2d.png';
import c2 from '../../../../../../assets/games/russianPoker/historycards/2c.png';
import h2 from '../../../../../../assets/games/russianPoker/historycards/2h.png';
import s2 from '../../../../../../assets/games/russianPoker/historycards/2s.png';
import d3 from '../../../../../../assets/games/russianPoker/historycards/3d.png';
import c3 from '../../../../../../assets/games/russianPoker/historycards/3c.png';
import h3 from '../../../../../../assets/games/russianPoker/historycards/3h.png';
import s3 from '../../../../../../assets/games/russianPoker/historycards/3s.png';
import d4 from '../../../../../../assets/games/russianPoker/historycards/4d.png';
import c4 from '../../../../../../assets/games/russianPoker/historycards/4c.png';
import h4 from '../../../../../../assets/games/russianPoker/historycards/4h.png';
import s4 from '../../../../../../assets/games/russianPoker/historycards/4s.png';
import d5 from '../../../../../../assets/games/russianPoker/historycards/5d.png';
import c5 from '../../../../../../assets/games/russianPoker/historycards/5c.png';
import h5 from '../../../../../../assets/games/russianPoker/historycards/5h.png';
import s5 from '../../../../../../assets/games/russianPoker/historycards/5s.png';
import d6 from '../../../../../../assets/games/russianPoker/historycards/6d.png';
import c6 from '../../../../../../assets/games/russianPoker/historycards/6c.png';
import h6 from '../../../../../../assets/games/russianPoker/historycards/6h.png';
import s6 from '../../../../../../assets/games/russianPoker/historycards/6s.png';
import d7 from '../../../../../../assets/games/russianPoker/historycards/7d.png';
import c7 from '../../../../../../assets/games/russianPoker/historycards/7c.png';
import h7 from '../../../../../../assets/games/russianPoker/historycards/7h.png';
import s7 from '../../../../../../assets/games/russianPoker/historycards/7s.png';
import d8 from '../../../../../../assets/games/russianPoker/historycards/8d.png';
import c8 from '../../../../../../assets/games/russianPoker/historycards/8c.png';
import h8 from '../../../../../../assets/games/russianPoker/historycards/8h.png';
import s8 from '../../../../../../assets/games/russianPoker/historycards/8s.png';
import d9 from '../../../../../../assets/games/russianPoker/historycards/9d.png';
import c9 from '../../../../../../assets/games/russianPoker/historycards/9c.png';
import h9 from '../../../../../../assets/games/russianPoker/historycards/9h.png';
import s9 from '../../../../../../assets/games/russianPoker/historycards/9s.png';
import dt from '../../../../../../assets/games/russianPoker/historycards/dt.png';
import ct from '../../../../../../assets/games/russianPoker/historycards/ct.png';
import ht from '../../../../../../assets/games/russianPoker/historycards/ht.png';
import st from '../../../../../../assets/games/russianPoker/historycards/st.png';
import dj from '../../../../../../assets/games/russianPoker/historycards/jd.png';
import cj from '../../../../../../assets/games/russianPoker/historycards/jc.png';
import hj from '../../../../../../assets/games/russianPoker/historycards/jh.png';
import sj from '../../../../../../assets/games/russianPoker/historycards/js.png';
import dq from '../../../../../../assets/games/russianPoker/historycards/qd.png';
import cq from '../../../../../../assets/games/russianPoker/historycards/qc.png';
import hq from '../../../../../../assets/games/russianPoker/historycards/qh.png';
import sq from '../../../../../../assets/games/russianPoker/historycards/qs.png';
import dk from '../../../../../../assets/games/russianPoker/historycards/kd.png';
import ck from '../../../../../../assets/games/russianPoker/historycards/kc.png';
import hk from '../../../../../../assets/games/russianPoker/historycards/kh.png';
import sk from '../../../../../../assets/games/russianPoker/historycards/ks.png';
import jk from '../../../../../../assets/games/russianPoker/historycards/jk.png';

const resultCards = [
    {src: da, rsc_val : 'AD' },
    {src: ca, rsc_val : 'AC' },
    {src: ha, rsc_val : 'AH' },
    {src: sa, rsc_val : 'AS' },
    {src: d2, rsc_val : '2D' },
    {src: c2, rsc_val : '2C' },
    {src: h2, rsc_val : '2H' },
    {src: s2, rsc_val : '2S' },
    {src: d3, rsc_val : '3D' },
    {src: c3, rsc_val : '3C' },
    {src: h3, rsc_val : '3H' },
    {src: s3, rsc_val : '3S' },
    {src: d4, rsc_val : '4D' },
    {src: c4, rsc_val : '4C' },
    {src: h4, rsc_val : '4H' },
    {src: s4, rsc_val : '4S' },
    {src: d5, rsc_val : '5D' },
    {src: c5, rsc_val : '5C' },
    {src: h5, rsc_val : '5H' },
    {src: s5, rsc_val : '5S' },
    {src: d6, rsc_val : '6D' },
    {src: c6, rsc_val : '6C' },
    {src: h6, rsc_val : '6H' },
    {src: s6, rsc_val : '6S' },
    {src: d7, rsc_val : '7D' },
    {src: c7, rsc_val : '7C' },
    {src: h7, rsc_val : '7H' },
    {src: s7, rsc_val : '7S' },
    {src: d8, rsc_val : '8D' },
    {src: c8, rsc_val : '8C' },
    {src: h8, rsc_val : '8H' },
    {src: s8, rsc_val : '8S' },
    {src: d9, rsc_val : '9D' },
    {src: c9, rsc_val : '9C' },
    {src: h9, rsc_val : '9H' },
    {src: s9, rsc_val : '9S' },
    {src: dt, rsc_val : '10D' },
    {src: ct, rsc_val : '10C' },
    {src: ht, rsc_val : '10H' },
    {src: st, rsc_val : '10S' },
    {src: dj, rsc_val : 'JD' },
    {src: cj, rsc_val : 'JC' },
    {src: hj, rsc_val : 'JH' },
    {src: sj, rsc_val : 'JS' },
    {src: dq, rsc_val : 'QD' },
    {src: cq, rsc_val : 'QC' },
    {src: hq, rsc_val : 'QH' },
    {src: sq, rsc_val : 'QS' },
    {src: dk, rsc_val : 'KD' },
    {src: ck, rsc_val : 'KC' },
    {src: hk, rsc_val : 'KH' },
    {src: sk, rsc_val : 'KS' },
    {src: jk, rsc_val : 'JK' },
     
]

export default resultCards;