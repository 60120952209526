import React from "react";
// import CardDefault from "../assests/Images/cards/cards2.png";
import CardDefault from '../../../../../../assets/games/tropicalstudpoker/TP_sprite_card.png';
import selectCard from "../../../../../../assets/sounds/selectcard.mp3"
import unselectCard from "../../../../../../assets/sounds/unselectcard.mp3"
// import cardsdropping_snd from "././../../../../../assets/sounds/carddroping.mp3";
import cardsdropping_snd from '../../../../../../assets/sounds/carddroping.mp3'
import cardsshuffle from "../../../../../../assets/sounds/shuffle-cards.mp3";
import { Group, Sprite } from "react-konva";
import gsap from "gsap";
import { Howl } from "howler";
// import cardSound from "../../../../../../assets/audio/Card.mp3";
import Konva from "konva";
Konva.showWarnings = false;

export class Card extends React.Component {


    constructor(props) {
        super(props);
        this.width = window.innerWidth * 0.5;
        console.log(props.x1,)
        console.log(props.y1,)
        this.state = {
            image: null,
            x: props.x1,
            y: props.y1,
            // x: this.width - 26,
            // y: 50,
            pulldown1: false,
            cardpositon: { x: 100, y: -100 },
            cardDropSound: null,
            isCardDropSound: false,
            cardShuffledSound: null,
            isCardShuffledSound: false,
        };

        this.cardStyle = CardDefault;
        this.cardDropPositionX = null;
        this.cardDropPositionY = null;
        this.selectCard = new Audio(selectCard);
        this.unselectCard = new Audio(unselectCard);
        // this.carddropsound = new Audio(cardsdropping_snd);
        // this.cardsshuffleSound = new Audio(cardsshuffle);
        this.cardContext = null;

        // this.cardSound = new Audio(cardSound);
        this.handleResize = this.handleResize.bind(this)
        this.handleResize();
    }



    componentDidMount() {
        this.carddropsound = this.initializeCardDropSound();
        this.cardsshuffleSound = this.initializeCardShuffledSound();

        this.loadImage(CardDefault);
        if (this.imageNode && this.imageNode !== null && this.imageNode != undefined) {
            window.addEventListener("resize", this.handleResize);
        }
    }
    initializeCardDropSound = () => {
        let cardDropSound = new Howl({
            src: [cardsdropping_snd],
            html5: true, // Enable HTML5 Audio for mobile compatibility
        });
        this.setState({
            cardDropSound,
            isCardDropSound: false
        })
        return cardDropSound

    }
    initializeCardShuffledSound = () => {
        let cardShuffledSound = new Howl({
            src: [cardsshuffle],
            html5: true, // Enable HTML5 Audio for mobile compatibility
        });
        this.setState({
            cardShuffledSound,
            isCardShuffledSound: false
        })
        return cardShuffledSound

    }

    playPokerCardDropSound = (chipSound) => {
        const { isCardDropSound } = this.state;
        const { mutesounds } = this.props
        let currentSound = chipSound;
        if (!mutesounds) {

            if (isCardDropSound) {
                chipSound.stop();
            }
            if (!chipSound) {
                currentSound = this.initializeCardDropSound();
            }

            currentSound.play();
            this.setState({
                isCardDropSound: true
            })
        }

    };
    playPokerCardShuffledSound = (chipSound) => {
        const { isCardShuffledSound } = this.state;
        const { mutesounds } = this.props
        let currentSound = chipSound;
        if (!mutesounds) {

            if (isCardShuffledSound) {
                chipSound.stop();
            }
            if (!chipSound) {
                currentSound = this.initializeCardShuffledSound();
            }
            
            currentSound.play();
            this.setState({
                isCardShuffledSound: true
            })
        }

    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.selectCard.pause() ;
        this.unselectCard.pause() ;
        this.carddropsound.pause() ;
        this.cardsshuffleSound.pause() ;
    }


    // componentDidUpdate(){
    //     window.removeEventListener("resize", this.handleResize);
    // }
    handleResize() {
        // this.imageNode.to({ 
        //                     duration: 0.0001,
        //                     scaleX:this.props.scaleX,
        //                     scaleY:this.props.scaleY,
        //                     rotation: this.props.angled });


        if (this.imageNode && this.imageNode !== null) {
            console.log(this.props.cardsXYAProps)
            this.cardContext = gsap.context(() => {
                this.imageNode.to({
                    // x: this.props.x,
                    // y: this.props.y,
                    // rotation: this.props.angled,
                    x: this.props.cardsXYAProps.x,
                    y: this.props.cardsXYAProps.y,
                    rotation: this.props.cardsXYAProps.angle,
                    scaleX: this.props.scaleX,
                    scaleY: this.props.scaleY,
                    duration: 0.001, ease: "EaseInOut",
                    // onFinish: this.afterFinish()
                });
            }, this.imageNode)
        }

        if ((navigator.userAgentData && navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows") || (navigator.userAgent.indexOf("Windows") !== -1)) {
            // this.cardDropPositionX = window.innerWidth / 2 + 900;
            // this.cardDropPositionY = -window.innerHeight / 2 - 250;
            this.cardDropPositionX = 1100
            this.cardDropPositionY = -100


        } else {

            if (window.innerWidth > window.innerHeight) {

                // this.cardDropPositionX = window.innerWidth/2 -400 ;
                // this.cardDropPositionY = -window.innerHeight/2;


                this.cardDropPositionX = window.innerWidth / 2 + 100;
                this.cardDropPositionY = - (window.innerHeight / 2) - 350;
                // this.cardDropPositionX = window.innerWidth / 2 + 800;
                // this.cardDropPositionY = - (window.innerHeight / 2) - 300;

            } else {
                // this.cardDropPositionY = -(window.innerHeight/2) - 500;


                this.cardDropPositionX = window.innerWidth / 2 + 250;
                this.cardDropPositionY = -(window.innerHeight / 2) - 500;
            }
        }
    }


    componentDidUpdate(prevProps) {
        if (this.cardStyle !== this.props.cardStyle) {
            this.loadImage(this.props.cardStyle);
            this.cardStyle = this.props.cardStyle;
        }
        if (this.props.x != prevProps.x) {
            console.log('prevProps', prevProps)
            this.changeCardPosition();
        }

    }
    changeCardPosition() {
        // alert("position")
        this.cardContext = gsap.context(() => {
            this.imageNode.to({ x: this.props.x, y: this.props.y, duration: 0.5, ease: "Linear" });
        }, this.imageNode)

    }
    pullDown() {
        this.imageNode.to({ y: this.props.y + 50, x: this.props.x, opacity: 0.35, duration: 0.5, ease: "Linear", visible: false });
    }
    pullUpCards() {
        this.imageNode.to({ y: this.props.y, x: this.props.x, opacity: 0.5, duration: 0.5, ease: "Linear", visible: true });
    }
    pullUp() {
        this.imageNode.to({ y: this.props.y - 10, opacity: 1, duration: 0.5, ease: "Linear" });
    }


    showPreviousPlayercards() {

        this.props.UpdateCard({ x: this.props.x, y: this.props.y });
        if (this.imageNode && this.imageNode !== null && this.imageNode !== undefined) {
            this.imageNode.to({ visible: true });
            gsap.fromTo(
                this.imageNode,
                { duration: 0.000001, x: this.cardDropPositionX, y: this.cardDropPositionY },
                { x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, rotation: this.props.angled, duration: 0.5, ease: "EaseInOut", onComplete: this.afterFinish() }
            );

        }

    }



    animateCard() {
        console.log("this.props.text", this.props.text,
            "this.props.frame :", this.props.frame, "this.props.angled", this.props.angled);
        // this.cardSound.play();
        // setTimeout(() => {
        // this.setState({ x: this.props.x, y: this.props.y });
        // gsap.to(this.imageNode, { x: this.props.x, y: this.props.y, duration: 0.5, ease: "Linear" });
        this.setState({ x: this.props.x, y: this.props.y })
        this.props.UpdateCard({ x: this.props.x, y: this.props.y });

        if (this.imageNode && this.imageNode != null && this.imageNode !== undefined) {
            // this.imageNode.to({
            //     x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY,
            //     // easing: Konva.Easings.EaseInOut,
            //     rotation: this.props.angled, duration: 0.5, ease: "EaseInOut", onFinish: this.afterFinish()
            // });
            this.imageNode.to({ visible: true, });
            gsap.fromTo(
                this.imageNode,
                { duration: 0.05, x: this.cardDropPositionX, y: this.cardDropPositionY },
                {
                    x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, rotation: this.props.angled, duration: 0.5, ease: "EaseInOut", onComplete: this.afterFinish(), onStart: (() => {
                        // this.playPokerCardDropSound(this.carddropsound);
                        // if (!this.props.mutesounds) {
                        //     this.carddropsound.play()
                        // }else{
                        //     this.carddropsound.pause()
                            
                        // }
                    })
                }
            );


        }
        // }, this.props.delay);
    }
    afterFinish() {
        this.props.XY({ index: -1, text: this.props.text, x: this.props.x, y: this.props.y })

    }

    SuffleCard(data, cardsLength, cardXYA, num) {
        console.log(data, 'cardsLength', cardsLength, 'num', num, 'cardXYA', cardXYA);
        // let X  = cardsLength == 5 ? this.props.x : cardXYA.x
        // let Y  = cardsLength == 5 ? this.props.y : cardXYA.y
        if (cardsLength === 5) {
            console.log('x:data.x', data.x, 'data.y', data.y, 'this.props.x', this.props.x, 'this.props.y', this.props.y)

            gsap.fromTo(this.imageNode, { x: data.x, y: data.y - 10, opacity: 0.5 },
                {
                    x: this.props.x, y: this.props.y, duration: 0.5, ease: "EaseInOut",
                    opacity: 1, onComplete: this.Uc(data), onStart:() =>{
                        // if (!this.props.mutesounds) {
                        //     this.cardsshuffleSound.play()
                        // }else{
                        //     this.cardsshuffleSound.pause()
                            
                        // }
                        this.playPokerCardShuffledSound(this.cardsshuffleSound)
                    }
                });


        } else {
            if (cardXYA) {
                console.log('x: data.x', data.x, 'data.y', data.y, 'cardXYA.x', cardXYA.x, 'cardXYA.y', cardXYA.y)
                gsap.fromTo(this.imageNode, { x: data.x, y: data.y - 10, opacity: 0.5 },
                    {
                        x: cardXYA.x, y: cardXYA.y, duration: 0.5, ease: "EaseInOut",
                        opacity: 1, onComplete: this.Uc(cardXYA), onStart:() =>{
                            // if (!this.props.mutesounds) {
                            //     this.cardsshuffleSound.play()
                            // }else{
                            //     this.cardsshuffleSound.pause()
                                
                            // }
                            this.playPokerCardShuffledSound(this.cardsshuffleSound)
                        }
                    });
            }


        }


        // this.props.XY({index:this.props.index,text:this.props.text, x: this.props.x, y: this.props.y})
        // this.imageNode.to({ y: this.props.y - 10, opacity: 1, duration: 0.25, ease: "Linear" });
        // this.imageNode.to({ visible: true, duration: 0.05,x:this.props.x-20 });
        // this.imageNode.to({ x: data.x, y: this.props.y,delay:0.25, duration: 0.75,ease: "Linear" });
        // this.imageNode.to({ x: this.props.x, y: this.props.y, duration: 0.5, ease: "EaseInOut" });
    }


    Uc(data) {
        this.props.XY({ index: this.props.index, text: this.props.text, x: data.x, y: data.y })
    }


    DrawanimateCard(data) {
        // alert("hi")
        // console.log("dataxy",data.x,",",data.y,"      ","props",this.props.x,",",this.props.y)
        // gsap.to(this.imageNode,{x: this.props.x, y: this.props.y-20,opacity:0})
        // tl.fromTo(this.imageNode,{ x: this.props.x, y: this.props.y,opacity: 1,duration:0.5},{ x: this.props.x, y: this.props.y-300, duration: 0.5, ease: "EaseInOut",opacity:0 })
        // tl.fromTo(this.imageNode,{ x: this.props.x, y: this.props.y-300,opacity: 0,duration:0.5},{ x: this.props.x, y: this.props.y, duration: 0.5, ease: "EaseInOut",opacity:1 })
        var tl = gsap.timeline({ onComplete: this.draw() });
        tl.fromTo(this.imageNode, { x: this.props.x, y: this.props.y, scaleX: 1, scaleY: 1, opacity: 1, duration: 0.5 }, { x: this.cardDropPositionX, y: this.cardDropPositionY, scaleX: 0.8, scaleY: 0.6, duration: 0.6, ease: "EaseInOut", opacity: 0 ,onStart:()=>{
            // if (!this.props.mutesounds) {
            //     this.carddropsound.play()
            // }else{
            //     this.carddropsound.pause()
                
            // }
            this.playPokerCardDropSound(this.carddropsound)
        }})
        tl.fromTo(this.imageNode, { x: this.cardDropPositionX, y: this.cardDropPositionY, scaleX: 0.15, scaleY: 0.15, opacity: 0, duration: 0.5 }, { x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, duration: 0.5, ease: "EaseInOut", opacity: 1 ,onStart:()=>{
            // if (!this.props.mutesounds) {
            //     this.carddropsound.play()
            // }else{
            //     this.carddropsound.pause()
                
            // }
            this.playPokerCardDropSound(this.carddropsound)
        }})
        tl.play()


        // this.imageNode.to({ y: this.props.y - 10, opacity: 1, duration: 0.25, ease: "Linear" });
        // this.imageNode.to({ visible: true, duration: 0.05,x:this.props.x-20 });
        // this.imageNode.to({ x: data.x, y: this.props.y,delay:0.25, duration: 0.75,ease: "Linear" });
        // this.imageNode.to({ x: this.props.x, y: this.props.y, duration: 0.5, ease: "EaseInOut" });
    }
    draw() {
        this.props.XY({ index: this.props.index, text: this.props.text, x: this.props.x, y: this.props.y })

    }
    moveCard(data) {
        console.log(data.x)
        // this.imageNode.to({ x: this.props.x, y: this.props.y, scaleX: this.props.scaleX, scaleY: this.props.scaleY, rotation: this.props.angled, duration: 0.5, ease: "EaseInOut" });
        this.imageNode.to({ visible: true, duration: 0.05 });
        this.imageNode.to({ x: data.x, y: data.y, opacity: 1, duration: 0.5, ease: "Linear", rotation: data.angle, onFinish: this.MoveFInished(data) });


        // this.props.XY({index:this.props.index,text:this.props.text, x: data.x, y: data.y})
        // var tl = gsap.timeline({});
        // tl.fromTo(this.imageNode,{ x: this.props.x, y: this.props.y,opacity: 1,duration:0.5},{ x:data.x, y:data.y, duration: 0.5, ease: "EaseInOut",opacity:1,rotation:data.angle })
        // tl.play()
    }
    MoveFInished(data) {
        this.props.XY({ index: this.props.index, text: this.props.text, x: data.x, y: data.y })
    }
    componentWillUnmount() {
        this.image.removeEventListener("load", this.handleLoad);
        // this.props.UpdateCard({x:this.state.x,y:this.state.y})
    }
    loadImage(img) {
        this.image = new window.Image();
        this.image.src = img;
        this.image.addEventListener("load", this.handleLoad);
    }

    handleLoad = () => {
        this.setState({
            image: this.image,
        });
    };
    CardPullUP(e) {
        console.log(e)
        // selectCardToBeRemove={this.selectCardToBeRemove} selectCardToBeUnRemove={this.selectCardToBeUnRemove}
        const { selectCardToBeRemove, selectCardToBeUnRemove } = this.props;
        // console.log(this.props.text)
        // console.log(this.state.pulldown1)
        this.setState({ pulldown1: !this.state.pulldown1 })
        // console.log(this.state.pulldown1)
        if (this.state.pulldown1) {
            console.log('...........')
            this.imageNode.to({ y: this.props.y - 20, opacity: 1, duration: 0.25, ease: "Linear" });
            selectCardToBeRemove(this.props.text);
            console.log(this.props.mutesounds);
            if (this.props.mutesounds) {
                this.selectCard.pause()
            } else {
                this.selectCard.play()
            }

        } else {
            console.log('...........................................')
            this.imageNode.to({ y: this.props.y, opacity: 1, duration: 0.25, ease: "Linear" });
            selectCardToBeUnRemove(this.props.text);
            if (this.props.mutesounds) {
                this.unselectCard.pause();
            } else {
                this.unselectCard.play();
            }
        }

    }

    unselectPokerCards() {
        this.imageNode.to({ y: this.props.y, opacity: 1, duration: 0.25, ease: "Linear" });
        this.setState({ pulldown1:false })
        // selectCardToBeUnRemove(this.props.text);
        if (this.props.mutesounds) {
            this.unselectCard.pause();
        } else {
            this.unselectCard.play();
        }

    }


    resizecard() {

    }

    endGame = () => {

        if (this.cardContext) {
            this.cardContext.revert();
        }

    }

    render() {
        console.log("playerSpriteX", this.cardDropPositionX, "playerSpriteY", this.cardDropPositionY)
        return (
            <React.Fragment>
                <Group>
                    <Sprite
                        // x={this.width - 26}
                        // x={100}
                        // y={-400}
                        // x={this.cardDropPositionX}
                        // y={this.cardDropPositionY}
                        // y={-200}
                        // x={0}
                        // y={50}
                        // x={this.props.x}
                        // y={this.props.y}
                        ref={(node) => {
                            this.imageNode = node;
                        }}
                        image={this.state.image}
                        // scaleX={this.props.scale}
                        // scaleY={this.props.scale}
                        scaleX={0.15}
                        scaleY={0.15}
                        animations={{
                            cardFrame: this.props.frame,
                        }}
                        animation={"cardFrame"}
                        frameRate={1}
                        frameIndex={0}

                        opacity={this.props.alpha}
                        visible={false}
                        // visible={this.props.show}
                        // rotation={this.props.angled}
                        rotation={0}
                        onClick={(e) => { this.CardPullUP(e) }}
                        onTap={(e) => { this.CardPullUP(e) }}
                    ></Sprite>
                </Group>
            </React.Fragment>
        );
    }
}
